import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Skeleton,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { FC, useEffect, useState } from 'react';
import { useTags } from '@/components/Tags/useTags';
import { Tag } from '@/components/Tags/Tag';
import { TagsWrapper } from '@/components/Tags/Tags.styled';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { AddTagPopup } from '@/components/Tags/AddTagPopup';
import { TagsResponse } from '@/dto/TagsDto';
import { BoxShadow } from '../ui/BoxShadow';
import { TagsList } from '@/components/Tags/TagsList';

interface ITags {
  taskId: string;
}

export const Tags: FC<ITags> = ({ taskId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  return (
    <BoxShadow p={0}>
      <Accordion
        elevation={0}
        expanded={isOpen}
        onChange={(event: React.SyntheticEvent, isExpanded: boolean) => {
          setIsOpen(isExpanded);
          setIsFirst(false);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>Теги</Typography>
        </AccordionSummary>
        <AccordionDetails>{!isFirst && <TagsList taskId={taskId} />}</AccordionDetails>
      </Accordion>
    </BoxShadow>
  );
};
