import React, { useEffect, useMemo, useState } from 'react';
import { useForm, Controller, FieldValues, FormProvider } from 'react-hook-form';
import {
  TextField,
  Button,
  Box,
  Grid,
  Autocomplete,
  InputAdornment,
  Typography,
} from '@mui/material';
import { DateRange } from '@/components/inputs/DateRange';
import { useCatalog } from '@/hooks/CatalogHook';
import {
  createComplaintOdpu,
  editComplaintOdpu,
} from '@/services/ComplaintService/ComplaintService';
import { color } from '@/styles/mixins';
import { useComplaintStore } from '../store';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import SearchIcon from '@mui/icons-material/Search';
import useDebounce from '@/components/maps/zoningMap/hook/useDebounce';
import shallow from 'zustand/shallow';
import { findOdpuVruInput } from '@/services/TaskService';
import { Spin } from 'antd';
import { ComplaintFormODPUDatePickerFixWrapper } from './ComplaintFormODPU.styled';
import { useComplainFormEnergyValidation } from '../forms';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import FileInput from '@/components/inputs/FileInput/FileInput';
import { DEFAULT_ACCEPTED_EXTENSIONS_COMPLAINTS } from '../utils';
import { MainText } from '../ComplaintAddButton/ComplaintAddButton.styled';
import { useFormErrorToScrollUp } from '@/hooks/useFormErrorToScrollUp';

const ComplaintFormODPU = ({ closeModal, handleSearch }: any) => {
  const { addNewComplaint, setSelectedCompliant } = useComplaintStore(
    (state) => ({
      addNewComplaint: state.addNewComplaint,
      setSelectedCompliant: state.setSelectedCompliant,
    }),
    shallow
  );
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  const validationRules = useComplainFormEnergyValidation();

  const catalog = [...getCatalogMapWithErr('claimType')].map(([key, value]) => ({
    label: value,
    value: key,
  }));

  const [vruInput, setVruInput] = useState<any>(null);
  const [IsInputDisable, setIsInputDisable] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debounceSearchTerm = useDebounce(searchTerm, 500);
  const onSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  const [plannedDate, setOdpuPlannedDate] = React.useState<Date | null | Date[] | number | string>(
    []
  );

  useEffect(() => {
    if (searchTerm?.trim()) {
      setIsInputDisable(true);
      findOdpuVruInput(debounceSearchTerm?.trim())
        .then(({ data }) => {
          if (data) {
            setVruInput(data);
          }
        })

        .catch((error) => {
          fetchCatch(error);
          setVruInput(null);
        })
        .finally(() => {
          setIsInputDisable(false);
        });
    } else {
      setVruInput(null);
    }
  }, [debounceSearchTerm]);

  const { selectedCompliant } = useComplaintStore(
    (state) => ({
      selectedCompliant: state.selectedCompliant,
    }),
    shallow
  );

  const { fetchCatch, addActionLog } = useActionLog();
  const { setIsInProgress, isInProgress } = useScreenHoldHook();
  const methods = useForm();

  const {
    register,
    setValue,
    control,
    handleSubmit,
    setValue: setFileValue,
    getValues,
    formState: { errors },
  } = methods;
  const onSubmit = (data: FieldValues) => {
    const files = getValues('files');
    data.inputId = vruInput.id;
    data.reasonTaskId = vruInput.reasonTaskId;
    data.address = vruInput.address;
    data.inputName = vruInput.inputName;
    data.meterNumber = vruInput.puNumber;
    data.plannedDate = moment(data?.plannedDate).format('YYYY-MM-DD');
    const formData = new FormData();
    files?.forEach((file: any) => {
      formData.append('files', file);
    });
    formData.append('request', new Blob([JSON.stringify(data)], { type: 'application/json' }));

    if (!selectedCompliant) {
      setIsInProgress(true);
      createComplaintOdpu(formData)
        .then(({ data }) => {
          addActionLog(ActionLogType.SUCCESS, 'Успешно');
          closeModal();
          handleSearch();
          addNewComplaint(data);
        })
        .catch((error) => {
          fetchCatch(error);
        })
        .finally(() => {
          setIsInProgress(false);
        });
    } else {
      setIsInProgress(true);
      editComplaintOdpu(data, selectedCompliant.complaint.base.id)
        .then(({ data }) => {
          setSelectedCompliant(data);
          addActionLog(ActionLogType.SUCCESS, 'Успешно');
          handleSearch();
          closeModal();
        })
        .catch((error) => {
          fetchCatch(error);
        })
        .finally(() => {
          setIsInProgress(false);
        });
    }
  };

  useEffect(() => {
    register('plannedDate');
  }, [register]);

  const onHandleScheduledDate = (newValue: number[] | null) => {
    if (newValue && newValue[0]) {
      setOdpuPlannedDate(newValue[0]);
      setValue('plannedDate', newValue[0]);
    }
  };
  useEffect(() => {
    if (selectedCompliant?.complaint?.plannedDate) {
      setValue('plannedDate', selectedCompliant.complaint?.plannedDate);
      setOdpuPlannedDate(selectedCompliant?.complaint?.plannedDate);
    }
    if (selectedCompliant?.inputId) {
      setSearchTerm(selectedCompliant?.inputId);
    }
    setValue('odpuType', selectedCompliant?.odpuType);
  }, [selectedCompliant]);

  useFormErrorToScrollUp(Object.keys(errors));

  return (
    <Box>
      {selectedCompliant && <MainText>Для изменения жалобы внесите необходимые данные</MainText>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          mt={1}
        >
          <Grid
            xs={12}
            md={12}
            item
          >
            <Box>
              <TextField
                fullWidth
                value={searchTerm}
                onChange={onSearchTermChange}
                label='Внесите №ПУ или id Ввода'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      {IsInputDisable ? <Spin /> : <SearchIcon color='primary' />}
                    </InputAdornment>
                  ),
                }}
                variant='outlined'
              />
              {vruInput && (
                <Box marginTop={3}>
                  <Typography
                    variant='subtitle2'
                    color={color('trueBlack')}
                  >
                    {vruInput.address?.readOnlyAddress}
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    color={color('trueBlack')}
                  >
                    {vruInput.inputName}
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    color={color('trueBlack')}
                  >
                    № ПУ {vruInput.puNumber}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <Controller
              name='odpuType'
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field, fieldState }) => (
                <Autocomplete
                  id='odpuType'
                  fullWidth
                  options={catalog}
                  value={{
                    value: field?.value ?? '',
                    label:
                      catalog.find(
                        (el) =>
                          el.value === field?.value ||
                          el.value === selectedCompliant?.odpuType?.toString()
                      )?.label || '',
                  }}
                  onChange={(event: any, newValue: any) => {
                    field?.onChange(newValue?.value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      name='odpuType'
                      {...params}
                      label={'Тип жалобы'}
                      variant='standard'
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <ComplaintFormODPUDatePickerFixWrapper
            item
            xs={12}
            md={6}
          >
            <Controller
              name='plannedDate'
              control={control}
              rules={validationRules.plannedDate}
              render={({ field, fieldState }) => (
                <DateRange
                  id='plannedDate'
                  label='Плановая дата'
                  onChange={onHandleScheduledDate}
                  value={[plannedDate]}
                  argValue={2}
                  showSelect={false}
                  showCalenderIcon={true}
                  style={{
                    width: '100%',
                    borderBottomColor: fieldState.error ? 'red' : '',
                  }}
                  showClearButton={false}
                  errorText={fieldState.error?.message}
                />
              )}
            />
          </ComplaintFormODPUDatePickerFixWrapper>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Controller
              name='letterDetails'
              control={control}
              defaultValue={selectedCompliant?.letterDetails}
              rules={validationRules.letterDetails}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id='letterDetails'
                  fullWidth
                  label='Реквизиты письма'
                  variant='standard'
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <Controller
              name='responseDetails'
              control={control}
              defaultValue={selectedCompliant?.responseDetails}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id='responseDetails'
                  fullWidth
                  label='Реквизиты ответа'
                  variant='standard'
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
          >
            <Controller
              name='text'
              control={control}
              defaultValue={selectedCompliant?.complaint.text}
              rules={validationRules.text}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id='text'
                  fullWidth
                  label='Текст обращения'
                  multiline
                  rows={4}
                  variant='standard'
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
          >
            <Grid
              item
              xs={4}
              display={'flex'}
            >
              <FormProvider {...methods}>
                <FileInput
                  register={register('files')}
                  acceptedExtensions={DEFAULT_ACCEPTED_EXTENSIONS_COMPLAINTS}
                  setFormFiles={setFileValue}
                  disableEdit={!vruInput || isInProgress}
                />
              </FormProvider>
            </Grid>
          </Grid>

          <Grid
            item
            xs={6}
          >
            <LoadingButton
              variant='text'
              color='primary'
              type='submit'
              fullWidth
              loading={isInProgress}
              disabled={!vruInput || isInProgress}
            >
              Сохранить
            </LoadingButton>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Button
              variant='text'
              type='reset'
              fullWidth
              onClick={closeModal}
            >
              Отмена
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ComplaintFormODPU;
