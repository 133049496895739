import { RefObject } from 'react';
import { StatisticInvestProgramItem } from '../../types/types';
import * as d3 from 'd3';
import { colorsSegment } from '../utils';
import { StatistiSummaryResponse } from '../../api/dto/response';

export const normalizeData = (data: StatisticInvestProgramItem[], minPercentage = 5) => {
  const total = data.reduce((sum, item) => sum + item.count, 0);
  const minValue = (minPercentage / 100) * total;

  const updatedData = data.map((item) => ({
    ...item,
    // Модифицируем значение, для учета "минимальной" ширины сегмента
    count: item.count < minValue ? minValue : item.count,
    // Используем исходное значение для отрисовки  в тултипе
    totalCount: item.count,
  }));

  const adjustedTotal = updatedData.reduce((sum, item) => sum + item.count, 0);
  const scale = total / adjustedTotal;

  // Масштабируем значения обратно к исходной сумме
  return updatedData.map((item) => ({
    ...item,
    count: Math.round(item.count * scale),
  }));
};

export const width = 870;
export const height = 870;
export const padding = 120;

export const paddingChart = (data: StatisticInvestProgramItem[]) => {
  if (data.some((item) => item.segments.some((segment) => segment > item.count))) {
    return 120;
  } else {
    return 0;
  }
};

export const createSvg = (svgRef: RefObject<SVGSVGElement>) => {
  return d3
    .select(svgRef.current)
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', `translate(${width / 2}, ${height / 2})`);
};

export const createPie = () => {
  return d3
    .pie<StatisticInvestProgramItem>()
    .value((d) => d.count)
    .padAngle(0.01)
    .sort(null);
};

export const createArc = (radius: number) => {
  return d3
    .arc<d3.PieArcDatum<StatisticInvestProgramItem>>()
    .innerRadius(radius * 0.4)
    .outerRadius(radius * 1)
    .cornerRadius(8);
};

export const TOOLTIP_POSITION_GAP = 15;

export const getMouseCordsWithGap = (event: any) => ({
  top: event.clientY + TOOLTIP_POSITION_GAP,
  left: event.clientX + TOOLTIP_POSITION_GAP,
});

export const drawSegments = (
  d: d3.PieArcDatum<StatisticInvestProgramItem>,
  svg: d3.Selection<SVGGElement, unknown, null, undefined>,
  equipmentsData: StatistiSummaryResponse[],
  i: number,
  handleMouseover: (event: MouseEvent, d: d3.PieArcDatum<StatisticInvestProgramItem>) => void,
  handleMousemove: (event: MouseEvent) => void,
  handleMouseout: (event: MouseEvent) => void,
  handleOnClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    d: d3.PieArcDatum<StatisticInvestProgramItem>
  ) => void,
  radius: number
) => {
  const offsetAngle = equipmentsData.length > 1 ? 0.008 : 0;
  const startAngle = d.startAngle + offsetAngle;
  const endAngle = d.endAngle - offsetAngle;

  const segments = d.data.segments.filter(
    (it) => d.data?.showInfo?.default && it !== d.data?.showInfo?.default[1]?.amount
  );

  segments.forEach((item, index) => {
    if (item > 0) {
      if ((item * 100) / d.data.count < 5) {
        segments[index] = d.data.count * 0.05;
      } else {
        segments[index] = item;
      }
    }
  });

  segments.forEach((element, index) => {
    const innerArc: any = d3
      .arc()
      .innerRadius(radius * (0.4 + ((segments[index] * 100) / d.data.count / 100) * 0.6))
      .outerRadius(radius * 0.4)
      .startAngle(startAngle)
      .endAngle(endAngle);

    const title = equipmentsData[i]?.title as keyof typeof colorsSegment;
    svg
      .append('path')
      .attr('d', innerArc)
      .attr('fill', colorsSegment[title][index + 1])
      .attr('stroke', '#FFFFFF')
      .attr('stroke-width', 1)
      .attr('class', () => `sector`)
      .style('cursor', 'pointer')
      .on('mouseover', (event) => handleMouseover(event, d))
      .on('mousemove', handleMousemove)
      .on('mouseout', (event) => handleMouseout(event))
      .on('click', (event) => handleOnClick(event, d));
  });
};

export const drowSectorsDoughnut = (
  arcs: d3.PieArcDatum<StatisticInvestProgramItem>[],
  svg: d3.Selection<SVGGElement, unknown, null, undefined>,
  equipmentsData: StatistiSummaryResponse[],
  handleMouseover: (event: MouseEvent, d: d3.PieArcDatum<StatisticInvestProgramItem>) => void,
  handleMousemove: (event: MouseEvent) => void,
  handleMouseout: (event: MouseEvent) => void,
  handleOnClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    d: d3.PieArcDatum<StatisticInvestProgramItem>
  ) => void,
  radius: number
) => {
  const arc = createArc(radius);

  svg
    .selectAll('path.sector')
    .data(arcs)
    .enter()
    .append('path')
    .attr('class', (d, i) => `sector sector-${i}`)
    .style('cursor', 'pointer')
    .attr('d', arc)
    .attr('fill', (d, i) => {
      const title = equipmentsData[i]?.title as keyof typeof colorsSegment;
      return colorsSegment[title][0];
    })
    .each(function (d, i) {
      drawSegments(
        d,
        svg,
        equipmentsData,
        i,
        handleMouseover,
        handleMousemove,
        handleMouseout,
        handleOnClick,
        radius
      );
    })
    .on('mouseover', (event, d) => handleMouseover(event, d))
    .on('mousemove', handleMousemove)
    .on('mouseout', (event, d) => handleMouseout(event))
    .on('click', (event, d) => handleOnClick(event, d));
};
