import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { DYNAMIC_SWITCH_LABELS, formatCount } from '../utils';
import { ChartWrapper, InvestStatisticTitle } from '../InvestStatistic.styled';
import shallow from 'zustand/shallow';
import { useInvestStatisticStore } from '../../store';
import { LineWrapper } from './DynamicStatistic.styled';
import * as d3 from 'd3';
import {
  margin,
  createScaleX,
  getAllData,
  createScaleY,
  createSvg,
  createVerticalGridLines,
  createHorizontalGridLines,
  drawLine,
  drawVerticalIntersectionLine,
  drawHorizontalIntersectionLine,
  drawIntersectionLineLabel,
  drawCircle,
  mouseOverHandler,
  mouseOutHandler,
  drawLabelLines,
  createXAxis,
  backgrounds,
  createYAxisLabels,
} from './utils';
import { BudgetStatisticTooltip } from '../BudgetStatisticTooltip/BudgetStatisticTooltip';
import { DynamicStatisticControl } from './DynamicStatisticControl';
import { GetDynamicStatistic } from '../../api/InvestStatisticService';
import { getDataFromFilter } from '@/components/inputs/MultiSelectNew/utils';
import { fetchCatch } from '@/hooks/ActionLogHook';

export const DynamicStatistic = () => {
  const {
    equipmentsData,
    activeIndex,
    selectedColumn,
    dataDynamicStatistic,
    setDataDynamicStatistic,
    selectedTimePeriod,
    selectedContract,
    dataColumns,
    checkedEnergyCompany,
    countAsMoney,
  } = useInvestStatisticStore(
    (state) => ({
      equipmentsData: state.equipmentsData,
      activeIndex: state.activeIndex,
      selectedColumn: state.selectedColumn,
      dataDynamicStatistic: state.dataDynamicStatistic,
      setDataDynamicStatistic: state.setDataDynamicStatistic,
      selectedTimePeriod: state.selectedTimePeriod,
      selectedContract: state.selectedContract,
      dataColumns: state.dataColumns,
      checkedEnergyCompany: state.checkedEnergyCompany,
      countAsMoney: state.countAsMoney,
    }),
    shallow
  );
  const equipmentType = activeIndex !== null ? equipmentsData[activeIndex].title : '';

  const [withNartis, setWithNartis] = useState(false);

  const refWrapper = useRef() as MutableRefObject<HTMLDivElement>;
  const svgRef = useRef<SVGSVGElement | null>(null);
  const yAxisRef = useRef<SVGSVGElement | null>(null);

  const [tooltip, setTooltip] = useState({
    display: 'none',
    tooltipData: null,
    top: 0,
    left: 0,
  });

  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //запрос на бэк, параметры запроса selectedTimePeriod.name
    refWrapper?.current?.scrollIntoView({ behavior: 'smooth' });
    // setDataDynamicStatistic(DATA_DYNAMIC_STATISTIC);
    const contractIds = () => {
      if (selectedContract.length) {
        if (checkedEnergyCompany) {
          return getDataFromFilter(selectedContract, 'value');
        } else {
          return [dataColumns[selectedColumn ?? 0].catalogId];
        }
      } else if (!checkedEnergyCompany) {
        return [dataColumns[selectedColumn ?? 0].catalogId];
      } else {
        return [];
      }
    };
    GetDynamicStatistic({
      energyCompanyId: checkedEnergyCompany ? dataColumns[selectedColumn ?? 0].catalogId : '',
      contractIds: contractIds(),
      equipmentType,
      periodType: selectedTimePeriod.name,
      countAsMoney,
    })
      .then(({ data }) => setDataDynamicStatistic(data))
      .catch((err) => fetchCatch(err, 'Ошибка получения данных статистики'));
  }, [selectedColumn, selectedTimePeriod]);

  const changePositionTooltip = (event: MouseEvent) => {
    const { clientX, clientY } = event;

    if (!tooltipRef.current) {
      return;
    }
    const { width: widthTooltop } = tooltipRef.current.getBoundingClientRect();
    if (clientX + widthTooltop > window.innerWidth) {
      setTooltip((prev) => ({
        ...prev,
        top: clientY,
        left: clientX - widthTooltop,
      }));
    }
  };

  // FIXME: разбить на более мелкие функции
  const drawDynamicLines = () => {
    d3.select(svgRef.current).selectAll('*').remove();
    d3.select(yAxisRef.current).selectAll('*').remove();
    if (!svgRef.current) {
      return;
    }
    const { height } = svgRef.current.getBoundingClientRect();

    const divBounds = d3.select('#dynamicStatistic');

    const divElement = divBounds.node();

    if (!(divElement instanceof Element)) {
      return;
    }

    const divRect = divElement.getBoundingClientRect();

    const allData = getAllData(dataDynamicStatistic, withNartis);

    const uniqueDates = Array.from(new Set(allData?.map((d) => d.date)));

    if (!allData.length) {
      return;
    }

    const maxCountObject = allData?.reduce((maxObj, currentObj) => {
      return currentObj.count > maxObj.count ? currentObj : maxObj;
    });

    const paddingLeft = (maxCountObject.count.toString().length + 2) * 10 + 60;

    const width =
      divRect.width > uniqueDates.length * 110 ? divRect.width : uniqueDates.length * 110;

    if (allData.length === 0) return;

    const x = createScaleX(dataDynamicStatistic, width);
    const y = createScaleY(allData, height);

    const svg = createSvg(svgRef, width, height);
    const yAxisSvg = createSvg(yAxisRef, paddingLeft, height);

    createVerticalGridLines(svg, allData, height, x);
    createHorizontalGridLines(svg, height, width, y, paddingLeft);
    createYAxisLabels(yAxisSvg, height, y, paddingLeft);

    dataDynamicStatistic
      .filter(
        (item) =>
          withNartis || item.title.toLowerCase() !== DYNAMIC_SWITCH_LABELS.NARTIS.toLowerCase()
      )
      .forEach((dataset, idx) => {
        drawLine(
          svg,
          dataset.data,
          x,
          y,
          withNartis && dataset.title.toLowerCase() === DYNAMIC_SWITCH_LABELS.NARTIS.toLowerCase()
            ? '15,15'
            : '0,0',
          idx
        );

        const verticalLine = drawVerticalIntersectionLine(svg);
        const horizontalLine = drawHorizontalIntersectionLine(svg);
        const valueLabel = drawIntersectionLineLabel(yAxisSvg, paddingLeft);

        dataset.data.forEach((d, i) => {
          const circle = drawCircle(svg, x, y, d);

          circle
            .on('mouseover', (event: MouseEvent) =>
              mouseOverHandler(
                this,
                event,
                verticalLine,
                horizontalLine,
                x,
                y,
                height,
                width,
                d,
                yAxisSvg,
                setTooltip,
                changePositionTooltip,
                paddingLeft,
                countAsMoney
              )
            )
            .on('mousemove', changePositionTooltip)
            .on('mouseout', () =>
              mouseOutHandler(this, verticalLine, horizontalLine, valueLabel, yAxisSvg, setTooltip)
            );
        });

        //задержка чтобы сначала отрисовались линии, а поверх лейблы
        setTimeout(() => drawLabelLines(dataset, x, y, svg, idx));
      });

    createXAxis(x, dataDynamicStatistic, svg, height);

    const scrollableDiv = document.getElementById('scrollableWrapper');
    if (scrollableDiv) {
      scrollableDiv.scrollLeft = scrollableDiv.scrollWidth;
    }
  };

  useEffect(() => {
    drawDynamicLines();
  }, [dataDynamicStatistic, withNartis]);

  return (
    <div
      style={{ marginTop: '70px' }}
      ref={refWrapper}
    >
      {dataDynamicStatistic && (
        <>
          <InvestStatisticTitle>В ДИНАМИКЕ</InvestStatisticTitle>
          <ChartWrapper>
            <DynamicStatisticControl
              withNartis={withNartis}
              setWithNartis={setWithNartis}
            />

            <div
              id='dynamicStatistic'
              style={{ display: 'flex' }}
            >
              <svg ref={yAxisRef}></svg>
              <LineWrapper id='scrollableWrapper'>
                <svg
                  viewBox='0 0 100 100'
                  ref={svgRef}
                  preserveAspectRatio='none'
                  width='auto'
                  height={720}
                />
                {tooltip?.tooltipData && (
                  <BudgetStatisticTooltip
                    tooltip={tooltip}
                    tooltipRef={tooltipRef}
                  />
                )}
              </LineWrapper>
            </div>
          </ChartWrapper>
        </>
      )}
    </div>
  );
};
