import { useState } from 'react';
import { IComplaintItem } from '../types';
import { useCatalog } from '@/hooks/CatalogHook';
import { Checkbox, Fade, FormControlLabel, Grid } from '@mui/material';
import { DateRange } from '@/components/inputs/DateRange';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import ButtonsEnergy from './ButtonsEnergy';
import { ComplaintFormODPUDatePickerFixWrapper } from '../ComplaintFormODPU/ComplaintFormODPU.styled';
import { color } from '@/styles/mixins';

const ResponsibleTextFields = () => {
  const [executorId, setExecutorId] = useState('');
  const [showExtraTime, setShowExtraTime] = useState(false);
  const [plannedDate, setPlannedDate] = useState<Date | null | Date[] | number | string | any>(
    null
  );
  const [consumerReadyToMeet, setConsumerReadyToMeet] = useState<
    Date | null | Date[] | number | string | any
  >(null);

  const { getGroupCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
  }));

  const handleExecutorId = (id: string) => {
    setExecutorId(id);
  };

  const onHandleScheduledDate = (newValue: number[] | null) => {
    if (newValue && newValue[0]) {
      setPlannedDate(newValue[0]);
    }
  };
  const onHandleConsumerReadyToMeetDate = (newValue: number[] | null) => {
    if (newValue && newValue[0]) {
      setConsumerReadyToMeet(newValue[0]);
    }
  };

  return (
    <>
      <ComplaintFormODPUDatePickerFixWrapper
        item
        xs={12}
        container
        spacing={2}
      >
        <DateRange
          label='Плановая дата'
          onChange={onHandleScheduledDate}
          value={[plannedDate]}
          argValue={2}
          showSelect={false}
          showCalenderIcon={true}
          showClearButton={false}
          style={{
            width: '100%',
            marginTop: 15,
            borderBottomColor: !plannedDate ? color('muiError') : '',
          }}
          labelstyle={{ color: !plannedDate ? color('muiError') : '' }}
          calendarPosition='top-start'
        />
      </ComplaintFormODPUDatePickerFixWrapper>
      <Grid
        item
        xs={12}
        mt={2}
      >
        <AutocompleteValue
          title='Исполнитель'
          fieldName={'executorId'}
          values={getGroupCatalog()}
          optionOnChangeValue={handleExecutorId}
          required
        />
      </Grid>

      <Grid
        item
        xs={12}
        mt={2}
      >
        <FormControlLabel
          control={<Checkbox onChange={(e) => setShowExtraTime(e.target.checked)} />}
          label='Потребитель не готов встретиться'
        />
      </Grid>
      <Fade in={showExtraTime}>
        <ComplaintFormODPUDatePickerFixWrapper
          item
          xs={12}
          mt={2}
        >
          <DateRange
            label='Необходимо связаться с абонентом'
            onChange={onHandleConsumerReadyToMeetDate}
            value={[consumerReadyToMeet]}
            argValue={2}
            showSelect={false}
            showCalenderIcon={true}
            style={{ width: '100%', marginTop: 7 }}
            showClearButton={false}
          />
        </ComplaintFormODPUDatePickerFixWrapper>
      </Fade>

      <ButtonsEnergy
        responsibleId={executorId}
        executorId={executorId}
        plannedDate={plannedDate && new Date(plannedDate)?.toISOString()}
        consumerReadyToMeet={consumerReadyToMeet && new Date(consumerReadyToMeet)?.toISOString()}
      />
    </>
  );
};

export default ResponsibleTextFields;
