import { MeterChangeReworkRow } from '@/components/sections/MeterChangeRework/MeterChangeRework.styled';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { color } from '@/styles/mixins';
import { FormControlLabel, IconButton, styled, Typography } from '@mui/material';
import { Tooltip } from '../types/types';

export const ChartWrapper = styled(BoxShadow)({
  padding: '27px',
  paddingBottom: '15px',
  marginTop: '5px',
  marginBottom: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
});

export const DoughnutWrapper = styled('div')({
  position: 'relative',
  width: '900px',
  height: '850px',
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
});

export const BarWrapper = styled('div')({
  height: '420px',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  overflowX: 'auto',
});

export const ButtonClose = styled(IconButton)({
  marginLeft: 'auto',
});

export const WrapperFilters = styled(MeterChangeReworkRow)({
  display: 'flex',
  // justifyContent: 'space-between',
  width: '100%',
  paddingRight: 37,
});

export const BudgetStatisticBarWrapper = styled(BoxShadow)({
  padding: '25px',
  marginTop: '10px',
  marginBottom: '5px',
});

interface ShowInfoControllLabelProps {
  active: boolean;
}

export const ShowInfoControllLabel = styled(FormControlLabel)<ShowInfoControllLabelProps>(
  ({ active }) => ({
    marginLeft: '10px',

    '.MuiFormControlLabel-label': {
      userSelect: 'none',
      textTransform: 'uppercase',
      fontSize: 14,
      color: active ? '#1976D2' : color('grayLabel'),
      fontWeight: 500,
    },
  })
);

interface BarShowInfoProps {
  width: number;
  background: string;
}

interface TitleShowInfoProps {
  width: number;
}

interface LabelSwitchProps {
  checked: boolean;
}

export const ShowInfoItem = styled('div')({
  display: 'flex',
  gap: '5px',
  height: '17px',
});

export const TitleShowInfo = styled('div')<TitleShowInfoProps>(({ width }) => ({
  justifyContent: 'end',
  alignItems: 'center',
  display: 'flex',
  width: `${width}px`,
  fontWeight: 500,
  textTransform: 'uppercase',
}));

export const BarShowInfo = styled('div')<BarShowInfoProps>(({ width, background }) => ({
  width: `${width}px`,
  background: background,
}));

export const WrapperSwitch = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const LabelSwitch = styled(Typography)<LabelSwitchProps>(({ checked }) => ({
  textTransform: 'uppercase',
  fontSize: 14,
  color: checked ? '#1976D2' : color('grayLabel'),
  fontWeight: 500,
}));

export const ShowInfoCount = styled('div')({
  display: 'flex',
  gap: '20px',
  alignItems: 'center',
});

export const InvestStatisticTitle = styled('p')({
  fontFamily: 'Roboto',
  fontSize: 20,
  fontWeight: 500,
  marginBottom: 0,
});

interface TooltipProps {
  tooltip: Tooltip;
}

export const TooltipWrapper = styled('div')<TooltipProps>(({ tooltip }) => ({
  visibility: tooltip.display === 'block' ? 'visible' : 'hidden',
  opacity: tooltip.display === 'block' ? 1 : 0,
  position: 'fixed',
  top: tooltip.top,
  left: tooltip.left,
  transition: 'opacity 0.3s ease',
  pointerEvents: 'none',
  borderRadius: '10px',
  border: '1px solid #2C3E50',
}));
