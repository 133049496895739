import { useActionLog } from '@/hooks/ActionLogHook';
import { useEffect, useState } from 'react';
import { useNavbar } from '../store';
import { IconButton, List, ListItem, Popover, Stack, Tooltip } from '@mui/material';
import { CustomTab, CustomTabs, CustomeBage } from './EventsLogAction.styled';
import FrontendVersion from '../../FrontendVersion/FrontendVersion';
import { ActionLogItem } from '../ActionLogItem/ActionLogItem';
import { color } from '@/styles/mixins';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import moment from 'moment';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';
import { TextActionLogMain } from '../NavBar.styled';

enum TabsEnum {
  ACTION_LOG = 'actionLog',
  PRESON_INFO = 'person',
}

const EventsLogAction = () => {
  const { actionLogList } = useActionLog();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [value, setValue] = useState<TabsEnum>(TabsEnum.ACTION_LOG);
  const handleChange = (event: React.SyntheticEvent, newValue: TabsEnum) => {
    setValue(newValue);
  };

  const isMobile = useMobileSize();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { openErrorsDialog, setOpenErrorsDialog } = useNavbar();

  useEffect(() => {
    if (openErrorsDialog) {
      setAnchorEl(document.querySelector('[data-notifications]') as HTMLButtonElement);
    }
    setOpenErrorsDialog(false);
  }, [openErrorsDialog]);

  return (
    <Stack>
      <Tooltip title='Журнал событий'>
        <CustomeBage
          color='error'
          data-notifications
        >
          <IconButton onClick={handleClick}>
            <NotificationsNoneIcon htmlColor={color('grayNav')} />
          </IconButton>
        </CustomeBage>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{ marginTop: '12px' }}
      >
        <Stack>
          <Stack p={2}>
            <TextActionLogMain mb={'10px'}>Уведомления</TextActionLogMain>
            <CustomTabs
              value={value}
              onChange={handleChange}
              textColor='inherit'
              indicatorColor='primary'
            >
              {/* <CustomTab
                value='person'
                label='Персональные'
                // disabled
              /> */}
              <CustomTab
                value='actionLog'
                label='Журнал событий'
              />
            </CustomTabs>
          </Stack>

          {value === TabsEnum.ACTION_LOG && (
            <Stack>
              <FrontendVersion withSessionId />
              <Stack
                style={{
                  maxHeight: isMobile ? 200 : 458,
                  width: '100%',
                  maxWidth: '701px',
                  overflow: 'auto',
                  scrollbarWidth: 'thin',
                }}
              >
                <List style={{ width: '100%' }}>
                  {[...actionLogList]
                    .sort((o1, o2) => (moment(o1.eventAt).isAfter(o2.eventAt) ? -1 : 1))
                    .map((t, index) => {
                      const isLast = index === actionLogList.length - 1;
                      return (
                        <ListItem key={t.id}>
                          <ActionLogItem
                            isLast={isLast}
                            key={t.id}
                            item={t}
                          />
                        </ListItem>
                      );
                    })}
                </List>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Popover>
    </Stack>
  );
};

export default EventsLogAction;
