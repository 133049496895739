import { styled } from '@mui/material';
import KeyboardTabRoundedIcon from '@mui/icons-material/KeyboardTabRounded';

export const NavigationWrapper = styled('div')({
  margin: '20px auto',
  width: 'calc(100vw - 30px)',
  maxWidth: 1170,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const ArrowBackIcon = styled(KeyboardTabRoundedIcon)({
  transform: 'rotate(180deg)',
});

export const ClickedText = styled('div')({
  cursor: 'pointer',
});
