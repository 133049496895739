import {
  Button,
  Typography,
  IconButton,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import {
  StorehouseAdminPuWrapper,
  StorehouseAdminModalInner,
  StorehouseAdminSaveButton,
  StorehouseAdminCommonModal,
  StorehouseAdminDeleteAttention,
} from './StorehouseAdminPu.styled';
import { TextField } from '@/components/fixUI';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { useAllPuScanFields } from '@/hooks/useQuery/useAllSupplyCatalogs';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { convertBillTypesIntoFilter } from '@/components/storehouse/SupplyBillBrowse/utils';
import { useAllPuTypes } from '@/hooks/useQuery/useAllPuTypes';
import { ConfirmationDialog } from '@/components/common/ConfirmationDialog';
import { SupplyPuTypeCreateDto, SupplyPuTypeResponse } from '@/dto/SupplyBillServiceDto';
import {
  SupplyBillApiCreatePuType,
  SupplyBillApiDeletePuType,
  SupplyBillApiEditPuType,
} from '@/services/SupplyBillService';
import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useInvalidateStorehouseTypes } from '../utils';
import { isChildType, useGeneratedYears } from '@/components/storehouse/SupplyPUComponent/utils';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';

type StorehouseAdmiActiveModal = 'create' | 'edit' | 'delete' | null;
type SelectedEdit = SelectFilterOptType & SupplyPuTypeResponse;

const StorehouseAdminPu = () => {
  const { setIsInProgress, isInProgress } = useScreenHoldHook();
  const { fetchCatch, addActionLog } = useActionLog();
  const { invalidatePuTypes } = useInvalidateStorehouseTypes();

  const { puTypesResponse } = useAllPuTypes(true);
  const puParentTypesOptions = useMemo(
    () => convertBillTypesIntoFilter(puTypesResponse ?? []),
    [puTypesResponse]
  );

  const { puTypesResponse: puParentsTypesResponse } = useAllPuTypes();
  const puAllTypesOptions = useMemo(
    () => convertBillTypesIntoFilter(puParentsTypesResponse ?? []),
    [puParentsTypesResponse]
  );
  const { scanFieldsOptions } = useAllPuScanFields();

  const [activeModal, setActiveModal] = useState<StorehouseAdmiActiveModal>(null);
  const [name, setName] = useState('');
  const [fullName, setFullName] = useState('');
  const [comment, setComment] = useState('');
  const [manufactureYear, setManufactureYear] = useState<SelectFilterOptType | null>();
  const [regExp, setRegExp] = useState('');
  const [selectedScanField, setSelectedScanField] = useState<SelectFilterOptType>();
  const [selectedParentType, setSelectedParentType] = useState<SelectFilterOptType>();
  const [selectedEditType, setSelectedEditType] = useState<SelectedEdit | undefined>();
  const [selectedManufacturer, setSelectedManufacturer] = useState<SelectFilterOptType>();
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [isRimFlag, setIsRimFlag] = useState(false);

  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);
  const optionsManufacturer = taskMapToArray(getCatalogMapWithErr('manufacturer'));
  const yearsOptions = useGeneratedYears();

  const handleOpenModal = (type: StorehouseAdmiActiveModal) => setActiveModal(type);
  const handleCloseModal = () => {
    setActiveModal(null);
    resetState();
  };

  const resetState = () => {
    setName('');
    setFullName('');
    setComment('');
    setManufactureYear(undefined);
    setRegExp('');
    setSelectedScanField(undefined);
    setSelectedParentType(undefined);
    setSelectedEditType(undefined);
    setDeleteAlertOpen(false);
    setIsRimFlag(false);
    setSelectedManufacturer(undefined);
  };

  const handleSave = (saveType: 'create' | 'edit') => {
    if (!selectedScanField?.value) return;

    setIsInProgress(true);
    const puType: SupplyPuTypeCreateDto = {
      id: selectedEditType?.id ?? null,
      name: name,
      fullName: fullName,
      comment: comment,
      manufactureYear: manufactureYear ? manufactureYear?.value : null,
      puNumRegexExpr: null,
      parentId: selectedParentType ? parseInt(selectedParentType?.value) : null,
      meterTypeScanFieldValue: selectedScanField?.value,
      isRim: isRimFlag,
      yodaManufactureId: selectedManufacturer ? selectedManufacturer?.value : null,
    };
    let apiCall = SupplyBillApiCreatePuType;

    if (saveType === 'edit') {
      apiCall = SupplyBillApiEditPuType;
    }

    apiCall(puType)
      .then(() =>
        addActionLog(
          ActionLogType.SUCCESS,
          `Тип ПУ ${name} успешно ${saveType === 'create' ? 'создан' : 'отредактирован'}`
        )
      )
      .catch((err) =>
        fetchCatch(err, `Ошибка ${saveType === 'create' ? 'создания' : 'редактирования'} типа ПУ`)
      )
      .finally(() => {
        invalidatePuTypes();
        setIsInProgress(false);
        handleCloseModal();
      });
  };

  const handleDelete = () => {
    if (!selectedEditType?.id) return;

    setIsInProgress(true);

    SupplyBillApiDeletePuType(selectedEditType?.id)
      .then(() => addActionLog(ActionLogType.SUCCESS, `Тип ПУ ${name} успешно удален`))
      .catch((err) => fetchCatch(err, 'Ошибка удаления типа ПУ'))
      .finally(() => {
        setIsInProgress(false);
        handleCloseModal();
        setDeleteAlertOpen(false);
        invalidatePuTypes();
      });
  };

  const handleOpenDeleteAlert = () => {
    setDeleteAlertOpen(true);
  };

  const handleSelectEditType = (type: SelectedEdit) => {
    setSelectedEditType(type);

    setName(type.name);
    setFullName(type.fullName);
    setComment(type.comment);

    const manufacturerYearOption = yearsOptions?.find(
      (option) => option?.value === type?.manufactureYear
    );
    if (manufacturerYearOption) {
      setManufactureYear(manufacturerYearOption);
    } else {
      setManufactureYear(undefined);
    }

    setRegExp(type?.puNumRegexExpr ?? '');
    setIsRimFlag(type?.isRim);

    const scanFieldOption = scanFieldsOptions?.find(
      (option) => option?.value === type?.meterTypeScanField
    );
    if (scanFieldOption) {
      setSelectedScanField(scanFieldOption);
    } else {
      setSelectedScanField(undefined);
    }

    const manufacturerOption = optionsManufacturer?.find(
      (option) => option?.value === type?.yodaManufactureId
    );
    if (scanFieldOption) {
      setSelectedManufacturer(manufacturerOption);
    } else {
      setSelectedManufacturer(undefined);
    }

    const parentTypeOption = puParentTypesOptions?.find(
      (option) => parseInt(option?.value) === type?.parentId
    );

    if (parentTypeOption) {
      setSelectedParentType(parentTypeOption);
    } else {
      setSelectedParentType(undefined);
    }
  };

  const childType = useMemo(() => isChildType(name), [name]);

  useEffect(() => {
    if (!childType) {
      setSelectedParentType(undefined);
    }
  }, [childType]);

  const disableCreate =
    !name?.length ||
    !fullName?.length ||
    !selectedScanField ||
    (childType && !selectedParentType) ||
    (!childType && !selectedManufacturer);

  return (
    <>
      <StorehouseAdminPuWrapper>
        <Typography
          variant='h6'
          align='center'
        >
          Типы ПУ
        </Typography>
        <Button
          variant='contained'
          onClick={() => handleOpenModal('create')}
        >
          Создание
        </Button>
        <Button
          variant='contained'
          onClick={() => handleOpenModal('edit')}
        >
          Редактирование
        </Button>
        <Button
          variant='contained'
          color='error'
          onClick={() => handleOpenModal('delete')}
          disabled
        >
          Удаление
        </Button>
      </StorehouseAdminPuWrapper>

      <StorehouseAdminCommonModal
        open={!!activeModal}
        onClose={handleCloseModal}
      >
        <StorehouseAdminModalInner>
          {activeModal === 'create' && (
            <>
              <Typography
                variant='h6'
                align='center'
              >
                Создание типа прибора учета
              </Typography>

              <TextField
                value={name}
                label={'Наименование'}
                onChange={(e) => setName(e.target.value)}
                variant='standard'
                inputProps={{ maxLength: 200 }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size={'small'}
                      onClick={() => setName('')}
                    >
                      <ClearRoundedIcon fontSize={'small'} />
                    </IconButton>
                  ),
                }}
              />
              <TextField
                value={fullName}
                label={'Полное наименование'}
                onChange={(e) => setFullName(e.target.value)}
                variant='standard'
                inputProps={{ maxLength: 200 }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size={'small'}
                      onClick={() => setFullName('')}
                    >
                      <ClearRoundedIcon fontSize={'small'} />
                    </IconButton>
                  ),
                }}
              />
              <TextField
                value={comment}
                label={'Комментарий'}
                onChange={(e) => setComment(e.target.value)}
                variant='standard'
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size={'small'}
                      onClick={() => setComment('')}
                    >
                      <ClearRoundedIcon fontSize={'small'} />
                    </IconButton>
                  ),
                }}
              />
              <Autocomplete
                options={yearsOptions ?? []}
                value={manufactureYear}
                onChange={(event: any, newValue: any) => setManufactureYear(newValue)}
                renderInput={(params) => (
                  <TextField
                    name='selectedScanField'
                    {...params}
                    label={'Год выпуска'}
                    variant='standard'
                  />
                )}
              />

              <TextField
                disabled
                value={regExp}
                label={'Регулярное выражение'}
                onChange={(e) => setRegExp(e.target.value)}
                variant='standard'
              />

              <Autocomplete
                options={scanFieldsOptions ?? []}
                value={selectedScanField}
                onChange={(event: any, newValue: any) => setSelectedScanField(newValue)}
                renderInput={(params) => (
                  <TextField
                    name='selectedScanField'
                    {...params}
                    label={'Сканируемое поле'}
                    variant='standard'
                  />
                )}
              />

              {childType && (
                <Autocomplete
                  options={puParentTypesOptions ?? []}
                  value={selectedParentType}
                  onChange={(event: any, newValue: any) => setSelectedParentType(newValue)}
                  renderInput={(params) => (
                    <TextField
                      name='selectedParentType'
                      {...params}
                      label={'Родительский тип'}
                      variant='standard'
                    />
                  )}
                />
              )}

              {!childType && (
                <Autocomplete
                  options={optionsManufacturer ?? []}
                  value={selectedManufacturer}
                  onChange={(event: any, newValue: any) => setSelectedManufacturer(newValue)}
                  renderInput={(params) => (
                    <TextField
                      name='selectedManufacturer'
                      {...params}
                      label={'Производитель'}
                      variant='standard'
                    />
                  )}
                />
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={isRimFlag}
                    onChange={(event, checked) => setIsRimFlag(checked)}
                  />
                }
                label='Двойной номер для сканирования (РиМ)'
              />

              <StorehouseAdminSaveButton
                variant='contained'
                onClick={() => handleSave('create')}
                loading={isInProgress}
                disabled={disableCreate}
              >
                Создать
              </StorehouseAdminSaveButton>
            </>
          )}
          {activeModal === 'edit' && (
            <>
              <Typography
                variant='h6'
                align='center'
              >
                Редактирование типа прибора учета
              </Typography>

              <Autocomplete
                options={puAllTypesOptions ?? []}
                value={selectedEditType}
                onChange={(event: any, newValue: any) => handleSelectEditType(newValue)}
                renderInput={(params) => (
                  <TextField
                    name='setSelectedEditType'
                    {...params}
                    label={'Тип прибора учета'}
                    variant='standard'
                  />
                )}
              />

              {selectedEditType && (
                <>
                  <TextField
                    value={name}
                    label={'Наименование'}
                    onChange={(e) => setName(e.target.value)}
                    variant='standard'
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          size={'small'}
                          onClick={() => setName('')}
                        >
                          <ClearRoundedIcon fontSize={'small'} />
                        </IconButton>
                      ),
                    }}
                  />
                  <TextField
                    value={fullName}
                    label={'Полное наименование'}
                    onChange={(e) => setFullName(e.target.value)}
                    variant='standard'
                    inputProps={{ maxLength: 200 }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          size={'small'}
                          onClick={() => setFullName('')}
                        >
                          <ClearRoundedIcon fontSize={'small'} />
                        </IconButton>
                      ),
                    }}
                  />
                  <TextField
                    value={comment}
                    label={'Комментарий'}
                    onChange={(e) => setComment(e.target.value)}
                    variant='standard'
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          size={'small'}
                          onClick={() => setComment('')}
                        >
                          <ClearRoundedIcon fontSize={'small'} />
                        </IconButton>
                      ),
                    }}
                  />
                  <Autocomplete
                    options={yearsOptions ?? []}
                    value={manufactureYear}
                    onChange={(event: any, newValue: any) => setManufactureYear(newValue)}
                    renderInput={(params) => (
                      <TextField
                        name='selectedScanField'
                        {...params}
                        label={'Год выпуска'}
                        variant='standard'
                      />
                    )}
                  />
                  <TextField
                    disabled
                    value={regExp}
                    label={'Регулярное выражение'}
                    onChange={(e) => setRegExp(e.target.value)}
                    variant='standard'
                  />

                  <Autocomplete
                    options={scanFieldsOptions ?? []}
                    value={selectedScanField}
                    onChange={(event: any, newValue: any) => setSelectedScanField(newValue)}
                    disabled
                    renderInput={(params) => (
                      <TextField
                        name='selectedScanField'
                        {...params}
                        label={'Сканируемое поле'}
                        variant='standard'
                      />
                    )}
                  />

                  {childType && (
                    <Autocomplete
                      options={puParentTypesOptions ?? []}
                      value={selectedParentType}
                      onChange={(event: any, newValue: any) => setSelectedParentType(newValue)}
                      getOptionDisabled={(option) =>
                        (option as SelectFilterOptType).value === selectedEditType.value
                      }
                      renderInput={(params) => (
                        <TextField
                          name='selectedParentType'
                          {...params}
                          label={'Родительский тип'}
                          variant='standard'
                        />
                      )}
                    />
                  )}
                  {!childType && (
                    <Autocomplete
                      options={optionsManufacturer ?? []}
                      value={selectedManufacturer}
                      onChange={(event: any, newValue: any) => setSelectedManufacturer(newValue)}
                      renderInput={(params) => (
                        <TextField
                          name='selectedManufacturer'
                          {...params}
                          label={'Производитель'}
                          variant='standard'
                        />
                      )}
                    />
                  )}

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isRimFlag}
                        onChange={(event, checked) => setIsRimFlag(checked)}
                      />
                    }
                    label='Двойной номер для сканирования (РиМ)'
                  />

                  <StorehouseAdminSaveButton
                    variant='contained'
                    onClick={() => handleSave('edit')}
                    loading={isInProgress}
                    disabled={disableCreate}
                  >
                    Сохранить
                  </StorehouseAdminSaveButton>
                </>
              )}
            </>
          )}
          {activeModal === 'delete' && (
            <>
              <Typography
                variant='h6'
                align='center'
              >
                Удаление типа прибора учета
              </Typography>
              <Autocomplete
                options={puAllTypesOptions ?? []}
                value={selectedEditType}
                onChange={(event: any, newValue: any) => setSelectedEditType(newValue)}
                renderInput={(params) => (
                  <TextField
                    name='setSelectedEditType'
                    {...params}
                    label={'Тип прибора учета'}
                    variant='standard'
                  />
                )}
              />

              {selectedEditType && (
                <StorehouseAdminSaveButton
                  variant='contained'
                  onClick={handleOpenDeleteAlert}
                >
                  Удалить
                </StorehouseAdminSaveButton>
              )}

              <ConfirmationDialog
                title={`Внимание!`}
                handleOk={() => handleDelete()}
                handleCancel={() => setDeleteAlertOpen(false)}
                open={deleteAlertOpen}
                textForOk='Всё равно удалить'
                textForCancel='Отмена'
              >
                <Typography variant='body1'>
                  Вы намереваетесь{' '}
                  <StorehouseAdminDeleteAttention>удалить</StorehouseAdminDeleteAttention> тип ПУ:{' '}
                  <StorehouseAdminDeleteAttention>
                    {selectedEditType?.name}
                  </StorehouseAdminDeleteAttention>
                  . Отменить действие будет{' '}
                  <StorehouseAdminDeleteAttention>невозможно</StorehouseAdminDeleteAttention>!
                </Typography>

                <Typography variant='body1'>
                  Рекомендуется производить удаление только со свежесозданными типами ПУ, операции
                  над которыми еще не производились на складах
                </Typography>
              </ConfirmationDialog>
            </>
          )}
        </StorehouseAdminModalInner>
      </StorehouseAdminCommonModal>
    </>
  );
};

export default StorehouseAdminPu;
