import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@/components/fixUI';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  TextField,
  Button,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { TaskRequest, TaskResponse } from '@/dto/taskmap/task/TasksDto';
import moment from 'moment';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { useCatalog } from '@/hooks/CatalogHook';
import { FormProvider, useForm } from 'react-hook-form';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { toReturnTask } from '@/services/TaskService';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDisabledAll } from '@/hooks/useAccess';
import { useTaskEditorOnlyShow } from '../useTaskEditorOnlyShow';

interface TaskExecutorSelectProps {
  taskResponse: TaskResponse;
  openDialog: boolean;
  handleCloseDialog(): void;
  handleChangeTask(taskResponse: TaskResponse): void;
}

export const TaskExecutorSelect = (props: TaskExecutorSelectProps) => {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const { taskResponse, openDialog, handleCloseDialog, handleChangeTask } = props;
  const methods = useForm<TaskRequest>();
  const [open, setOpen] = useState(false);
  const [scheduledDate, setScheduledDate] = useState<Date>();
  const { getGroupCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
  }));
  const { setIsInProgress, isInProgress } = useScreenHoldHook();
  const { addActionLog, fetchCatch } = useActionLog();

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleClose = () => {
    setOpen(false);
    handleCloseDialog();
  };

  useEffect(() => {
    methods.setValue('scheduledDate', taskResponse?.scheduledDate);
    setScheduledDate(taskResponse?.scheduledDate);
  }, [taskResponse]);

  const onHandleScheduledDate = (newValue: Date | null) => {
    if (newValue) {
      setScheduledDate(newValue);
      methods.setValue('scheduledDate', moment(newValue).toDate());
    }
  };

  const update = (taskRequest: TaskRequest) => {
    const taskId = taskResponse?.id;
    if (taskId && taskRequest) {
      setIsInProgress(true);
      toReturnTask(taskId, taskRequest)
        .then((res) => {
          handleClose();
          handleChangeTask(res.data);
          addActionLog(
            ActionLogType.SUCCESS,
            `Заявка № ${taskResponse?.number} успешно возвращена`
          );
        })
        .catch((err) => fetchCatch(err, 'Ошибка при сохранении данных'))
        .finally(() => setIsInProgress(false));
    } else {
      throw new Error('Ошибка при сохранении заявки, данные некорректны');
    }
  };

  return (
    <FormProvider {...methods}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='executor-select-dialog-title'
        aria-describedby='executor-select-dialog-description'
      >
        <DialogTitle id='executor-select-dialog-title'>
          Назначить плановую дату и бригаду исполнителя
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='executor-select-dialog-description' />
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={7}
            >
              <AutocompleteValue
                title='Бригада исполнителя'
                defaultValue={taskResponse?.executorId}
                fieldName={'executorId'}
                values={getGroupCatalog()}
              />
            </Grid>
            <Grid
              item
              xs={5}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label='Дата выполнения'
                  inputFormat='dd.MM.yyyy'
                  value={scheduledDate}
                  onChange={onHandleScheduledDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='standard'
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <LoadingButton
            disabled={disabledAll || onlyShow}
            loading={isInProgress}
            onClick={methods?.handleSubmit(update)}
            autoFocus
          >
            Ok
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};
