import React, { useEffect, useState } from 'react';
import { IconButton, Badge, Menu, Tooltip } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { useAnchorElement } from '@/hooks/useAnchorElement';
import AddToSaveButtonPopup, {
  IAddToSaveButtonPopup,
} from '@/components/popups/AddToSaveButtonPopup/AddToSaveButtonPopup';
import { ExpandButton } from '@/components/button/ExpandButton/ExpandButton';
import SaveButtonMenuItem from './SaveButtonMenuItem';
import { useStoredFilters } from './store';
import { SaveButtonSaveWrapper } from './SaveButton.styled';

interface SaveButtonProps extends IAddToSaveButtonPopup {
  tooltipText?: string;
}

const SaveButton = ({
  saveFilter,
  tooltipText = 'Сохранить текущий набор фильтров',
}: SaveButtonProps) => {
  const { anchorEl, handleOpen, handleClose, open } = useAnchorElement();
  const { allFilters } = useStoredFilters();

  const [isExpaned, setIsExpaned] = useState(false);
  const [showSaveButtonAddPopup, setShowSaveButtonAddPopup] = useState(false);

  useEffect(() => {
    if (!allFilters[saveFilter.filterType]?.length) closeMenu();
  }, [allFilters[saveFilter.filterType]?.length]);

  const flipExpand = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    handleOpen(event);
    setIsExpaned((prev) => !prev);
  };

  const closeMenu = (clickedValue?: string) => {
    if (clickedValue) {
      saveFilter?.selectCallback(clickedValue);
    }
    setIsExpaned(false);
    handleClose();
  };

  const onCloseShowAllPopup = () => setShowSaveButtonAddPopup(false);
  const onOpenSavePopup = () => setShowSaveButtonAddPopup(true);

  return (
    <>
      <SaveButtonSaveWrapper empty={!allFilters[saveFilter?.filterType]?.length}>
        <Badge
          badgeContent={
            <IconButton
              onClick={flipExpand}
              disabled={!allFilters[saveFilter?.filterType]?.length}
            >
              <Badge
                badgeContent={allFilters[saveFilter?.filterType]?.length}
                max={10}
                color='primary'
              >
                <ExpandButton
                  expand={isExpaned}
                  onAction={() => null}
                  turnOnPropagation
                />
              </Badge>
            </IconButton>
          }
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Tooltip title={tooltipText}>
            <IconButton onClick={onOpenSavePopup}>
              <SaveIcon
                color='primary'
                fontSize='large'
              />
            </IconButton>
          </Tooltip>
        </Badge>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => closeMenu()}
        >
          {allFilters[saveFilter?.filterType]?.map((saveButtonItem) => (
            <SaveButtonMenuItem
              key={saveButtonItem?.filterName}
              onClick={closeMenu}
              value={saveButtonItem?.filterName}
              saveFilter={saveFilter}
            />
          ))}
        </Menu>
      </SaveButtonSaveWrapper>
      <AddToSaveButtonPopup
        isOpen={showSaveButtonAddPopup}
        onClose={onCloseShowAllPopup}
        saveFilter={saveFilter}
      />
    </>
  );
};

export default SaveButton;
