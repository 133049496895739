import React from 'react';
import { Placemark, withYMaps } from 'react-yandex-maps';
import { PpoPlacemarkBalloon } from './TransStationBalloon';
import { GeoTask } from '@/dto/taskmap/Dto';
import { usePpoMap } from '../../store';
import { findAllDeliveryPoint } from '../../service';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { useActionLog } from '@/hooks/ActionLogHook';
import { useDeliveryPoints } from '@/components/maps/ppoMap/placemark/delivery-point/store';
import { usePillar } from '../../resCard/pillarStore';
import { transStationPointLayout } from '@/components/maps/ppoMap/placemark/trans-station/CastomPlacemark/CastomPlacemark';

interface TransStationPlacemarkProps {
  geoTask: GeoTask;
}
const roundStr = (str: string): string => (str?.length > 10 ? `${str?.slice(0, 10)}...` : str);
export const TransStationPlacemark = (props: TransStationPlacemarkProps) => {
  const { geoTask } = props;
  const { setGeoTransStation, setTmpTaskId } = usePpoMap();
  const { setIsInProgress } = useScreenHoldHook();
  const { fetchCatch } = useActionLog();
  const { clearTmpDeliveryPoint } = useDeliveryPoints();
  const { clearInfoPillar } = usePillar();

  const handleShowingDeliveryPoint = () => {
    setIsInProgress(true);
    setTmpTaskId(geoTask.taskId);
    clearTmpDeliveryPoint();
    clearInfoPillar();
    findAllDeliveryPoint(geoTask.taskId)
      .then(({ data }) => setGeoTransStation(data))
      .catch((err) => fetchCatch(err, 'Ошибка при загрузке точек поставки'))
      .finally(() => setIsInProgress(false));
  };

  const UserPlacemarkCore = React.memo(function Y({ ymaps }: any) {
    const makeLayout = (layoutFactory: any) => {
      const Layout = layoutFactory.createClass(PpoPlacemarkBalloon(geoTask), {
        build: function () {
          Layout.superclass.build.call(this);

          this.element = document.querySelector('.map__placemark-balloon');
          this.element = this.element
            .querySelector('#placemark-balloon_task_id_' + geoTask.taskId)
            .addEventListener('click', this.myClick);
        },

        myClick: handleShowingDeliveryPoint,
      });
      return Layout;
    };

    return (
      <Placemark
        geometry={[geoTask.longitudeX, geoTask.latitudeY]}
        // Опции для кастомных иконок
        // options={{
        //   balloonContentLayout: makeLayout(ymaps.templateLayoutFactory),
        //   balloonPanelMaxMapArea: 0,
        //   ...transStationPointLayout({
        //     ymaps: ymaps,
        //     id: geoTask.taskId,
        //
        //     transStationNumber: geoTask?.tpName ?? '',
        //     allComplete: geoTask?.isAllComplete,
        //   }),
        // }}
        options={{
          balloonContentLayout: makeLayout(ymaps.templateLayoutFactory),
          balloonPanelMaxMapArea: 0,
          preset: geoTask?.isAllComplete ? 'islands#greenStretchyIcon' : 'islands#redStretchyIcon',
        }}
        properties={{
          iconContent: `ТП: ${roundStr(geoTask?.tpName ?? '')}`,
        }}
      />
    );
  });

  const TransStationPlacemark = React.useMemo(() => {
    return withYMaps(UserPlacemarkCore, true, ['geoObject.addon.balloon', 'templateLayoutFactory']);
  }, [UserPlacemarkCore]);
  return <TransStationPlacemark />;
};
