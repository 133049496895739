import { useState } from 'react';
import { KeyString, getKttOrKtnValue, lookUpExistingEquipment } from './utils';
import { Grid, Stack, Typography } from '@mui/material';
import { color } from '@/styles/mixins';
import { HighlightText, PresentPuWrapper } from './ExistingEquipment.styled';
import { ExistingEquipmentEnum } from '@/dto/taskmap/Dto';
import {
  PresentPu,
  PresentTransformer,
  PresentTransformerVoltage,
} from '@/dto/taskmap/task/TaskResultDto';
import moment from 'moment';
import { toFormateDate } from '@/utils/heplers';
import { ExpandButton } from '@/components/button/ExpandButton/ExpandButton';

interface PresentTransformerEquipmenOrVoltage {
  existingEquipment: {
    key: string;
    value: Partial<PresentTransformer | PresentTransformerVoltage>;
  };
}

const PresentTransformerEquipmenOrVoltage = ({
  existingEquipment,
}: PresentTransformerEquipmenOrVoltage) => {
  const [open, setOpen] = useState<boolean>(false);
  const isPresentTransformer = existingEquipment.key === ExistingEquipmentEnum.presentTransformer;
  const serialOrNumber =
    existingEquipment.key === ExistingEquipmentEnum.presentTransformerVoltage ? 'number' : 'stamp';

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      position={'relative'}
      marginY={1}
    >
      <Grid
        container
        gap={1}
        mt={1}
      >
        <Grid
          item
          mt={open ? 1 : 0}
        >
          <Typography>
            {' '}
            <HighlightText>
              {' '}
              {lookUpExistingEquipment[existingEquipment.key as KeyString]}:
            </HighlightText>
            {existingEquipment.value.type || '-'}
            <HighlightText> {isPresentTransformer ? 'КТТ' : 'КТН'} : </HighlightText>
            {getKttOrKtnValue(existingEquipment.value)}
            <ExpandButton
              onAction={() => setOpen(!open)}
              expand={open}
            />
          </Typography>
          {open && isPresentTransformer && (
            <Stack
              flexDirection={'row'}
              gap={3}
              mt={1}
              mb={1}
            >
              <Stack>
                <PresentPuWrapper>
                  <Typography>Дата поверки:</Typography>
                  <HighlightText>
                    <HighlightText>
                      {toFormateDate(existingEquipment.value.checkDate)}
                    </HighlightText>
                  </HighlightText>
                </PresentPuWrapper>
                <PresentPuWrapper>
                  <Typography> Следующая поверка:</Typography>
                  <HighlightText>
                    <HighlightText>
                      {toFormateDate(existingEquipment.value.nextCheckDate)}
                    </HighlightText>
                  </HighlightText>
                </PresentPuWrapper>
              </Stack>
            </Stack>
          )}
          {open && (
            <Stack
              flexDirection={'row'}
              gap={1}
              mt={1}
            >
              {!!existingEquipment.value?.phaseList?.length &&
                existingEquipment.value?.phaseList.map((item) => (
                  <Grid
                    mt={open ? 1 : 0}
                    item
                    key={item.phase}
                    bgcolor={open ? color('gray3') : 'inherit'}
                    borderRadius={'10px'}
                    paddingX={'5px'}
                    height={!isPresentTransformer ? '100%' : 'auto'}
                  >
                    <HighlightText>{`ф.${item.phase} №: ${
                      isPresentTransformer ? 'КТТ' : 'ТН'
                    }`}</HighlightText>
                    {item[serialOrNumber]}
                    {open && (
                      <>
                        {isPresentTransformer && (
                          <Typography
                            mt={2}
                            mb={1}
                          >
                            Пломба:
                            <HighlightText> {item.serial}</HighlightText>
                          </Typography>
                        )}
                        <Typography mt={isPresentTransformer ? 0 : 2}>
                          Год выпуска:
                          <HighlightText> {item.year}</HighlightText>
                        </Typography>
                      </>
                    )}
                  </Grid>
                ))}
            </Stack>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default PresentTransformerEquipmenOrVoltage;
