import React from 'react';
import { frontendVersion } from './utils';
import { useSession } from '@/app/sentry';
import CopyButton from '@/components/button/CopyButton/CopyButton';
import { BaseUI } from '@/types/ui';
import { Maintext } from './FrontendVersion.styled';

export interface FrontendVersionProps extends BaseUI {
  withSessionId?: boolean;
}

const FrontendVersion = ({ withSessionId, className }: FrontendVersionProps) => {
  const { sessionId } = useSession();

  return (
    <div className={className}>
      <Maintext>Версия: {frontendVersion()}</Maintext>
      {withSessionId && (
        <Maintext>
          <span style={{ marginRight: 5 }}>Сессия: {sessionId}</span>
          <CopyButton
            textToCopy={sessionId}
            onlyIcon
            tooltipText='Копировать id сессии'
          />
        </Maintext>
      )}
    </div>
  );
};

export default FrontendVersion;
