import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navigation from '@/components/features/drive/navigation/Navigation';
import Search from '@/components/features/drive/search/Search';
import { GetRes } from '@/services/DriveService';
import { Loading } from '@/components/ui/Loading';
import { useActionLog } from '@/hooks/ActionLogHook';
import ResFolder from '@/components/features/drive/folder/ResFolder';
import { DriveWrapper, FoldersWrapper } from './Drive.styled';
import { DriveItem, driveMapToArray } from './utils';

const ResFolders = () => {
  const { fetchCatch } = useActionLog();
  const { resGroupId } = useParams();
  const [value, setValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<DriveItem[]>([]);
  const [filterData, setFilterData] = useState<DriveItem[]>([]);
  const getSome = () => {
    document.body.style.cursor = 'wait';
    resGroupId &&
      GetRes(resGroupId.split('_')[1])
        .then((res) => {
          setData(driveMapToArray(res.data));
          if (data.length > 0) {
            setFilterData(filterFunc(driveMapToArray(res.data)));
          } else {
            setFilterData(driveMapToArray(res.data));
          }
        })
        .catch((err) => {
          fetchCatch(err, 'Ошибка загрузки');
        })
        .finally(() => {
          setLoading(false);
          document.body.style.cursor = 'default';
        });
  };

  useEffect(() => {
    setLoading(true);
    getSome();
  }, []);

  const filterFunc = (arr: DriveItem[]): DriveItem[] =>
    arr.filter((elem) => elem.name.toLowerCase().includes(value.toLowerCase()));

  const filter = (str: string) => {
    setValue(str);
    setFilterData(data.filter((elem) => elem.name.toLowerCase().includes(str.toLowerCase())));
  };

  return (
    <>
      <Navigation />
      <Search
        value={value}
        onChange={filter}
      />
      <DriveWrapper>
        <Loading loading={loading}>
          <FoldersWrapper>
            {filterData.map((elem) => (
              <ResFolder
                key={elem.name}
                item={elem}
              />
            ))}
          </FoldersWrapper>
        </Loading>
      </DriveWrapper>
    </>
  );
};

export default ResFolders;
