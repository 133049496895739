import { useQuery } from 'react-query';
import { KEYS } from './Keys';
import { useActionLog } from '@/hooks/ActionLogHook';

import {
  SupplyBillApiGetNetworkElementTypes,
  SupplyBillApiGetNetworkElementTypesParents,
} from '@/services/SupplyBillService';

export function useAllNetworkElementTypes(onlyParentTypes?: boolean) {
  const { fetchCatch } = useActionLog();
  const { data: axiosResponse, isLoading } = useQuery({
    queryKey: KEYS.allNetworkElementTypes,
    staleTime: Infinity,
    queryFn: SupplyBillApiGetNetworkElementTypes,
    onError: (error) => fetchCatch(error, 'Ошибка инициализации сетевого оборудования'),
  });

  const { data: parentsResponse, isLoading: parentsLoading } = useQuery({
    queryKey: [...KEYS.allNetworkElementTypes, 'parents'],
    staleTime: Infinity,
    queryFn: SupplyBillApiGetNetworkElementTypesParents,
    onError: (error) => fetchCatch(error, 'Ошибка инициализации сетевого оборудования'),
    enabled: !!onlyParentTypes,
  });

  return {
    networkElementTypesResponse: onlyParentTypes ? parentsResponse?.data : axiosResponse?.data,
    isLoading: onlyParentTypes ? parentsLoading : isLoading,
  };
}
