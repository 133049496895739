import React, { useEffect } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import BoltIcon from '@mui/icons-material/Bolt';
import { useBoltFunctionality } from './utils';
import { useLocation } from 'react-router-dom';
import { color } from '@/styles/mixins';
import { BoltWrapper } from './NavbarBolt.styled';

const NavbarBolt = () => {
  const { boltFunctionality } = useBoltFunctionality();
  const pathName = useLocation()?.pathname;

  useEffect(() => {
    const keydownHandler = (ev: KeyboardEvent) => {
      if (ev.key === 'F4') handleEditNavigation();
    };
    document.addEventListener('keydown', keydownHandler, false);
    return () => {
      document.removeEventListener('keydown', keydownHandler, false);
    };
  }, [pathName]);

  const handleEditNavigation = () => {
    boltFunctionality();
  };

  return (
    <BoltWrapper onClick={handleEditNavigation}>
      <Tooltip
        style={{ paddingLeft: 0 }}
        title='Горячая клавиша - f4'
      >
        <IconButton>
          <BoltIcon htmlColor={color('trueWhite')} />
        </IconButton>
      </Tooltip>
    </BoltWrapper>
  );
};

export default NavbarBolt;
