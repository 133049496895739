import DraggablePopup from '@/components/popups/DraggablePopup';
import useDebounce from '@/components/maps/zoningMap/hook/useDebounce';
import { color } from '@/styles/mixins';
import { Box, Button, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { findTaskById } from '@/services/TaskService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { FieldValues, useForm } from 'react-hook-form';
import { useComplaintStore } from './store';
import shallow from 'zustand/shallow';
import { editComplaintEnergy } from '@/services/ComplaintService/ComplaintService';
import { Spin } from 'antd';
import { LoadingButton } from '@mui/lab';

const AddTaskToComplaintModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [IsInputDisable, setIsInputDisable] = useState(false);
  const [task, setTask] = useState<any>(null);
  const { fetchCatch, addActionLog } = useActionLog();

  const {
    selectedCompliant,
    isAddTaskToComplaintOpen,
    setIsAddTaskToComplaintOpen,
    fetchSingleComplaint,
  } = useComplaintStore(
    (state) => ({
      selectedCompliant: state.selectedCompliant,
      handleSearch: state.handleSearch,
      isAddTaskToComplaintOpen: state.isAddTaskToComplaintOpen,
      setIsAddTaskToComplaintOpen: state.setIsAddTaskToComplaintOpen,
      fetchSingleComplaint: state.fetchSingleComplaint,
    }),
    shallow
  );

  const debounceSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (searchTerm) {
      setIsInputDisable(true);
      findTaskById(debounceSearchTerm)
        .then(({ data }) => {
          if (data) {
            setTask(data);
          }
        })
        .catch((error) => {
          fetchCatch(error);
          setTask(null);
        })
        .finally(() => {
          setIsInputDisable(false);
        });
    }
  }, [debounceSearchTerm]);

  const onSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  const { handleSubmit, setValue } = useForm();

  useEffect(() => {
    if (selectedCompliant) {
      setValue('text', selectedCompliant?.complaint.text);
      setValue('address', selectedCompliant?.complaint?.address);
      setValue('tariffication', selectedCompliant?.tariffication);
      setValue('energyType', selectedCompliant?.energyType?.name);
      setValue('res', selectedCompliant?.res);
      setValue('classification', selectedCompliant?.classification?.name);
      setValue('sapNumber', selectedCompliant?.sapNumber);
      setValue('responseDate', selectedCompliant?.responseDate);
      setValue('declarerFio', selectedCompliant?.declarerFio);
      setValue('account', selectedCompliant?.account);
      setValue('phoneNumber', selectedCompliant?.phoneNumber);
      setValue('phoneNumberAdditional', selectedCompliant?.phoneNumberAdditional);
      setValue('meterType', selectedCompliant?.meterType);
      setValue('meterNumber', selectedCompliant?.complaint.meterNumber);
      setValue('res', selectedCompliant?.res);
      if (task) {
        setValue('reasonTaskId', task?.id);
      }
    }
  }, [selectedCompliant, task]);

  const onSubmit = (data: FieldValues) => {
    if (selectedCompliant) {
      setIsLoading(true);
      editComplaintEnergy(data, selectedCompliant?.complaint.base.id)
        .then(() => {
          addActionLog(ActionLogType.SUCCESS, 'Успешно');
          setIsAddTaskToComplaintOpen(false);
          setTask(null);
          setSearchTerm('');
          fetchSingleComplaint(
            selectedCompliant.complaint.base.id,
            selectedCompliant.complaint.type
          );
        })
        .catch((error) => {
          fetchCatch(error);
          setTask(null);
          setSearchTerm('');
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <DraggablePopup
      isOpen={isAddTaskToComplaintOpen}
      onClose={() => setIsAddTaskToComplaintOpen(false)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          xs={12}
        >
          <Grid
            xs={12}
            item
          >
            <Box sx={{ padding: 1 }}>
              <TextField
                placeholder='Внесите id заявки'
                fullWidth
                value={searchTerm}
                onChange={onSearchTermChange}
                id='input-with-icon-textfield'
                label='поиск'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      {IsInputDisable ? <Spin /> : <SearchIcon />}
                    </InputAdornment>
                  ),
                }}
                variant='outlined'
              />
              {task && (
                <Box marginTop={3}>
                  <Typography
                    variant='subtitle2'
                    color={color('trueBlack')}
                  >
                    {task.addressTitle}
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    color={color('trueBlack')}
                  >
                    {task.inputName}
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    color={color('trueBlack')}
                  >
                    № {task.number}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            container
            mt={3}
          >
            <Grid
              item
              xs={6}
            >
              <LoadingButton
                loading={isLoading}
                variant='text'
                color='primary'
                type='submit'
                fullWidth
                disabled={!task}
              >
                Сохранить
              </LoadingButton>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Button
                variant='text'
                type='reset'
                fullWidth
                onClick={() => setIsAddTaskToComplaintOpen(false)}
                disabled={isLoading}
              >
                Отмена
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </DraggablePopup>
  );
};

export default AddTaskToComplaintModal;
