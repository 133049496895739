import { bounce } from '@/components/features/pnr/HouseEntranceHeader/HouseEntranceHeader.styled';
import { color } from '@/styles/mixins';
import { styled } from '@mui/material';

export const BoltWrapper = styled('div')({
  backgroundColor: color('muiBlue'),
  borderTopRightRadius: 10,
  borderBottomRightRadius: 10,
  width: '23px',
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: '0.3s ease',
  transformOrigin: 'left center',
  marginRight: '5px',
  cursor: 'pointer',

  svg: {
    transition: '0.3s ease',
  },

  '&:hover': {
    width: '28px',
    marginRight: '0px',

    svg: {
      animation: `${bounce} 1s 1 both`,
    },
  },
});
