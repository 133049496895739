import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';
import React from 'react';
import { PpoMap } from '@/components/maps/ppoMap/PpoMap';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const PpoMapPage = () => {
  useAccess({
    access: [AccessEnum.TasksBrowse],
  });
  useMetaTitle('Карта ППО');
  return <PpoMap />;
};

export default PpoMapPage;
