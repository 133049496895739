/* eslint-disable */
//@ts-nocheck
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { ColorKeys, colorsSegment, filterShowInfo } from '../utils';
import { TooltipBarChart } from '../../types/types';
import {
  BarWrapper,
  BudgetStatisticBarWrapper,
  InvestStatisticTitle,
} from '../InvestStatistic.styled';
import { GetChartBarData } from '../../api/InvestStatisticService';
import shallow from 'zustand/shallow';
import { useInvestStatisticStore } from '../../store';
import * as d3 from 'd3';
import { BudgetStatisticTooltip } from '../BudgetStatisticTooltip/BudgetStatisticTooltip';
import {
  addHorizontalGridlines,
  changeXAxisLines,
  changeXAxisText,
  changeYAxisLines,
  createScaleX0,
  createScaleX1,
  createScaleY,
  createSvg,
  createXAxis,
  createYAxis,
  drawBarNartis,
  drawBars,
  formatStackedData,
} from './utils';
import { BudgetStatisticBarControl } from './BudgetStatisticBarControl';
import { getDataFromFilter } from '@/components/inputs/MultiSelectNew/utils';
import { useBudgetStatisticContracts } from '../../hooks/useBudgetStatisticContracts';
import { useBudgetStatisticEnergyCompany } from '../../hooks/useBudgetStatisticEnergyCompany';
import { fetchCatch } from '@/hooks/ActionLogHook';

export const BudgetStatisticBar = () => {
  const {
    equipmentsData,
    selectedContract,
    activeIndex,
    setSelectedColumn,
    checkedEnergyCompany,
    dataColumns,
    setDataColumns,
    selectedEnergyCompany,
    countAsMoney,
  } = useInvestStatisticStore(
    (state) => ({
      equipmentsData: state.equipmentsData,
      selectedContract: state.selectedContract,
      activeIndex: state.activeIndex,
      setSelectedColumn: state.setSelectedColumn,
      checkedEnergyCompany: state.checkedEnergyCompany,
      dataColumns: state.dataColumns,
      setDataColumns: state.setDataColumns,
      selectedEnergyCompany: state.selectedEnergyCompany,
      countAsMoney: state.countAsMoney,
    }),
    shallow
  );

  const title = activeIndex !== null ? equipmentsData[activeIndex].title : '';

  const refWrapper = useRef() as MutableRefObject<HTMLDivElement>;
  const svgRef = useRef<SVGSVGElement | null>(null);
  const yAxisRef = useRef<SVGSVGElement | null>(null);

  const [tooltip, setTooltip] = useState<TooltipBarChart>({
    display: 'none',
    tooltipData: null,
    top: 0,
    left: 0,
  });
  const [displayNartis, setDisplayNartis] = useState(false);
  const [loading, setLoading] = useState(false);

  const { catalogContracts } = useBudgetStatisticContracts();
  const { catalogEnergyCompany } = useBudgetStatisticEnergyCompany();

  useEffect(() => {
    refWrapper?.current?.scrollIntoView({ behavior: 'smooth' });
    // setDataColumns(DATA_COLUMNS);
    setLoading(true);
    GetChartBarData({
      contractIds: getDataFromFilter(selectedContract, 'value'),
      energyCompanyId: getDataFromFilter(selectedEnergyCompany, 'value'),
      equipmentType: title,
      groupByContract: !checkedEnergyCompany,
      countAsMoney,
    })
      .then(({ data }) => {
        setDataColumns(data);
        setSelectedColumn(null);
      })
      .catch((err) => fetchCatch(err, 'Ошибка получения данных статистики'))
      .finally(() => setLoading(false));
  }, [title, checkedEnergyCompany]);

  const tooltipRef = useRef<HTMLDivElement>(null);

  //   FIXME: не оптимально, постоянно вычисляем размеры
  const handleMouseOver = (event: MouseEvent, d: any) => {
    if (!tooltipRef.current) {
      return;
    }
    const { width, height } = tooltipRef.current.getBoundingClientRect();

    const { clientX, clientY } = event;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const tooltipWidth = width || 150; // Фолбэк на случай отсутствия данных
    const tooltipHeight = height || 50;

    //   FIXME: хардкод магич. числа 15, сделать по аналогии с бубликом
    let top = clientY + 15;
    let left = clientX + 15;

    // Корректировка координат для границ
    if (left + tooltipWidth > viewportWidth) {
      left = viewportWidth - tooltipWidth;
    }
    if (top + tooltipHeight > viewportHeight) {
      top = viewportHeight - tooltipHeight;
    }

    setTooltip({
      display: 'block',
      tooltipData: filterShowInfo(d?.showInfo?.showInfo, displayNartis),
      top,
      left,
    });
  };

  const handleMouseOut = () => {
    setTooltip({ display: 'none', tooltipData: null, top: 0, left: 0 });
  };

  const handleSelectColumn = (event: MouseEvent, d: any) => {
    setSelectedColumn(d.index);
  };

  const drawChart = () => {
    d3.select(svgRef.current).selectAll('*').remove();
    d3.select(yAxisRef.current).selectAll('*').remove();

    if (!svgRef.current) {
      return;
    }
    const { height } = svgRef.current.getBoundingClientRect();

    const divBounds = d3.select('#barChart');

    const divElement = divBounds.node();

    if (!(divElement instanceof Element)) {
      return;
    }

    const divRect = divElement.getBoundingClientRect();

    const width =
      divRect.width > dataColumns.length * 110 ? divRect.width : dataColumns.length * 110;

    const svg = createSvg(svgRef, width, height);

    const x0 = createScaleX0(dataColumns, width);

    const segmentKeys = dataColumns[0]?.segmentParts?.default
      ? d3.range(dataColumns[0].segmentParts.default.length).map(String)
      : [];
    const x1 = createScaleX1(dataColumns, segmentKeys);

    const maxCount =
      d3.max(dataColumns, (d) => d3.max(d.segmentParts.default.map((item) => item.count))) || 0;
    const padding = maxCount * 0.05;
    const paddedMaxValue = maxCount + padding;

    const paddingLeft = (maxCount.toString().length + 1) * 10 + 60;

    const yAxisSvg = createSvg(yAxisRef, paddingLeft, height);

    const y = createScaleY(paddedMaxValue, height);

    createXAxis(svg, height, x0, checkedEnergyCompany, catalogContracts, catalogEnergyCompany);

    changeXAxisLines(svg);
    changeXAxisText(svg);

    createYAxis(yAxisSvg, y, paddingLeft, height);
    changeYAxisLines(svg);

    addHorizontalGridlines(svg, width, y);

    const stack = d3.stack().keys(segmentKeys);

    const formatedData = formatStackedData(dataColumns, paddedMaxValue);

    const stackedData = stack(formatedData);

    const detailedStackedData = stackedData.map((d, index) =>
      d.map((segment, i) => {
        return {
          ...segment,
          showInfo: [...dataColumns[i].segmentParts.default].reverse()[stackedData[index].index],
          index: i,
        };
      })
    );

    drawBars(
      svg,
      detailedStackedData,
      handleMouseOut,
      x0,
      dataColumns,
      y,
      handleMouseOver,
      handleSelectColumn,
      [...colorsSegment[title as ColorKeys]].reverse(),
      countAsMoney
    );

    //если есть нартисы
    if (displayNartis) {
      drawBarNartis(svg, dataColumns, x0, x1, y, paddedMaxValue);
    }
  };

  useEffect(() => {
    drawChart();
  }, [dataColumns, displayNartis]);

  return (
    <div
      style={{ marginTop: '75px' }}
      ref={refWrapper}
    >
      <InvestStatisticTitle>{title}</InvestStatisticTitle>

      <BudgetStatisticBarWrapper>
        <>
          <BudgetStatisticBarControl
            displayNartis={displayNartis}
            setDisplayNartis={setDisplayNartis}
          />
          <div
            id='barChart'
            style={{ display: 'flex' }}
          >
            <svg ref={yAxisRef}></svg>
            <BarWrapper>
              <svg
                ref={svgRef}
                width='auto'
                height='420'
              />
              <BudgetStatisticTooltip
                tooltip={tooltip}
                tooltipRef={tooltipRef}
              />
            </BarWrapper>
          </div>
        </>
      </BudgetStatisticBarWrapper>
    </div>
  );
};
