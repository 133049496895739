import { RefObject } from 'react';
import * as d3 from 'd3';
import { BudgetStatisticBarItem } from '../../types/types';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import { formatCount } from '../utils';

export const MARGIN = { top: 20, right: 30, bottom: 60, left: 100 };
export const BAR_WIDTH = 90;

export const createSvg = (svgRef: RefObject<SVGSVGElement>, width: number, height: number) => {
  return d3.select(svgRef.current).attr('width', width).attr('height', height);
};

export const createScaleX0 = (data: BudgetStatisticBarItem[], width: number) => {
  return d3
    .scaleBand()
    .domain(data.map((d) => d.catalogId))
    .range([MARGIN.left, width - MARGIN.right])
    .padding(0.1)
    .align(0.5);
};

export const createScaleX1 = (data: BudgetStatisticBarItem[], segmentKeys: string[]) => {
  return d3.scaleBand().domain(segmentKeys).range([0, BAR_WIDTH]);
};

export const createScaleY = (paddedMaxValue: number, height: number) => {
  return d3
    .scaleLinear()
    .domain([0, paddedMaxValue])
    .nice()
    .range([height - MARGIN.bottom, MARGIN.top]);
};

export const createXAxis = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  height: number,
  x0: d3.ScaleBand<string>,
  checkedEnergyCompany: boolean,
  catalogContracts: SelectFilterOptType[],
  catalogEnergyCompany: SelectFilterOptType[]
) => {
  return svg
    .append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0,${height - MARGIN.bottom})`)
    .call(
      d3
        .axisBottom(x0)
        .tickSize(18)
        .tickPadding(15)
        .tickFormat((catalogId) => {
          const label = !checkedEnergyCompany
            ? catalogContracts.find((it) => it.value === catalogId)?.label
            : catalogEnergyCompany.find((it) => it.value === catalogId)?.label;
          return label || '';
        })
    );
};

export const changeXAxisLines = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>
) => {
  return svg
    .selectAll('.x-axis line')
    .style('stroke', '#BABABA')
    .style('stroke-width', 2)
    .attr('transform', 'translate(0, 5)');
};

export const changeXAxisText = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>
) => {
  return svg
    .selectAll('.x-axis text')
    .attr('font-size', '16px')
    .attr('font-weight', 'bold')
    .style('fill', '#000000');
};

export const createYAxis = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  y: d3.ScaleLinear<number, number, never>,
  width: number,
  height: number
) => {
  return svg
    .style('position', 'absolute')
    .style('z-index', 1)
    .append('g')
    .attr('class', 'y-axis')
    .attr('transform', `translate(${MARGIN.left},0)`)
    .call(d3.axisLeft(y).ticks(5))
    .attr('font-size', '18px')
    .attr('font-weight', 'bold')
    .insert('rect', ':first-child')
    .attr('x', -width)
    .attr('y', 0)
    .attr('width', width)
    .attr('height', height)
    .attr('fill', 'white')
    .style('pointer-events', 'none');
};

export const changeYAxisLines = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>
) => {
  return svg.selectAll('.y-axis line').style('stroke', 'none');
};

const transformValue = (value: number, maxValue: number) => {
  if (value > 0 && (value * 100) / maxValue < 5) {
    value = maxValue * 0.05;
  }
  return value;
};

export const formatStackedData = (
  dataColumns: BudgetStatisticBarItem[],
  paddedMaxValue: number
) => {
  return dataColumns?.map((item, i) =>
    item.segmentParts.default.map((it, idx) => {
      const segments = [...item.segmentParts.default].reverse();

      const newSegments = segments.map((segment, index) => {
        return segments[index - 1]
          ? segments[index]?.count - segments[index - 1]?.count
          : segments[index]?.count;
      });

      const resultSegments = newSegments.map((i, index) => {
        if (i !== transformValue(i, paddedMaxValue)) {
          const different = transformValue(i, paddedMaxValue) - i;
          newSegments[index] = i + different;
          newSegments[index + 1] = newSegments[index + 1] - different;
        }
        return newSegments[index];
      });

      return resultSegments[idx];
    })
  );
};

export const addHorizontalGridlines = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  width: number,
  y: d3.ScaleLinear<number, number, never>
) => {
  return svg
    .append('g')
    .attr('class', 'grid')
    .attr('transform', `translate(${MARGIN.left},0)`)
    .call(
      d3
        .axisLeft(y)
        .ticks(5)
        .tickSize(-width)
        .tickFormat(() => '')
    )
    .selectAll('.tick line')
    .attr('class', 'grid-line');
};

export const backgroundColorGradient = [
  ['#588ABE', '#294058'],
  ['#A3CCE9', '#5C7383'],
  ['transparent', 'transparent'],
];

export const createGradient = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  i: number
) => {
  const gradient = svg
    .append('defs')
    .append('linearGradient')
    .attr('id', `gradient-${i}`)
    .attr('x1', '0%')
    .attr('x2', '100%')
    .attr('y1', '0%')
    .attr('y2', '100%');

  gradient
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', backgroundColorGradient[i][0])
    .attr('stop-opacity', 1);

  gradient
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', backgroundColorGradient[i][1])
    .attr('stop-opacity', 1);
};

export const addText = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  x0: d3.ScaleBand<string>,
  dataColumns: BudgetStatisticBarItem[],
  y: d3.ScaleLinear<number, number, never>,
  segment: any,
  i: number,
  j: number,
  countAsMoney: boolean
) => {
  const x: number =
    (x0(dataColumns[segment.index]?.catalogId) ?? 0) + x0.bandwidth() / 2 - 0.5 * BAR_WIDTH;
  const width = BAR_WIDTH;

  const y0 = y(segment[0]);
  const y1 = y(segment[1]);

  const valueText = [...dataColumns[j].segmentParts.default].reverse()[i].count;

  svg
    .append('text')
    .attr('x', x + width / 2)
    .attr('y', i === 2 ? y1 - 10 : (y0 + y1) / 2)
    .attr('dy', '0.35em')
    .attr('text-anchor', 'middle')
    .style('pointer-events', 'none')
    .text(valueText > 0 ? (countAsMoney ? formatCount(valueText) : valueText) : '')
    .attr('fill', i === 2 ? 'black' : 'white')
    .attr('font-size', '14px')
    .attr('font-weight', 'bold');
};

export const createDashedSegment = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  detailedStackedData: any,
  x0: d3.ScaleBand<string>,
  dataColumns: BudgetStatisticBarItem[],
  y: d3.ScaleLinear<number, number, never>,
  i: number,
  color: string[]
) => {
  const upperSegment = detailedStackedData[2];
  const x: number | undefined =
    (x0(dataColumns[i].catalogId) ?? 0) + x0.bandwidth() / 2 - 0.5 * BAR_WIDTH;
  if (!x) {
    return;
  }
  const yTop = y(upperSegment[i][1]);

  const width = BAR_WIDTH;
  const height = y(upperSegment[i][1]) - y(upperSegment[i][0]);

  svg
    .append('line')
    .attr('x1', x)
    .attr('y1', yTop)
    .attr('x2', x + width)
    .attr('y2', yTop)
    .attr('stroke', color[2])
    .attr('stroke-dasharray', '3,3')
    .attr('stroke-width', 1);

  svg
    .append('line')
    .attr('x1', x)
    .attr('y1', yTop)
    .attr('x2', x)
    .attr('y2', yTop - height)
    .attr('stroke', color[2])
    .attr('stroke-dasharray', '3,3')
    .attr('stroke-width', 1);

  svg
    .append('line')
    .attr('x1', x + width)
    .attr('y1', yTop)
    .attr('x2', x + width)
    .attr('y2', yTop - height)
    .attr('stroke', color[2])
    .attr('stroke-dasharray', '3,3')
    .attr('stroke-width', 1);
};

export const drawBars = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  detailedStackedData: any,
  handleMouseOut: () => void,
  x0: d3.ScaleBand<string>,
  dataColumns: BudgetStatisticBarItem[],
  y: d3.ScaleLinear<number, number, never>,
  handleMouseOver: (event: MouseEvent, d: any) => void,
  handleSelectColumn: (event: MouseEvent, d: any) => void,
  color: string[],
  countAsMoney: boolean
) => {
  svg
    .append('g')
    .selectAll('g')
    .data(detailedStackedData)
    .join('g')
    .attr('fill', (d, i) => (i === 2 ? 'transparent' : color[i]))
    .style('cursor', 'pointer')
    .on('mouseout', handleMouseOut)
    .each(function (d: any, i: number) {
      d.forEach((segment: any, j: number) => {
        addText(svg, x0, dataColumns, y, segment, i, j, countAsMoney);
      });
    })
    .selectAll('rect')
    .data((d: any) => d)
    .join('rect')
    .attr('x', (d, i) => (x0(dataColumns[i].catalogId) ?? 0) + x0.bandwidth() / 2 - 0.5 * BAR_WIDTH)
    .attr('y', (d: any) => y(d[1]))
    .attr('height', (d: any) => y(d[0]) - y(d[1]))
    .attr('width', BAR_WIDTH)
    .each(function (d, i) {
      createDashedSegment(svg, detailedStackedData, x0, dataColumns, y, i, color);
    })
    .on('mouseover', handleMouseOver)
    .on('mousemove', handleMouseOver)
    .on('click', handleSelectColumn);
};

export const drawBarNartis = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  dataColumns: BudgetStatisticBarItem[],
  x0: d3.ScaleBand<string>,
  x1: d3.ScaleBand<string>,
  y: d3.ScaleLinear<number, number, never>,
  paddedMaxValue: number
) => {
  svg
    .append('g')
    .selectAll('rect')
    .data(dataColumns)
    .join('path')
    .attr('d', (d) => {
      if (!d.segmentParts.nartis[0]?.count) {
        return '';
      }

      const x = (x0(d.catalogId) ?? 0) + x0.bandwidth() / 2 + 0.5 * BAR_WIDTH;
      const width = x1.bandwidth() < 14 ? x1.bandwidth() : 14;
      const yValue = y(transformValue(d.segmentParts.nartis[0]?.count, paddedMaxValue));

      return `M${x},${yValue} 
                L${x + width - 10},${yValue} 
                Q${x + width},${yValue} ${x + width},${yValue + 10} 
                L${x + width},${y(0)} 
                L${x},${y(0)} 
                Z`;
    })
    .attr('fill', '#56CCF2');
};
