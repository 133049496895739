import { Button, Stack, Tabs, Tab, Box } from '@mui/material';
import React, { useState } from 'react';
import { ComplaintReportContainer, ComplaintReportModal } from './ComplaintReportButton.styled';
import { DateRange } from '@/components/inputs/DateRange';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { DEFAULT_RANGE, downLoadExcel } from './utils';
import {
  getReportCommonDay,
  getReportWeeklyDay,
} from '@/services/ComplaintService/ComplaintService';
import moment from 'moment';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { MainText } from '../ComplaintAddButton/ComplaintAddButton.styled';

import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';

const ComplaintReportButton = () => {
  const methods = useForm();
  const { fetchCatch, addActionLog } = useActionLog();

  const { handleSubmit } = methods;
  const [isOpen, setIsOpen] = useState(false);
  const [dataInRange, setDateInRange] = React.useState<number[] | Date[]>(DEFAULT_RANGE);
  const [isLoading, setIsLoading] = useState(false);

  const isInRange = dataInRange.length === 2;

  const [activeTab, setActiveTab] = useState(0);

  const onSubmitSearch = () => {
    if (activeTab !== 1) {
      setIsLoading(true);
      const startDate = moment(dataInRange[0]).format('YYYY-MM-DD');
      const endDate = moment(dataInRange[1]).format('YYYY-MM-DD');
      const reportName = `Отчет по жалобам за период с ${startDate} по ${endDate}`;
      getReportCommonDay({ startDate, endDate })
        .then((res) => {
          downLoadExcel(res, reportName);
          addActionLog(ActionLogType.SUCCESS, `${reportName} сгенерирован успешно`);
        })
        .catch((err) => fetchCatch(err))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(true);
      const reportNameWeekly = `Отчет по жалобам за неделю`;
      getReportWeeklyDay()
        .then((res) => {
          downLoadExcel(res, reportNameWeekly);
          addActionLog(ActionLogType.SUCCESS, `${reportNameWeekly} сгенерирован успешно`);
        })
        .catch((err) => fetchCatch(err))
        .finally(() => setIsLoading(false));
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleTabChange = (event: any, newValue: number) => {
    setActiveTab(newValue);
  };
  const isMobile = useMobileSize();

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Сгенерировать отчет</Button>
      <ComplaintReportModal
        onClose={handleClose}
        open={isOpen}
      >
        <form onSubmit={handleSubmit(onSubmitSearch)}>
          <ComplaintReportContainer>
            <Box>
              <Stack>
                <MainText>Генерация отчета</MainText>
              </Stack>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                centered
                orientation={isMobile ? 'vertical' : 'horizontal'}
              >
                <Tab label='Общий отчет по жалобам' />
                <Tab label='Отчет по жалобам к совещанию' />
              </Tabs>
              <Stack
                direction='row'
                gap={2}
                justifyContent={'center'}
              >
                {activeTab === 0 && (
                  <>
                    <Stack
                      direction='row'
                      gap={2}
                    >
                      <DateRange
                        label={'Выберите диапазон'}
                        isInRange={!isInRange}
                        onChange={setDateInRange}
                        value={dataInRange}
                        argValue={8}
                        showSelect={false}
                      />

                      <LoadingButton
                        type='submit'
                        disabled={!isInRange}
                        sx={{ mt: 4 }}
                        loading={isLoading}
                      >
                        Сгенерировать
                      </LoadingButton>
                    </Stack>
                  </>
                )}
                {activeTab === 1 && (
                  <LoadingButton
                    type='submit'
                    disabled={!isInRange}
                    sx={{ mt: 4 }}
                    loading={isLoading}
                  >
                    Сгенерировать
                  </LoadingButton>
                )}
              </Stack>
            </Box>
          </ComplaintReportContainer>
        </form>
      </ComplaintReportModal>
    </>
  );
};

export default React.memo(ComplaintReportButton);
