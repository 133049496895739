import { Collapse, Stack } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { ActionLog } from '@/hooks/ActionLogHook';
import {
  ActionLogItemPre,
  ActionLogItemExpandIcon,
  IconWrapper,
  VerticalLine,
  MainContentText,
  ReportGmailerrorredIconRed,
  CheckCircleOutlineIconGreen,
  StrongText,
  SmallText,
} from './ActionLogItem.styled';
import CopyButton from '@/components/button/CopyButton/CopyButton';
import { color } from '@/styles/mixins';

interface ActionLogItemProps {
  item: ActionLog;
  isLast: boolean;
}

export const ActionLogItem = (props: ActionLogItemProps) => {
  const { item, isLast } = props;
  const [hide, setHide] = useState(true);
  const handleHide = () => {
    setHide(!hide);
  };

  const stringifiedError = JSON.stringify(item?.errorBody, null, ' ');

  return (
    <Stack sx={{ width: '100%', flexDirection: 'row', gap: 1 }}>
      <Stack sx={{ position: 'relative', alignItems: 'center' }}>
        <IconWrapper>
          {item.type === 'error' ? <ReportGmailerrorredIconRed /> : <CheckCircleOutlineIconGreen />}
        </IconWrapper>
        {!isLast && <VerticalLine />}
      </Stack>

      <Stack sx={{ width: '100%', maxWidth: 701 }}>
        <MainContentText>
          <StrongText>{item.message}</StrongText>
          <CopyButton
            onlyIcon
            textToCopy={stringifiedError}
            tooltipText='Скопировать описание ошибки'
          />
        </MainContentText>

        <SmallText>{moment(item.eventAt).format('HH:mm:ss / DD.MM.yyyy')}</SmallText>

        {item?.errorBody && (
          <Stack
            direction='row'
            alignItems='center'
            gap={0.5}
            onClick={handleHide}
          >
            <SmallText hide={hide}>Показать код ошибки</SmallText>
            <span style={{ color: color('errorGrayNavbar') }}>|</span>
            <ActionLogItemExpandIcon hide={hide} />
          </Stack>
        )}

        <Collapse in={!hide}>
          <ActionLogItemPre>{stringifiedError}</ActionLogItemPre>
        </Collapse>
      </Stack>
    </Stack>
  );
};
