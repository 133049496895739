import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  PaperProps,
  Skeleton,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useActionLog } from '@/hooks/ActionLogHook';
import { findAllByParam } from '@/components/features/Yunga/api/YungaService';
import { YungaItemResponse } from '../api/dto/response';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle='#draggable-dialog-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

interface YungaViewDialogProps {
  id: string;
  open: boolean;
  close(): void;
}

export const YungaViewDialog = (props: YungaViewDialogProps) => {
  const { id, close, open } = props;
  const [yungaLogResponse, setYungaLogResponsen] = useState<YungaItemResponse>();
  const [loading, setLoading] = useState(false);
  const { fetchCatch } = useActionLog();

  useEffect(() => {
    if (open && id && !yungaLogResponse) {
      setLoading(true);
      findAllByParam(id, 0, 1)
        .then((res) => setYungaLogResponsen(res.data && res.data[0]))
        .catch((err) => fetchCatch(err, 'Ошибка при загрузке лога Юнги'))
        .finally(() => setLoading(false));
    }
  }, [id, open, yungaLogResponse]);

  const handleClose = () => close();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
      >
        <DialogTitle style={{ cursor: 'move' }}>Yunga</DialogTitle>
        <DialogContent>
          {loading ? (
            <Skeleton />
          ) : (
            <>
              {yungaLogResponse && (
                <Typography
                  component={'span'}
                  variant='h6'
                >
                  <pre style={{ whiteSpace: 'pre-wrap' }}> {yungaLogResponse?.response} </pre>
                </Typography>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
