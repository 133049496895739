import { ShowInfoControllLabel } from '../InvestStatistic.styled';
import {
  DynamicStatisticSwitchesWrapper,
  DynamicStatisticTimePeriodWrapper,
  HeaderDynamicStatistic,
} from './DynamicStatistic.styled';

import { Button, IconButton, Switch } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useInvestStatisticStore } from '../../store';
import shallow from 'zustand/shallow';
import { timePeriod } from '../utils';

interface DynamicStatisticControlProps {
  withNartis: boolean;
  setWithNartis: (value: boolean) => void;
}

export const DynamicStatisticControl = ({
  withNartis,
  setWithNartis,
}: DynamicStatisticControlProps) => {
  const { setSelectedColumn, setDataDynamicStatistic, selectedTimePeriod, setSelectedTimePeriod } =
    useInvestStatisticStore(
      (state) => ({
        setSelectedColumn: state.setSelectedColumn,
        setDataDynamicStatistic: state.setDataDynamicStatistic,
        selectedTimePeriod: state.selectedTimePeriod,
        setSelectedTimePeriod: state.setSelectedTimePeriod,
      }),
      shallow
    );

  const closeHandler = () => {
    setSelectedColumn(null);
    setDataDynamicStatistic([]);
  };

  return (
    <HeaderDynamicStatistic>
      <DynamicStatisticTimePeriodWrapper>
        {timePeriod.map((item, idx) => (
          <Button
            key={idx}
            variant={selectedTimePeriod.name === item.name ? 'contained' : 'outlined'}
            onClick={() => setSelectedTimePeriod(item)}
          >
            {item.title}
          </Button>
        ))}
      </DynamicStatisticTimePeriodWrapper>
      <DynamicStatisticSwitchesWrapper>
        <ShowInfoControllLabel
          active={withNartis}
          control={
            <Switch
              checked={withNartis}
              onChange={(e) => setWithNartis(e.target.checked)}
            />
          }
          label={'установлено пу нартис'}
        />
        <IconButton onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </DynamicStatisticSwitchesWrapper>
    </HeaderDynamicStatistic>
  );
};
