import {
  Box,
  CircularProgress,
  Grid,
  Snackbar,
  SnackbarContent,
  Stack,
  Typography,
} from '@mui/material';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { InstallationCallLogType } from '../types/types';
import { useCallingMapState } from '../store';
import shallow from 'zustand/shallow';
import agree from '../images/agree.svg';
import newCall from '../images/newCall.svg';
import refuseCall from '../images/refuseCall.svg';
import missedCall from '../images/missedCall.svg';
import callBack from '../images/callBack.svg';
import noResultCall from '../images/noResultCall.svg';
import wrongAdressCall from '../images/wrongAdressCall.svg';
import { color } from '@/styles/mixins';
import TkoForCallingMapContiner from './TkoForCallingMap';
import { useEffect, useState } from 'react';
import AbonentListItemNew from './AbonentListitemNew';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { getSingleRrquestHisoryCallMap, getSingleTKOCallMap } from '@/services/CallingMapService';
import { useActionLog } from '@/hooks/ActionLogHook';
import RequestHistory from './RequestHisory';
import { fetchData, isNumberInRange, statusText } from '../utils/CallingMapUtils';
import { SubscriberResponse } from '@/dto/SubscribersDto';
import CommonTaskInfo from './CommonTaskInfo';

const imageStatus: Record<string, string> = {
  AGREEMENT: agree,
  NO_RESULT: newCall,
  WRONG_ADDRESS: refuseCall,
  CALLBACK: callBack,
  MISSED_CALL: missedCall,
  REFUSAL: wrongAdressCall,
  ADD_NUMBER: newCall,
};

const renderStatusImage = (status: string | null) => {
  if (status) return imageStatus[status];
  return newCall;
};

const InfoBlock = () => {
  const {
    singleGeoPoint,
    setOpenPopup,
    setSingleAbonent,
    isOpenSettings,
    taskId,
    isAuthorized,
    setTKO,
    tko,
    requestHistory,
    setRequestHistory,
    setOpenAddAbonentPopup,
    mapZoom,
  } = useCallingMapState(
    (state) => ({
      singleGeoPoint: state.singleGeoPoint,
      setOpenPopup: state.setOpenPopup,
      setSingleAbonent: state.setSingleAbonent,
      isOpenSettings: state.isOpenSettings,
      taskId: state.taskId,
      isAuthorized: state.isAuthorized,
      setTKO: state.setTKO,
      tko: state.tko,
      requestHistory: state.requestHistory,
      setRequestHistory: state.setRequestHistory,
      setOpenAddAbonentPopup: state.setOpenAddAbonentPopup,
      mapZoom: state.mapZoom,
    }),
    shallow
  );
  const { fetchCatch } = useActionLog();

  const [showTKO, setShowTKO] = useState(false);
  const [showHistory, setshowHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRequstHisoty, setIsLoadingRequstHisoty] = useState(false);

  const [showAuthError, setShowAuthError] = useState(false);
  const [errorMEssage, setErrorMEssage] = useState('');

  const getTKO = async () => {
    await fetchData(getSingleTKOCallMap, setTKO, setIsLoading, tko, fetchCatch, taskId);
  };

  const getRequestHistory = async () => {
    await fetchData(
      getSingleRrquestHisoryCallMap,
      setRequestHistory,
      setIsLoadingRequstHisoty,
      requestHistory,
      fetchCatch,
      taskId
    );
  };
  const handleAbonentItemClick = (abonent: SubscriberResponse) => {
    if (!isAuthorized) {
      setShowAuthError(true);
      setErrorMEssage('Пользователь не авторизован нет доступа к звонку');
      return;
    }
    setSingleAbonent(abonent);
    setOpenPopup(true);
    setShowAuthError(false);
    setErrorMEssage('');
  };
  const renderTKO = !!tko.length && showTKO;
  const renderTKOText = tko.length === 0 && showTKO;
  const renderRequsHisoryText = requestHistory.length === 0 && showHistory;
  const renderRequstHistory = !!requestHistory.length && showHistory;

  useEffect(() => {
    let timeoutId: any;
    if (showAuthError) {
      timeoutId = setTimeout(() => {
        setShowAuthError(false);
      }, 3000);
    }
    return () => clearTimeout(timeoutId);
  }, [showAuthError]);

  if (!isOpenSettings && singleGeoPoint)
    return (
      <Stack
        mt={2}
        overflow={'auto'}
        maxHeight={'52vh'}
      >
        <BoxShadow background={color('mapBackgroundColorBoxShadow')}>
          <Stack spacing={1}>
            <CommonTaskInfo
              taskId={taskId}
              singleGeoPoint={singleGeoPoint}
            />
            <Grid
              container
              xs={12}
            >
              <Grid
                item
                xs={3}
              >
                <Typography
                  color={color('typographyColorMap2')}
                  variant='body1'
                >
                  Результат звонка
                </Typography>
              </Grid>
              <Grid
                item
                xs={9}
              >
                <Box
                  display={'flex'}
                  gap={1}
                >
                  <img
                    width={25}
                    height={25}
                    src={renderStatusImage(singleGeoPoint?.installationCallGeneralStatus)}
                    alt='statusIcon'
                  />
                  <Typography color={color('typographyColorMap1')}>
                    {singleGeoPoint && singleGeoPoint.installationCallGeneralStatus
                      ? statusText[singleGeoPoint.installationCallGeneralStatus] ?? 'Новая'
                      : 'Новая'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Stack>
              {!!singleGeoPoint.abonents.length &&
                singleGeoPoint.abonents.map((abonent, index) => {
                  const installationCallLogsForAbonent = singleGeoPoint.installationCallLogs.filter(
                    (call: InstallationCallLogType) => call.abonentId === abonent.id
                  );
                  return (
                    <>
                      <AbonentListItemNew
                        key={abonent.id}
                        abonent={abonent}
                        installationCallLogsForAbonent={installationCallLogsForAbonent}
                        onClick={() => handleAbonentItemClick(abonent)}
                      />
                    </>
                  );
                })}
            </Stack>
            <Stack>
              <Typography
                color={color('blue')}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setOpenAddAbonentPopup(true);
                }}
              >
                + Добавить номер телефона
              </Typography>
            </Stack>

            <Typography
              color={color('blue')}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowTKO(!showTKO);
                getTKO();
              }}
            >
              Информация по ТКО {!!tko.length && `(${tko.length})`}
              {isLoading && (
                <CircularProgress
                  size={20}
                  color='inherit'
                />
              )}
            </Typography>
            {renderTKO && (
              <TkoForCallingMapContiner
                techInspections={[...tko].sort((a, b) => b.eventOn - a.eventOn)}
              />
            )}
            {renderTKOText && !isLoading && <Typography>нет ТКО</Typography>}
            <Typography
              color={color('blue')}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setshowHistory(!showHistory);
                getRequestHistory();
              }}
            >
              История обращений {!!requestHistory.length && `(${requestHistory.length})`}
              {isLoadingRequstHisoty && (
                <CircularProgress
                  size={20}
                  color='inherit'
                />
              )}
            </Typography>
            {renderRequstHistory && (
              <Stack>
                {[...requestHistory]
                  .sort((a, b) => b.createAt - a.createAt)
                  .map((r, index) => {
                    return (
                      <RequestHistory
                        key={index}
                        history={r}
                      />
                    );
                  })}
                {renderRequsHisoryText && !isLoadingRequstHisoty && (
                  <Typography>нет ТКО</Typography>
                )}
              </Stack>
            )}
          </Stack>
        </BoxShadow>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showAuthError}
        >
          <SnackbarContent
            sx={{ backgroundColor: '#FBDEDB' }}
            message={
              <Stack
                display={'flex'}
                flexDirection={'row'}
                gap={2}
                p={3}
              >
                <ErrorOutlineIcon style={{ color: color('trueRed') }} />
                <Typography style={{ color: color('typographyColorMap1') }}>
                  {errorMEssage}
                </Typography>
              </Stack>
            }
          />
        </Snackbar>
      </Stack>
    );
  else {
    return null;
  }
};

export default InfoBlock;
