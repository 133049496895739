import { color, font } from '@/styles/mixins';
import { IconButton, styled } from '@mui/material';

export const ExitButton = styled('button')({
  display: 'flex',
  border: 'none',
  backgroundColor: 'transparent',
  padding: 0,
  opacity: 0.7,
  color: color('muiBlue'),
  transition: '0.3s ease-in-out',
  ':hover': {
    opacity: 0.5,
    color: `${color('errorGrayNavbar')}`,
  },
  fontSize: 18,
});
export const IconButtonCustom = styled(IconButton)({
  border: `1px solid ${color('errorGrayNavbar')}`,
  borderRadius: 5,
  width: 30,
  height: 30,

  transition: 'background-color 0.3s ease',

  '&:hover': {
    backgroundColor: color('muiBlue'),
    borderColor: color('muiBlue'),
  },
  '&:hover .MuiSvgIcon-root': {
    color: 'white',
  },
});
export const ExitUserNameText = styled('p')`
  ${font('text2')}
  padding:0;
  margin: 0;
  color: black;
`;
