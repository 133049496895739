import axios from 'axios';
import {
  CallCenterBashkortostanRequest,
  CallCenterCallType,
  CallCenterRefundDto,
  CallCenterRemoteSolutionDto,
  CallCenterRequest,
  CallCenterScheduledDateDto,
  CallCenterSendedToBillingDto,
  CallCenterTaskCommentDto,
  CallCenterWorkType,
  CCContractDto,
  DadataResponseDto,
  GeoBounds,
  GeoRequest,
  GeoResponse,
  GuaranteeBodyDto,
  HouseDataBrowseDto,
  HouseDataDto,
  HouseDataFilterParam,
  HouseDataOdpuReplacement,
  HouseRelevantTaskDto,
  NetworkElementsTaskWithComment,
  StatisticUrgentAndSuperUrgentTasksDto,
  TaskAddressDetail,
  TaskCC,
  TaskCCTko,
  TkoStatusDto,
  TkoToSaveDto,
  UserResponse,
  HouseOrderDto,
  CreateHouseOrderDto,
  CreateHouseOrderResponseDto,
  PlannedInstalationsDto,
  SaveHousePlanningsDto,
  HouseLayoutDto,
  HouseAdressesChangesDto,
  PnrAddressDetail,
} from '@/dto/taskmap/Dto';
import { saveYodaToken, yodaTokenLocal, yodaRefreshTokenLocal } from '@/app/auth/AuthProvider';
import { BubbleDataPoint, ChartData, ScatterDataPoint } from 'chart.js';
import {
  MinimalYodaDTO,
  UnAttachmentDto as UnAttachmentDtoYoda,
  CopyRequestDTO,
  GetRequestsDTO,
  AssignContractRequestDTO,
  AssignContractorRequestDTO,
  AssignRequestDTO,
  CallingMapOperatorCredentials,
} from '@/dto/YodaRestDto';

import { NetworkResponse } from '@/components/sections/NetworkMap/UspdDto';
import {
  axiosGet,
  axiosGetExl,
  axiosGetExlByPost,
  axiosPost,
  axiosPut,
  axiosDelete,
} from './helpers';

import { OdpuManagementCompanyResponse } from '@/dto/taskmap/task/TasksDto';
import { FilterStatisticDistribution } from '@/components/statistics/StatisticDistributionOfInstallationByConctror/utils';
import { saveRolesLocal } from '@/hooks/useRole';
import { RolesEnum } from '@/app/auth/roles';
import { HermesAnswer, HermesListItem, HermesLog } from '@/dto/HermesDto';
import { TaskByMeterRequestDTO } from '@/dto/SupplyBillService/SupplyBillServiceRequest';
import { TaskByMeterResponseDTO } from '@/dto/SupplyBillService/SupplyBillServiceResponse';
import { YodaToken } from '@/app/auth/types';
import { ApiFindAllContractResponse, ApiResponse } from '@/types/api';
import { StatisticsContractorDto } from '@/components/statistics/CustomerInteractionStatistics/StatisticsResponseble/types';
import { AsumbTaskStatisticsType } from '@/components/statistics/AsumbStatistic/types';
import { AsumbTaskStatisticsForCheckChartType } from '@/components/statistics/RequestStatistic/CheckStatistics/types';

const SERCRET =
  'ZW5lcnN0cm95bWFpbi1FcUZHVUx4djpjOTg5ZGYxMGQ2MTk5NDk5MGJiMjBmODBkNDAwNTdlNmYzYzcxNWJmMGRlMGMyNDIyZGNkY2M0ZTY1N2M1ODcx';
export const HOST = getHost();
const GET_USER_URL = HOST + 'users';
const TAG = 'YodaService';

function getHost() {
  const HOST = import.meta.env.VITE_YODA_BACKEND_BASE_URL;
  if (typeof HOST == 'undefined') {
    alert('Адрес сервера для запросов к API не задан');
  } else return HOST as string;
}
export const ydoaAouth = (login: string, psw: string): ApiResponse<YodaToken> => {
  const url = HOST + '/rest/v2/oauth/token';
  return axios.post(url, `grant_type=password&username=${login}&password=${psw}`, {
    headers: {
      Authorization: 'Basic ' + SERCRET,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

/**
 * If token overdue invoke yodaFerfeshAouth
 * @returns accessToke or Promise.reject
 */
export async function yodaGetAccessToken(): Promise<string> {
  const token = yodaTokenLocal();
  if (token) return token;

  const refreshToken = yodaRefreshTokenLocal();
  if (!refreshToken) {
    return Promise.reject(new Error('Refresh token is not available'));
  }

  const response = await ydoaRefreshAouth().catch((er) => {
    // alert("Сервер временно недоступен, повторите попытку через 5 минут");
    return Promise.reject(er);
  });

  saveYodaToken(response.data);

  await yodaRefreshRoles();

  if (response.data.access_token) {
    return Promise.resolve(response.data.access_token);
  } else {
    return Promise.reject();
  }
}

export function ydoaRefreshAouth(): ApiResponse<YodaToken> {
  const url = HOST + '/rest/v2/oauth/token';
  return axios.post(url, `grant_type=refresh_token&refresh_token=${yodaRefreshTokenLocal()}`, {
    headers: {
      Authorization: 'Basic ' + SERCRET,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export async function yodaRefreshRoles() {
  return await ApiFindUserRoles()
    .then(({ data }) => {
      saveRolesLocal(data);
      return Promise.resolve(data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
}

/*
 *
 * @param stop it's a cruthc. Optional attribute for preventing recursion
 * @param entityName
 * @returns  ApiResponse<any>
 */

//ToDo remove limit
export async function yodaGetEntities(entityName: string, stop = false): ApiResponse<any> {
  const url = HOST + '/rest/v2/entities/' + entityName;
  const token = await yodaGetAccessToken();
  console.log(TAG + 'GET ENTITIES ' + url + ' the tokein is' + token);
  return axios.get(url, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
    params: {
      limit: 300,
      timeout: 1,
    },
  });
}

/**
 *
 * @param stop it's a cruthc. Optional attribute for preventing recursion
 * @param entityName
 * @param body
 * @returns  ApiResponse<any>
 */
export async function yodaPostEntities(
  entityName: string,
  body: any,
  stop = false
): ApiResponse<any> {
  const url = HOST + '/rest/v2/entities/' + entityName;
  const token = await yodaGetAccessToken();
  return axios.post(url, body, {
    headers: {
      authorization: `Bearer ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function ApiFindAllContract(): ApiResponse<ApiFindAllContractResponse> {
  const url = HOST + '/rest/contract';
  return axiosGet(url);
}

export async function ApiFindSingleContractor(filters?: any): ApiResponse<StatisticsContractorDto> {
  const { path, ...restFilter } = filters;
  const url = HOST + `/rest/statistics/contractorTasks/${path}`;
  return axiosPost(url, restFilter);
}

export async function ApiFindUserRoles(): ApiResponse<RolesEnum[]> {
  const url = HOST + '/rest/user/role';
  return axiosGet(url);
}

export async function ApiFindAllGroup(): ApiResponse<any> {
  const url = HOST + '/rest/group';
  return axiosGet(url);
}

export async function ApiFindAllOperators(): ApiResponse<UserResponse[]> {
  const url = HOST + '/rest/user/operators';
  return axiosGet(url);
}

export async function ApiGetRequests(params: GetRequestsDTO) {
  const url =
    HOST +
    `/rest/task/find-tasks-byparam?offset=${params.page * params.pageSize}&limit=${
      params.pageSize
    }`;
  const token = await yodaGetAccessToken();
  return axios.post(url, params.filters || {}, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function ApiGetRequestsCount(filters?: any) {
  const url = HOST + '/rest/task/get-task-count-byparam';
  const token = await yodaGetAccessToken();
  return axios.post(url, filters || {}, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function ApiGetAllUUID(filters?: any) {
  const url = HOST + '/rest/task/find-ids';
  const token = await yodaGetAccessToken();
  return axios.post(url, filters || {}, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function ApiAssignContractRequest(data: AssignContractRequestDTO) {
  const url = HOST + '/rest/task/assign-contract';
  const token = await yodaGetAccessToken();
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function ApiAssignContractorRequest(data: AssignContractorRequestDTO) {
  const url = HOST + '/rest/task/assign-contractor';
  const token = await yodaGetAccessToken();
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function ApiAssignRequest(data: AssignRequestDTO) {
  const url = HOST + '/rest/task/assign-executor-and-planneddate';
  const token = await yodaGetAccessToken();
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function ApiCancelRequest(data: string[]) {
  const url = HOST + '/rest/task/cancel-tasks';
  const token = await yodaGetAccessToken();
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function ApiCopyRequest(data: CopyRequestDTO) {
  const url = HOST + '/rest/task/copy-tasks';
  const token = await yodaGetAccessToken();
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function ApiTrailScreen(coord: any): ApiResponse<any> {
  const params = new URLSearchParams(coord);
  const url = HOST + `/rest/geo?${params}`;
  return axiosGet(url);
}

export async function ApiFindAllTaskGeoInBoundByParam(
  geoRequest: GeoRequest
): ApiResponse<GeoResponse> {
  const url = HOST + `/rest/geo`;
  return axiosPost(url, geoRequest);
}

export async function ApiFindAllTaskGeoByTaskIdList(
  taskIdList: string[]
): ApiResponse<GeoResponse> {
  const url = HOST + `/rest/geo/find-by-task-id`;
  return axiosPost(url, taskIdList);
}

export async function ApiFindAllPnrTag(taskId: string): ApiResponse<GeoResponse> {
  const url = HOST + `/rest/pnr/tag/${taskId}`;
  return axiosGet(url);
}

export async function ApiFindAllPnrHermes(): ApiResponse<HermesListItem[]> {
  const url = HOST + `/rest/pnr/hermes/list`;
  return axiosGet(url);
}

export async function ApiGetPnrHermesTerminal(
  hermesId: string,
  terminalNumber: string,
  houseDataId: string
): ApiResponse<HermesAnswer> {
  const url = HOST + `/rest/pnr/hermes/${hermesId}/discover/${terminalNumber}/${houseDataId}`;
  return axiosGet(url);
}

export async function ApiGetPnrHermesLog(
  terminalNumber: string,
  houseDataId: string
): ApiResponse<HermesLog> {
  const url = HOST + `/rest/pnr/hermes/${terminalNumber}/${houseDataId}`;
  return axiosGet(url);
}

export async function ApiDeletePnrHermesLog(hermesSurveyLogId: string): ApiResponse<HermesLog> {
  const url = HOST + `/rest/pnr/hermes/${hermesSurveyLogId}`;
  return axiosDelete(url);
}

export async function ApiFindAlPnrTransformerStation(geoBounds: GeoBounds): ApiResponse<any> {
  const url = HOST + `/rest/pnr/transformer-station`;
  return axiosPost(url, geoBounds);
}

export async function ApiFindAllTaskIdAroundTaskByPnrTag(
  taskId: string,
  geoRequest: GeoRequest
): ApiResponse<string[]> {
  const url = HOST + `/rest/pnr/tag/task/${taskId}`;
  const token = await yodaGetAccessToken();
  return axios.post(url, geoRequest, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function ApiFindAllTaskAttribute(): ApiResponse<any> {
  const url = HOST + '/rest/task/attribute';
  return axiosGet(url);
}

export async function ApiFindTaskHintByTaskId(id: string): ApiResponse<any> {
  const url = HOST + `/rest/task/hint/${id}`;
  return axiosGet(url);
}

export async function ApiFindTaskElementByTaskId(id: string): ApiResponse<any> {
  const url = HOST + `/rest/task/element/${id}`;
  return axiosGet(url);
}

export async function ApiFindTaskHintNetworkEquipmentByTaskId(id: string): ApiResponse<any> {
  const url = HOST + `/rest/network-equipment/hint/${id}`;
  return axiosGet(url);
}

export async function ApiFindAllPnrInfoByTaskIdList(
  pnrInfoType: string,
  taskIdList: string[]
): ApiResponse<any> {
  const url = HOST + `/rest/pnr/info/${pnrInfoType}`;
  const token = await yodaGetAccessToken();
  return axios.post(url, taskIdList, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function ApiFindAsumbTaskStatistics(): ApiResponse<AsumbTaskStatisticsType[]> {
  const url = HOST + `/rest/statistics/asumb/task`;
  return axiosGet(url);
}

export async function ApiGetAsumbTaskStatusStatisticsForDate(
  dateFrom: string,
  dateTo: string
): ApiResponse<ChartData<'line', (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown>> {
  const url =
    HOST + `/rest/statistics/asumb/date` + '?dateFrom=' + dateFrom + '&' + 'dateTo=' + dateTo;
  return axiosGet(url);
}

export async function ApiGetAsumbTaskStatisticsCheckStatus(): ApiResponse<AsumbTaskStatisticsForCheckChartType> {
  const url = HOST + `/rest/statistics/asumb/check`;
  return axiosGet(url);
}

export async function ApiFindAllUnownedPu(): ApiResponse<any> {
  const url = HOST + `/rest/pnr/unowned-pu`;
  return axiosGet(url);
}

export async function ApiFindUnAttachmentByFilter(
  filter: string
): ApiResponse<UnAttachmentDtoYoda[]> {
  const url = HOST + '/rest/v2/entities/enerstroymain$UnAttachment/search';
  const token = await yodaGetAccessToken();
  return axios.post(url, filter, {
    headers: {
      Authorization: `Bearer ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function GetFileUrlByFileId(id: string): Promise<string> {
  const token = await yodaGetAccessToken();
  return HOST + '/rest/v2/files/' + id + '?access_token=' + token;
}

export async function ApiGetFileById(id: string): ApiResponse<any> {
  const token = await yodaGetAccessToken();
  const url = HOST + '/rest/v2/files/' + id + '?access_token=' + token;
  return axiosGet(url);
}

export async function ApiGetFileByUrlS3(url: string): ApiResponse<any> {
  return axios.get(url);
}

export async function ApiGetActualMeteChangeMinimalByTaskId(
  id: string
): ApiResponse<MinimalYodaDTO[]> {
  const filter =
    '{"filter":{"conditions":[{"property": "task.id","value": "' +
    id +
    '","operator": "="},{"property": "actual","value": true,"operator": "="}]},"view": "_minimal"}';
  const url = HOST + '/rest/v2/entities/enerstroymain_MeterChange/search';
  const token = await yodaGetAccessToken();
  return axios.post(url, filter, {
    headers: {
      Authorization: `Bearer ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function ApiFindTasksByAccNum(
  accountNum: string,
  address: string,
  flatNumber: string
): ApiResponse<TaskCC[]> {
  const url =
    HOST +
    `/rest/callcentre/get` +
    '?accountNumber=' +
    accountNum +
    '&address=' +
    address +
    '&apartment=' +
    flatNumber;
  return axiosGet(url);
}

export async function ApiFindSuggestionsAddress(address: string): ApiResponse<string[]> {
  const url = HOST + `/rest/callcentre/getSuggestionsAddress` + '?address=' + address;
  return axiosGet(url);
}

export async function ApiFindSuggestionsAddressForPNR(address: string): ApiResponse<string[]> {
  const url = HOST + `/rest/house/address/get-suggestions-address` + '?address=' + address;
  return axiosGet(url);
}

export async function ApiGetTaskInfoById(taskInfoId: string): ApiResponse<TaskCC> {
  const url = HOST + `/rest/callcentre/getById` + '?taskInfoId=' + taskInfoId;
  return axiosGet(url);
}

export async function ApiGetTaskTkoByTaskInfoId(taskInfoId: string): ApiResponse<TaskCCTko[]> {
  const url = HOST + `/rest/callcentre/getTkoByTaskInfoId` + '?taskInfoId=' + taskInfoId;
  return axiosGet(url);
}

export async function ApiGetTkoStatusList(): ApiResponse<TkoStatusDto[]> {
  const url = HOST + `/rest/callcentre/get-task-tko-statuses`;
  return axiosGet(url);
}

export async function ApiGetWorkTypes(callTypeIds: string[]): ApiResponse<CallCenterWorkType[]> {
  const url = HOST + `/rest/callcentre/getWorkTypes` + '?callTypeIds=' + callTypeIds;
  return axiosGet(url);
}

export async function ApiGetCCContracts(): ApiResponse<CCContractDto[]> {
  const url = HOST + `/rest/callcentre/getContracts`;
  return axiosGet(url);
}

export async function ApiGetCallTypes(): ApiResponse<CallCenterCallType[]> {
  const url = HOST + `/rest/callcentre/getCallTypes`;
  return axiosGet(url);
}

export async function ApiSaveCallCenterRequest(
  callCenterRequest: CallCenterRequest
): ApiResponse<string> {
  const url = HOST + `/rest/callcentre/saveCallCenterRequest`;
  return axiosPost(url, callCenterRequest);
}

export async function ApiCreateGuarantee(guaranteeBodyDto: GuaranteeBodyDto): ApiResponse<string> {
  const url = HOST + `/rest/callcentre/create-guarantee`;
  return axiosPost(url, guaranteeBodyDto);
}

export async function ApiSaveCallCenterRefund(refundDto: CallCenterRefundDto): ApiResponse<string> {
  const url = HOST + `/rest/callcentre/save-refund`;
  return axiosPost(url, refundDto);
}

export async function ApiSaveRemoteSolution(
  refundDto: CallCenterRemoteSolutionDto
): ApiResponse<string> {
  const url = HOST + `/rest/callcentre/remote-solution`;
  return axiosPost(url, refundDto);
}

export async function ApiSaveSendToBilling(
  refundDto: CallCenterSendedToBillingDto
): ApiResponse<string> {
  const url = HOST + `/rest/callcentre/send-to-billing`;
  return axiosPost(url, refundDto);
}

export async function ApiSaveCallCenterTko(tkoToSave: TkoToSaveDto): ApiResponse<string> {
  const url = HOST + `/rest/callcentre/save-tko`;
  return axiosPost(url, tkoToSave);
}

export async function ApiSaveCallCenterTaskComment(
  taskComment: CallCenterTaskCommentDto
): ApiResponse<string> {
  const url = HOST + `/rest/callcentre/save-task-comment`;
  return axiosPost(url, taskComment);
}

export async function ApiFindGroupByChildName(childName: string): ApiResponse<any> {
  const url = HOST + '/rest/group/parent' + '?childName=' + childName;
  return axiosGet(url);
}
export async function ApiFindCurrentGroup(): ApiResponse<any> {
  const url = HOST + '/rest/user/current/group';
  return axiosGet(url);
}

export async function findAllNetworkLocationResponse(
  geoBounds: any,
  toCleanCache: boolean
): ApiResponse<NetworkResponse[]> {
  const url = HOST + `/rest/network-polling/search?toCleanCache=${toCleanCache}`;
  const token = await yodaGetAccessToken();
  return axios.post(url, geoBounds, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}
export async function GetUSPDXlsx(filter: {
  polygon?: null | string;
  networkPollingUspdId?: null | number;
}): ApiResponse<any> {
  const url = HOST + '/rest/network-polling/report';
  return axiosPost(url, filter, true);
}
export async function updateUSPD(uspdId: number, body: any): ApiResponse<NetworkResponse[]> {
  const url = HOST + `/rest/network-polling/${uspdId}`;
  const token = await yodaGetAccessToken();
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function createNewUSPD(newUSPD: any): ApiResponse<any> {
  const url = HOST + `/rest/network-polling`;
  const token = await yodaGetAccessToken();
  return axios.post(url, newUSPD, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}
export async function deleteUSPD(id: any): ApiResponse<any> {
  const url = HOST + `/rest/network-polling/${id}`;
  const token = await yodaGetAccessToken();
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function ApiFindHouseBrowseData(
  filter: HouseDataFilterParam
): ApiResponse<HouseDataBrowseDto[]> {
  const url = HOST + `/rest/house/find-by-filter`;
  return axiosPost(url, filter);
}

export async function ApiCountOfHouseBrowseData(filter: HouseDataFilterParam): ApiResponse<number> {
  const url = HOST + `/rest/house/count`;
  return axiosPost(url, filter);
}

export async function ApiFindHouseData(id: string): ApiResponse<HouseDataDto> {
  const url = HOST + `/rest/house/${id}`;
  return axiosGet(url);
}

export async function ApiFindHouseAddressChanges(id: string): ApiResponse<HouseAdressesChangesDto> {
  const url = HOST + `/rest/house/address/${id}/addresses`;
  return axiosGet(url);
}

export async function ApiFindHouseRelevantTaskDtos(
  id: string,
  currentYear?: boolean
): ApiResponse<HouseRelevantTaskDto[]> {
  const url = HOST + `/rest/house/${id}/relevant?isCurrentYear=${currentYear ? 'true' : 'false'}`;
  return axiosGet(url);
}

export async function ApiFindHouseOrders(id: string): ApiResponse<HouseOrderDto[]> {
  const url = HOST + `/rest/house/${id}/orders`;
  return axiosGet(url);
}

export async function ApiPnrFindAddressDetails(id: string): ApiResponse<PnrAddressDetail> {
  const url = HOST + `/rest/house/address/${id}/main-address`;
  return axiosGet(url);
}

export async function ApiPnrChangeAddressDetail(
  id: string,
  newDetails: PnrAddressDetail
): ApiResponse<PnrAddressDetail> {
  const url = HOST + `/rest/house/address/${id}/main-address`;
  return axiosPut(url, newDetails);
}

export async function ApiCreateHouseOrders(
  houseId: string,
  order: CreateHouseOrderDto
): ApiResponse<CreateHouseOrderResponseDto> {
  const url = HOST + `/rest/house/${houseId}/order`;
  return axiosPost(url, order);
}

export async function ApiFindHousePlannings(id: string): ApiResponse<PlannedInstalationsDto[]> {
  const url = HOST + `/rest/house/${id}/plannings`;
  return axiosGet(url);
}

export async function ApiFindHouseLayout(id: string): ApiResponse<HouseLayoutDto> {
  const url = HOST + `/rest/house/${id}/layout`;
  return axiosGet(url);
}

export async function ApiUpdateHouseLayout(
  id: string,
  layout: HouseLayoutDto
): ApiResponse<HouseLayoutDto> {
  const url = HOST + `/rest/house/${id}/layout`;
  return axiosPut(url, layout);
}

export async function ApiResetHouseLayout(id: string): ApiResponse<HouseLayoutDto> {
  const url = HOST + `/rest/house/${id}/layout`;
  return axiosDelete(url);
}

export async function ApiUpdateHouseEntranceEnumeration(
  id: string,
  startingNumber: string
): ApiResponse<HouseLayoutDto> {
  const url = HOST + `/rest/house/${id}/layout/enumeration?startingNumber=${startingNumber}`;
  return axiosPut(url, {});
}

export async function ApiFormHousePlannings(id: string): ApiResponse<PlannedInstalationsDto[]> {
  const url = HOST + `/rest/house/${id}/blueprint/primary`;
  return axiosPost(url, {});
}

export async function ApiFormHousePrePlannings(id: string): ApiResponse<PlannedInstalationsDto[]> {
  const url = HOST + `/rest/house/${id}/blueprint/secondary`;
  return axiosPost(url, {});
}

export async function ApiSaveHousePlannings(
  houseId: string,
  plannings: SaveHousePlanningsDto
): ApiResponse<PlannedInstalationsDto[]> {
  const url = HOST + `/rest/house/${houseId}/plannings`;
  return axiosPost(url, plannings);
}

export async function ApiDeleteHousePlannings(
  id: string,
  secondary?: boolean
): ApiResponse<PlannedInstalationsDto[]> {
  const url = HOST + `/rest/house/${id}/blueprint${secondary ? '/secondary' : '/primary'}`;
  return axiosDelete(url);
}

export async function ApiFindHouseOdpuReplacement(
  id?: string
): ApiResponse<HouseDataOdpuReplacement[]> {
  const url = HOST + `/rest/house/${id}/odpu/replacement`;
  return axiosGet(url);
}

export async function ApiFindHouseOdpuReplacementCount(id?: string): ApiResponse<number> {
  const url = HOST + `/rest/house/${id}/odpu/replacement/count`;
  return axiosGet(url);
}

export async function ApiUpdateHouseData(
  houseId: string,
  floorCount: number,
  entranceCount: number,
  flatCount: number,
  address?: string,
  puConnectType?: string | null
): ApiResponse<HouseDataDto> {
  const url = HOST + `/rest/house/`;
  return axiosPut(url, { houseId, floorCount, entranceCount, flatCount, address, puConnectType });
}

export async function ApiSaveAddedNetworkOS(
  networkElementOS: NetworkElementsTaskWithComment
): ApiResponse<string> {
  const url = HOST + `/rest/task/network-elements-with-comments`;
  return axiosPost(url, networkElementOS);
}

export async function ApiUpdateAddressDetail(
  taskAddressDetail: TaskAddressDetail
): ApiResponse<TaskAddressDetail> {
  const url = HOST + `/rest/address-table/detail-address`;
  return axiosPut(url, taskAddressDetail);
}

export async function ApiMergeAddresses(
  houseIdsForMerge: string[],
  houseId: string
): ApiResponse<TaskAddressDetail[]> {
  const url = HOST + `/rest/house/address/${houseId}/merge`;
  return axiosPost(url, houseIdsForMerge);
}

export async function ApiRefreshAddressDetail(
  taskAddressDetail: TaskAddressDetail
): ApiResponse<TaskAddressDetail> {
  const url = HOST + `/rest/address-table/refresh-address`;
  return axiosPost(url, taskAddressDetail);
}

export async function ApiGetAddressDetailByTaskId(taskId: string): ApiResponse<TaskAddressDetail> {
  const url = HOST + `/rest/address-table/detail-address/${taskId}`;
  return axiosGet(url);
}

export async function ApiGetAddressDetailCoordsByTaskId(
  taskId: string
): ApiResponse<DadataResponseDto> {
  const url = HOST + `/rest/address-table/detail-address-coord/${taskId}`;
  return axiosGet(url);
}

export async function ApiGetOdpuManagementCompanyById(
  id: string
): ApiResponse<OdpuManagementCompanyResponse> {
  const url = HOST + `/rest/odpu-management-company/${id}`;
  return axiosGet(url);
}
export async function GetCallCenterRequestBashkortostanbyTaskId(
  taskId: string
): ApiResponse<CallCenterBashkortostanRequest> {
  const url = HOST + `/rest/callcentre/bashkortostan/${taskId}`;
  return axiosGet(url);
}

export async function UpdateCallCenterRequestScheduledDate(
  request: CallCenterScheduledDateDto
): ApiResponse<any> {
  const url = HOST + '/rest/callcentre/bashkortostan/updateScheduledDate';
  return axiosPost(url, request);
}

export async function DownloadHouseDataReport(houseDataId: string): ApiResponse<ArrayBuffer> {
  const url = HOST + `/rest/report/house_data/${houseDataId}/installed_equipments`;
  return axiosGetExl(url);
}

export async function GetCurrentUser(): ApiResponse<UserResponse> {
  const url = HOST + '/rest/user/current';
  return axiosGet(url);
}
export async function GetScheduleDate(body: any): ApiResponse<any> {
  const url = HOST + '/rest/task/customer-schedule-date';
  return axiosPost(url, body);
}

export async function DownloadStatisticDistributionExel(
  body: FilterStatisticDistribution
): ApiResponse<ArrayBuffer> {
  const url = HOST + `/rest/statistics/distribution/contract/month/report`;
  return axiosGetExlByPost(url, body);
}
export async function DownloadStatisticUrgentAndSuperUrgentExel(
  body: StatisticUrgentAndSuperUrgentTasksDto[]
): ApiResponse<ArrayBuffer> {
  const url = HOST + `/rest/statistics/task-urgent-excel`;
  return axiosGetExlByPost(url, body);
}

export async function GetCallingMapOperator(): ApiResponse<CallingMapOperatorCredentials> {
  const url = HOST + '/rest/installation-call/operator';
  return axiosGet(url);
}

export async function GetJWT(): ApiResponse<string> {
  const url = HOST + '/rest/jwt';
  return axiosGet(url);
}

export async function GetTaskByMeter({
  type,
  mac,
  number,
}: TaskByMeterRequestDTO): ApiResponse<TaskByMeterResponseDTO[]> {
  const url =
    HOST + `/rest/task/meter?mac=${mac || null}&number=${number || null}&type=${type || null}`;

  return axiosGet(url);
}
