import React, { useEffect, useState } from 'react';
import {
  ComplaintCommentButton,
  ComplaintCommentIconButton,
  ComplaintCommentsWrapper,
  ComplaintCommentWrapper,
} from './ComplaintComment.styled';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { Grid, Stack, TextField, Typography, CircularProgress } from '@mui/material';
import { color } from '@/styles/mixins';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  createComment,
  getAllCommentsByComplaintId,
} from '@/services/ComplaintService/ComplaintService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useComplaintStore } from '../store';
import shallow from 'zustand/shallow';
import moment from 'moment';
import { CommentCompalaint } from '../types';
import { useComplaintsEditAccess } from '../roles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ComplaintComment = () => {
  const hasAccessToEdit = useComplaintsEditAccess();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [open, setOpen] = useState(!isMobile);
  const [text, setText] = useState('');
  const [comments, setComments] = useState<CommentCompalaint[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { addActionLog, fetchCatch } = useActionLog();

  const { selectedCompliant } = useComplaintStore(
    (state) => ({
      selectedCompliant: state.selectedCompliant,
    }),
    shallow
  );
  const selectedCompliantId = selectedCompliant?.complaint?.base?.id;

  const handleChange = (event: any) => {
    setText(event.target.value);
  };
  const onKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleSaveComment();
    }
  };

  const handleSaveComment = () => {
    if (!selectedCompliantId || !hasAccessToEdit || !text) return;
    setIsLoading(true);
    createComment({ text }, selectedCompliantId)
      .then((res) => {
        addActionLog(ActionLogType.SUCCESS, 'Комментарий сохранен');
        getComments();
      })
      .catch((err) => fetchCatch(err))
      .finally(() => {
        setText('');
        setIsLoading(false);
      });
  };

  const getComments = () => {
    if (selectedCompliantId) {
      getAllCommentsByComplaintId(selectedCompliantId).then(({ data }) => {
        setComments(data.content);
      });
    }
  };

  const handleClick = () => setOpen(!open);

  useEffect(() => {
    getComments();
  }, [selectedCompliantId]);

  return (
    <ComplaintCommentWrapper open={open}>
      <ComplaintCommentButton onClick={handleClick}>
        <CommentOutlinedIcon />
      </ComplaintCommentButton>
      <ComplaintCommentsWrapper open={open}>
        <Stack>
          <Typography
            variant='subtitle1'
            color={color('trueBlack')}
          >
            Комментарии
          </Typography>
          <Stack
            maxHeight={300}
            overflow={'auto'}
          >
            {comments.map((el) => {
              return (
                <Stack key={el.base.id}>
                  <Grid
                    container
                    spacing={1}
                  >
                    <Grid
                      item
                      xs={6}
                    >
                      <Typography
                        variant='subtitle2'
                        color={color('muiBlue')}
                      >
                        {moment(el.base.createAt).format('DD.MM.YYYYY')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                    >
                      <Typography
                        textAlign={'end'}
                        variant='subtitle2'
                        color={color('muiBlue')}
                      >
                        {el.base.createBy || 'автор'}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography variant='subtitle2'>{el.text}</Typography>
                    </Grid>
                  </Grid>
                </Stack>
              );
            })}
            <Grid
              item
              xs={10}
              display={'flex'}
              alignItems={'flex-end'}
              paddingBottom={3}
            >
              <TextField
                multiline
                variant='standard'
                fullWidth
                value={text}
                disabled={!hasAccessToEdit}
                onChange={handleChange}
                onKeyPress={onKeyPress}
              />

              <ComplaintCommentIconButton
                onClick={handleSaveComment}
                color='success'
                disabled={!text || !hasAccessToEdit}
              >
                {isLoading ? <CircularProgress size={24} /> : <CheckCircleOutlineIcon />}
              </ComplaintCommentIconButton>
            </Grid>
          </Stack>
        </Stack>
      </ComplaintCommentsWrapper>
    </ComplaintCommentWrapper>
  );
};

export default ComplaintComment;
