import { useQuery } from 'react-query';
import { KEYS } from './Keys';
import { useActionLog } from '@/hooks/ActionLogHook';

import {
  ApiFindHouseOdpuReplacement,
  ApiFindHouseOdpuReplacementCount,
} from '@/services/YodaRestService';

export function useHouseOdpuReplacement(id?: string) {
  const { fetchCatch } = useActionLog();
  const { data: axiosResponse, isFetching } = useQuery({
    queryKey: [...KEYS.houseOdpuReplacement, id],
    staleTime: Infinity,
    enabled: !!id,
    queryFn: () => ApiFindHouseOdpuReplacement(id),
    onError: (error) => fetchCatch(error, 'Ошибка загрузки информации ОДПУ'),
  });

  //Решение проблемы с ключами datagrid- значения возвращаемых с бэка объектов могут дублироваться.
  const odpuReplacementWithTmpId = axiosResponse?.data?.map((el, index) => ({
    ...el,
    tmpId: index,
  }));

  return {
    odpuReplacement: odpuReplacementWithTmpId,
    isFetching,
  };
}

export function useHouseOdpuReplacementCount(id?: string) {
  const { fetchCatch } = useActionLog();
  const { data: axiosResponse, isFetching } = useQuery({
    queryKey: [...KEYS.houseOdpuReplacement, 'count', id],
    staleTime: Infinity,
    enabled: !!id,
    queryFn: () => ApiFindHouseOdpuReplacementCount(id),
    onError: (error) => fetchCatch(error, 'Ошибка загрузки информации ОДПУ'),
  });

  return {
    odpuReplacementCount: axiosResponse?.data,
    isLoadingOdpuReplacementCount: isFetching,
  };
}
