import React, { useState } from 'react';
import { SupplyPUVirtualizedTableProps } from './SupplyPUVirtualizedTable';
import {
  SupplyPUTableCell,
  SupplyPUComponentTableRowWrapper,
  SupplyPUTableInputCell,
  SupplyPUTableInput,
  SupplyPURowButton,
} from './SupplyPUComponent.styled';
import {
  DOUBLE_NUMBER_METER_TYPE_CELL_DATA_ATTRIBUTE,
  DOUBLE_NUMBER_METER_TYPE_SPLITTER,
  focusOnCell,
  PU_SCAN_FIELD,
  splitDoubleNumber,
  SupplyPUItemConfirmState,
  useAdminAccess,
  useStorehouseWorkerAccess,
} from './utils';
import SupplyReturnBillExtraColumns from '../SupplyReturnBillExtraColumns/SupplyReturnBillExtraColumns';
import { playAudioByKey } from '@/utils/heplers';
import shallow from 'zustand/shallow';
import useSupplyActiveRow from '../SupplyActiveRow/store';
import { EquipmentTypes } from '../utils';

export interface SupplyPUTableProps {
  index: number;
  style: React.CSSProperties;
  data: Omit<SupplyPUVirtualizedTableProps, 'confirmScanedNumber' | 'setPUListItems'>;
}

const SupplyPURow = ({
  index,
  style,
  data: {
    puListItems,
    removeScanRow,
    handleSave,
    onEditStart,
    disabledOrDraftCheck,
    selectedPU,
    osDupFieldError,
    noYodaTaskFieldError,
    osExistsFieldError,
    returnBill,
    showMessage,
    confirmScanRow,
    viewMode,
    billWithConfirm,
    relocationGetBill,
    tabType,
    accessProject,
  },
}: SupplyPUTableProps) => {
  const workerAccess = useStorehouseWorkerAccess();
  const adminAccess = useAdminAccess();
  const { setActiveRowIndex, activeRowIndex } = useSupplyActiveRow(
    (state) => ({
      setActiveRowIndex: state.setActiveRowIndex,
      activeRowIndex: state.activeRowIndex,
    }),
    shallow
  );

  const row = puListItems[index];
  const puScanField = (selectedPU?.meterTypeScanField ?? 'number').toLowerCase() as PU_SCAN_FIELD;

  const [macNumber, setMacNumber] = useState(() => {
    let value = row[puScanField];
    if (selectedPU?.isRim) {
      const { number1 } = splitDoubleNumber(value);
      value = number1;
    }
    return value || '';
  });

  const [macNumber2, setMacNumber2] = useState(() => {
    let value = row[puScanField];
    if (selectedPU?.isRim) {
      const { number2 } = splitDoubleNumber(value);
      value = number2;
    }
    return value || '';
  });

  const error =
    osDupFieldError?.get(selectedPU?.tmpId ?? -1)?.get(row.tmpId) ||
    osExistsFieldError?.get(selectedPU?.tmpId ?? -1)?.get(row.tmpId) ||
    noYodaTaskFieldError?.get(selectedPU?.tmpId ?? -1)?.get(row.tmpId);

  const handleBlur = ({ withSound }: { withSound?: boolean }) => {
    let puValue = '';
    if (selectedPU?.isRim && macNumber.length && macNumber2.length) {
      puValue = `${macNumber}${DOUBLE_NUMBER_METER_TYPE_SPLITTER}${macNumber2}`;
    }

    if (selectedPU?.isRim && returnBill) {
      puValue = `${macNumber}${DOUBLE_NUMBER_METER_TYPE_SPLITTER}${macNumber2}`;
    }
    if (!selectedPU?.isRim) {
      puValue = macNumber;
    }

    handleSave({
      ...row,
      [puScanField]: puValue,
    });
    if (withSound) {
      playAudioByKey('attention');
    }
  };
  const handleFocus = () => onEditStart({ ...row });
  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, rowId: number) => {
    e.stopPropagation();
    row?.tmpId && removeScanRow(rowId);
    setActiveRowIndex(null);
  };
  const handleConfirm = (rowId: number) => row?.tmpId && confirmScanRow(rowId);

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const selectNextRow = () => {
        const nextRowCell = puListItems[index + 1]?.tmpId;
        nextRowCell && focusOnCell({ cellRowId: nextRowCell });
      };
      if (selectedPU?.isRim) {
        const cellDoubleNumberStage =
          e.currentTarget.dataset[DOUBLE_NUMBER_METER_TYPE_CELL_DATA_ATTRIBUTE]!;
        if (cellDoubleNumberStage === '1') {
          const currentRowId = puListItems[index]?.tmpId;
          currentRowId && focusOnCell({ cellRowId: currentRowId, secondNumber: true });
        } else {
          selectNextRow();
        }
      } else {
        selectNextRow();
      }
    }
  };

  const disableWorkerAccess = workerAccess && returnBill && !adminAccess;
  const disableNumberInput = disabledOrDraftCheck || disableWorkerAccess || relocationGetBill;
  const handleActiveRow = () => {
    if (tabType === 'return') {
      setActiveRowIndex(row?.tmpId);
    }
  };

  return (
    <div
      style={style}
      onClick={handleActiveRow}
    >
      <SupplyPUComponentTableRowWrapper
        error={!!error}
        unSavedItem={!row.backendItem}
        className='SupplyVirtualizedTable_Row'
        active={activeRowIndex === row?.tmpId}
      >
        <SupplyPUTableCell
          withoutPadding
          narrow
        >
          {row.rowNum}
        </SupplyPUTableCell>
        <SupplyPUTableCell>{selectedPU?.meterName}</SupplyPUTableCell>
        {selectedPU?.isRim ? (
          <>
            <SupplyPUTableInputCell
              confirmed={
                !error &&
                (row.confirmed === SupplyPUItemConfirmState.firstNumber ||
                  row.confirmed === SupplyPUItemConfirmState.bothNumbers)
              }
              onBlur={() => handleBlur({ withSound: false })}
            >
              <SupplyPUTableInput
                type='text'
                name='mac/number'
                value={macNumber}
                onChange={(e) => setMacNumber(e.target.value)}
                onFocus={handleFocus}
                onKeyDown={handleEnter}
                autoComplete='off'
                data-tmpid={row.tmpId}
                data-double-number='1'
                disabled={disableNumberInput}
              />
            </SupplyPUTableInputCell>
            <SupplyPUTableInputCell
              onBlur={() => handleBlur({ withSound: true })}
              confirmed={
                !error &&
                (row.confirmed === SupplyPUItemConfirmState.secondNumber ||
                  row.confirmed === SupplyPUItemConfirmState.bothNumbers)
              }
            >
              <SupplyPUTableInput
                type='text'
                name='mac/number'
                value={macNumber2}
                onChange={(e) => setMacNumber2(e.target.value)}
                onFocus={handleFocus}
                onKeyDown={handleEnter}
                autoComplete='off'
                data-tmpid={row.tmpId}
                data-double-number='2'
                disabled={disableNumberInput}
              />
            </SupplyPUTableInputCell>
          </>
        ) : (
          <SupplyPUTableInputCell
            onBlur={() => handleBlur({ withSound: false })}
            confirmed={
              !error &&
              (row.confirmed === SupplyPUItemConfirmState.firstNumber ||
                row.confirmed === SupplyPUItemConfirmState.bothNumbers)
            }
          >
            <SupplyPUTableInput
              type='text'
              name='mac/number'
              value={macNumber}
              onChange={(e) => setMacNumber(e.target.value)}
              onFocus={handleFocus}
              onKeyDown={handleEnter}
              autoComplete='off'
              data-tmpid={row.tmpId}
              disabled={disableNumberInput}
            />
          </SupplyPUTableInputCell>
        )}
        {returnBill && (
          <SupplyReturnBillExtraColumns
            handleSave={handleSave}
            row={row}
            disabled={disabledOrDraftCheck && !accessProject}
            showMessage={showMessage}
            type={EquipmentTypes.PU}
            selectedEquipment={selectedPU}
          />
        )}

        {!viewMode && (
          <>
            {tabType !== 'relocation_get' && (
              <SupplyPUTableCell>
                <SupplyPURowButton
                  disabled={disabledOrDraftCheck}
                  onClick={(e) => {
                    handleDelete(e, row.tmpId);
                  }}
                >
                  Удалить
                </SupplyPURowButton>
              </SupplyPUTableCell>
            )}

            {billWithConfirm && workerAccess && (
              <SupplyPUTableCell>
                <SupplyPURowButton
                  disabled={disabledOrDraftCheck && !relocationGetBill}
                  onClick={() => handleConfirm(row.tmpId)}
                  errorBorder={
                    selectedPU?.isRim
                      ? row.confirmed !== SupplyPUItemConfirmState.bothNumbers
                      : !row.confirmed
                  }
                >
                  Подтвердить
                </SupplyPURowButton>
              </SupplyPUTableCell>
            )}
          </>
        )}
      </SupplyPUComponentTableRowWrapper>
    </div>
  );
};

export default SupplyPURow;
