import { Card, CardMedia, Grid, Paper, Typography, CardActionArea } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import analysis from '../../../assets/ОДПУ.png';
import asumb from '../../../assets/АСУМБ.png';
import callCenter from '../../../assets/Статистика взаимодействий с абоненом.png';
import statistic from '../../../assets/Статистика заявок.png';
import investStatistic from '../../../assets/Инвест программа.png';
import { StatisticCardContent, StatisticCardMediaWrapper } from './StatisticCard.styled';

interface StatisticCardProps {
  title: string;
  img: string;
  navigateTo: string;
}
const images = [
  { id: 'analysis', imeg: analysis },
  { id: 'asumb', imeg: asumb },
  { id: 'callCenter', imeg: callCenter },
  { id: 'statistic', imeg: statistic },
  { id: 'investStatistic', imeg: investStatistic },
];

function StatisticCard({ title, img, navigateTo }: StatisticCardProps) {
  const navigate = useNavigate();
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      onClick={() => navigate(navigateTo)}
    >
      <CardActionArea>
        <Paper
          elevation={6}
          sx={{ borderRadius: '10px' }}
        >
          <CardMedia
            component='img'
            src={images.find((i) => i.id === img)?.imeg}
            alt={img}
            style={{ width: '100%', height: 'auto' }} // Set width to 90% and let height adjust
          />
        </Paper>
      </CardActionArea>
    </Grid>
  );
}

export default StatisticCard;
