import React, { useEffect, useMemo } from 'react';
import { useForm, Controller, FieldValues, FormProvider } from 'react-hook-form';
import { TextField, Button, Box, Grid, Autocomplete, Typography } from '@mui/material';
import { DateRange } from '@/components/inputs/DateRange';
import { useCatalog } from '@/hooks/CatalogHook';
import {
  createComplaintEnergy,
  editComplaintEnergy,
} from '@/services/ComplaintService/ComplaintService';
import { color } from '@/styles/mixins';
import { useComplaintStore } from '../store';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { DEFAULT_ACCEPTED_EXTENSIONS_COMPLAINTS, tariffOptions } from '../utils';
import FileInput from '@/components/inputs/FileInput/FileInput';
import shallow from 'zustand/shallow';
import { ComplaintEnergyClassification } from '@/services/ComplaintService/ComplaintServiceDto';
import { ComplaintFormODPUDatePickerFixWrapper } from '../ComplaintFormODPU/ComplaintFormODPU.styled';
import { handleChangeAccount, useComplainFormEnergyValidation } from '../forms';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { MainText } from '../ComplaintAddButton/ComplaintAddButton.styled';
import { formatPhoneNumberMask } from '@/utils/heplers';
import { useFormErrorToScrollUp } from '@/hooks/useFormErrorToScrollUp';
interface ComplaintFormEnergyProps {
  closeModal: () => void;
}

const ComplaintFormEnergy = ({ closeModal }: ComplaintFormEnergyProps) => {
  const {
    energyTypeList,
    classificationList,
    selectedCompliant,
    fetchSingleComplaint,
    addNewComplaint,
    setSelectedCompliant,
  } = useComplaintStore(
    (state) => ({
      setNewCreatedComplaint: state.setNewCreatedComplaint,
      energyTypeList: state.energyTypeList,
      classificationList: state.classificationList,
      selectedCompliant: state.selectedCompliant,
      setSelectedCompliant: state.setSelectedCompliant,
      fetchSingleComplaint: state.fetchSingleComplaint,
      addNewComplaint: state.addNewComplaint,
    }),
    shallow
  );
  const { fetchCatch, addActionLog } = useActionLog();
  const { setIsInProgress, isInProgress } = useScreenHoldHook();
  const [responseDate, setResponseDate] = React.useState<any>([]);

  const complaintType = selectedCompliant?.complaint.type;
  const selectedCompliantId = selectedCompliant?.complaint?.base?.id;

  const onSubmit = (data: FieldValues) => {
    const files = getValues('files');

    if (
      files?.length === 0 &&
      !selectedCompliant &&
      data.classification !== ComplaintEnergyClassification.ISSUE_DD &&
      data.classification !== ComplaintEnergyClassification.TRANSFER_CONSUMER_INTO_ACCOUNT
    ) {
      setError('files', { message: 'Файл обязателен для загрузки' });
      return;
    }
    const formData = new FormData();
    data.responseDate = moment(data?.responseDate).format('YYYY-MM-DD');

    files?.forEach((file: any) => {
      formData.append('files', file);
    });

    data.phoneNumberAdditional = data.phoneNumberAdditional || null;

    formData.append('request', new Blob([JSON.stringify(data)], { type: 'application/json' }));
    setIsInProgress(true);
    if (!selectedCompliant) {
      createComplaintEnergy(formData)
        .then(({ data }) => {
          addActionLog(ActionLogType.SUCCESS, 'Успешно');
          addNewComplaint(data);
          closeModal();
        })
        .catch((error) => {
          fetchCatch(error);
        })
        .finally(() => {
          setIsInProgress(false);
        });
    } else {
      if (!selectedCompliantId) return;
      // запрос на сервер измененеия
      editComplaintEnergy(data, selectedCompliant.complaint.base.id)
        .then(({ data }) => {
          setSelectedCompliant(data);
          addActionLog(ActionLogType.SUCCESS, 'Успешно');
          fetchSingleComplaint(selectedCompliantId, complaintType);
          closeModal();
        })
        .catch((error) => {
          fetchCatch(error);
        })
        .finally(() => {
          setIsInProgress(false);
        });
    }
  };

  const { getCatalogMapWithErr, regionalElectricNetwork } = useCatalog(
    (state) => ({
      getCatalogMapWithErr: state.getCatalogMapWithErr,
      energyCompany: state.energyCompany,
      regionalElectricNetwork: state.regionalElectricNetwork,
    }),
    shallow
  );

  const optionsRes = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('regionalElectricNetwork')),
    [regionalElectricNetwork]
  );

  const typeCatalogOptions = energyTypeList.map((el: any) => ({ value: el.name, label: el.title }));
  const classificationOptions = classificationList.map((el: any) => ({
    value: el.name,
    label: el.title,
  }));

  const methods = useForm();
  const {
    register,
    setValue: setFileValue,
    getValues,
    setError,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = methods;
  const validationRules = useComplainFormEnergyValidation(watch);

  useEffect(() => {
    register('responseDate');
  }, [register]);

  const onHandleScheduledDate = (newValue: number[] | null) => {
    if (newValue && newValue[0]) {
      setResponseDate(newValue[0]);
      setValue('responseDate', newValue[0]);
    }
  };
  useEffect(() => {
    if (selectedCompliant) {
      setValue('responseDate', selectedCompliant?.responseDate);
      setValue('energyType', selectedCompliant?.energyType?.name);
      setValue('tariffication', selectedCompliant?.tariffication);
      setValue('res', selectedCompliant?.res);
      setValue('classification', selectedCompliant?.classification?.name);
      setValue('region', selectedCompliant?.complaint?.address?.region);
      setValue('area', selectedCompliant?.complaint?.address?.area);
      setValue('locality', selectedCompliant?.complaint?.address?.locality);
      setValue('street', selectedCompliant?.complaint?.address?.street);
      setValue('house', selectedCompliant?.complaint?.address?.house);
      setValue('apartment', selectedCompliant?.complaint?.address?.apartment);
      setResponseDate(selectedCompliant.responseDate);
    }
  }, [selectedCompliant]);

  useFormErrorToScrollUp(Object.keys(errors));

  return (
    <FormProvider {...methods}>
      <Box>
        {selectedCompliant && <MainText>Для изменения жалобы внесите необходимые данные</MainText>}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            mt={1}
          >
            <Grid
              item
              xs={12}
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={4}
              >
                <Controller
                  name='energyType'
                  control={control}
                  rules={validationRules.energyType}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id='energyType'
                      fullWidth
                      options={typeCatalogOptions}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      value={{
                        value: field?.value || selectedCompliant?.energyType?.title || '',
                        label:
                          typeCatalogOptions.find(
                            (el) =>
                              el.value === field?.value ||
                              el.value === selectedCompliant?.energyType?.name
                          )?.label || '',
                      }}
                      onChange={(event: any, newValue: any) => {
                        field?.onChange(newValue?.value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          name='typeCatalog'
                          {...params}
                          label={'Вид обращения'}
                          variant='standard'
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Controller
                name='classification'
                control={control}
                rules={validationRules.classification}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    id='classification'
                    fullWidth
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    options={classificationOptions}
                    value={{
                      value: field?.value ?? '',
                      label:
                        classificationOptions.find(
                          (el: any) =>
                            el.value === field?.value ||
                            el.value === selectedCompliant?.classification?.name
                        )?.label || '',
                    }}
                    onChange={(event: any, newValue: any) => {
                      field?.onChange(newValue?.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        name='classification'
                        {...params}
                        label={'Классификация'}
                        variant='standard'
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Controller
                name='sapNumber'
                control={control}
                rules={validationRules.sapNumber}
                defaultValue={selectedCompliant?.sapNumber}
                render={({ field, fieldState }) => (
                  <TextField
                    id='sapNumber'
                    {...field}
                    fullWidth
                    label='Номер САП'
                    variant='standard'
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <ComplaintFormODPUDatePickerFixWrapper
              item
              xs={12}
              md={4}
            >
              <Controller
                name='responseDate'
                control={control}
                rules={validationRules.responseDate}
                render={({ field, fieldState }) => (
                  <DateRange
                    id='responseDate'
                    label='Срок ответа'
                    onChange={onHandleScheduledDate}
                    value={[responseDate]}
                    argValue={2}
                    showSelect={false}
                    showCalenderIcon={true}
                    style={{
                      width: '100%',
                      borderBottomWidth: '1px',
                      borderBottomColor: fieldState.error ? color('muiError') : '',
                    }}
                    showClearButton={false}
                    labelstyle={{ color: fieldState.error ? color('muiError') : '' }}
                    errorText={fieldState.error?.message}
                  />
                )}
              />
            </ComplaintFormODPUDatePickerFixWrapper>
            <Grid
              item
              mt={3}
              xs={12}
              md={12}
            >
              <Controller
                name='declarerFio'
                control={control}
                defaultValue={selectedCompliant?.declarerFio}
                rules={validationRules.declarerFio}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id='declarerFio'
                    fullWidth
                    label='ФИО заявителя'
                    variant='standard'
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Controller
                name='account'
                control={control}
                defaultValue={selectedCompliant?.account}
                rules={validationRules.account}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id='account'
                    fullWidth
                    label='Лицевой счет'
                    variant='standard'
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={handleChangeAccount(field.onChange)}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Controller
                name='phoneNumber'
                defaultValue={selectedCompliant?.phoneNumber}
                control={control}
                rules={validationRules.phoneNumber}
                render={({ field: { onChange, value }, fieldState }) => (
                  <TextField
                    id='phoneNumber'
                    onChange={(e) => onChange(formatPhoneNumberMask(e.target.value))}
                    value={value}
                    fullWidth
                    placeholder='+7'
                    label='Телефон'
                    variant='standard'
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Controller
                name='phoneNumberAdditional'
                defaultValue={selectedCompliant?.phoneNumberAdditional}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id={'phoneNumberAdditional'}
                    placeholder='+7'
                    fullWidth
                    label='Доп. телефон'
                    variant='standard'
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Controller
                name='meterType'
                control={control}
                defaultValue={selectedCompliant?.meterType}
                rules={validationRules.meterType}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id='meterType'
                    fullWidth
                    label='Тип ПУ'
                    variant='standard'
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Controller
                name='meterNumber'
                control={control}
                defaultValue={selectedCompliant?.complaint.meterNumber}
                rules={validationRules.meterNumber}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id={'meterNumber'}
                    fullWidth
                    label='№ прибора учета'
                    variant='standard'
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Controller
                name='tariffication'
                control={control}
                rules={validationRules.tariffication}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    id='tariffication'
                    {...field}
                    fullWidth
                    options={tariffOptions}
                    onChange={(event: any, newValue: any) => {
                      field?.onChange(newValue?.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        name='tariffication'
                        {...params}
                        label={'Тарификация'}
                        variant='standard'
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              container
              mt={2}
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={4}
              >
                <Controller
                  name='address.region'
                  control={control}
                  rules={validationRules.addressRegion}
                  defaultValue={selectedCompliant?.complaint.address.region || null}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      id='address'
                      fullWidth
                      label='Регион'
                      variant='standard'
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
              >
                <Controller
                  name='address.area'
                  rules={validationRules.addressArea}
                  control={control}
                  defaultValue={selectedCompliant?.complaint.address.area || null}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      id='address'
                      fullWidth
                      label='Район'
                      variant='standard'
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
              >
                <Controller
                  name='address.locality'
                  control={control}
                  rules={validationRules.addressLocality}
                  defaultValue={selectedCompliant?.complaint.address.locality}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      id='address'
                      fullWidth
                      label='Населеный пункт'
                      variant='standard'
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
              >
                <Controller
                  name='address.street'
                  rules={validationRules.addressStreet}
                  control={control}
                  defaultValue={selectedCompliant?.complaint.address.street || null}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      id='address'
                      fullWidth
                      label='Улица'
                      variant='standard'
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
              >
                <Controller
                  name='address.house'
                  rules={validationRules.addressHouse}
                  control={control}
                  defaultValue={selectedCompliant?.complaint.address.house}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      id='address'
                      fullWidth
                      label='Дом'
                      variant='standard'
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
              >
                <Controller
                  name='address.apartment'
                  rules={validationRules.addressApartment}
                  control={control}
                  defaultValue={selectedCompliant?.complaint.address.apartment || null}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      id='address'
                      fullWidth
                      label='Квартира'
                      variant='standard'
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              mt={3}
            >
              <Controller
                name='res'
                control={control}
                rules={validationRules.res}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    id='res'
                    fullWidth
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    options={optionsRes}
                    getOptionLabel={(option) => option.label}
                    value={{
                      value: field?.value ?? '',
                      label:
                        getCatalogMapWithErr('regionalElectricNetwork').get(field?.value) ??
                        getCatalogMapWithErr('regionalElectricNetwork').get(
                          selectedCompliant?.res || ''
                        ) ??
                        '',
                    }}
                    onChange={(event: any, newValue: any) => {
                      field?.onChange(newValue?.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        name='res'
                        {...params}
                        label={'РЭС'}
                        variant='standard'
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Controller
                name='text'
                control={control}
                defaultValue={selectedCompliant?.complaint.text}
                rules={validationRules.text}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id='text'
                    fullWidth
                    label='Текст обращения'
                    multiline
                    rows={4}
                    variant='standard'
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            {!selectedCompliant && (
              <Grid
                item
                xs={12}
                container
              >
                <Grid
                  item
                  xs={4}
                  display={'flex'}
                >
                  <FileInput
                    register={register('files')}
                    acceptedExtensions={DEFAULT_ACCEPTED_EXTENSIONS_COMPLAINTS}
                    setFormFiles={setFileValue}
                    disableEdit={!!selectedCompliant}
                  />
                </Grid>
              </Grid>
            )}

            <Grid
              item
              xs={6}
            >
              <LoadingButton
                variant='text'
                color='primary'
                type='submit'
                fullWidth
                loading={isInProgress}
                disabled={isInProgress}
              >
                Сохранить
              </LoadingButton>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Button
                variant='text'
                type='reset'
                fullWidth
                onClick={closeModal}
              >
                Отмена
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </FormProvider>
  );
};

export default ComplaintFormEnergy;
