import { Button, Grid, Skeleton, Typography } from '@mui/material';
import { TextField } from '@/components/fixUI';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { toFormateDate } from '@/utils/heplers';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { TaskInfoResponse, TaskResponse } from '@/dto/taskmap/task/TasksDto';
import { useCatalog } from '@/hooks/CatalogHook';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import DateField from '@/components/DateField';
import { useAllowedAccessByRole } from '@/hooks/useAccess';
import { RolesEnum } from '@/app/auth/roles';

const REGISTRATION_NUM_TYPES = [
  'FZ_522',
  'COMPLAINT_FZ_522',
  'MASS_INSTALLATION_FZ_522',
  'TECH_CONNECTION',
];
const TYPES_FOR_REGISTRATIONDATE = [
  'SMR_STOLBI',
  'FZ_522',
  'FZ_522_ISUR',
  'COMPLAINT_FZ_522',
  'MASS_INSTALLATION_FZ_522',
  'TECH_CONNECTION',
];

interface TaskInfoPanelProps {
  taskResponse: TaskResponse;
}

const arrTypeToShowRES = [
  'FZ_522',
  'PPO_TP',
  'SMR_STOLBI',
  'FZ_522_ISUR',
  'NETWORK_ELEMENTS_STOLBY',
  'MASS_INSTALLATION_FZ_522',
  'COMPLAINT_FZ_522',
  'TECH_CONNECTION',
];

export const TaskInfoPanel = (props: TaskInfoPanelProps) => {
  const { register, getValues, watch } = useFormContext();
  const [editable, setEditable] = useState(true);
  const [taskInfoResponse, setTaskInfoResponse] = useState<TaskInfoResponse>();
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  // const [energyCompanyDistinctOptions, setEnergyCompanyDistinctOptions] = useState<
  //   Map<string, string>
  // >(new Map());

  const [loading, setLoading] = useState(true);
  const { addActionLog, fetchCatch } = useActionLog();

  useEffect(() => {
    if (props.taskResponse) {
      const taskInfo: TaskInfoResponse = props.taskResponse.taskInfo as TaskInfoResponse;
      setTaskInfoResponse(taskInfo);
      setEditable(props.taskResponse.editable);
      setLoading(false);
    }

    // props.taskResponse?.contractResponse?.id &&
    //   energyCompanyDistinctResNameById(props.taskResponse.contractResponse.id)
    //     .then(({ data }) => {
    //       setEnergyCompanyDistinctOptions(convertIntoMap(data));
    //     })
    //     .catch((err) => fetchCatch(err,'Каталог не загружен', ));
  }, [props.taskResponse]);
  watch('taskInfoRequest.consumerType');

  const onCopy = () => {
    const latitude = getValues('taskInfoRequest.latitude');
    const longitude = getValues('taskInfoRequest.longitude');
    navigator.clipboard
      .writeText(`${latitude}, ${longitude}`)
      .then(() => addActionLog(ActionLogType.SUCCESS, `Координаты скопированы`))
      .catch((err) => fetchCatch(err, 'Something went wrong'));
  };

  const leaderAccsess = useAllowedAccessByRole({
    allowRoles: [RolesEnum.Administrators, RolesEnum.Leader],
  });

  return (
    <>
      {loading ? (
        <Skeleton animation='wave' />
      ) : (
        <form>
          <Grid
            container
            spacing={1}
          >
            {props.taskResponse?.createDate && (
              <Grid
                item
                xs={12}
              >
                <Typography mt={3}>
                  Дата создания заявки: {toFormateDate(props.taskResponse?.createDate)}
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
            >
              <DateField
                defaultValue={props.taskResponse?.createdOn}
                hookFormString={`createdOn`}
                label={'Дата создания заявки'}
                disabled
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                id='tpName'
                label='№ Трансформаторной Подстанции'
                variant='standard'
                disabled
                fullWidth
                {...register('taskInfoRequest.tpName', {
                  value: taskInfoResponse?.tpName,
                })}
              />
            </Grid>
            {arrTypeToShowRES.find((elem) => elem === props.taskResponse?.type) && (
              <Grid
                item
                xs={12}
              >
                <AutocompleteValue
                  disabled={!editable}
                  title='РЭС'
                  defaultValue={taskInfoResponse?.regionalElectricNetworkId}
                  fieldName={'taskInfoRequest.regionalElectricNetworkId'}
                  values={getCatalogMapWithErr('regionalElectricNetwork')}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
            >
              <TextField
                id='tf-pointName'
                label='Наименование точки поставки'
                variant='standard'
                disabled={!editable}
                fullWidth
                {...register('taskInfoRequest.pointName', {
                  value: taskInfoResponse?.pointName,
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                id='tf-subscriberFIO'
                label='Имя потребителя'
                variant='standard'
                fullWidth
                {...register('taskInfoRequest.subscriberFIO', {
                  value: taskInfoResponse?.subscriberFIO,
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                id='tf-consumerType'
                label='Тип потребителя'
                variant='standard'
                fullWidth
                disabled
                {...register('taskInfoRequest.consumerType', {
                  value: getCatalogMapWithErr('taskConsumer').get(
                    taskInfoResponse?.consumerType || ''
                  ),
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                id='tf-subscriberObjName'
                label='Наименование объекта потребителя'
                variant='standard'
                disabled={!editable}
                fullWidth
                {...register('taskInfoRequest.subscriberObjName', {
                  value: taskInfoResponse?.subscriberObjName,
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                id='tf-account-number'
                label='Номер лицевого счета'
                variant='standard'
                disabled={!editable}
                fullWidth
                {...register('taskInfoRequest.accountNumber', {
                  value: taskInfoResponse?.accountNumber,
                })}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                id='tf-book-number'
                disabled={!editable}
                label='Номер книги'
                variant='standard'
                {...register('taskInfoRequest.bookNumber', {
                  value: taskInfoResponse?.bookNumber,
                })}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                id='tf-subscriber-number'
                label='Номер абонента'
                variant='standard'
                disabled={!editable}
                {...register('taskInfoRequest.subscribeNumber', {
                  value: taskInfoResponse?.subscriberNumber,
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              {taskInfoResponse?.abonentsList?.map((item, index) => (
                <TextField
                  id='tf-phone-number'
                  key={item.id}
                  disabled={!editable}
                  label='Телефон'
                  variant='standard'
                  {...register('taskInfoRequest.abonentsList.' + index + '.contactPhone', {
                    value: item.contactPhone,
                  })}
                />
              ))}
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                id='tf-latitude'
                label='Широта'
                variant='standard'
                disabled={!editable}
                {...register('taskInfoRequest.latitude', { value: taskInfoResponse?.latitude })}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                id='tf-longitude'
                label='Долгота'
                variant='standard'
                disabled={!editable}
                {...register('taskInfoRequest.longitude', { value: taskInfoResponse?.longitude })}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                multiline
                id='tf-originalAddress'
                label='Адрес'
                variant='standard'
                disabled
                {...register('taskInfoRequest.originalAddress', {
                  value: taskInfoResponse?.originalAddress,
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                id='tf-appartment'
                label='№ квартиры'
                variant='standard'
                disabled
                {...register('taskInfoRequest.appartment', { value: taskInfoResponse?.appartment })}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Button
                onClick={onCopy}
                sx={{ width: '100%' }}
              >
                Скопировать
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                id='tf-territorial-branch-name'
                label='Территориальное отделение'
                variant='standard'
                disabled
                {...register('taskInfoRequest.territorialBranchName', {
                  value: taskInfoResponse?.territorialBranchName,
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                id='tf-office'
                label='Клиентский офис'
                variant='standard'
                disabled
                {...register('taskInfoRequest.clientOfficeName', {
                  value: taskInfoResponse?.clientOfficeName,
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                id='tf-schem-BYT'
                label='Схема BYT'
                variant='standard'
                disabled
                {...register('taskInfoRequest.schemeBYT', {
                  value: taskInfoResponse?.schemeBYT,
                })}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                id='tf-customer-Id'
                label='Идентификатор в системе заказчика'
                variant='standard'
                {...register('taskInfoRequest.customerId', {
                  value: taskInfoResponse?.customerID,
                })}
              />
            </Grid>
            {TYPES_FOR_REGISTRATIONDATE.includes(props?.taskResponse?.type ?? '') && (
              <Grid
                item
                xs={12}
              >
                <DateField
                  defaultValue={props.taskResponse?.registrationDate}
                  hookFormString={`registrationDate`}
                  label={'Дата обращения потребителя'}
                  disabled={!editable || !leaderAccsess}
                  roundTime={false}
                />
              </Grid>
            )}
            {REGISTRATION_NUM_TYPES.includes(props?.taskResponse?.type ?? '') && (
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  id='tf-registration-Num'
                  label='Регистрационный номер / Номер ТУ'
                  variant='standard'
                  disabled={true}
                  {...register('taskInfoRequest.registrationNum', {
                    value: taskInfoResponse?.registrationNum,
                  })}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                id='tf-original-Fias'
                label='ФИАС ID'
                variant='standard'
                disabled={!editable}
                {...register('taskInfoRequest.originalFias', {
                  value: taskInfoResponse?.originalFias,
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                id='tf-assigned-work'
                label='Вид работ'
                variant='standard'
                {...register('taskInfoRequest.assignedWork', {
                  value: taskInfoResponse?.assignedWork,
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography mt={3}>Существующий ПУ: {taskInfoResponse?.puType}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                id='tf-montage-place'
                label='Место установки'
                variant='standard'
                disabled={!editable}
                {...register('taskInfoRequest.montagePlace', {
                  value: taskInfoResponse?.montagePlace,
                })}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <AutocompleteValue
                disabled={!editable}
                title='Фазность'
                defaultValue={taskInfoResponse?.phase}
                fieldName={'taskInfoRequest.phase'}
                values={getCatalogMapWithErr('phase')}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                id='tf-tariff'
                label='Тариф'
                variant='standard'
                disabled={!editable}
                {...register('taskInfoRequest.tariff', { value: taskInfoResponse?.tariff })}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                id='tf-factory-number-pu'
                label='Заводской номер'
                variant='standard'
                disabled={!editable}
                {...register('taskInfoRequest.factoryNumberPu', {
                  value: taskInfoResponse?.factoryNumberPu,
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <AutocompleteValue
                disabled={!editable}
                title='Коэффицент трансформации'
                defaultValue={taskInfoResponse?.ttKoef}
                fieldName={'taskInfoRequest.ttKoef'}
                values={getCatalogMapWithErr('ktt')}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography mt={3}>
                Последние показания:{' '}
                {taskInfoResponse?.lastReadDate
                  ? toFormateDate(taskInfoResponse?.lastReadDate)
                  : ''}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                id='tf-t1'
                label='T1'
                variant='standard'
                disabled={!editable}
                {...register('taskInfoRequest.t1', { value: taskInfoResponse?.t1 })}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                id='tf-t2'
                label='T2'
                variant='standard'
                disabled={!editable}
                {...register('taskInfoRequest.t2', { value: taskInfoResponse?.t2 })}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                id='tf-t3'
                label='T3'
                variant='standard'
                disabled={!editable}
                {...register('taskInfoRequest.t3', { value: taskInfoResponse?.t3 })}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                id='tf-t0'
                label='T0'
                variant='standard'
                disabled={!editable}
                {...register('taskInfoRequest.t0', { value: taskInfoResponse?.t0 })}
              />
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};
