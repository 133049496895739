import React, { FC } from 'react';
import { GroupImg, GroupName, GroupWrapper } from '@/components/features/drive/group/Group.styled';
import fileFolder from '@/images/file/fileFolder.png';
import { useNavigate, useParams } from 'react-router-dom';
import { DriveItem } from '@/components/features/drive/utils';
import { useIsTouchDevice } from '@/hooks/useIsTouchDevice';

interface IGroup {
  item: DriveItem;
}

const Group: FC<IGroup> = ({ item }) => {
  const isTouch = useIsTouchDevice();
  const navigate = useNavigate();
  const handleClick = () => navigate(`/registry/drive/${item.name}_${item.id}`);
  return (
    <GroupWrapper
      onDoubleClick={!isTouch && handleClick}
      onClick={isTouch && handleClick}
    >
      <GroupImg src={fileFolder} />
      <GroupName>{item.name}</GroupName>
    </GroupWrapper>
  );
};

export default Group;
