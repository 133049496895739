import { Button, Grid } from '@mui/material';

import React, { useState } from 'react';
import {
  InfoComplaintBlockLabel,
  InfoComplaintBlockText,
} from '../ComplaintInfo/InfoComplaintBlock.styled';
import { useComplaintStore } from '../store';
import { ComplaintResultTaskBlockWrapper } from './ComplaintResultTaskBlock.styled';
import { color } from '@/styles/mixins';
import { useCatalog } from '@/hooks/CatalogHook';
import { UserSwitchOutlined } from '@ant-design/icons';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { changeResponsibleApi } from '@/services/ComplaintService/ComplaintService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import shallow from 'zustand/shallow';
import { ComplaintStage } from '@/services/ComplaintService/ComplaintServiceDto';
import moment from 'moment';

const ComplaintResultTaskBlock = () => {
  const { selectedCompliant, setSelectedCompliant } = useComplaintStore(
    (state) => ({
      selectedCompliant: state.selectedCompliant,
      setSelectedCompliant: state.setSelectedCompliant,
      fetchSingleComplaint: state.fetchSingleComplaint,
      addNewComplaint: state.addNewComplaint,
    }),
    shallow
  );
  const resultTaskId = selectedCompliant?.complaint?.resultTaskId;
  const stage = selectedCompliant?.complaint.stage.name;
  const [changeResponsible, setChangeResponsible] = useState(false);
  const [selectedResponsibleId, setSelectedResponsibleId] = useState('');
  const handleResponsible = (id: string) => {
    setSelectedResponsibleId(id);
  };
  const { fetchCatch, addActionLog } = useActionLog();
  const { setIsInProgress, isInProgress } = useScreenHoldHook();

  const handleSelectedResponsible = () => {
    setIsInProgress(true);
    changeResponsibleApi(selectedCompliant?.complaint.base.id, selectedResponsibleId)
      .then(({ data }) => {
        setSelectedCompliant(data);
        addActionLog(ActionLogType.SUCCESS, 'Успешно');
        setChangeResponsible(false);
      })
      .catch((error) => {
        fetchCatch(error);
      })
      .finally(() => setIsInProgress(false));
  };
  const { getGroupCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
  }));
  if (stage === ComplaintStage.TRANSFERRED_TO_RESPONSIBLE) {
    return (
      <ComplaintResultTaskBlockWrapper>
        <Grid
          xs={12}
          container
          spacing={1}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Grid
            item
            xs={3}
          >
            <InfoComplaintBlockLabel>Ответственный</InfoComplaintBlockLabel>
          </Grid>
          <Grid
            item
            xs={5}
          >
            <InfoComplaintBlockText>
              {(selectedCompliant?.complaint?.responsibleId &&
                getGroupCatalog()?.get(selectedCompliant?.complaint?.responsibleId)) ||
                ''}
            </InfoComplaintBlockText>
          </Grid>
          <Grid
            xs={1}
            item
            mb={1}
          >
            <UserSwitchOutlined
              style={{ cursor: 'pointer', color: color('aBlue') }}
              size={2}
              rev={undefined}
              disabled={isInProgress}
              onClick={() => setChangeResponsible((val) => !val)}
            />
          </Grid>
        </Grid>
        {changeResponsible && (
          <Grid
            container
            mt={3}
            spacing={1}
            xs={12}
          >
            <Grid
              item
              xs={6}
            >
              <AutocompleteValue
                title='Ответственный подрядчик'
                fieldName={'responsibleId'}
                defaultValue={selectedCompliant?.complaint?.responsibleId}
                values={getGroupCatalog()}
                optionOnChangeValue={handleResponsible}
              />
            </Grid>
            <Grid
              item
              xs={6}
              alignSelf={'end'}
              justifyContent={'space-evenly'}
              display={'flex'}
            >
              <Button
                disabled={isInProgress}
                onClick={handleSelectedResponsible}
              >
                применить
              </Button>
              <Button
                disabled={isInProgress}
                onClick={() => setChangeResponsible(false)}
              >
                отмена
              </Button>
            </Grid>
          </Grid>
        )}
      </ComplaintResultTaskBlockWrapper>
    );
  }
  if (!resultTaskId) return null;

  return (
    <ComplaintResultTaskBlockWrapper>
      <Grid
        xs={12}
        container
        spacing={1}
      >
        {selectedCompliant?.workType && (
          <>
            <Grid
              item
              xs={4}
            >
              <InfoComplaintBlockLabel>Тип работы</InfoComplaintBlockLabel>
            </Grid>
            <Grid
              item
              xs={8}
            >
              <InfoComplaintBlockText>{selectedCompliant?.workType?.title}</InfoComplaintBlockText>
            </Grid>
          </>
        )}

        {selectedCompliant?.complaint?.responsibleId && (
          <>
            <Grid
              item
              xs={4}
            >
              <InfoComplaintBlockLabel>Ответственный</InfoComplaintBlockLabel>
            </Grid>
            <Grid
              item
              xs={8}
            >
              <InfoComplaintBlockText>
                {getGroupCatalog()?.get(selectedCompliant?.complaint?.responsibleId) || ''}
              </InfoComplaintBlockText>
            </Grid>
          </>
        )}

        {selectedCompliant?.executorId && (
          <>
            <Grid
              item
              xs={4}
            >
              <InfoComplaintBlockLabel>Исполнитель</InfoComplaintBlockLabel>
            </Grid>
            <Grid
              item
              xs={8}
            >
              <InfoComplaintBlockText>
                {getGroupCatalog()?.get(selectedCompliant?.executorId) || ''}
              </InfoComplaintBlockText>
            </Grid>
          </>
        )}

        <Grid
          item
          xs={4}
        >
          <InfoComplaintBlockLabel>Заявка в YODA</InfoComplaintBlockLabel>
        </Grid>
        <Grid
          item
          xs={8}
        >
          <InfoComplaintBlockText>
            <span
              style={{
                cursor: 'pointer',
                color: color('muiBlue'),
              }}
              onClick={() => window.open(`/task/edit/${resultTaskId}`)}
            >
              {selectedCompliant?.complaint.resultTaskNumber}{' '}
            </span>
          </InfoComplaintBlockText>
        </Grid>
        {selectedCompliant?.complaint?.plannedDate &&
          selectedCompliant.complaint.stage?.name === ComplaintStage.IN_PROGRESS && (
            <>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel>Плановая дата</InfoComplaintBlockLabel>
              </Grid>

              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText>
                  {moment(selectedCompliant?.complaint.plannedDate).format('DD.MM.YYYY')}
                </InfoComplaintBlockText>
              </Grid>
            </>
          )}
      </Grid>
    </ComplaintResultTaskBlockWrapper>
  );
};

export default ComplaintResultTaskBlock;
