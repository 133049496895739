import { styled } from '@mui/material';

export const HeaderDynamicStatistic = styled('div')({
  display: 'flex',
  marginBottom: '20px',
  justifyContent: 'space-between',
});

export const LineWrapper = styled('div')({
  height: '745px',
  marginLeft: 'auto',
  marginRight: 'auto',
  overflowX: 'auto',
});

export const DynamicStatisticTimePeriodWrapper = styled('div')({
  display: 'flex',
  gap: 40,
  alignItems: 'center',
});

export const DynamicStatisticSwitchesWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
});
