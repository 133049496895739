import { HOST, yodaGetAccessToken } from './YodaRestService';
import axios, { AxiosResponse } from 'axios';
import qs from 'qs';

export async function axiosPost(
  url: string,
  data: any,
  isBlob?: boolean,
  isMultipart?: boolean,
  isZip?: boolean,
  isPlain?: boolean
): Promise<AxiosResponse<any, any>> {
  const token = await yodaGetAccessToken();
  if (isBlob) {
    return axios.post(url, JSON.stringify(data), {
      headers: {
        Authorization: `Bearer  ${token}`,
        'content-type': 'application/json',
      },
      responseType: 'blob',
    });
  }
  if (isMultipart) {
    return axios.post(url, data, {
      headers: {
        Authorization: `Bearer  ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
  }
  if (isZip) {
    return axios.post(url, data, {
      headers: {
        Authorization: `Bearer  ${token}`,
        'Content-Type': 'application/zip',
      },
    });
  }
  if (isPlain) {
    return axios.post(url, data, {
      headers: {
        Authorization: `Bearer  ${token}`,
        'Content-Type': 'text/plain',
      },
    });
  }
  return axios.post(url, JSON.stringify(data), {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function axiosPut(url: string, data: any): Promise<AxiosResponse<any, any>> {
  const token = await yodaGetAccessToken();
  return axios.put(url, JSON.stringify(data), {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function axiosPatch(url: string, data: any): Promise<AxiosResponse<any, any>> {
  const token = await yodaGetAccessToken();
  return axios.patch(url, JSON.stringify(data), {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function axiosGet(url: string, params?: any): Promise<AxiosResponse<any, any>> {
  const token = await yodaGetAccessToken();
  return axios.get(url, {
    params: params || null,
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function axiosGetExl(url: string): Promise<AxiosResponse<any, any>> {
  const token = await yodaGetAccessToken();
  return axios.get(url, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });
}

export async function axiosDelete(url: string): Promise<AxiosResponse<any, any>> {
  const token = await yodaGetAccessToken();
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function axiosGetExlByPost(url: string, data: any): Promise<AxiosResponse<any, any>> {
  const token = await yodaGetAccessToken();
  return axios.post(url, JSON.stringify(data), {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
    responseType: 'blob',
  });
}

export async function axiosGetPdfByPost(url: string, data: any): Promise<AxiosResponse<any, any>> {
  const token = await yodaGetAccessToken();
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
    responseType: 'blob',
  });
}

export async function axiosGetZip(url: string): Promise<AxiosResponse<any, any>> {
  const token = await yodaGetAccessToken();
  return axios.get(url, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
    responseType: 'blob',
  });
}
