import { Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReplacementRequest, TechInspectionResponse } from '@/dto/taskmap/task/TaskResultDto';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { useGalleryData } from '@/components/Gallery/store';
import { getSMRTkoResImageGroup } from '../panel/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from './tko/Form';
import { ExpandButton } from '@/components/button/ExpandButton/ExpandButton';
import { deleteTKOById, updateTko } from '@/services/TaskService';
import { convertFormData } from './tko/utils';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { toFormateDate } from '@/utils/heplers';
import { SaveButton } from '@/components/ui/SaveButton/SaveButton';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';

import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import { IdPopup } from '../panel/IdPopup';
import CopyButton from '@/components/button/CopyButton/CopyButton';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { TaskEditorOverflowText, TASK_EDITOR_SMALL_PREVIEW } from './TaskEditorItem.styled';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';
import { DownloadPopup } from '@/components/popups/DownloadPopup/DownloadPopup';

interface TechInspectionItemProps {
  response: TechInspectionResponse;
  contractId?: string;
  update: () => void;
}

export const TechInspectionItem = (props: TechInspectionItemProps) => {
  const onlyShow = useTaskEditorOnlyShow();
  const { response, contractId, update } = props;
  const { setIsInProgress } = useScreenHoldHook();
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);
  const [isOpenDownload, setIsOpenDownload] = useState<boolean>(false);

  const [isOpenNewFunctionPopup, setIsOpenNewFunctionPopup] = useState<boolean>(false);
  const { fetchCatch, addActionLog } = useActionLog();
  const setImageGroups = useGalleryData(({ setImageGroups }) => setImageGroups);
  const methods = useForm<ReplacementRequest>();

  const onSubmit = (e: any) => {
    setIsInProgress(true);
    !onlyShow &&
      updateTko(e.TKOForm.id, convertFormData(e))
        .then(() => {
          addActionLog(ActionLogType.SUCCESS, 'Изменения сохранены');
        })
        .catch((err) =>
          fetchCatch(err, 'Изменения не сохранены, попробуйте заполнить пропущенные поля')
        )
        .finally(() => setIsInProgress(false));
  };

  const handleClick = (e: any) => {
    e.stopPropagation();
    setImageGroups(getSMRTkoResImageGroup(response));
  };

  const deleteTKO = () => {
    !onlyShow &&
      deleteTKOById(response.id)
        .then(() => {
          addActionLog(ActionLogType.SUCCESS, 'ТКО удален');
          update();
        })
        .catch((err) => fetchCatch(err, 'Ошибка при удалнеии ТКО'));
  };

  return (
    <>
      <DownloadPopup
        id={response?.id ? response?.id : ''}
        isOpen={isOpenDownload}
        onClose={() => setIsOpenDownload(false)}
        isTKO={true}
      />
      <AccessPopup
        headerText={'После удаления восстановить данные будет невозможно'}
        titleText={'Продолжить?'}
        successText={'Да'}
        closeText={'Отменить'}
        disabledSuccess={onlyShow}
        onSuccess={() => {
          deleteTKO();
          setIsOpenNewFunctionPopup(false);
        }}
        onClose={() => setIsOpenNewFunctionPopup(false)}
        isOpen={isOpenNewFunctionPopup}
      />

      <BoxShadow onDoubleClick={handleClick}>
        {isOpenForm ? (
          <div>
            <FormProvider {...methods}>
              <div style={{ marginBottom: 16 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>{response?.tkoStatusResponse?.description}</span>
                  <div style={{ display: 'flex', gap: 10 }}>
                    <FileDownloadRoundedIcon
                      sx={{ color: 'gray', cursor: 'pointer' }}
                      onClick={() => setIsOpenDownload(true)}
                    />
                    <DeleteIcon
                      sx={{
                        color: 'gray',
                        fontSize: 22,
                        cursor: onlyShow ? '' : 'pointer',
                        top: 5,
                        right: 5,
                      }}
                      onClick={() => !onlyShow && setIsOpenNewFunctionPopup(true)}
                    />

                    <IdPopup sx={{ color: 'gray' }}>
                      {response?.id && (
                        <div>
                          <CopyButton textToCopy={response?.id} />
                          {response?.id}
                        </div>
                      )}
                    </IdPopup>
                  </div>
                </div>
              </div>
              <Form
                contractId={contractId}
                data={{ ...response }}
              />
              <div
                style={{
                  marginTop: 16,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: ' center',
                }}
              >
                <SaveButton
                  onSave={methods.handleSubmit(onSubmit)}
                  disabled={onlyShow}
                />
                <ExpandButton
                  expand={true}
                  onAction={() => setIsOpenForm(false)}
                />
              </div>
            </FormProvider>
          </div>
        ) : (
          <Grid>
            <Grid {...TASK_EDITOR_SMALL_PREVIEW.inner}>
              <Grid {...TASK_EDITOR_SMALL_PREVIEW.firstItem}>
                <Typography>{toFormateDate(response?.eventOn)}</Typography>
              </Grid>
              <Grid {...TASK_EDITOR_SMALL_PREVIEW.secondItem}>
                <TaskEditorOverflowText>{response?.executorName}</TaskEditorOverflowText>
              </Grid>
              <Grid {...TASK_EDITOR_SMALL_PREVIEW.thirdItem}>
                <Typography>{response?.tkoStatusResponse?.description}</Typography>
              </Grid>
              <Grid {...TASK_EDITOR_SMALL_PREVIEW.lastItem}>
                <Stack
                  direction='row'
                  justifyContent={'flex-end'}
                >
                  <Typography>ТКО</Typography>
                  <ExpandButton
                    expand={false}
                    onAction={() => setIsOpenForm(true)}
                    sx={{ marginLeft: 1 }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        )}
      </BoxShadow>
    </>
  );
};
