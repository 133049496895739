import { useRef } from 'react';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { formatPhoneNumber } from '../utils/CallingMapUtils';
import greenPhone from '../images/greenPhone.svg';
import redPhone from '../images/redPhone.svg';
import greyPhone from '../images/greyPhone.svg';
import { useCallingMapState } from '../store';
import { startWebRTCCall, hardCancel, VATS_CONFIG, getWebRTCClient } from '../utils/vats';
import TimerComponent from './TimerComponent';
import Phone from './Phone';
import { SubscriberResponse } from '@/dto/SubscribersDto';
import { useActionLog } from '@/hooks/ActionLogHook';

type SingleAbonentNumberProps = {
  abonent?: SubscriberResponse | null;
};

const SingleAbonentNumber = ({ abonent }: SingleAbonentNumberProps) => {
  const { singleGeoPoint, webRtcCallStatus, setWebRtcCallStatus } = useCallingMapState((state) => ({
    singleGeoPoint: state.singleGeoPoint,
    webRtcCallStatus: state.webRtcCallStatus,
    setWebRtcCallStatus: state.setWebRtcCallStatus,
  }));
  const { fetchCatch } = useActionLog();

  const timerRef = useRef(null);

  const callResultText = () => {
    if (timerRef.current) return `Вызов ${timerRef.current}`;
    return `Отменен`;
  };

  const renderCallStatustext = () => {
    if (webRtcCallStatus === 'connecting') return 'Вызов...';
    if (webRtcCallStatus === 'default') return 'Нажмите на кнопку вызова';
    if (webRtcCallStatus === 'ended') return callResultText();
  };

  const onConnected = () => {
    setWebRtcCallStatus('connected');
  };

  const onDisconnected = () => {
    setWebRtcCallStatus('ended');
  };

  const onFailed = (e: any) => {
    setWebRtcCallStatus('failed');
    fetchCatch(e, 'Ошибка vats-телефонии невозможно осуществить вызов');
  };

  const handleEndCall = () => {
    hardCancel();
  };

  const startCall = () => {
    if (abonent?.contactPhone) handleCall(abonent?.contactPhone);
  };

  const handleCall = (phone: string) => {
    setWebRtcCallStatus('connecting');
    startWebRTCCall(phone, onConnected, onDisconnected, onFailed);
  };

  const isConnected = webRtcCallStatus === 'connected';

  return (
    <BoxShadow>
      <Stack
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={1}
      >
        <Grid
          container
          spacing={10}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Grid
            item
            xs={6}
          >
            <Box
              flexDirection={'column'}
              display={'flex'}
              gap={1}
            >
              <Typography
                variant='h5'
                color={'black'}
              >
                {abonent?.fio ?? 'нет имени '}
              </Typography>
              <Typography
                variant='subtitle1'
                color={'#606060'}
              >
                {formatPhoneNumber(abonent?.contactPhone, true)}
              </Typography>
              <Stack
                display={'flex'}
                flexDirection={'row'}
                gap={1}
              >
                <Typography
                  color={'#606060'}
                  variant='subtitle1'
                >
                  л/с:
                </Typography>
                <Typography
                  color={'#0B203E'}
                  variant='subtitle1'
                  fontWeight={'bold'}
                >
                  {singleGeoPoint?.accountingNumber ?? 'отстутсвует'}
                </Typography>
              </Stack>
              <Stack
                display={'flex'}
                flexDirection={'row'}
                gap={1}
              >
                <Typography
                  color={'#0B203E'}
                  variant='subtitle1'
                  fontWeight={'bold'}
                >
                  {singleGeoPoint?.address ?? 'отстутсвует'}{' '}
                  {singleGeoPoint?.apartmentNumber && `кв.${singleGeoPoint.apartmentNumber}`}
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item>
            <Box
              flexDirection={'row'}
              display={'flex'}
              alignItems={'center'}
              gap={0.2}
            >
              <Typography
                variant='h6'
                color={'#717171'}
              >
                {renderCallStatustext()}
                <TimerComponent
                  isConnected={isConnected}
                  timerRef={timerRef}
                />
              </Typography>
              {webRtcCallStatus === 'default' && (
                <Phone
                  handleEndCall={startCall}
                  image={greenPhone}
                />
              )}
              {webRtcCallStatus === 'ended' && (
                <Phone
                  handleEndCall={handleEndCall}
                  image={greyPhone}
                />
              )}
              {(webRtcCallStatus === 'connecting' || webRtcCallStatus === 'connected') && (
                <Phone
                  handleEndCall={handleEndCall}
                  image={redPhone}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </BoxShadow>
  );
};
export default SingleAbonentNumber;
