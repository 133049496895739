import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { DetailedInfoItem } from './DetailedInfoItem';
import { color } from '@/styles/mixins';
import { Rnd } from 'react-rnd';
import { Portal, IconButton } from '@mui/material';

export interface DetailedInfoButtonProps {
  info: DetailedInfoItem[];
  disable?: boolean;
}

export const DetailedInfoButton = ({ info, disable }: DetailedInfoButtonProps) => {
  const [open, setOpen] = useState(false);
  const defaultPosition = {
    x: 10,
    y: 100,
    width: 'auto',
    height: 'auto',
  };

  return (
    <>
      <IconButton
        onClick={() => {
          if (!disable) setOpen((prev) => !prev);
        }}
        style={{
          cursor: disable ? 'not-allowed' : 'pointer',
        }}
      >
        {open ? (
          <VisibilityOffIcon
            fontSize='large'
            style={{ color: color('muiBlue') }}
          />
        ) : (
          <VisibilityIcon
            fontSize='large'
            style={{ color: color('muiBlue') }}
          />
        )}
      </IconButton>

      {open && (
        <Portal>
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 1000,
              backgroundColor: 'transparent',
              overflowY: 'auto',
              height: '100vh',
              width: '100vw',
            }}
            onClick={(e) => e.target === e.currentTarget && setOpen(false)}
          >
            <Rnd
              default={defaultPosition}
              minWidth={1}
              minHeight={1}
              bounds='window'
            >
              <Box
                sx={{
                  backgroundColor: '#FFF',
                  border: '1px solid gray',
                  borderRadius: '5px',
                  position: 'relative',
                  padding: 2,
                }}
                onClick={(e) => e.stopPropagation()}
              >
                {info?.map((infoElement, index) => (
                  <DetailedInfoItem
                    key={infoElement.title}
                    index={index}
                    {...infoElement}
                  />
                ))}

                <IconButton
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: -13,
                    right: -13,
                    color: color('trueBlack'),
                    zIndex: 100000,
                  }}
                  onTouchStart={() => setOpen(false)}
                  onClick={() => setOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Rnd>
          </Box>
        </Portal>
      )}
    </>
  );
};
