import { Grid, styled } from '@mui/material';
import { DataGrid as DataGrid_ } from '@mui/x-data-grid';
import styles from '@/components/features/tasksBrowse/TasksBrowse.module.scss';
import React, { FC, useEffect, useState } from 'react';
import { CatalogTypes } from './constants';
import { useActionLog } from '@/hooks/ActionLogHook';
import { useCatalogCounterFilter } from './store';
import { converter } from './utils';
import { MainFilter } from './MainFilter';
import {
  KooBrowseResponse,
  MeterBrowseResponse,
  PuBrowseResponse,
  SimBrowseResponse,
  StampBrowseResponse,
} from '@/dto/CatalogsDto';
import { getBrowse } from '@/services/CatalogsService';
import { ResultTable } from './ResultTable';

export interface ICatalogsBrowse {
  type: CatalogTypes;
}

export const DataGrid = styled(DataGrid_)({
  '&.MuiDataGrid-root': {
    display: 'flex',
  },
});

export const CatalogsBrowse: FC<ICatalogsBrowse> = ({ type }) => {
  useEffect(() => {
    function keydownHandler(ev: KeyboardEvent) {
      if (ev.key === 'Enter') {
        onSubmit();
      }
    }
    document.addEventListener('keydown', keydownHandler, false);
    return function cleanup() {
      document.removeEventListener('keydown', keydownHandler, false);
    };
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPag, setIsLoadingPag] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [first, setFirst] = useState<boolean>(true);

  const { fetchCatch } = useActionLog();

  const [currentPage, setCurrentPage] = useState(0);

  const [items, setItems] = useState<
    StampBrowseResponse[] | KooBrowseResponse[] | SimBrowseResponse[] | PuBrowseResponse[]
  >([]);

  const { setDefaultValue } = useCatalogCounterFilter();

  const getItems = () => {
    const o = useCatalogCounterFilter.getState();
    setIsLoading(true);
    getBrowse(type)
      .filter({
        page: currentPage,
        filters: converter({ o, type }),
        pageSize: pageSize,
      })
      .then(({ data }) => {
        setItems(data);
        setFirst(false);
      })
      .catch((error) => {
        fetchCatch(error);
      })
      .finally(() => setIsLoading(false));
  };
  const getItemsCount = () => {
    const o = useCatalogCounterFilter.getState();
    setIsLoadingPag(true);
    getBrowse(type)
      .count({ filters: converter({ o, type }) })
      .then(({ data }) => {
        setTotalRows(data);
      })
      .catch((error) => {
        fetchCatch(error);
      })
      .finally(() => setIsLoadingPag(false));
  };

  useEffect(() => {
    !first && setIsLoading(true);
    !first && getItems();
  }, [currentPage, pageSize]);

  const handleReset = () => {
    setCurrentPage(0);
    setDefaultValue();
    getItems();
    getItemsCount();
  };

  const onSubmit = () => {
    getItems();
    getItemsCount();
    setCurrentPage(0);
  };

  useEffect(() => {
    handleReset();
  }, [type]);

  useEffect(() => {
    const scrollToTop = () => {
      const virtualScroller = document.querySelector(
        '.MuiDataGrid-virtualScroller'
      ) as HTMLElement | null;
      if (virtualScroller) {
        virtualScroller.scrollTop = 0; // Прокручиваем в верхнюю часть
        virtualScroller.style.overflowY = 'scroll';
      }
    };
    if (currentPage >= 1 && items.length > 20) {
      scrollToTop();
    }
  }, [currentPage, items]);

  return (
    <>
      <div className={styles.container}>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={12}
          >
            <MainFilter
              onSubmit={onSubmit}
              type={type}
            />
          </Grid>
        </Grid>

        <ResultTable
          type={type}
          totalRows={totalRows}
          items={items}
          isLoading={isLoading}
          isLoadingPag={isLoadingPag}
          pageSize={pageSize}
          currentPage={currentPage}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};
