import DateField from '@/components/DateField';
import { useCatalog } from '@/hooks/CatalogHook';
import { color } from '@/styles/mixins';
import { Autocomplete, Grid, Stack, TextField, Typography } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { KeyString, lookUpExistingEquipment } from './utils';
import { PresentPu } from '@/dto/taskmap/task/TaskResultDto';
import { useExsistingEquipmentValidation } from './forms';
import { useMemo } from 'react';
import { taskMapToArray } from '../../tasksBrowse/utils';

interface FormPresentPuProps {
  keyString: KeyString;
  formData: PresentPu;
}
const FormPresentPu = ({ keyString, formData }: FormPresentPuProps) => {
  const { register, watch, setValue, control } = useFormContext();
  const { getCatalogMapWithErr, referenceMeterType } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    referenceMeterType: state.referenceMeterType,
  }));

  const handleT1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(`${keyString}.measurement.t1`, value);
    if (!value) {
      setValue(`${keyString}.measurement.t2`, '');
      setValue(`${keyString}.measurement.t3`, '');
    }
    updateTariff();
  };

  const handleT2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(`${keyString}.measurement.t2`, value);
    if (!value) {
      setValue(`${keyString}.measurement.t3`, '');
    }
    updateTariff();
  };
  const handleT3Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(`${keyString}.measurement.t3`, value);
    updateTariff();
  };
  const updateTariff = () => {
    const t1 = watch(`${keyString}.measurement.t1`);
    const t2 = watch(`${keyString}.measurement.t2`);
    const t3 = watch(`${keyString}.measurement.t3`);
    const tariff = [t1, t2, t3].filter(Boolean).length;
    setValue(`${keyString}.measurement.tariff`, tariff);
  };

  const rules = useExsistingEquipmentValidation();
  const optionsReferenceMeterType = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('referenceMeterType')),
    [referenceMeterType]
  );

  return (
    <>
      <Stack mb={3}>
        <Typography
          variant='h6'
          color={color('trueBlack')}
        >
          {lookUpExistingEquipment[keyString]}
        </Typography>
      </Stack>
      <Grid
        container
        spacing={3}
        mb={3}
      >
        <Grid item>
          <Controller
            name={`${keyString}.type`}
            control={control}
            defaultValue={formData.type}
            rules={rules.puType}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                sx={{ width: '200px' }}
                options={optionsReferenceMeterType}
                onChange={(event, newValue) => {
                  field.onChange(newValue ? newValue.value : null);
                }}
                value={
                  optionsReferenceMeterType.find((option) => option.value === field.value) || {
                    label: field.value,
                    value: field.value,
                  }
                }
                getOptionLabel={(option) => {
                  return (
                    getCatalogMapWithErr('referenceMeterType').get(option.value) ||
                    option.label ||
                    ''
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Тип'
                    variant='standard'
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Controller
            name={`${keyString}.number`}
            control={control}
            defaultValue={formData?.number}
            rules={rules.puNumber}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                id={`${keyString}`}
                sx={{ width: '200px' }}
                label='№'
                variant='standard'
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <TextField
            hidden
            {...register(`${keyString}.balance`, {
              value: formData?.balance,
            })}
          />
          <TextField
            hidden
            {...register(`${keyString}.measurement.tariff`, {
              value: formData?.measurement?.tariff,
            })}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        mb={3}
      >
        <Grid item>
          <Controller
            name={`${keyString}.editionYear`}
            control={control}
            defaultValue={formData?.stampNumber}
            rules={rules.puEditionYear}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                sx={{ width: '200px' }}
                id={`${keyString}`}
                label='Год выпуска'
                variant='standard'
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item>
          <DateField
            label={'Дата поверки'}
            defaultValue={formData?.verificationDate}
            hookFormString={`${keyString}.verificationDate`}
            roundTime={false}
          />
        </Grid>
        <Grid item>
          <DateField
            label={'Следующая поверка'}
            defaultValue={formData?.nextVerificationDate}
            hookFormString={`${keyString}.nextVerificationDate`}
            roundTime={false}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        mb={3}
      >
        <Grid item>
          <Controller
            name={`${keyString}.stampNumber`}
            control={control}
            defaultValue={formData?.stampNumber}
            rules={rules.puStampNumber}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                sx={{ width: '200px' }}
                id={`${keyString}`}
                label='Пломба'
                variant='standard'
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Controller
            name={`${keyString}.kkiStamp`}
            control={control}
            defaultValue={formData?.kkiStamp}
            rules={rules.puKkiStamp}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                sx={{ width: '200px' }}
                id={`${keyString}`}
                label='Пломба ККИ'
                variant='standard'
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item>
          <TextField
            label='№ Антимагнитного индикатора'
            variant='standard'
            sx={{ width: '200px' }}
            {...register(`${keyString}.aiNumber`, {
              value: formData?.aiNumber,
            })}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        mb={3}
      >
        <Grid item>
          <TextField
            label='Т1'
            variant='standard'
            sx={{ width: '200px' }}
            {...register(`${keyString}.measurement.t1`, {
              value: formData?.measurement?.t1,
              onChange: handleT1Change,
            })}
          />
        </Grid>
        <Grid item>
          <TextField
            label='Т2'
            variant='standard'
            disabled={!watch(`${keyString}.measurement.t1`)}
            sx={{ width: '200px' }}
            {...register(`${keyString}.measurement.t2`, {
              value: formData?.measurement?.t2,
              onChange: handleT2Change,
            })}
          />
        </Grid>
        <Grid item>
          <TextField
            label='Т3'
            variant='standard'
            disabled={
              !watch(`${keyString}.measurement.t1`) || !watch(`${keyString}.measurement.t2`)
            }
            sx={{ width: '200px' }}
            {...register(`${keyString}.measurement.t3`, {
              value: formData?.measurement?.t3,
              onChange: handleT3Change,
            })}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default FormPresentPu;
