import { AccessController } from '@/components/common/AccessController';
import { NavExitWrapper } from './NavBar.styled';
import { AccessEnum } from '@/app/auth/roles';
import { NavDropdownItem } from './NavDropdownItem';
import { CustomLink, LinkType } from './CustomLink';
import { color } from '@/styles/mixins';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import ExitActionButton from './ExitActionButton/ExitActionButton';
import EventsLogAction from './EventsLogAction/EventsLogAction';

const NavbarActionButtons = () => {
  return (
    <NavExitWrapper>
      <AccessController access={[AccessEnum.YUNGA, AccessEnum.StorehouseChief]}>
        <NavDropdownItem
          type={'icon'}
          parentTitle={<AdminPanelSettingsRoundedIcon htmlColor={color('grayNav')} />}
          tooltipText={'Администрирование'}
        >
          <AccessController access={[AccessEnum.YUNGA]}>
            <CustomLink
              to='/yunga'
              type={LinkType.ItemLink}
            >
              Yunga
            </CustomLink>
          </AccessController>
          <AccessController access={[AccessEnum.YUNGA]}>
            <CustomLink
              to='/roles'
              type={LinkType.ItemLink}
            >
              Роли
            </CustomLink>
          </AccessController>
          <AccessController access={[AccessEnum.YUNGA, AccessEnum.StorehouseChief]}>
            <CustomLink
              to='/storehouse_admin'
              type={LinkType.ItemLink}
            >
              Склад
            </CustomLink>
          </AccessController>
          <AccessController access={[AccessEnum.YUNGA]}>
            <CustomLink
              to='https://sentry.sec2.ru/organizations/tpplab/issues/'
              type={LinkType.OutLink}
            >
              Sentry
            </CustomLink>
          </AccessController>

          <AccessController access={[AccessEnum.YUNGA]}>
            <CustomLink
              to='https://metrika.yandex.ru/overview?id=99055086&period=week&group=day&isMinSamplingEnabled=false'
              type={LinkType.OutLink}
            >
              Метрика
            </CustomLink>
          </AccessController>

          <AccessController access={[AccessEnum.Admin]}>
            <CustomLink
              to='https://storybook.yoda.lan.tpplab'
              type={LinkType.OutLink}
            >
              StoryBook
            </CustomLink>
          </AccessController>
        </NavDropdownItem>
      </AccessController>

      <NavDropdownItem
        type={'icon'}
        parentTitle={<HelpOutlineRoundedIcon htmlColor={color('grayNav')} />}
        tooltipText={'Инструкции и помощь'}
      >
        <CustomLink
          to='https://docs.sec2.ru'
          type={LinkType.OutLink}
        >
          Инструкция
        </CustomLink>
        <CustomLink
          to='https://web.telegram.org/a/#697643914'
          type={LinkType.OutLink}
        >
          Техподдержка
        </CustomLink>
        <CustomLink
          to='https://www.youtube.com/channel/UCL8vrVbPasmK8T4y3nl1q0w'
          type={LinkType.OutLink}
        >
          Видео-инструкции
        </CustomLink>
      </NavDropdownItem>
      <EventsLogAction />
      <ExitActionButton />
    </NavExitWrapper>
  );
};

export default NavbarActionButtons;
