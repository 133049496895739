import useStorehouseUserName from '@/components/blocks/StorehouseUserName/StorehouseUserName';
import { useLogOut } from '@/hooks/useLogOut';
import { Popover, Stack, Tooltip } from '@mui/material';
import { useState } from 'react';
import { ExitButton, IconButtonCustom, ExitUserNameText } from './ExitActionButton.styled';
import { color } from '@/styles/mixins';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

const ExitActionButton = () => {
  const logOut = useLogOut();
  const userName = useStorehouseUserName();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Stack sx={{ marginLeft: '4px' }}>
      <Tooltip title='Выйти'>
        <IconButtonCustom onClick={handleClick}>
          <PersonOutlineIcon htmlColor={color('grayNav')} />
        </IconButtonCustom>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: '12px' }}
      >
        <Stack
          p={2}
          gap={1}
        >
          {!!userName && <ExitUserNameText>{userName}</ExitUserNameText>}
          <ExitButton onClick={logOut}>Выйти</ExitButton>
        </Stack>
      </Popover>
    </Stack>
  );
};

export default ExitActionButton;
