import React, { useEffect } from 'react';
import { BudgetStatisticBar } from './BudgetStatisticBar/BudgetStatisticBar';

import './InvestStatistic.css';
import { useInvestStatisticStore } from '../store';
import shallow from 'zustand/shallow';

import { DynamicStatistic } from './DynamicStatistic/DynamicStatistic';
import { BudgetStatisticDoughnut } from './BudgetStatisticDoughnut/BudgetStatisticDoughnut';

export const InvestStatistic = () => {
  const { equipmentsData, activeIndex, selectedColumn } = useInvestStatisticStore(
    (state) => ({
      equipmentsData: state.equipmentsData,
      activeIndex: state.activeIndex,
      selectedColumn: state.selectedColumn,
    }),
    shallow
  );

  return (
    <div style={{ marginLeft: '-64px', marginRight: '-64px' }}>
      {equipmentsData && <BudgetStatisticDoughnut />}

      {activeIndex !== null && <BudgetStatisticBar />}

      {selectedColumn !== null && activeIndex !== null && <DynamicStatistic />}
    </div>
  );
};
