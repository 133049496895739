import { IQureInput } from './types';
import { converToDoobleOrSting, DataTypeConvert } from './utils';
import { Grid, IconButton } from '@mui/material';
import { SingleLine } from '../SingleLineNew';
import { color } from '@/styles/mixins';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DateRange } from '../DateRange';
import { MultiSelect } from '../MultiSelectNew';
import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';
import { InputGrid } from './CustomeStyleDynamicFilter.styled';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';

interface RenderInputsProps {
  input: DataTypeConvert;
  queryFields: Record<string, any>;
  defaultArgInput: Record<string, any>;
  setQueryFields: React.Dispatch<React.SetStateAction<IQureInput>>;
  deleteInput?: (input: DataTypeConvert) => void;
  getCatalogList?: (value: string) => any[];
}

const RenderInputs = ({
  input,
  queryFields,
  defaultArgInput,
  setQueryFields,
  deleteInput,
  getCatalogList,
}: RenderInputsProps) => {
  const isMobile = useMobileSize();
  const handleInput = (param: { value: string; label: string }[] | string[] | string) => {
    const updatedField: { arg: number; values?: string[]; type: string } = {
      arg:
        queryFields[input.value]?.arg == undefined
          ? defaultArgInput[input.dType].defaultArgNum
          : queryFields[input.value].arg,
      type: '',
    };
    updatedField.type = input.dType;
    if (typeof param === 'string') {
      updatedField.values = [converToDoobleOrSting(input.dType, param)];
      // } else if (param?.length > 0 && typeof param[0] === 'string') {
    } else {
      updatedField.values = param.map((el: any) => el || el);
    }
    setQueryFields((oldVal) => ({
      ...oldVal,
      [input.value]: { ...queryFields[input.value], ...updatedField },
    }));
  };
  const handleInputArg = (param: FilterArgEnum) => {
    const updatedField: { arg: number; values?: string[]; type: string } = {
      arg:
        queryFields[input.value]?.arg == undefined
          ? defaultArgInput[input.dType].defaultArgNum
          : queryFields[input.value].arg,
      type: '',
    };
    updatedField.type = input.dType;
    updatedField.arg = param;
    if (
      queryFields[input.value]?.arg === FilterArgEnum.INSTALLED ||
      param === FilterArgEnum.INSTALLED
    ) {
      updatedField.values = [];
    }
    setQueryFields((oldVal) => ({
      ...oldVal,
      [input.value]: { ...queryFields[input.value], ...updatedField },
    }));
  };

  const width = isMobile ? '100%' : input.id === 'address' ? '370px' : '160px';

  if (
    input.dType === 'TEXT' ||
    input.dType === 'DOUBLE' ||
    input.dType === 'LONG' ||
    input.dType === 'EQUEL_TEXT'
  ) {
    return (
      <InputGrid
        item
        display={'flex'}
        alignItems={'center'}
      >
        <SingleLine
          sx={{ width }}
          type={input.dType === 'DOUBLE' || input.dType === 'LONG' ? 'number' : ''}
          argList={defaultArgInput[input.dType].argList}
          onChange={handleInput}
          label={input.label}
          onArgChange={handleInputArg}
          value={
            queryFields[input?.value] && queryFields[input?.value]?.values
              ? queryFields[input.value].arg === FilterArgEnum.INSTALLED
                ? queryFields[input.value].values
                : queryFields[input.value].values[0]
              : ''
          }
          argValue={
            queryFields[input.value]?.arg == undefined
              ? defaultArgInput[input.dType].defaultArgNum
              : queryFields[input.value].arg
          }
        />
        {deleteInput && (
          <IconButton
            sx={{ color: color('gray'), cursor: 'pointer', alignSelf: 'end' }}
            onClick={() => deleteInput(input)}
          >
            <DeleteForeverIcon />
          </IconButton>
        )}
      </InputGrid>
    );
  } else if (input.dType === 'DATE') {
    const isInRange =
      queryFields[input?.value]?.values?.length !== 2 &&
      queryFields[input.value]?.arg === FilterArgEnum.RANGE;
    const timeToolTipLabel =
      queryFields[input?.value]?.values?.length === 1 &&
      queryFields[input.value]?.arg === FilterArgEnum.RANGE;

    return (
      <InputGrid
        item
        display={'flex'}
        alignItems={'center'}
      >
        <DateRange
          // style={{ width: '100%' }}
          style={{ width }}
          label={timeToolTipLabel ? 'Введите вторую дату' : input.label}
          value={queryFields[input?.value]?.values || []}
          onChange={handleInput}
          isInRange={isInRange}
          onArgChange={handleInputArg}
          argList={defaultArgInput[input.dType].argList}
          argValue={queryFields[input.value]?.arg || defaultArgInput[input.dType].defaultArgNum}
        />
        {deleteInput && (
          <IconButton
            sx={{ color: color('gray'), cursor: 'pointer', alignSelf: 'end' }}
            onClick={() => deleteInput(input)}
          >
            <DeleteForeverIcon />
          </IconButton>
        )}
      </InputGrid>
    );
  }

  return (
    <InputGrid
      item
      key={input.value}
      display={'flex'}
      alignItems={'center'}
    >
      <MultiSelect
        sx={{ width }}
        label={input.label}
        onChange={handleInput}
        argList={defaultArgInput[input.dType].argList}
        argValue={queryFields[input.value]?.arg || defaultArgInput[input.dType].defaultArgNum}
        value={queryFields[input.value]?.values || []}
        onArgChange={handleInputArg}
        options={(getCatalogList && getCatalogList(input.value)) || []}
      />
      {deleteInput && (
        <IconButton
          sx={{ color: color('gray'), cursor: 'pointer', alignSelf: 'end' }}
          onClick={() => deleteInput(input)}
        >
          <DeleteForeverIcon />
        </IconButton>
      )}
    </InputGrid>
  );
};

export default RenderInputs;
