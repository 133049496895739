import React, { useEffect, useState } from 'react';
import { TextField, Button } from '@mui/material';

import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { IPopup, Popup } from '../Popup';
import styles from '../Popup.module.scss';
import { IFilterCombo, useStoredFilters } from '@/components/button/SaveButton/store';

export interface IAddToSaveButtonPopup {
  saveFilter: {
    infoToSave: object;
    filterType: string;
    selectCallback: (selectedValue: string) => void;
  };
}

export interface AddToSaveButtonPopupProps extends IPopup, IAddToSaveButtonPopup {}

const AddToSaveButtonPopup = ({ isOpen, onClose, saveFilter }: AddToSaveButtonPopupProps) => {
  const { fetchCatch, addActionLog } = useActionLog();
  const { addFilters } = useStoredFilters();

  const [textValue, setTextValue] = useState('');
  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => setTextValue(e.target.value);

  useEffect(() => {
    setTextValue('');
  }, [isOpen]);

  const handleSave = () => {
    try {
      addFilters(saveFilter.filterType, textValue, saveFilter?.infoToSave as IFilterCombo);
      addActionLog(ActionLogType.SUCCESS, 'Фильтр сохранен');
      onClose();
    } catch (error) {
      fetchCatch(error, 'Ошибка сохранения фильтра');
    }
  };

  return (
    <>
      <Popup
        customStyle={{ minWidth: 'auto' }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <div>
          <h2>Сохранить набор</h2>
          <p>Введите название для сохранения текущего набора фильтров</p>
        </div>

        <TextField
          autoFocus
          margin='dense'
          label='Название набора'
          fullWidth
          variant='standard'
          autoComplete='off'
          value={textValue}
          onChange={onTextChange}
        />
        <div className={styles['modal-buttons']}>
          <Button
            onClick={handleSave}
            variant='contained'
            disabled={!textValue.length}
          >
            Сохранить
          </Button>
          <Button
            onClick={onClose}
            variant='contained'
          >
            Отмена
          </Button>
        </div>
      </Popup>
    </>
  );
};

export default AddToSaveButtonPopup;
