import React, { FC } from 'react';
import { Autocomplete, FormControl, IconButton } from '@mui/material';
import { ArgumentSelect } from '../ArgumentSelectNew';
import { SingleLineFilterType } from './types';
import { Popper } from '../../fixUI/Popper';
import { FilterArgEnum } from '../ArgumentSelectNew/types';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { TextField } from '@/components/fixUI';

export const SingleLine: FC<SingleLineFilterType> = (props) => {
  const {
    value,
    argValue,
    argList,
    label,
    inputLabelProps = {},
    params,
    minWidth,
    onChange,
    onArgChange,
    isTrim = false,
    type = '',
    isCleared = false,
    sx,
  } = props;

  const [isboolAnswer, setIsboolAnswer] = React.useState<boolean>(false);

  const isInstalledOpt = [
    { value: 'yes', label: 'Да' },
    { value: 'no', label: 'Нет' },
  ];
  const booleanOpt = ['yes', 'no'];

  return (
    <div>
      <div className='parent'>
        <ArgumentSelect
          onArgChange={onArgChange}
          argList={argList}
          argValue={argValue}
          isBooleanHandler={setIsboolAnswer}
        />
        <FormControl
          className='child child_input'
          variant='outlined'
          sx={{ m: 1, minWidth: minWidth }}
          style={{ margin: 0 }}
        >
          {argValue === FilterArgEnum.IN_LIST || argValue === FilterArgEnum.NOT_IN_LIST ? (
            <TextField
              sx={{ width: '100%', ...sx }}
              value={value}
              label={label}
              type={type}
              InputLabelProps={inputLabelProps}
              InputProps={{
                endAdornment: (
                  <IconButton
                    size={'small'}
                    aria-label='toggle password visibility'
                    onClick={() => onChange('')}
                  >
                    <ClearRoundedIcon fontSize={'small'} />
                  </IconButton>
                ),
              }}
              {...params}
              onChange={(e) =>
                onChange(
                  e.target.value
                    .split(' ')
                    .join(',')
                    .split(',')
                    .filter((elem, index, arr) => elem || index + 1 === arr.length)
                    .join(',')
                )
              }
              variant='standard'
            />
          ) : isboolAnswer ? (
            <Autocomplete
              value={value ? value[0] : null}
              sx={{ width: '100%', minWidth: '140px', ...sx }}
              options={booleanOpt}
              getOptionLabel={(option) =>
                isInstalledOpt?.find((elem) => elem.value === option)?.label || ''
              }
              includeInputInList
              onChange={(e, v, r) => {
                if (v) {
                  onChange([v]);
                } else {
                  onChange([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  label={label}
                  placeholder={label}
                />
              )}
              PopperComponent={Popper}
            />
          ) : (
            <TextField
              sx={{ width: '100%', ...sx }}
              value={value}
              label={label}
              InputLabelProps={inputLabelProps}
              {...params}
              onChange={(e) => onChange(isTrim ? e.target.value.trim() : e.target.value)}
              variant='standard'
              type={type}
              InputProps={{
                endAdornment: isCleared ? (
                  <IconButton
                    size={'small'}
                    aria-label='clear-field'
                    onClick={() => onChange('')}
                  >
                    <ClearRoundedIcon fontSize={'small'} />
                  </IconButton>
                ) : null,
              }}
            />
          )}
        </FormControl>
      </div>
    </div>
  );
};
