import { mediaBreakpointDown, scrollbarThin } from '@/styles/mixins';
import { Box, styled, Grid, List } from '@mui/material';
import { MailNotBS } from '@/components/MailNotifications/MailNotifications.styled';

export const MainWrapper = styled(Grid)({
  position: 'relative',
  justifyContent: 'space-between',
  [mediaBreakpointDown('md')]: {
    // flexDirection: 'column',
  },
});

export const ContainerForInputs = styled(Grid)({
  [mediaBreakpointDown('sm')]: {
    maxWidth: '100%',
  },
});
export const InputGrid = styled(Grid)({
  [mediaBreakpointDown('sm')]: {
    display: 'block',
    alignItems: 'start',
    width: '100%',
  },
});
export const ButtonsGrid = styled(Grid)<{ withChild?: boolean }>(({ withChild }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: withChild ? 'space-between' : 'end',
  alignItems: 'center',
  [mediaBreakpointDown('md')]: {
    width: '100%',
    alignItems: 'start',
    flexDirection: 'column',
  },
}));
export const ButtonsBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
  gap: '10px',
  [mediaBreakpointDown('md')]: {
    marginTop: 20,
    justifyContent: 'space-between',
    width: '100%',
  },
});

const FieldsList_ = styled(List)({
  width: '100%',
  maxWidth: 360,
  bgcolor: 'background.paper',
  maxHeight: 300,
  overflow: 'auto',
});

export const FieldsList = styled(FieldsList_)`
  ${scrollbarThin}
`;
