import { ArrowButton } from '@/components/ui/ArrowButton/ArrowButton';

import { radioItems } from '../constants';
import { RadioGroupForm } from '@/components/RadioGroupForm';
import React, { FC } from 'react';
import { MainCarousel } from '@/components/sections/PnrMainTable/PnrMainTable';
import { BottomPaginationPnr } from '../BottomPaginationPnr/BottomPaginationPnr';
import { usePNRStore } from '../store';
import shallow from 'zustand/shallow';
import PnrDndEditModal from '../modals/PnrDndEditModal/PnrDndEditModal';
import {
  PnrFirstTabButtonsWrapper,
  PnrFirstTabMainCarouselWrapper,
  PnrFirstTabPaginationWrapper,
} from './FirstTab.styled';

export const FirstTab = () => {
  const {
    selectedIndicator,
    setSelectedIndicator,
    layoutData,
    entranceIdx,
    valueRadio,
    setValueRadio,
    visibleIndicators,
    setVisibleIndicators,
    setCurrentEntranceLayout,
  } = usePNRStore(
    (state) => ({
      selectedIndicator: state.selectedIndicator,
      setSelectedIndicator: state.setSelectedIndicator,
      entranceIdx: state.entranceIdx,
      valueRadio: state.valueRadio,
      setValueRadio: state.setValueRadio,
      visibleIndicators: state.visibleIndicators,
      setVisibleIndicators: state.setVisibleIndicators,
      layoutData: state.layoutData,
      currentEntranceLayout: state.currentEntranceLayout,
      setCurrentEntranceLayout: state.setCurrentEntranceLayout,
    }),
    shallow
  );

  const onPaginationIndicatorClick = (idx: number) => {
    setSelectedIndicator(idx);
    if (layoutData) {
      const currentEntranceLayout = layoutData.entrances.find((it) => it.number === idx);
      if (currentEntranceLayout) {
        setCurrentEntranceLayout(currentEntranceLayout);
      }
    }
  };

  const onLeftArrowIndicatorClick = () => {
    if (selectedIndicator > Math.min(...entranceIdx)) {
      if (selectedIndicator - 1 < visibleIndicators[0]) {
        const pageNum = entranceIdx.find((it) => it === selectedIndicator);
        if (pageNum) {
          const idx = entranceIdx.indexOf(pageNum);
          const firstIdx = idx - 5 < 0 ? 0 : idx - 5;
          setVisibleIndicators(entranceIdx.slice(firstIdx, idx));
        }
      }
      setSelectedIndicator(selectedIndicator - 1);
      if (layoutData) {
        const currentEntranceLayout = layoutData.entrances.find(
          (it) => it.number === selectedIndicator - 1
        );
        if (currentEntranceLayout) {
          setCurrentEntranceLayout(currentEntranceLayout);
        }
      }
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueRadio((event.target as HTMLInputElement).value);
  };

  const onRightArrowIndicatorClick = () => {
    if (selectedIndicator < entranceIdx[entranceIdx.length - 1]) {
      if (selectedIndicator + 1 > visibleIndicators[visibleIndicators.length - 1]) {
        const pageNum = entranceIdx.find((it) => it === selectedIndicator + 1);
        if (pageNum) {
          const idx = entranceIdx.indexOf(pageNum);
          const secondIdx = idx + 5 > entranceIdx.length - 1 ? entranceIdx.length : idx + 5;
          setVisibleIndicators(entranceIdx.slice(idx, secondIdx));
        }
      }
      setSelectedIndicator(selectedIndicator + 1);
      if (layoutData) {
        const currentEntranceLayout = layoutData.entrances.find(
          (it) => it.number === selectedIndicator + 1
        );
        if (currentEntranceLayout) {
          setCurrentEntranceLayout(currentEntranceLayout);
        }
      }
    }
  };

  return (
    <>
      <PnrFirstTabMainCarouselWrapper>
        <ArrowButton
          direction='left'
          onClick={() => onLeftArrowIndicatorClick()}
        />
        <MainCarousel />
        <ArrowButton
          direction='right'
          onClick={() => onRightArrowIndicatorClick()}
        />
      </PnrFirstTabMainCarouselWrapper>
      <PnrFirstTabButtonsWrapper>
        <PnrDndEditModal />

        <RadioGroupForm
          value={valueRadio}
          onChange={handleRadioChange}
          items={radioItems}
        />
      </PnrFirstTabButtonsWrapper>

      <PnrFirstTabPaginationWrapper>
        <BottomPaginationPnr
          onLeftArrowIndicatorClick={onLeftArrowIndicatorClick}
          onPaginationIndicatorClick={onPaginationIndicatorClick}
          onRightArrowIndicatorClick={onRightArrowIndicatorClick}
        />
      </PnrFirstTabPaginationWrapper>
    </>
  );
};
