import { styled } from '@mui/material';
import EastRoundedIcon from '@mui/icons-material/EastRounded';

export const BreadcrumbsImg = styled('img')({
  width: 50,
  height: 50,
  objectFit: 'contain',
  objectPosition: 'center',
  margin: '0 10px',
});

export const RightArrowIcon = styled(EastRoundedIcon)({
  marginLeft: '10px',
});

export const BreadcrumbsWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const BreadcrumbsItemWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
