import produce from 'immer';
import { createStore } from '../utils/createStore';
import { v4 as uuid } from 'uuid';

const _MAX_ELEMENT_COUNT = 20;
export enum ActionLogType {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFO = 'info',
}

export class ActionLog {
  id: string = uuid();
  eventAt: Date = new Date();
  type!: ActionLogType;
  message!: string;
  errorBody?: string;
  errorTrace?: string;
  viewed = false;
  constructor(type: ActionLogType, message: string, errorBody?: string, errorTrace?: string) {
    this.type = type;
    this.message = message;
    this.errorBody = errorBody;
    this.errorTrace = errorTrace;
  }
}

export type FetchWithUserMessageFlagCatchErrorFunction = (
  error: any,
  frontendMessage?: string
) => void;

export type AddActionLogFunction = (
  type: ActionLogType,
  message: string,
  errorBody?: string,
  errorTrace?: string
) => void;

export interface ActionLogStore {
  actionLogList: ActionLog[];
  addActionLog: AddActionLogFunction;
  setActionLogViewed: (id: string) => void;
  fetchCatch: FetchWithUserMessageFlagCatchErrorFunction;
}

export const useActionLog = createStore<ActionLogStore>(
  (set: any, get: any) => ({
    actionLogList: [],

    addActionLog: (
      type: ActionLogType,
      message: string,
      errorBody?: string,
      errorTrace?: string
    ) => {
      set(
        produce((items: any) => {
          if (items.actionLogList.length >= _MAX_ELEMENT_COUNT) {
            items.actionLogList.shift();
          }
          items.actionLogList.push(new ActionLog(type, message, errorBody, errorTrace));
        })
      );
    },

    fetchCatch: (error, frontendMessage) => {
      let textToShow = 'Непредвиденная ошибка';
      const isUserMessage =
        !!error?.response?.data?.userMessage && !!error?.response?.data?.messages?.length;
      if (isUserMessage) {
        textToShow = error?.response?.data?.messages?.join('; ');
      }
      if (!isUserMessage && frontendMessage) {
        textToShow = frontendMessage;
      }

      get().addActionLog(ActionLogType.ERROR, textToShow, error?.response?.data);
    },

    setActionLogViewed: (id: string) => {
      set(
        produce((items: any) => {
          const item = items.actionLogList.find((t: ActionLog) => t.id === id);
          item.viewed = true;
        })
      );
    },
  }),
  'Action logs',
  'action-logs'
);

/**
 * @description
 * Функция для перехвата синхронных ошибок и добавления их в журнал событий
 * @param message - текст, который будет отображаться пользователю
 * @param error - (необязательно) Объект ошибки, содержащий дополнительную информацию о произошедшей ошибке(доступен внутри журнала событий)
 */
export const syncErrorCatch = (message: string, error?: any) =>
  useActionLog.getState().addActionLog(ActionLogType.ERROR, message, error, error);

export const addSuccessActionLog = (message: string) =>
  useActionLog.getState().addActionLog(ActionLogType.SUCCESS, message);

/**
 * @description
 * Перехватчик ошибок запросов, для отображения их пользователю в ActionLog
 * @param error - ошибка из запроса
 * @param frontendMessage - текст, который будет отображаться пользователю, если ошибка из бэкенда не содержит userMessage:true
 * @example
  .catch((err) => fetchCatch(err, 'Ошибка редактирования абонента'));
  .catch((err) => fetchCatch(err));
 */
export const fetchCatch = (error: any, frontendMessage?: string) =>
  useActionLog.getState().fetchCatch(error, frontendMessage);
