import React from 'react';
import { NavWrapper } from '@/components/widgets/Navbar/NavBar.styled';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';
import { NavbarLinks } from './NavbarLinks/NavbarLinks';
import { MobileNavbar } from './MobileNavbar/MobileNavbar';
import NavbarActionButtons from './NavbarActionButtons';
import NavbarLogoWithBolt from './NavbarLogoWithBolt';

export default function Navbar() {
  const isMobile = useMobileSize();
  return (
    <>
      <NavWrapper>
        <NavbarLogoWithBolt />
        {isMobile ? <MobileNavbar /> : <NavbarLinks />}
        {!isMobile && <NavbarActionButtons />}
      </NavWrapper>
    </>
  );
}
