import { StatisticByInstallationLocationData } from './types';
import React from 'react';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import Paper from '@mui/material/Paper';
import { Grid } from '@material-ui/core';
import { MultiSelect } from '../../../inputs/MultiSelectNew';
import { Button } from '@mui/material';
import { Table } from 'antd';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectFilterOptType } from '../../../filter/MultySelectFilter';
import { Popup } from '../../../popups';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { getDataFromFilter } from '../../../inputs/MultiSelectNew/utils';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { generateDetailedInfo } from '../../../button/DetailedInfo/utils';
import {
  columns,
  options,
  structureTableDataFunction,
  getDataForCharts,
  labels,
  TABLE_CELL_OPTIONS,
  formattedValue,
  generateStatiscByInstallationLocationExcelFile,
} from './utils';
import {
  StatisticByInstallationLocationFiltersWrapper,
  StatisticByInstallationLocationTableWrapper,
} from './StatisticByInstallationLocation.styled';
import { useCatalog } from '@/hooks/CatalogHook';

interface StatisticByInstallationLocationProps {
  header: string;
  load: boolean;
  data: StatisticByInstallationLocationData[];
  updateFunction: (filter: any) => void;
}

export const StatisticByInstallationLocation: React.FC<StatisticByInstallationLocationProps> = ({
  header,
  load,
  data,
  updateFunction,
}) => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const { getContractCatalog } = useCatalog((state) => ({
    getContractCatalog: state.getContractCatalog,
  }));
  const contractListResponse = getContractCatalog();
  const [selectedContractList, setSelectedContractList] = React.useState<SelectFilterOptType[]>([]);

  const [isOpenPopUp, setIsOpenPopUp] = React.useState<boolean>(false);

  const formatedData = structureTableDataFunction(data);

  const summary = formatedData.total || [];
  const contractorItems = formatedData.contractorItems || [];

  const onSubmitSearch = () => {
    updateFunction(getDataFromFilter(selectedContractList, 'value'));
  };

  const onResetClick = () => {
    setSelectedContractList([]);
    updateFunction([]);
  };

  const dataBar: any = {
    labels,
    datasets: [
      {
        type: 'bar' as const,
        label: 'Фаза 1',
        data: getDataForCharts(summary).map((item) => item.total1),
        backgroundColor: '#219ebc',
        minBarLength: 40,
        barThickness: 30,
        tooltip: {
          position: 'average',
          callbacks: {
            label: (context: any) => {
              if (context.dataIndex >= 0 && context.dataIndex < getDataForCharts(summary).length) {
                const tooltipContent = [
                  `Квартира 1ф: ${getDataForCharts(summary)[context.dataIndex].apartment1}`,
                  `Лестница 1ф: ${getDataForCharts(summary)[context.dataIndex].stairs1}`,
                  `Холл 1ф: ${getDataForCharts(summary)[context.dataIndex].hall1}`,
                ];
                return tooltipContent;
              }
            },
          },
        },
      },
      {
        type: 'bar' as const,
        label: 'Фаза 3',
        data: getDataForCharts(summary).map((item) => item.total3),
        backgroundColor: '#ffb703',
        minBarLength: 40,
        barThickness: 30,
        tooltip: {
          callbacks: {
            label: (context: any) => {
              if (context.dataIndex >= 0 && context.dataIndex < getDataForCharts(summary).length) {
                const tooltipContent = [
                  `Квартира 3ф: ${getDataForCharts(summary)[context.dataIndex].apartment3}`,
                  `Лестница 3ф: ${getDataForCharts(summary)[context.dataIndex].stairs3}`,
                  `Холл 3ф: ${getDataForCharts(summary)[context.dataIndex].hall3}`,
                ];
                return tooltipContent;
              }
            },
          },
        },
      },
    ],
  };

  const detailedInfo = generateDetailedInfo([selectedContractList, 'Контракт']);

  const downloadExcel = () => {
    generateStatiscByInstallationLocationExcelFile([...summary, ...contractorItems]);
  };

  return (
    <>
      <Popup
        isOpen={isOpenPopUp}
        onClose={() => setIsOpenPopUp(false)}
        customStyle={{ minWidth: '50%' }}
      >
        <Chart
          options={options}
          type='bar'
          data={dataBar}
          plugins={[ChartDataLabels]} // тут добовлять плагины массивом
        />
      </Popup>
      <Paper elevation={6}>
        <p className={'headerPageStatistics'}>{header}</p>

        <Grid>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitSearch)}>
              <StatisticByInstallationLocationFiltersWrapper>
                <MultiSelect
                  label={'Контракт'}
                  value={selectedContractList}
                  onlyInListArgument
                  options={contractListResponse}
                  onChange={(sel: any) => setSelectedContractList(sel)}
                />

                <FilterButtons
                  info={detailedInfo}
                  onSearchClick={onSubmitSearch}
                  onCloseClick={onResetClick}
                  disable={!selectedContractList.length}
                />
                {!!summary.length && (
                  <Button
                    disabled={!selectedContractList.length}
                    variant='text'
                    onClick={() => setIsOpenPopUp(true)}
                    startIcon={<ShowChartIcon />}
                  >
                    Показать диаграмму
                  </Button>
                )}
                {!!summary.length && (
                  <Button
                    disabled={!selectedContractList.length}
                    variant='text'
                    onClick={downloadExcel}
                    startIcon={<FileDownloadOutlinedIcon />}
                  >
                    скачать excel
                  </Button>
                )}
              </StatisticByInstallationLocationFiltersWrapper>
            </form>
          </FormProvider>
          {load ? (
            <LoadSpinner />
          ) : (
            <StatisticByInstallationLocationTableWrapper>
              <Table
                columns={columns}
                summary={() => (
                  <Table.Summary fixed={'top'}>
                    {summary?.map((item, index) => {
                      return (
                        <Table.Summary.Row
                          key={index}
                          style={{ backgroundColor: '#FAFAFA' }}
                        >
                          <Table.Summary.Cell
                            index={1}
                            colSpan={1}
                            align='right'
                          >
                            {item.montagePlace}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            index={1}
                            colSpan={1}
                            align='center'
                            className='custom-column-class-blue'
                          >
                            {formattedValue(item.allTaskOnePhase)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            {...TABLE_CELL_OPTIONS}
                            className='custom-column-class-yellow'
                          >
                            {formattedValue(item.allTaskThreePhase)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            {...TABLE_CELL_OPTIONS}
                            className='custom-column-class-blue'
                          >
                            {formattedValue(item.tkoOnePhaseCount)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            {...TABLE_CELL_OPTIONS}
                            className='custom-column-class-yellow'
                          >
                            {formattedValue(item.tkoThreePhaseCount)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            {...TABLE_CELL_OPTIONS}
                            className='custom-column-class-blue'
                          >
                            {formattedValue(item.replacementOnePhaseCount)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            {...TABLE_CELL_OPTIONS}
                            className='custom-column-class-yellow'
                          >
                            {formattedValue(item.replacementThreePhaseCount)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            {...TABLE_CELL_OPTIONS}
                            className='custom-column-class-blue'
                          >
                            {formattedValue(item.remainderOnePhaseCount)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            {...TABLE_CELL_OPTIONS}
                            className='custom-column-class-yellow'
                          >
                            {formattedValue(item.remainderThreePhaseCount)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell {...TABLE_CELL_OPTIONS}>
                            {formattedValue(item.dynamicTotal)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell {...TABLE_CELL_OPTIONS}>
                            {formattedValue(item.dynamicOneWeek)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell {...TABLE_CELL_OPTIONS}>
                            {formattedValue(item.dynamicTwoWeek)}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      );
                    })}
                  </Table.Summary>
                )}
                dataSource={contractorItems}
                pagination={false} // Отключаем пагинацию
                scroll={{ y: 300 }}
                style={{ tableLayout: 'fixed', width: '89%', margin: 'auto' }}
                bordered
                locale={{ emptyText: 'Нет данных' }}
                size='small'
              />
            </StatisticByInstallationLocationTableWrapper>
          )}
        </Grid>
      </Paper>
    </>
  );
};
