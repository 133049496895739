import { Stack, Typography } from '@mui/material';
import { TextField } from '@/components/fixUI';
import { Box } from '@mui/system';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import shallow from 'zustand/shallow';
import { useStatus } from '@/components/features/taskEditor/panel/store';
import { Phase } from '@/dto/TransformerDto';
import { TTMount } from '@/components/Transformer/TransformerAmperage/TransTT';

interface TransPhaseProps {
  phase: Phase;
  index: number;
  keyString: 'mount' | 'unmount' | 'unexpected';
}

export const TransPhase: FC<TransPhaseProps> = ({ phase, index, keyString }) => {
  const { register, setValue } = useFormContext();
  setValue(`${keyString}.phaseList.${index}.id`, phase?.id);

  const { isDisabledAll } = useStatus(
    (state) => ({
      isDisabledAll: state.isDisabledAll,
    }),
    shallow
  );

  return (
    <Box sx={{ p: 2 }}>
      {phase && (
        <Stack
          spacing={2}
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
        >
          <Typography variant='inherit'>{phase?.phase}</Typography>
          <TextField
            id='tf-number-tt'
            label='Номер ТТ'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${keyString}.phaseList.${index}.serial`, {
              value: phase?.serial,
            })}
            disabled={isDisabledAll()}
          />
          <TextField
            id='tf-stamp-number'
            label='Пломба ТТ'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${keyString}.phaseList.${index}.stamp`, {
              value: phase?.stamp,
            })}
            disabled={isDisabledAll() || keyString === 'unmount'}
          />
          <TextField
            id='tf-prev-factory-year'
            label='Год выпуска'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${keyString}.phaseList.${index}.year`, {
              value: phase?.year,
            })}
            disabled={isDisabledAll() || keyString === 'mount'}
          />
        </Stack>
      )}
    </Box>
  );
};
