import { color } from '@/styles/mixins';
import { Autocomplete, Grid, Stack, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { KeyString, getKtnValue, lookUpExistingEquipment } from './utils';
import { PresentTransformerVoltage } from '@/dto/taskmap/task/TaskResultDto';
import { useCatalog } from '@/hooks/CatalogHook';
import { useExsistingEquipmentValidation } from './forms';
import { taskMapToArray } from '../../tasksBrowse/utils';
import { useMemo } from 'react';
interface FromPresentTransformerVoltageProps {
  keyString: KeyString;
  formData: PresentTransformerVoltage;
}
const FromPresentTransformerVoltage = ({
  formData,
  keyString,
}: FromPresentTransformerVoltageProps) => {
  const { register, control } = useFormContext();
  const { getCatalogMapWithErr, ktnType } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    ktnType: state.ktnType,
  }));
  const rules = useExsistingEquipmentValidation();

  const optionsktnType = useMemo(() => taskMapToArray(getCatalogMapWithErr('ktnType')), [ktnType]);

  return (
    <>
      <Stack
        mb={3}
        mt={3}
      >
        <Typography
          variant='h6'
          color={color('trueBlack')}
        >
          {lookUpExistingEquipment[keyString]}
        </Typography>
      </Stack>
      <Grid
        container
        spacing={3}
        mb={3}
      >
        <Grid item>
          <Controller
            name={`${keyString}.type`}
            control={control}
            defaultValue={formData.type}
            rules={rules.tnType}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                sx={{ width: '200px' }}
                id={`${keyString}`}
                label='Тип'
                variant='standard'
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Controller
            name={`${keyString}.ktn`}
            control={control}
            defaultValue={getKtnValue(formData.ktn)}
            rules={rules.tnKtn}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                sx={{ width: '200px' }}
                options={optionsktnType}
                onChange={(event, newValue) => {
                  field.onChange(newValue ? newValue.value : null);
                }}
                value={
                  optionsktnType.find(
                    (option) => option.value === field.value || option.label === field.value
                  ) || {
                    label: field.value,
                    value: field.value,
                  }
                }
                getOptionLabel={(option) => {
                  return getCatalogMapWithErr('ktnType').get(option.value) || option.label || '';
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='КТН'
                    variant='standard'
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
      <Stack>
        {[...formData.phaseList]
          .sort((a, b) => a.phase.localeCompare(b.phase))
          .map((item, index) => {
            return (
              <Grid
                item
                container
                key={item.phase}
              >
                <Typography
                  variant='h6'
                  color={color('trueBlack')}
                  marginY={2}
                >{`фаза ${item.phase}`}</Typography>
                <Grid
                  item
                  container
                  spacing={3}
                >
                  <Grid item>
                    <Controller
                      name={`${keyString}.phaseList.${index}.number`}
                      control={control}
                      defaultValue={formData?.phaseList[index].number}
                      rules={rules.tnNumber}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          sx={{ width: '200px' }}
                          id={`${keyString}`}
                          label='№ ТН'
                          variant='standard'
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Controller
                      name={`${keyString}.phaseList.${index}.year`}
                      control={control}
                      defaultValue={formData?.phaseList[index].year}
                      rules={rules.tnYear}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          sx={{ width: '200px' }}
                          id={`${keyString}`}
                          label='Год выпуска'
                          variant='standard'
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <TextField
                      hidden
                      {...register(`${keyString}.phaseList.${index}.phase`, {
                        value: formData.phaseList[index].phase,
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Stack>
    </>
  );
};
export default FromPresentTransformerVoltage;
