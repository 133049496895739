import { color, font } from '@/styles/mixins';
import { styled, Badge, Tab, Tabs, Stack } from '@mui/material';

export const CustomeBage = styled(Badge)({
  cursor: 'pointer',
  ':hover svg': {
    color: color('muiBlue'),
  },
});

export const CustomTab = styled(Tab)`
  all: unset;
  min-height: 32px;
  ${font('text2')}
  padding: 6px 12px;
  color: black;
  cursor: pointer;
  font-weight: 500;
`;
export const CustomTabs = styled(Tabs)({
  minHeight: '12px',
  '& .MuiTabs-indicator': {
    backgroundColor: 'black',
    height: '1px',
    bottom: '4px',
  },
});

export const EventsLogActionOverflow = styled(Stack)`
  width: '100%';
  max-width: '701px';
  overflow: 'auto';
  max-height: '400px';
`;
