import { HermesAnswer } from '@/dto/HermesDto';
import { Button, CircularProgress, Tooltip, Typography } from '@mui/material';
import { ruRU } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';
import {
  PnrHermesWrapper,
  PnrHermesButtonsWrapper,
  PnrHermesDataWrapper,
  PnrHermesTerminal,
  PnrHermesTerminalEmptyText,
  PnrHermesDataGrid,
  PnrHermesTerminalRow,
  PnrHermesTerminalMacWrapper,
} from './PnrHermes.styled';
import { getAllGateways, HOUSE_GATEWAY_COLUMNS, useHermesLog } from './utils';
import CopyButton from '@/components/button/CopyButton/CopyButton';
import { useHouseLevelFloorType } from '@/components/features/pnr/HouseEntranceEdit/utils';
import { usePNRStore } from '@/components/features/pnr/store';

const PnrHermes = () => {
  const { houseData, hermesGateways, setHermesGateways, terminalMap, hermesBackendCalled } =
    usePNRStore((state) => ({
      houseData: state.houseData,
      hermesGateways: state.hermesGateways,
      setHermesGateways: state.setHermesGateways,
      terminalMap: state.terminalMap,
      hermesBackendCalled: state.hermesBackendCalled,
    }));
  const { floorTypes } = useHouseLevelFloorType();

  const { getHermesLog, handleHermesCall, handleClearLog } = useHermesLog();

  const [checkedRows, setCheckedRows] = useState<string[]>([]);
  const gatewayColumns = useMemo(() => HOUSE_GATEWAY_COLUMNS, []);

  useEffect(() => {
    if (houseData && !hermesBackendCalled) {
      const allGateways = getAllGateways(houseData, floorTypes);
      setHermesGateways(allGateways);
      allGateways.forEach((gateway) => {
        getHermesLog(gateway.smrImei, houseData.houseId);
      });
    }
  }, [houseData]);

  useEffect(() => {
    if (terminalMap.size && hermesGateways.length) {
      const gateways = [...hermesGateways];
      for (const [imei, terminalStatus] of terminalMap) {
        const gatewayIndex = gateways.findIndex((obj) => obj.smrImei == imei);

        if (gateways[gatewayIndex]) {
          gateways[gatewayIndex].positiveResponses = terminalStatus?.positiveAnswers ?? ' ';
          gateways[gatewayIndex].loading = terminalStatus?.pending ?? false;
        }
      }
      setHermesGateways(gateways);
    }
  }, [terminalMap]);

  return (
    <PnrHermesWrapper>
      <PnrHermesButtonsWrapper>
        <Button onClick={() => handleHermesCall(houseData, checkedRows)}>ОТПРАВИТЬ</Button>

        <Tooltip
          title={
            <span style={{ display: 'flex', textAlign: 'center' }}>
              Результат будет сброшен <br />
              только для выбранных строк
            </span>
          }
        >
          <Button onClick={() => handleClearLog(houseData, checkedRows)}>СБРОСИТЬ РЕЗУЛЬТАТ</Button>
        </Tooltip>
      </PnrHermesButtonsWrapper>

      <PnrHermesDataWrapper>
        <div style={{ width: 700 }}>
          <PnrHermesDataGrid
            checkboxSelection
            rows={hermesGateways}
            columns={gatewayColumns}
            disableColumnFilter={true}
            sortingOrder={['asc', 'desc']}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            hideFooter={true}
            onSelectionModelChange={(model: any) => setCheckedRows(model)}
            selectionModel={checkedRows}
            getRowId={(row) => row.smrImei}
          />
        </div>

        <PnrHermesTerminal>
          {checkedRows.length ? (
            <>
              {checkedRows?.map((row, index) => {
                const terminalInfo = terminalMap.get(row);
                const result: HermesAnswer | null = terminalInfo?.result?.resultJson
                  ? JSON.parse(terminalInfo?.result?.resultJson)
                  : null;
                return (
                  <PnrHermesTerminalRow key={index}>
                    <Typography
                      sx={{ textAlign: 'center', mb: 1 }}
                      variant='h6'
                    >
                      IMEI: {row}
                    </Typography>
                    {terminalInfo?.pending ? (
                      <CircularProgress size={15} />
                    ) : (
                      <>
                        {result ? (
                          <>
                            <Typography variant='subtitle2'>{result?.message}</Typography>
                            <>
                              {!!result?.resultJsonArray?.length && (
                                <>
                                  <Typography variant='subtitle2'>
                                    Список успешно опрошенных MAC адресов:
                                  </Typography>

                                  {result?.resultJsonArray?.map((result, index) => {
                                    return (
                                      <PnrHermesTerminalMacWrapper key={index}>
                                        <Typography
                                          variant='body2'
                                          sx={{ width: '150px' }}
                                        >
                                          {result?.mac}
                                        </Typography>
                                        <CopyButton textToCopy={result?.mac} />
                                      </PnrHermesTerminalMacWrapper>
                                    );
                                  })}
                                </>
                              )}
                            </>
                          </>
                        ) : (
                          <Typography variant='body1'>Данные отсутствуют</Typography>
                        )}
                      </>
                    )}
                  </PnrHermesTerminalRow>
                );
              })}
            </>
          ) : (
            <PnrHermesTerminalEmptyText>
              ПОЛЕ ДЛЯ ВЫВОДА БОЛЕЕ <br />
              ПОДРОБНОЙ ИНФОРМАЦИИ ПО
              <br /> РЕЗУЛЬТАТУ
            </PnrHermesTerminalEmptyText>
          )}
        </PnrHermesTerminal>
      </PnrHermesDataWrapper>
    </PnrHermesWrapper>
  );
};

export default PnrHermes;
