import { Autocomplete, Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import styles from './Popup.module.scss';
import { Popup } from './Popup';
import { FormProvider, useForm } from 'react-hook-form';
import { DesktopDatePicker, TextField } from '../fixUI';
import { AutocompleteValue } from '../inputs/AutocompleteValue/AutocompleteValue';
import { useCatalog } from '../../hooks/CatalogHook';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { useRegistryEditStore } from '@/components/features/registryEditor/EditTable/store';

export enum RegistryEditPopupType {
  ALL,
  DATE,
  EXECUTOR,
}

interface IRegistryEditPopup {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  type: RegistryEditPopupType;
}

export const RegistryEditPopup: React.FC<IRegistryEditPopup> = ({ isOpen, setIsOpen, type }) => {
  const methods = useForm<any>();
  const { setValue, register } = methods;

  const { addDataAndExecutorByIds, selected, setSelected, addDataByIds, addExecutorByIds } =
    useRegistryEditStore((store) => ({
      addDataAndExecutorByIds: store.addDataAndExecutorByIds,
      addDataByIds: store.addDataByIds,
      addExecutorByIds: store.addExecutorByIds,
      selected: store.selected,
      setSelected: store.setSelected,
      registryResultTable: store.registryResultTable,
    }));
  const { getGroupCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
  }));
  const onSave = (data: any) => {
    switch (type) {
      case RegistryEditPopupType.ALL:
        addDataAndExecutorByIds(selected, data.data, data.executor);
        break;
      case RegistryEditPopupType.DATE:
        addDataByIds(selected, data.data);
        break;
      case RegistryEditPopupType.EXECUTOR:
        addExecutorByIds(selected, data.executor);
        break;
    }
    setIsOpen(false);
    setValue(`executor`, undefined);
    setValue(`data`, undefined);
    // setSelected([]);
    setScheduledDate(null);
  };

  const [scheduledDate, setScheduledDate] = useState<Date | null>(null);
  const onHandleScheduledDate = (newValue: Date | null) => {
    if (newValue) {
      setScheduledDate(newValue);
      setValue('data', moment(newValue).valueOf());
    }
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onSuccess={() => null}
    >
      <FormProvider {...methods}>
        <Grid
          container
          spacing={2}
        >
          {(type === RegistryEditPopupType.ALL || type === RegistryEditPopupType.DATE) && (
            <Grid
              item
              xs={4}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label='Плановая дата'
                  inputFormat='dd.MM.yyyy'
                  value={scheduledDate}
                  onChange={onHandleScheduledDate}
                  // disabled={!editable}
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: '100%' }}
                      {...params}
                      variant='standard'
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          )}
          {(type === RegistryEditPopupType.ALL || type === RegistryEditPopupType.EXECUTOR) && (
            <Grid
              item
              xs={4}
            >
              <AutocompleteValue
                title='Исполнитель'
                fieldName={`executor`}
                values={getGroupCatalog()}
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
          ></Grid>

          <Grid
            item
            xs={12}
          >
            <div className={styles['modal-buttons']}>
              <Button
                onClick={methods?.handleSubmit(onSave)}
                variant='contained'
              >
                Сохранить
              </Button>
            </div>
          </Grid>
        </Grid>
      </FormProvider>
    </Popup>
  );
};
