import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { ApiMergeAddresses } from '@/services/YodaRestService';
import { LoadingButton } from '@mui/lab';
import { Button, Modal, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import shallow from 'zustand/shallow';
import { usePnrBrowseStore } from '../../HousePnrBrowse/store';
import {
  HousePnrBrowseEditAddressModalWrapper,
  HousePnrBrowseModalHeader,
} from '../HousePnrBrowseEditAddressModal/HousePnrBrowseEditAddressModal.styled';
import {
  HousePnrBrowseMergeAddressModalButtonsWrapper,
  HousePnrBrowseMergeAddressModalRowWrapper,
  IOSSwitch,
  HousePnrBrowseMergeAddressModalFormControlLabel,
  HousePnrBrowseMergeAddressModalRow,
  HousePnrBrowseMergeAddress,
} from './HousePnrBrowseMergeAddressModal.styled';
import { HousePnrBrowseModalProps } from '../HousePnrBrowseEditAddressModal/HousePnrBrowseEditAddressModal';

const HousePnrBrowseMergeAddressModal = ({ refetchBrowse }: HousePnrBrowseModalProps) => {
  const {
    mergeAddressModalOpen,
    closeMergeAddressModal,
    selectedRowsData,
    openEditAddressModal,
    setSelectedRowsData,
  } = usePnrBrowseStore(
    (state) => ({
      mergeAddressModalOpen: state.mergeAddressModalOpen,
      selectedRowsData: state.selectedRowsData,
      closeMergeAddressModal: state.closeMergeAddressModal,
      openEditAddressModal: state.openEditAddressModal,
      setSelectedRowsData: state.setSelectedRowsData,
    }),
    shallow
  );

  const { fetchCatch, addActionLog } = useActionLog();

  const [mainAddressId, setMainAddressId] = useState<string | null>(null);
  const handleMainAddressChange = (event: React.ChangeEvent<HTMLInputElement>, houseId: string) => {
    const checked = event.target.checked;
    if (checked) {
      setMainAddressId(houseId);
    }
  };

  const handleClose = () => {
    setMainAddressId(null);
    closeMergeAddressModal();
  };

  const handleMerge = () => mutMergeHouseAddresses.mutate();

  const mutMergeHouseAddresses = useMutation<void, any>(() => {
    const addressesToMerges = selectedRowsData
      ?.map((row) => row.houseId)
      .filter((houseId) => houseId !== mainAddressId);
    return ApiMergeAddresses(addressesToMerges, mainAddressId ?? '')
      .then(() => {
        addActionLog(ActionLogType.SUCCESS, 'Адреса объединены');
        const mainAddressRow = selectedRowsData?.filter((el) => el.houseId === mainAddressId);
        setSelectedRowsData([...mainAddressRow]);
        openEditAddressModal();
        refetchBrowse();
        handleClose();
      })
      .catch((err) => fetchCatch(err, 'Ошибка при объединении адресов'));
  });
  return (
    <Modal
      open={mergeAddressModalOpen}
      onClose={handleClose}
    >
      <HousePnrBrowseEditAddressModalWrapper>
        <HousePnrBrowseModalHeader variant='h5'>
          Будет выполнено объединение адресов
          <br /> Выберите главный адрес
        </HousePnrBrowseModalHeader>

        <HousePnrBrowseMergeAddressModalRowWrapper>
          {selectedRowsData?.map((row, index) => (
            <HousePnrBrowseMergeAddressModalRow
              key={row.houseId}
              mainAddress={mainAddressId === row.houseId}
            >
              <Tooltip title={row.address ?? ''}>
                <HousePnrBrowseMergeAddress>{`${index + 1}. ${
                  row.address
                }`}</HousePnrBrowseMergeAddress>
              </Tooltip>
              <HousePnrBrowseMergeAddressModalFormControlLabel
                control={
                  <IOSSwitch
                    checked={mainAddressId === row.houseId}
                    onChange={(ev) => handleMainAddressChange(ev, row.houseId)}
                  />
                }
                label='Главный адрес'
              />
            </HousePnrBrowseMergeAddressModalRow>
          ))}
        </HousePnrBrowseMergeAddressModalRowWrapper>
        <HousePnrBrowseMergeAddressModalButtonsWrapper>
          <LoadingButton
            onClick={handleMerge}
            variant='contained'
            disabled={!mainAddressId}
            loading={mutMergeHouseAddresses.isLoading}
          >
            Объединить
          </LoadingButton>
          <Button onClick={handleClose}>Отменить</Button>
        </HousePnrBrowseMergeAddressModalButtonsWrapper>
      </HousePnrBrowseEditAddressModalWrapper>
    </Modal>
  );
};

export default HousePnrBrowseMergeAddressModal;
