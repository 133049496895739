import React from 'react';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';
import { InvestStatistic } from '@/components/features/InvestStatistic/ui/InvestStatistic';

const InvestStatisticPage = () => {
  useAccess({
    access: [AccessEnum.Statistic],
  });

  return <InvestStatistic />;
};

export default InvestStatisticPage;
