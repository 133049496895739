import { Grid, Switch } from '@mui/material';
import {
  ButtonClose,
  LabelSwitch,
  ShowInfoControllLabel,
  WrapperSwitch,
} from '../InvestStatistic.styled';
import CloseIcon from '@mui/icons-material/Close';
import { useInvestStatisticStore } from '../../store';
import shallow from 'zustand/shallow';

interface BudgetStatisticBarControlProps {
  displayNartis: boolean;
  setDisplayNartis: (value: boolean) => void;
}

export const BudgetStatisticBarControl = ({
  displayNartis,
  setDisplayNartis,
}: BudgetStatisticBarControlProps) => {
  const { setActiveIndex, checkedEnergyCompany, setCheckedEnergyCompany, setDataColumns } =
    useInvestStatisticStore(
      (state) => ({
        setActiveIndex: state.setActiveIndex,
        checkedEnergyCompany: state.checkedEnergyCompany,
        setCheckedEnergyCompany: state.setCheckedEnergyCompany,
        setDataColumns: state.setDataColumns,
      }),
      shallow
    );

  const closeHandler = () => {
    setActiveIndex(null);
    setDataColumns([]);
  };

  return (
    <Grid
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '11px',
      }}
    >
      <WrapperSwitch>
        <LabelSwitch
          checked={!checkedEnergyCompany}
          sx={{ cursor: 'pointer' }}
          onClick={() => setCheckedEnergyCompany(false)}
        >
          КОНТРАКТ
        </LabelSwitch>
        <Switch
          checked={checkedEnergyCompany}
          onChange={(e) => setCheckedEnergyCompany(e.target.checked)}
        />
        <LabelSwitch
          checked={checkedEnergyCompany}
          sx={{ cursor: 'pointer' }}
          onClick={() => setCheckedEnergyCompany(true)}
        >
          СБЫТ
        </LabelSwitch>
      </WrapperSwitch>
      <div>
        <ShowInfoControllLabel
          active={displayNartis}
          style={{ marginLeft: 'auto' }}
          control={
            <Switch
              checked={displayNartis}
              onChange={(e) => setDisplayNartis(e.target.checked)}
            />
          }
          label={'установлено пу нартис'}
        />
        <ButtonClose onClick={closeHandler}>
          <CloseIcon />
        </ButtonClose>
      </div>
    </Grid>
  );
};
