import { IQureFilter } from '@/components/statistics/StatisticWithDateForRP/types';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import {
  dowmloadCompalintsByFilterParams,
  getComaplaintSorceList,
  getComaplaintStageTypeList,
  getCompalintWorkTypeList,
  getComplaintTypeList,
  getComplaintsByFilter,
  getEnergeClassificationList,
  getEnergeTypeList,
  getEnergyComplaint,
  getOdpuComplaint,
} from '@/services/ComplaintService/ComplaintService';
import {
  CatalogResponse,
  ComplaintEnergyClassification,
} from '@/services/ComplaintService/ComplaintServiceDto';
import { createStore } from '@/utils/createStore';
import { EComplaintType, Filter, IComplaintItem } from './types';
import { getComplaintsWithFilesFix, getComplaintsWithFilesFixSingle } from './utils';
import { downLoadExcel } from './ComplaintReportButton/utils';
import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';

interface ComplaintCatalogItem {
  title: string;
  name: string;
}
export type ILabelCheckbox = {
  overdueResponseDate: boolean;
  overduePlannedDate: boolean;
};
export interface FixedValues {
  [key: string]: string | string[] | number | Date | boolean;
  [key: `${string}Arg`]: string | number;
}

type ObjectKey<Obj> = keyof Obj;
export type LabelCheckboxKeys = ObjectKey<ILabelCheckbox>;

interface ComplaintStore {
  compalaintList: IComplaintItem[];
  currentPage: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
  isLoading: boolean;
  isDownloadingReport: boolean;
  energyTypeList: ComplaintCatalogItem[];
  classificationList: CatalogResponse<ComplaintEnergyClassification>;
  compalintWorkTypeList: ComplaintCatalogItem[];
  filterParamsToSend: IQureFilter[];
  isEditFormOpenEnergy: boolean;
  isEditFormOpenOdpu: boolean;
  isAddTaskToComplaintOpen: boolean;
  stageTypeList: { name: string; title: string }[];
  complaintTypeList: { name: string; title: string }[];
  sorceList: { name: string; title: string }[];
  overdueResponseDate: false;
  overduePlannedDate: false;

  //   TODO:А почему не useQuery?
  fetchEnergeTypeList: () => void;
  //   TODO:А почему не useQuery?
  fetchEnergeClassificationList: () => void;
  fetchCompalintWorkTypeEnergy: () => void;
  fetchCompalintStageTypeList: () => void;
  fetchComplaintTypeList: () => void;
  fetchCompalaintSorceList: () => void;

  fetchData: (filter: Filter) => void;
  fetchSingleComplaint: (
    id: number | null | undefined,
    complaintType: string | undefined,
    fromQuery?: boolean
  ) => void;
  setNewPage: (newPage: number) => void;
  setNewCreatedComplaint: (newComplaint: IComplaintItem) => void;
  setPageSize: (pageSize: number) => void;
  setFilterParamsToSend: (filterParamsToSend: IQureFilter[]) => void;
  handleSearch: () => void;
  getFixedValues: () => FixedValues;
  downloadComplaintsByFilter: () => void;
  selectedCompliant: IComplaintItem | null;
  setSelectedCompliant: (complaint: IComplaintItem | null) => void;
  setIsEditFormOpenEnergy: (flag: boolean) => void;
  setIsEditFormOpenOdpu: (flag: boolean) => void;
  setIsAddTaskToComplaintOpen: (flag: boolean) => void;
  addNewComplaint: (complaint: IComplaintItem) => void;
  setBooleanValue: (key: LabelCheckboxKeys) => (value: boolean) => void;
}

export const useComplaintStore = createStore<ComplaintStore>(
  (set, get) => ({
    compalaintList: [],
    isEditFormOpenOdpu: false,
    isEditFormOpenEnergy: false,
    isAddTaskToComplaintOpen: false,
    currentPage: 0,
    pageSize: 5,
    totalPages: 0,
    totalElements: 0,
    isLoading: false,
    isDownloadingReport: false,
    energyTypeList: [],
    classificationList: [],
    stageTypeList: [],
    sorceList: [],
    filterParamsToSend: [],
    compalintWorkTypeList: [],
    complaintTypeList: [],
    selectedCompliant: null,
    overdueResponseDate: false,
    overduePlannedDate: false,

    fetchEnergeTypeList: () => {
      if (get().energyTypeList.length === 0) {
        getEnergeTypeList()
          .then(({ data }) => {
            set(() => ({
              energyTypeList: data,
            }));
          })
          .catch((error) => {
            useActionLog.getState().fetchCatch(error);
          });
      }
    },
    setBooleanValue: (key) => (value) =>
      set(() => ({
        [key]: value,
      })),
    fetchCompalintWorkTypeEnergy: () => {
      if (get().compalintWorkTypeList.length === 0) {
        getCompalintWorkTypeList()
          .then(({ data }) => {
            set(() => ({
              compalintWorkTypeList: data,
            }));
          })
          .catch((error) => {
            useActionLog.getState().fetchCatch(error);
          });
      }
    },
    addNewComplaint: (complaint) => {
      set(() => ({
        compalaintList: [complaint, ...get().compalaintList],
        // selectedCompliant: complaint, // comment for a while dont show just created complaint
      }));
    },
    fetchEnergeClassificationList: () => {
      if (get().classificationList.length === 0) {
        getEnergeClassificationList()
          .then(({ data }) => {
            set(() => ({
              classificationList: data,
            }));
          })
          .catch((error) => {
            useActionLog.getState().fetchCatch(error);
          });
      }
    },
    fetchComplaintTypeList: () => {
      if (get().classificationList.length === 0) {
        getComplaintTypeList()
          .then(({ data }) => {
            set(() => ({
              complaintTypeList: data,
            }));
          })
          .catch((error) => {
            useActionLog.getState().fetchCatch(error);
          });
      }
    },
    fetchCompalintStageTypeList: () => {
      if (get().stageTypeList.length === 0) {
        getComaplaintStageTypeList()
          .then(({ data }) => {
            set(() => ({
              stageTypeList: data,
            }));
          })
          .catch((error) => {
            useActionLog.getState().fetchCatch(error);
          });
      }
    },
    fetchCompalaintSorceList: () => {
      if (get().sorceList.length === 0) {
        getComaplaintSorceList()
          .then(({ data }) => {
            set(() => ({
              sorceList: data,
            }));
          })
          .catch((error) => {
            useActionLog.getState().fetchCatch(error);
          });
      }
    },
    fetchData: (filter: Filter) => {
      set(() => ({
        isLoading: true,
      }));
      getComplaintsByFilter(filter)
        .then(({ data }) => {
          const complaintsWithFiles = getComplaintsWithFilesFix(data);
          set(() => ({
            compalaintList: complaintsWithFiles,
            totalPages: data.totalPages,
            totalElements: data.totalElements,
          }));
        })
        .catch((error) => {
          useActionLog.getState().fetchCatch(error);
        })
        .finally(() => set(() => ({ isLoading: false })));
    },
    setNewPage: (newPage: number) => set(() => ({ currentPage: newPage })),

    fetchSingleComplaint: (complaintId, complaintType, fromQuery) => {
      set(() => ({
        isLoading: true,
      }));
      if (complaintType === EComplaintType.ENERGY_SERVICE) {
        getEnergyComplaint(complaintId)
          .then(({ data }) => {
            if (fromQuery) {
              set(() => ({
                compalaintList: [getComplaintsWithFilesFixSingle(data)],
                selectedCompliant: getComplaintsWithFilesFixSingle(data),
              }));
              return;
            }
            set(() => ({
              compalaintList: get().compalaintList.map((item) =>
                item.complaint.base.id === complaintId
                  ? getComplaintsWithFilesFixSingle(data)
                  : item
              ),
              selectedCompliant: getComplaintsWithFilesFixSingle(data),
            }));
          })
          .catch((error) => {
            useActionLog.getState().fetchCatch(error);
          })
          .finally(() => set(() => ({ isLoading: false })));
      } else {
        getOdpuComplaint(complaintId)
          .then(({ data }) => {
            if (fromQuery) {
              set(() => ({
                compalaintList: [getComplaintsWithFilesFixSingle(data)],
                selectedCompliant: getComplaintsWithFilesFixSingle(data),
              }));
              return;
            }

            set(() => ({
              compalaintList: get().compalaintList.map((item) =>
                item.complaint.base.id === complaintId
                  ? getComplaintsWithFilesFixSingle(data)
                  : item
              ),
              selectedCompliant: getComplaintsWithFilesFixSingle(data),
            }));
          })
          .catch((error) => {
            useActionLog.getState().fetchCatch(error);
          })
          .finally(() => set(() => ({ isLoading: false })));
      }
    },

    setFilterParamsToSend: (filterParamsToSend) => set(() => ({ filterParamsToSend })),

    setNewCreatedComplaint: (newComplaint: any) =>
      set(() => ({ compalaintList: [...get().compalaintList, newComplaint] })),
    setPageSize: (pageSize: number) => set(() => ({ pageSize: pageSize })),
    setSelectedCompliant: (selectedCompliant) => set(() => ({ selectedCompliant })),
    setIsEditFormOpenEnergy: (isEditFormOpenEnergy) => set(() => ({ isEditFormOpenEnergy })),
    setIsEditFormOpenOdpu: (isEditFormOpenOdpu) => set(() => ({ isEditFormOpenOdpu })),
    setIsAddTaskToComplaintOpen: (isAddTaskToComplaintOpen) =>
      set(() => ({ isAddTaskToComplaintOpen })),

    getFixedValues: () => {
      return get().filterParamsToSend.reduce((res, current) => {
        let tmp;
        if (current.type === 'DATE') {
          if (current.arg === FilterArgEnum.EQUAL || current.arg === FilterArgEnum.NOT_EQUAL) {
            tmp = current.values[0];
          } else {
            tmp = current.values;
          }
        } else if (current.type === 'TAG') {
          tmp = current.values.map((elem) => elem.value);
        } else {
          tmp = current.values[0];
        }
        return {
          ...res,
          [current.name]: tmp,
          [`${current.name}Arg`]: current.arg,
        };
      }, {});
    },
    handleSearch: () => {
      const filterData = {
        page: get().currentPage,
        size: get().pageSize,
        overdueResponseDate: get().overdueResponseDate,
        overduePlannedDate: get().overduePlannedDate,
        ...get().getFixedValues(),
      };
      get().fetchData(filterData);
    },
    downloadComplaintsByFilter: () => {
      const filterData = {
        page: get().currentPage,
        size: get().pageSize,
        overdueResponseDate: get().overdueResponseDate,
        overduePlannedDate: get().overduePlannedDate,
        ...get().getFixedValues(),
      };
      set(() => ({
        isDownloadingReport: true,
      }));
      dowmloadCompalintsByFilterParams(filterData)
        .then((data) => {
          downLoadExcel(data, 'compalints-by-filter');
          useActionLog.getState().addActionLog(ActionLogType.SUCCESS, `отчет сгенерирован успешно`);
        })
        .catch((err) => useActionLog.getState().fetchCatch(err))
        .finally(() => set(() => ({ isDownloadingReport: false })));
    },
  }),
  'Complaint store'
);
