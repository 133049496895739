import React, { useRef, useEffect, useState, MutableRefObject } from 'react';
import * as d3 from 'd3';
import { StatisticInvestProgramItem, Tooltip } from '../../types/types';

import shallow from 'zustand/shallow';

import BudgetStatisticDoughnutForm from './BudgetStatisticDoughnutForm';
import { ChartWrapper, InvestStatisticTitle } from '../InvestStatistic.styled';
import { useInvestStatisticStore } from '../../store';
import { DoughnutLegend } from './DoughnutLegend';
import {
  createPie,
  createSvg,
  drowSectorsDoughnut,
  getMouseCordsWithGap,
  height,
  normalizeData,
  paddingChart,
  width,
} from './utils';
import { DoughnutTooltip } from './DoughnutTooltip';
import moment from 'moment';

export const BudgetStatisticDoughnut = () => {
  const [withNartis, setWithNartis] = useState(false);

  const { showInfo, setActiveIndex, equipmentsData } = useInvestStatisticStore(
    (state) => ({
      showInfo: state.showInfo,
      setActiveIndex: state.setActiveIndex,
      equipmentsData: state.equipmentsData,
    }),
    shallow
  );
  const [loading, setLoading] = useState(false);

  const svgRef = useRef<SVGSVGElement | null>(null);
  const refWrapper = useRef() as MutableRefObject<HTMLDivElement>;

  const [tooltip, setTooltip] = useState<Tooltip>({
    display: 'none',
    tooltipData: null,
    top: 0,
    left: 0,
  });

  const handleMouseover = (event: MouseEvent, d: d3.PieArcDatum<StatisticInvestProgramItem>) => {
    if (!showInfo) {
      setTooltip({
        display: 'block',
        tooltipData: d.data,
        ...getMouseCordsWithGap(event),
      });
    }
  };

  const handleMousemove = (event: MouseEvent) => {
    setTooltip((prev) => ({
      ...prev,
      ...getMouseCordsWithGap(event),
    }));
  };

  const handleMouseout = (event: MouseEvent) => {
    setTooltip({ display: 'none', tooltipData: null, top: 0, left: 0 });
  };

  const handleOnClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    d: d3.PieArcDatum<StatisticInvestProgramItem> | { index: number }
  ) => {
    setActiveIndex(d.index);
  };

  useEffect(() => {
    refWrapper?.current?.scrollIntoView({ behavior: 'smooth' });
    d3.select(svgRef.current).selectAll('*').remove();

    const radius = Math.min(width, height) / 2 - 10 - paddingChart(equipmentsData);

    const svg = createSvg(svgRef);

    const pie = createPie();

    const normalizedData = normalizeData(equipmentsData, 2);

    const arcs = pie(normalizedData);
    // FIXME: на случай отката
    // const arcs = pie(equipmentsData);

    drowSectorsDoughnut(
      arcs,
      svg,
      equipmentsData,
      handleMouseover,
      handleMousemove,
      handleMouseout,
      handleOnClick,
      radius
    );
  }, [showInfo, equipmentsData, withNartis]);

  return (
    <>
      <div ref={refWrapper}>
        <InvestStatisticTitle>ПЛАН СБЫТЫ {moment().format('YYYY')}</InvestStatisticTitle>

        <ChartWrapper>
          <BudgetStatisticDoughnutForm
            withNartis={withNartis}
            setWithNartis={setWithNartis}
          />

          <>
            <div style={{ display: 'flex' }}>
              <div
                style={{ paddingTop: '60px', display: 'flex', justifyContent: 'center', flex: 1 }}
              >
                <svg ref={svgRef}></svg>
              </div>

              <DoughnutLegend
                withNartis={withNartis}
                onClick={handleOnClick}
              />
            </div>
            <DoughnutTooltip
              tooltip={tooltip}
              withNartis={withNartis}
            />
          </>
        </ChartWrapper>
      </div>
    </>
  );
};
