import { Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useState } from 'react';
import { NetworkElementEquippedSimResponse } from '@/dto/NetworkDto';
import { DisabledTextField } from '../../../../fixUI';
import { useCatalog } from '@/hooks/CatalogHook';
import { useActionLog } from '@/hooks/ActionLogHook';
import { AutocompleteValue } from '../../../../inputs/AutocompleteValue/AutocompleteValue';
import { useNetworkStore } from './store';
import { ConfirmationDialog } from '../../../../common/ConfirmationDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';

interface ISimItem {
  sim: NetworkElementEquippedSimResponse;
}

export const SimItem: FC<ISimItem> = ({ sim }) => {
  const onlyShow = useTaskEditorOnlyShow();
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { deleteNetworkElementSim } = useNetworkStore((store) => ({
    deleteNetworkElementSim: store.deleteNetworkElementSim,
  }));
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Box sx={{ p: 2, border: 'dashed rgb(152, 152, 152)', position: 'relative' }}>
      <ConfirmationDialog
        title='Удалить SIM?'
        open={isOpen}
        handleOk={() => {
          deleteNetworkElementSim(sim.id);
          setIsOpen(false);
        }}
        handleCancel={() => setIsOpen(false)}
        textForOk='удалить'
      >
        Действие не получится отменить
      </ConfirmationDialog>
      <DeleteIcon
        sx={{
          color: 'gray',
          fontSize: 22,
          cursor: onlyShow ? '' : 'pointer',
          position: 'absolute',
          top: 5,
          right: 5,
        }}
        onClick={() => !onlyShow && setIsOpen(true)}
      />
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <DisabledTextField
            id={'sim-iccid'}
            label={'ICCID'}
            defaultValue={sim.iccid}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <DisabledTextField
            id={'sim-number'}
            label={'Номер'}
            defaultValue={sim.number}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <DisabledTextField
            id={'sim-ip'}
            label={'IP'}
            defaultValue={sim.ip}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <AutocompleteValue
            title='Тип'
            fieldName={'sim-type'}
            defaultValue={sim.simType}
            disabled
            values={getCatalogMapWithErr('simType')}
          />
        </Grid>

        <Grid
          item
          xs={6}
        >
          <AutocompleteValue
            title='Владелец'
            fieldName={'sim-operator'}
            defaultValue={sim.simOperatorEnum}
            disabled
            values={getCatalogMapWithErr('simOperator')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
