import { useActionLog } from '@/hooks/ActionLogHook';
import { GetCatalogContracts } from '../api/InvestStatisticService';
import { ApiFindAllContractResponse } from '@/types/api';
import { useQuery } from 'react-query';
import { ApiFindAllContract } from '@/services/YodaRestService';
import { useMemo } from 'react';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';

function convertIntoFilter(data: ApiFindAllContractResponse): SelectFilterOptType[] {
  return data.map((contract) => ({ value: contract.id, label: contract.title }));
}

export function useBudgetStatisticContracts() {
  const { fetchCatch } = useActionLog();

  const { data } = useQuery({
    queryKey: ['contracts'],
    staleTime: Infinity,
    queryFn: ApiFindAllContract,
    onError: fetchCatch,
  });

  const { data: budgetStatisticContracts, isFetching: budgetStatisticFetchng } = useQuery({
    queryKey: ['budgetStatisticContracts'],
    staleTime: Infinity,
    queryFn: GetCatalogContracts,
    onError: fetchCatch,
  });

  const catalogContracts = useMemo(() => {
    if (!data?.data || !budgetStatisticContracts?.data) return [];

    const filteredContracts = data.data.filter((contract) =>
      budgetStatisticContracts.data.some((budgetContract) => budgetContract.id === contract.id)
    );

    return convertIntoFilter(filteredContracts);
  }, [data, budgetStatisticContracts]);

  return { catalogContracts, budgetStatisticFetchng };
}
