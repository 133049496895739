import { useActionLog } from '@/hooks/ActionLogHook';
import { GetCatalogEnergyCompany } from '../api/InvestStatisticService';
import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '../../tasksBrowse/utils';

export function useBudgetStatisticEnergyCompany() {
  const { fetchCatch } = useActionLog();
  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);

  const energyCompany = taskMapToArray(getCatalogMapWithErr('energyCompany'));

  const { data: budgetEnergyCompany, isFetching: budgetStatisticFetchng } = useQuery({
    queryKey: ['budgetStatisticEnergyCompany'],
    staleTime: Infinity,
    queryFn: GetCatalogEnergyCompany,
    onError: fetchCatch,
  });

  const catalogEnergyCompany = useMemo(
    () =>
      energyCompany?.filter((energyCompany) =>
        budgetEnergyCompany?.data.some(
          (budgetEnergyCompany) => budgetEnergyCompany === energyCompany.value
        )
      ),
    [energyCompany, budgetEnergyCompany]
  );

  return { catalogEnergyCompany, budgetStatisticFetchng };
}
