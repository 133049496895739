import variables, { FontFamily } from './variables';
import breakpoints from './breakpoints';
import { css } from '@mui/material';
import { SerializedStyles } from '@emotion/react';

type AdaptiveFonts = (
  mobile: number,
  tablet: number,
  desktopXXL: number,
  desktopFHD: number
) => SerializedStyles;
const getAdaptiveFont: AdaptiveFonts = (mobile, tablet, desktopXXL, desktopFHD) => {
  return css`
    font-size: ${mobile}px;

    @media (min-width: ${breakpoints.md}px) {
      font-size: calc(
        ${tablet}px + ${desktopXXL - tablet} * (100vw - ${breakpoints.md}px) /
          (${breakpoints.xxl} - ${breakpoints.md})
      );
    }

    @media (min-width: ${breakpoints.xxl}px) {
      font-size: calc(
        ${desktopXXL}px + ${desktopFHD - desktopXXL} * (100vw - ${breakpoints.xxl}px) /
          (${breakpoints.fhd} - ${breakpoints.xxl})
      );
    }

    @media (min-width: ${breakpoints.fhd}px) {
      font-size: ${desktopFHD}px;
    }
  `;
};

/**
 * @param name - Название семейства шрифтов
 * @returns Стандартные стили для указанного семейства шрифтов
 * @example
 * ${fontFamily('Arial')}
 */
export const fontFamily: (name: FontFamily) => SerializedStyles = (name) => {
  return css`
    font-family: ${variables.fonts[name]};
  `;
};

const typography = {
  h2: css`
    ${getAdaptiveFont(16, 16, 18, 20)};
    ${fontFamily('roboto')};
    line-height: 1;
    font-weight: 500;
  `,
  text1: css`
    ${getAdaptiveFont(12, 14, 16, 18)};
    ${fontFamily('roboto')};
    line-height: 1;
    font-weight: 500;
  `,
  pagination: css`
    ${getAdaptiveFont(8, 10, 14, 16)};
    ${fontFamily('roboto')};
  `,
  text2: css`
    ${getAdaptiveFont(12, 14, 16, 18)};
    ${fontFamily('inter')};
    font-weight: 300;
    text-decoration: none;
  `,
  text3: css`
    ${getAdaptiveFont(12, 14, 16, 24)};
    ${fontFamily('inter')};
    font-weight: 500;
    text-decoration: none;
  `,
  text4: css`
    ${getAdaptiveFont(12, 14, 16, 20)};
    ${fontFamily('inter')};
    font-weight: 500;
    text-decoration: none;
  `,
  text5: css`
    ${getAdaptiveFont(12, 14, 16, 16)};
    ${fontFamily('inter')};
    font-weight: 300;
    text-decoration: none;
  `,
};

export type Typography = keyof typeof typography;

export default typography;
