import { IconButton, Tooltip } from '@mui/material';
import React, { FC, useMemo, useState } from 'react';
import { BoxShadow } from '@/components/ui/BoxShadow';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { ApiUpdateHouseData, DownloadHouseDataReport } from '@/services/YodaRestService';
import { useParams } from 'react-router-dom';
import { useActionLog } from '@/hooks/ActionLogHook';
import moment from 'moment';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { copyToClipboard, downLoadExcel, openUrlInNewTab } from '@/utils/heplers';
import { useCatalog } from '@/hooks/CatalogHook';
import { useHouseOdpuReplacementCount } from '@/hooks/useQuery/useHouseOdpuReplacement';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import {
  HouseEntranceHeaderRow,
  HouseEntranceHeaderInputsWrapper,
  HouseEntranceHeaderButtonsWrapper,
  HouseEntranceHeaderEditWrapper,
  HouseEntranceHeaderWrapper,
  HouseEntranceSettingsButton,
  HouseEntranceMapButton,
  HouseEntranceDocumentButton,
  HouseEntranceDownloadButton,
  HouseEntranceCancelButton,
  HouseEntrancePencilButton,
  HouseEntranceHeaderTextField,
  HouseEntranceAutocompleteValue,
  HouseEntranceHeaderBoxWrapper,
  HouseEntranceHeaderFlatCountMatched,
} from './HouseEntranceHeader.styled';
import PnrPlanningButtons, {
  PnrPlanningsButtonsProps,
} from '@/components/blocks/PnrPlanningButtons/PnrPlanningButtons';
import { getAllPuWithMacApartments } from '../HouseEntranceEdit/utils';
import PnrPlanningsCount from '@/components/blocks/PnrPlanningsCount/PnrPlanningsCount';
import shallow from 'zustand/shallow';
import { IdPopup } from '../../taskEditor/panel/IdPopup';
import { EntranceEnumerationModal } from '../modals/EntranceEnumerationModal/EntranceEnumerationModal';
import { EntranceResetAlertModal } from '../modals/EntranceResetAlertModal';
import { usePNRStore } from '../store';
import { getUrlByXY } from '../Yandex';
import { useIsIncorrectHouseShema } from '../HouseEntranceFlatCountMatched/utils';

const NOT_N_NUMBER = 'Число не натуральное';

type IHouseEntranceHeader = PnrPlanningsButtonsProps;

export const HouseEntranceHeader: FC<IHouseEntranceHeader> = ({ refetchHousePlannings }) => {
  const { getCatalogMapWithErr, puConnectType } = useCatalog((store) => ({
    getCatalogMapWithErr: store.getCatalogMapWithErr,
    puConnectType: store.puConnectType,
  }));
  const puConnectTypeOptions = useMemo(
    () => getCatalogMapWithErr('puConnectType'),
    [puConnectType]
  );
  const { houseDtoId } = useParams();
  const { odpuReplacementCount } = useHouseOdpuReplacementCount(houseDtoId);
  const isIncorrectHouseShema = useIsIncorrectHouseShema();

  const fetchCatch = useActionLog((state) => state.fetchCatch);
  const {
    houseData,
    setHouseData,
    entranceCount,
    setEntranceCount,
    floorCount,
    setFloorCount,
    flatCount,
    setFlatCount,
    address,
    setAddress,
    puConnectionType,
    setPuConnectionType,
  } = usePNRStore(
    (state) => ({
      houseData: state.houseData,
      setHouseData: state.setHouseData,
      entranceCount: state.entranceCount,
      setEntranceCount: state.setEntranceCount,
      floorCount: state.floorCount,
      setFloorCount: state.setFloorCount,
      flatCount: state.flatCount,
      setFlatCount: state.setFlatCount,
      address: state.address,
      setAddress: state.setAddress,
      puConnectionType: state.puConnectionType,
      setPuConnectionType: state.setPuConnectionType,
    }),
    shallow
  );

  const [disabled, setDisabled] = useState<boolean>(true);

  const [isChange, setIsChange] = useState<boolean>(false);
  const [openEntranceEnumeration, setOpenEntranceEnumeration] = React.useState(false);
  const [openEntranceResetModal, setOpenEntranceResetModal] = React.useState(false);

  const { setIsInProgress } = useScreenHoldHook();
  const [loading, setLoading] = useState<boolean>(false);
  const replaceInput = (str: string): number => Number(str.replace(/[^0-9]/g, ''));

  const clearHandler = () => {
    setIsChange(false);
    setAddress(houseData?.address ? houseData?.address : '');
    setEntranceCount(houseData?.entranceCount ? houseData?.entranceCount : 0);
    setFloorCount(houseData?.floorCount ? houseData?.floorCount : 0);
    setFlatCount(houseData?.flatCount ? houseData?.flatCount : 0);
    setPuConnectionType(houseData?.puConnectType ? houseData?.puConnectType : '');
  };

  const handleCopy = () => copyToClipboard(houseData?.fias, `ФИАС скопирован`, `ФИАС отсутствует`);

  const handleSave = () => {
    if (houseDtoId) {
      setIsInProgress(true);
      ApiUpdateHouseData(
        houseDtoId,
        floorCount,
        entranceCount,
        flatCount,
        address,
        puConnectionType == '' ? null : puConnectionType
      )
        .then((res) => setHouseData(res.data))
        .catch((error) => fetchCatch(error, 'Ошибка сохранения данных'))
        .finally(() => setIsInProgress(false));
    }
  };
  const handleLoading = (isLoading: boolean) => setLoading(isLoading);

  const handeLDownLoadExcel = () =>
    downLoadExcel(DownloadHouseDataReport, houseDtoId, fetchCatch, handleLoading);

  const handleShowOnMap = () =>
    openUrlInNewTab(getUrlByXY(houseData?.longitudeX, houseData?.latitudeY));

  const puWithMacCount = useMemo(() => getAllPuWithMacApartments(houseData).length, [houseData]);
  const montagePercent =
    puWithMacCount && houseData?.flatCount
      ? Math.round((puWithMacCount / houseData?.flatCount) * 100)
      : 0;

  return (
    <HouseEntranceHeaderBoxWrapper>
      <BoxShadow sx={{ gap: 16 }}>
        <HouseEntranceHeaderWrapper>
          <HouseEntranceHeaderRow>
            <HouseEntranceHeaderInputsWrapper>
              <Tooltip
                title={address ?? ''}
                placement='top'
              >
                <HouseEntranceHeaderTextField
                  sx={{ width: 450 }}
                  disabled={disabled}
                  label='Адрес'
                  value={address}
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  contentEditable={disabled ? undefined : false}
                />
              </Tooltip>

              <HouseEntranceHeaderFlatCountMatched>
                <HouseEntranceHeaderTextField
                  disabled={disabled}
                  label='Подъездов'
                  error={!entranceCount && isChange}
                  helperText={!entranceCount && !disabled && NOT_N_NUMBER}
                  onChange={(e) => {
                    setIsChange(true);
                    setEntranceCount(replaceInput(e.target.value));
                  }}
                  value={entranceCount}
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                />

                <HouseEntranceHeaderTextField
                  disabled={disabled}
                  label='Этажей'
                  error={!floorCount && isChange}
                  helperText={!floorCount && !disabled && NOT_N_NUMBER}
                  onChange={(e) => {
                    setIsChange(true);
                    setFloorCount(replaceInput(e.target.value));
                  }}
                  value={floorCount}
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                />

                <HouseEntranceHeaderTextField
                  disabled={disabled}
                  label='Квартир'
                  error={!flatCount && isChange}
                  helperText={!flatCount && !disabled && NOT_N_NUMBER}
                  onChange={(e) => {
                    setIsChange(true);
                    setFlatCount(replaceInput(e.target.value));
                  }}
                  value={flatCount}
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                />
              </HouseEntranceHeaderFlatCountMatched>

              <HouseEntranceHeaderTextField
                disabled={disabled}
                label='ИПУ'
                value={puWithMacCount}
                variant='standard'
                InputLabelProps={{ shrink: true }}
                contentEditable={disabled ? undefined : false}
              />

              <HouseEntranceHeaderTextField
                disabled={disabled}
                label='Процент монтажа ИПУ'
                value={montagePercent}
                variant='standard'
                InputLabelProps={{ shrink: true }}
                contentEditable={disabled ? undefined : false}
              />

              <HouseEntranceHeaderTextField
                disabled={disabled}
                label='ОДПУ'
                value={odpuReplacementCount}
                variant='standard'
                InputLabelProps={{ shrink: true }}
                contentEditable={disabled ? undefined : false}
              />

              <HouseEntranceAutocompleteValue
                disabled={disabled}
                title='Технология связи'
                sx={{ width: 120 }}
                defaultValue={puConnectionType ? puConnectionType : houseData?.puConnectType}
                values={puConnectTypeOptions}
                optionOnChangeValue={(e) => {
                  setIsChange(true);
                  setPuConnectionType(e);
                }}
              />
            </HouseEntranceHeaderInputsWrapper>

            <HouseEntranceHeaderEditWrapper>
              {disabled ? (
                <>
                  <HouseEntrancePencilButton
                    key={1}
                    onClick={() => setDisabled(false)}
                    size='small'
                  >
                    <BorderColorRoundedIcon
                      color='primary'
                      fontSize='inherit'
                    />
                  </HouseEntrancePencilButton>

                  <IdPopup title={<HomeRoundedIcon color='primary' />}>
                    <div>
                      {houseData?.odpuManagementCompanyName ?? 'Управляющая компания не указана'}
                    </div>
                  </IdPopup>

                  {houseData?.updatedBy !== null && houseData?.updatedBy !== 'system' && (
                    <IdPopup title={<InfoOutlinedIcon color='primary' />}>
                      <div>{`Информация была изменена пользователем: `}</div>
                      <div>{houseData?.updatedBy}</div>
                      <div>
                        {houseData?.updateTs
                          ? moment(houseData?.updateTs).format('DD.MM.yyyy')
                          : ''}
                      </div>
                    </IdPopup>
                  )}
                </>
              ) : (
                <>
                  <IconButton
                    key={2}
                    onClick={() => {
                      setDisabled(true);
                      clearHandler();
                    }}
                    size='small'
                  >
                    <CloseRoundedIcon
                      color='primary'
                      fontSize='inherit'
                    />
                  </IconButton>
                  <IconButton
                    aria-label=''
                    key={3}
                    disabled={!entranceCount || !floorCount || !flatCount || !isChange}
                    onClick={handleSave}
                    size='small'
                  >
                    <SaveRoundedIcon fontSize='inherit' />
                  </IconButton>
                </>
              )}
            </HouseEntranceHeaderEditWrapper>
          </HouseEntranceHeaderRow>

          <HouseEntranceHeaderRow sx={{ flexWrap: 'wrap' }}>
            <HouseEntranceHeaderButtonsWrapper>
              <HouseEntranceDocumentButton
                variant='text'
                sx={{ fontSize: '14px' }}
                onClick={handleCopy}
                startIcon={<FileCopyOutlinedIcon color='primary' />}
              >
                ФИАС
              </HouseEntranceDocumentButton>

              <HouseEntranceDownloadButton
                loading={loading}
                onClick={handeLDownLoadExcel}
                startIcon={<FileDownloadOutlinedIcon color='primary' />}
              >
                Выгрузить отчет
              </HouseEntranceDownloadButton>

              <HouseEntranceMapButton
                onClick={handleShowOnMap}
                startIcon={<PinDropOutlinedIcon color='primary' />}
              >
                Показать на карте
              </HouseEntranceMapButton>

              <PnrPlanningButtons refetchHousePlannings={refetchHousePlannings} />
            </HouseEntranceHeaderButtonsWrapper>
            <HouseEntranceHeaderButtonsWrapper>
              <HouseEntranceSettingsButton
                onClick={() => setOpenEntranceEnumeration(true)}
                startIcon={
                  <SettingsOutlinedIcon color={isIncorrectHouseShema ? undefined : 'primary'} />
                }
                disabled={isIncorrectHouseShema}
              >
                Нумерация подъездов
              </HouseEntranceSettingsButton>

              <HouseEntranceCancelButton
                onClick={() => setOpenEntranceResetModal(true)}
                startIcon={
                  <CancelOutlinedIcon color={isIncorrectHouseShema ? undefined : 'primary'} />
                }
                disabled={isIncorrectHouseShema}
              >
                Сбросить распределение
              </HouseEntranceCancelButton>
            </HouseEntranceHeaderButtonsWrapper>
          </HouseEntranceHeaderRow>
        </HouseEntranceHeaderWrapper>

        <EntranceResetAlertModal
          open={openEntranceResetModal}
          setOpen={setOpenEntranceResetModal}
        />
        <EntranceEnumerationModal
          open={openEntranceEnumeration}
          setOpen={setOpenEntranceEnumeration}
        />
      </BoxShadow>
      <PnrPlanningsCount />
    </HouseEntranceHeaderBoxWrapper>
  );
};
