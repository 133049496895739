import React, { FC } from 'react';
import { IconButton } from '@mui/material';
import fileFolder from '@/images/file/fileFolder.png';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

import {
  BreadcrumbsImg,
  BreadcrumbsItemWrapper,
  BreadcrumbsWrapper,
  RightArrowIcon,
} from '@/components/features/drive/breadcrumbs/Breadcrumbs.styled';
import { useNavigate } from 'react-router-dom';

interface IBreadcrumbs {
  children?: React.ReactNode;
}

const Breadcrumbs: FC<IBreadcrumbs> = ({ children }) => {
  const navigate = useNavigate();
  return (
    <BreadcrumbsWrapper>
      <IconButton onClick={() => navigate(`/registry/drive`)}>
        <HomeRoundedIcon />
      </IconButton>
      {React.Children.toArray(children).map((elem, index) => (
        <React.Fragment key={index}>
          <RightArrowIcon />
          <BreadcrumbsItemWrapper>
            <BreadcrumbsImg src={fileFolder} />
            {elem}
          </BreadcrumbsItemWrapper>
        </React.Fragment>
      ))}
    </BreadcrumbsWrapper>
  );
};

export default Breadcrumbs;
