import { HermesListItem, HermesLog, HermesTerminalAnswerItem } from '@/dto/HermesDto';
import { HouseDataDto, MKDNetworkElementHouseDto } from '@/dto/taskmap/Dto';
import { ActionLogType, syncErrorCatch, useActionLog } from '@/hooks/ActionLogHook';
import { useAllHermes } from '@/hooks/useQuery/useAllHermes';
import { CellWithTooltip } from '@/components/features/tasksBrowse/constants';
import {
  ApiDeletePnrHermesLog,
  ApiGetPnrHermesLog,
  ApiGetPnrHermesTerminal,
} from '@/services/YodaRestService';
import { CircularProgress } from '@mui/material';
import { GridColDef, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import { HouseLevelFloorType, NOT_DEFINED_MONTAGE_PLACE } from '../blocks/PnrElementsInfo/utils';
import { GATEWAY_TYPE } from '../features/pnr/constants';
import { usePNRStore } from '../features/pnr/store';

export const HOUSE_GATEWAY_COLUMNS: GridColDef[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    hide: true,
  },
  {
    field: 'entrance',
    headerName: 'Подъезд',
    width: 100,
    renderCell: (params) => <CellWithTooltip params={params} />,
  },
  {
    field: 'montagePlace',
    headerName: 'Этаж',
    width: 100,
    renderCell: (params) => <CellWithTooltip params={params} />,
  },
  {
    field: 'smrImei',
    headerName: 'IMEI',
    width: 170,
    renderCell: (params) => <CellWithTooltip params={params} />,
  },
  {
    field: 'positiveResponses',
    headerName: 'Положительные ответы',
    width: 200,
    renderCell: (params) => <CellWithTooltip params={params} />,
  },
  {
    field: 'loading',
    headerName: 'Загрузка',
    width: 100,
    renderCell: (params) => (
      <span>{params.value === true ? <CircularProgress size={15} /> : <></>}</span>
    ),
  },
];

const getGatewayMontagePlace = (
  gateway: MKDNetworkElementHouseDto | undefined,
  floorTypes: Map<string, string>
) => {
  let montagePlace = NOT_DEFINED_MONTAGE_PLACE;
  if (gateway?.houseLevel) {
    if (gateway?.houseLevel?.floorType === HouseLevelFloorType.FLOOR) {
      montagePlace = gateway?.houseLevel?.floorNumber?.toString() ?? NOT_DEFINED_MONTAGE_PLACE;
    } else {
      montagePlace =
        floorTypes.get(gateway?.houseLevel?.floorType ?? '') ?? NOT_DEFINED_MONTAGE_PLACE;
    }
  }

  return {
    ...gateway,
    entrance: gateway?.houseLevel?.entranceNumber ?? NOT_DEFINED_MONTAGE_PLACE,
    montagePlace,
  };
};

export const getAllGateways = (
  houseData?: HouseDataDto,
  floorTypes: Map<string, string> = new Map()
) => {
  const allNetworkElements = houseData?.osTasks
    ?.map((task) =>
      task.networkElements?.map((el) => ({ ...el, associationСode: task.associationСode }))
    )
    ?.flat()
    ?.filter(
      (networkElement) =>
        networkElement?.type?.toLowerCase() === GATEWAY_TYPE && networkElement?.smrImei
    )
    ?.map((el) => getGatewayMontagePlace(el, floorTypes));

  return allNetworkElements ?? [];
};

const PES_HERMES_NAME = 'Гермес ПЭС';
const MES_HERMES_NAME = 'Гермес МЭС';

const PES_ASSOCIATION_CODE = 16;

export const getHermesConfig = (
  gateway: HermesGateway,
  hermesList: (HermesListItem | undefined)[]
) => {
  if (!gateway.smrImei) return;
  const getewayHermesName =
    gateway?.associationСode === PES_ASSOCIATION_CODE ? PES_HERMES_NAME : MES_HERMES_NAME;

  const neededHermesItem = hermesList.find(
    (hermes) => hermes && hermes?.hermesName === getewayHermesName
  );
  if (!neededHermesItem) return;

  return { hermesId: neededHermesItem.id, terminalNumber: gateway.smrImei };
};

export interface HermesGateway extends MKDNetworkElementHouseDto {
  associationСode?: number | null;
  positiveResponses?: string;
  loading?: boolean;
}

interface TerminalStatus {
  pending: boolean;
  result?: HermesLog;
  positiveAnswers?: string;
}
export type TerminalMap = Map<string, TerminalStatus>;

export const useHermesLog = () => {
  const {
    addHermesPositiveMac,
    setTerminalMap,
    hermesGateways,
    terminalMap,
    setHermesGateways,
    setHermesBackendCalled,
    removeHermesPositiveMac,
  } = usePNRStore((state) => ({
    addHermesPositiveMac: state.addHermesPositiveMac,
    setTerminalMap: state.setTerminalMap,
    hermesGateways: state.hermesGateways,
    terminalMap: state.terminalMap,
    setHermesGateways: state.setHermesGateways,
    setHermesBackendCalled: state.setHermesBackendCalled,
    removeHermesPositiveMac: state.removeHermesPositiveMac,
  }));
  const { fetchCatch, addActionLog } = useActionLog();
  const { hermesList } = useAllHermes();

  const getHermesLog = (terminalNumber?: string, houseDataId?: string) => {
    if (terminalNumber && houseDataId) {
      setTerminalMap((prev) => new Map(prev.set(terminalNumber, { pending: true })));

      ApiGetPnrHermesLog(terminalNumber, houseDataId)
        .then(({ data }) => {
          if (terminalNumber) {
            try {
              const positiveAnswerArray: HermesTerminalAnswerItem[] | null = JSON.parse(
                data.resultJson
              )?.resultJsonArray;
              if (positiveAnswerArray) {
                positiveAnswerArray.forEach((positiveAnswer) =>
                  addHermesPositiveMac(positiveAnswer?.mac)
                );
              }
              setTerminalMap(
                (prev) =>
                  new Map(
                    prev.set(terminalNumber, {
                      result: data,
                      pending: false,
                      positiveAnswers: (positiveAnswerArray?.length || '0').toString(),
                    })
                  )
              );
            } catch (error) {
              fetchCatch(error, 'Ошибка в полученных данных');
            }
          }
        })
        .finally(() => {
          setHermesBackendCalled(true);
          if (terminalNumber) {
            setTerminalMap(
              (prev) =>
                new Map(
                  prev.set(terminalNumber, {
                    ...prev.get(terminalNumber),
                    pending: false,
                  })
                )
            );
          }
        });
    }
  };

  const handleHermesCall = (houseData: HouseDataDto | undefined, checkedRows: string[]) => {
    if (!checkedRows.length) syncErrorCatch('Выберите шлюзы для опроса');
    checkedRows.forEach((checkedGatewayImei) => {
      const neededGateway = hermesGateways.find(
        (hermesGateway) => hermesGateway.smrImei === checkedGatewayImei
      );
      if (neededGateway && hermesList && houseData?.houseId) {
        const hermesConfig = getHermesConfig(neededGateway, hermesList);
        if (hermesConfig) {
          setTerminalMap(
            (prev) =>
              new Map(
                terminalMap.set(hermesConfig.terminalNumber, {
                  ...terminalMap.get(hermesConfig.terminalNumber),
                  pending: true,
                })
              )
          );
          ApiGetPnrHermesTerminal(
            hermesConfig.hermesId,
            hermesConfig.terminalNumber,
            houseData?.houseId
          ).finally(() => getHermesLog(hermesConfig.terminalNumber, houseData.houseId));
        }
      }
    });
  };

  const handleClearLog = (houseData: HouseDataDto | undefined, checkedRows: string[]) => {
    checkedRows.forEach((checkedGatewayImei) => {
      if (!houseData || !houseData?.houseId) return;
      const neededTerminal = terminalMap.get(checkedGatewayImei);
      const logId = neededTerminal?.result?.id;

      if (logId) {
        ApiDeletePnrHermesLog(logId)
          .then(() => {
            const gateways = [...hermesGateways];
            const gatewayIndex = gateways.findIndex((obj) => obj.smrImei == checkedGatewayImei);
            gateways[gatewayIndex].positiveResponses = ' ';
            setHermesGateways(gateways);
            setTerminalMap((prev) => {
              const prevMap = new Map(prev);
              prevMap.delete(checkedGatewayImei);
              return new Map(prevMap);
            });

            if (neededTerminal?.result?.resultJson) {
              const macsToRemove: string[] | null = JSON.parse(
                neededTerminal.result?.resultJson
              )?.resultJsonArray?.map((terminal: HermesTerminalAnswerItem) => terminal.mac);

              if (macsToRemove) {
                macsToRemove.forEach((mac) => removeHermesPositiveMac(mac));
              }
            }

            addActionLog(
              ActionLogType.SUCCESS,
              `Данные данные по шлюзу c IMEI:${checkedGatewayImei} сброшены`
            );
          })
          .catch((err) => fetchCatch(err));
      } else {
        syncErrorCatch(`Данные по шлюзу c IMEI:${checkedGatewayImei} отсутствуют`);
      }
    });
  };

  return { getHermesLog, handleHermesCall, handleClearLog };
};
