import { useCallCenterBrowseFilter } from './store';
import { BoxShadow } from '../../ui/BoxShadow';
import { Button, Grid, Stack } from '@mui/material';
import { SingleLine } from '../../inputs/SingleLineNew';
import React, { useMemo } from 'react';
import { MultiSelect } from '../../inputs/MultiSelectNew';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { DateRange } from '../../inputs/DateRange';

import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { generateDetailedInfo } from '@/components/button/DetailedInfo/utils';
import moment from 'moment';

const FILTERS_LABELS = {
  taskNum: 'Номер заявки',
  address: 'Адрес',
  operatorIdList: 'Имя диспетчера',
  nextCallDateOn: 'Дата следующего звонка',
  statusList: 'Статус звонка',
};

import { useActionLog } from '@/hooks/ActionLogHook';

interface CallCenterBashkortostanFilterProps {
  operators: any[];
  onSubmit: () => void;
}

export const CallCenterBashkortostanFilter = (props: CallCenterBashkortostanFilterProps) => {
  const {
    onlyBashkortostan,
    address,
    taskNum,
    statusList,
    operatorIdList,
    nextCallDateOn,
    setSingleValue,
    setSingleValueArg,
    setDefaultValue,
    setDateValue,
    setDateValueArg,
    setMultiValue,
    setMultiValueArg,
  } = useCallCenterBrowseFilter((state) => ({
    onlyBashkortostan: true,
    address: state.address,
    taskNum: state.taskNum,
    statusList: state.statusList,
    operatorIdList: state.operatorIdList,
    nextCallDateOn: state.nextCallDateOn,
    setSingleValue: state.setSingleValue,
    setSingleValueArg: state.setSingleValueArg,
    setDefaultValue: state.setDefaultValue,
    setMultiValue: state.setMultiValue,
    setMultiValueArg: state.setMultiValueArg,
    setDateValue: state.setDateValue,
    setDateValueArg: state.setDateValueArg,
  }));

  const detailedInfo = [
    ...generateDetailedInfo(
      [taskNum.value, FILTERS_LABELS.taskNum],
      [address.value, FILTERS_LABELS.address],
      [operatorIdList.value, FILTERS_LABELS.operatorIdList],
      [statusList.value, FILTERS_LABELS.statusList]
    ),
    { items: nextCallDateOn.value, title: FILTERS_LABELS.nextCallDateOn },
  ];

  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const callStatus = getCatalogMapWithErr('callStatus');
  const callStatusOptions = useMemo(() => taskMapToArray(callStatus), [callStatus]);

  return (
    <BoxShadow>
      <Grid
        container
        spacing={0.5}
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-end'
        wrap='nowrap'
        marginBottom={'10px'}
        position='relative'
      >
        <Grid
          item
          xs={2}
        >
          <SingleLine
            onChange={setSingleValue('taskNum')}
            label={FILTERS_LABELS.taskNum}
            onArgChange={setSingleValueArg('taskNum')}
            {...taskNum}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <SingleLine
            onChange={setSingleValue('address')}
            label={FILTERS_LABELS.address}
            onArgChange={setSingleValueArg('address')}
            {...address}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <MultiSelect
            options={props.operators}
            label={FILTERS_LABELS.operatorIdList}
            onChange={setMultiValue('operatorIdList')}
            onArgChange={setMultiValueArg('operatorIdList')}
            {...operatorIdList}
          />
        </Grid>
        <Grid
          item
          xs={1.5}
        >
          <DateRange
            label={FILTERS_LABELS.nextCallDateOn}
            onChange={setDateValue('nextCallDateOn')}
            onArgChange={setDateValueArg('nextCallDateOn')}
            {...nextCallDateOn}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <MultiSelect
            options={callStatusOptions}
            label={FILTERS_LABELS.statusList}
            onChange={setMultiValue('statusList')}
            onArgChange={setMultiValueArg('statusList')}
            {...statusList}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <FilterButtons
            info={detailedInfo}
            onSearchClick={props.onSubmit}
            onCloseClick={setDefaultValue}
          />
        </Grid>
      </Grid>
    </BoxShadow>
  );
};
