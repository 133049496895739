import { Grid } from '@mui/material';
import { TextField } from '@/components/fixUI';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Loading } from '@/components/ui/Loading';
import {
  OdpuInfoResponse,
  OdpuManagementCompanyResponse,
  TaskResponse,
} from '@/dto/taskmap/task/TasksDto';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { useCatalog } from '@/hooks/CatalogHook';
import { ApiGetOdpuManagementCompanyById } from '@/services/YodaRestService';
import { useActionLog } from '@/hooks/ActionLogHook';
import { useStatus } from './store';
import { OdpuNextStageButton } from '../item/OdpuNextStageButton';

interface OdpuInfoPanelProps {
  taskResponse: TaskResponse;
}

export const OdpuInfoPanel = (props: OdpuInfoPanelProps) => {
  const { taskResponse } = props;
  const [loading, setLoading] = useState(true);
  const [odpuInfoResponse, setOdpuInfoResponse] = useState<OdpuInfoResponse>();
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const [odpuManagementCompanyState, setOdpuManagementCompanyState] =
    useState<OdpuManagementCompanyResponse>();
  const [managementCompanyId, setManagementCompanyId] = useState<string>('');
  const { fetchCatch } = useActionLog();
  const { setValue, formState, watch, register } = useFormContext();

  useEffect(() => {
    const odpuInfo: OdpuInfoResponse = taskResponse?.taskInfo as OdpuInfoResponse;
    setOdpuInfoResponse(odpuInfo);
    if (!odpuManagementCompanyState && odpuInfo.odpuManagementCompanyResponse) {
      setOdpuManagementCompanyState(odpuInfo.odpuManagementCompanyResponse);
    }
    setLoading(false);
  }, [taskResponse]);

  const updateManagementCompanyId = (id: string) => {
    if (id) {
      formState.dirtyFields;
      setManagementCompanyId(id);
    }
  };

  useEffect(() => {
    if (managementCompanyId && managementCompanyId != '') {
      ApiGetOdpuManagementCompanyById(managementCompanyId)
        .then((res) => {
          setOdpuManagementCompanyState(res.data);
        })
        .catch((error) => fetchCatch(error, 'Ошибка загрузки ук'));
    }
  }, [managementCompanyId]);

  useEffect(() => {
    setValue(
      'odpuInfoRequest.odpuManagementCompanyRequest.title',
      odpuManagementCompanyState?.title,
      { shouldDirty: true }
    );
    setValue(
      'odpuInfoRequest.odpuManagementCompanyRequest.legalAddress',
      odpuManagementCompanyState?.legalAddress,
      { shouldDirty: true }
    );
    setValue(
      'odpuInfoRequest.odpuManagementCompanyRequest.director',
      odpuManagementCompanyState?.director,
      { shouldDirty: true }
    );
    setValue(
      'odpuInfoRequest.odpuManagementCompanyRequest.phoneNumber',
      odpuManagementCompanyState?.phoneNumber,
      { shouldDirty: true }
    );
    setValue(
      'odpuInfoRequest.odpuManagementCompanyRequest.agentNetworkCompany',
      odpuManagementCompanyState?.agentNetworkCompany,
      { shouldDirty: true }
    );
    setValue(
      'odpuInfoRequest.odpuManagementCompanyRequest.agentEnergyServiceCompany',
      odpuManagementCompanyState?.agentEnergyServiceCompany,
      { shouldDirty: true }
    );
    setValue(
      'odpuInfoRequest.odpuManagementCompanyRequest.agentOwner',
      odpuManagementCompanyState?.agentOwner,
      { shouldDirty: true }
    );
    setValue(
      'odpuInfoRequest.odpuManagementCompanyRequest.agentOwnerPu',
      odpuManagementCompanyState?.agentOwnerPu,
      { shouldDirty: true }
    );
    setValue(
      'odpuInfoRequest.odpuManagementCompanyRequest.agentExecutor',
      odpuManagementCompanyState?.agentExecutor,
      { shouldDirty: true }
    );
  }, [odpuManagementCompanyState]);

  const { isDisabledAll } = useStatus((state) => ({
    isDisabledAll: state.isDisabledAll,
  }));

  return (
    <form>
      <Loading loading={loading}>
        {odpuInfoResponse && (
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <OdpuNextStageButton taskId={taskResponse.id} />
              <AutocompleteValue
                title='Этап'
                fieldName={`odpuInfoRequest.odpuStage`}
                values={getCatalogMapWithErr('odpuStage')}
                defaultValue={odpuInfoResponse?.odpuStage}
                forcedValueFromForm={watch('odpuInfoRequest.odpuStage')}
                disabled
              />
              <AutocompleteValue
                title='Территориальное отделение'
                fieldName={'odpuInfoRequest.localBranch'}
                values={getCatalogMapWithErr('territorialBranch')}
                defaultValue={odpuInfoResponse?.localBranch}
                disabled={isDisabledAll()}
              />
              <AutocompleteValue
                title='Управляющая компания'
                fieldName={'odpuInfoRequest.odpuManagementCompanyRequest.id'}
                values={getCatalogMapWithErr('odpuManagementCompany')}
                defaultValue={odpuInfoResponse?.odpuManagementCompanyResponse?.id.toString()}
                optionOnChangeValue={updateManagementCompanyId}
                disabled={isDisabledAll()}
              />
              <TextField
                sx={{ width: '100%' }}
                id='tf-local-branch-legalAddress'
                label='Юридический адрес управляющей компании'
                disabled
                variant='standard'
                {...register('odpuManagementCompanyState.legalAddress', {
                  value: odpuManagementCompanyState?.legalAddress
                    ? odpuManagementCompanyState?.legalAddress
                    : '',
                })}
              />
              <TextField
                sx={{ width: '100%' }}
                id='tf-local-branch-director'
                label='Руководитель управляющей компании'
                disabled
                variant='standard'
                {...register('odpuManagementCompanyState.director', {
                  value: odpuManagementCompanyState?.director
                    ? odpuManagementCompanyState?.director
                    : '',
                })}
              />
              <TextField
                sx={{ width: '100%' }}
                id='tf-local-branch-phoneNumber'
                label='Телефон управляющей компании'
                disabled
                variant='standard'
                {...register('odpuManagementCompanyState.phoneNumber', {
                  value: odpuManagementCompanyState?.phoneNumber
                    ? odpuManagementCompanyState?.phoneNumber
                    : '',
                })}
              />
              <TextField
                sx={{ width: '100%' }}
                id='tf-agent-network-company'
                label='Уп.пред. сетевой компании'
                disabled
                variant='standard'
                {...register('odpuManagementCompanyState.agentNetworkCompany', {
                  value: odpuManagementCompanyState?.agentNetworkCompany
                    ? odpuManagementCompanyState?.agentNetworkCompany
                    : '',
                })}
              />
              <TextField
                sx={{ width: '100%' }}
                id='tf-agent-energy-service-company'
                label='Уп.пред. сбытовой организации'
                disabled
                variant='standard'
                {...register('odpuManagementCompanyState.agentEnergyServiceCompany', {
                  value: odpuManagementCompanyState?.agentEnergyServiceCompany
                    ? odpuManagementCompanyState?.agentEnergyServiceCompany
                    : '',
                })}
              />
              <TextField
                sx={{ width: '100%' }}
                id='tf-agent-owner'
                label='Уп.пред. собственника энергопринимающих устройств'
                variant='standard'
                disabled
                {...register('odpuManagementCompanyState.agentOwner', {
                  value: odpuManagementCompanyState?.agentOwner
                    ? odpuManagementCompanyState?.agentOwner
                    : '',
                })}
              />

              <TextField
                sx={{ width: '100%' }}
                id='tf-agent-owner-pu'
                label='Уп.пред. собственника ПУ'
                variant='standard'
                disabled
                {...register('odpuManagementCompanyState.agentOwnerPu', {
                  value: odpuManagementCompanyState?.agentOwnerPu
                    ? odpuManagementCompanyState?.agentOwnerPu
                    : '',
                })}
              />
              <TextField
                sx={{ width: '100%' }}
                id='tf-agent-executor'
                label='Уп.пред. исполнителя комунальных услуг'
                variant='standard'
                disabled
                {...register('odpuManagementCompanyState.agentExecutor', {
                  value: odpuManagementCompanyState?.agentExecutor
                    ? odpuManagementCompanyState?.agentExecutor
                    : '',
                })}
              />
            </Grid>
            <Grid
              item
              mt={3}
              xs={12}
            >
              <TextField
                sx={{ width: '100%' }}
                id='tf-agent-accountNumber'
                label='Номер ЛС'
                variant='standard'
                disabled
                {...register('odpuInfoRequest.accountNumber', {
                  value:
                    taskResponse?.taskInfo && 'accountNumber' in taskResponse.taskInfo
                      ? taskResponse.taskInfo.accountNumber
                      : '',
                })}
              />
              <TextField
                sx={{ width: '100%' }}
                id='tf-agent-contractNumber'
                label='Номер Договора'
                variant='standard'
                disabled
                {...register('odpuInfoRequest.contractNumber', {
                  value:
                    taskResponse?.taskInfo && 'contractNumber' in taskResponse.taskInfo
                      ? taskResponse.taskInfo.contractNumber
                      : '',
                })}
              />
              <TextField
                sx={{ width: '100%' }}
                id='tf-agent-factoryNumberPu'
                label='Номер сущ. ПУ'
                variant='standard'
                disabled
                {...register('odpuInfoRequest.factoryNumberPu', {
                  value:
                    taskResponse?.taskInfo && 'factoryNumberPu' in taskResponse.taskInfo
                      ? taskResponse.taskInfo.factoryNumberPu
                      : '',
                })}
              />
              <TextField
                sx={{ width: '100%' }}
                id='tf-agent-subscriberNumber'
                label='Номер Точки Учета'
                variant='standard'
                disabled
                {...register('odpuInfoRequest.subscriberNumber', {
                  value:
                    taskResponse?.taskInfo && 'subscriberNumber' in taskResponse.taskInfo
                      ? taskResponse.taskInfo.subscriberNumber
                      : '',
                })}
              />
              <TextField
                sx={{ width: '100%' }}
                id='tf-agent-pointName'
                label='Имя Ввода'
                variant='standard'
                disabled
                {...register('odpuInfoRequest.deliveryPoint', {
                  value:
                    taskResponse?.taskInfo && 'deliveryPoint' in taskResponse.taskInfo
                      ? taskResponse.taskInfo.deliveryPoint
                      : '',
                })}
              />
            </Grid>
          </Grid>
        )}
      </Loading>
    </form>
  );
};
