import { Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { PpoTransformerResponseList, PpoVruInputResponse } from '@/dto/taskmap/task/TaskResultDto';
import DoneIcon from '@mui/icons-material/Done';
import { ExpandButton } from '@/components/button/ExpandButton/ExpandButton';
import { IdPopup } from '../panel/IdPopup';
import { PpoTTItem } from './PpoTTItem';
import { PpoPUItem } from './PpoPUItem';
import { ModeViewerResult } from '@/components/features/taskEditor/types';
import CopyButton from '@/components/button/CopyButton/CopyButton';
import { PpoPlanItem } from './PpoPlanItem';

interface GeneralReplacementProps {
  replacementResponse?: PpoTransformerResponseList;
  mode: ModeViewerResult;
  preview: React.ReactNode;
  vruInput: PpoVruInputResponse;
  prevString: string;
}

export const PpoGeneralReplacement = (props: GeneralReplacementProps) => {
  const { preview, vruInput, prevString } = props;
  const [replacementResponse, setReplacementResponse] = useState<PpoTransformerResponseList>();
  const [mode, setMode] = useState<ModeViewerResult>(ModeViewerResult.LARGE);

  useEffect(() => {
    setMode(props.mode);
    setReplacementResponse(props.replacementResponse);
  }, [props]);

  const handleMode = (forMode: ModeViewerResult) => (isExpanded: boolean) => {
    if (forMode === ModeViewerResult.SMALL) {
      setMode(isExpanded ? ModeViewerResult.MEDIUM : ModeViewerResult.SMALL);
    } else if (forMode === ModeViewerResult.LARGE) {
      setMode(isExpanded ? ModeViewerResult.LARGE : ModeViewerResult.MEDIUM);
    }
  };

  return (
    <BoxShadow>
      <Grid
        container
        spacing={1}
        p={1}
      >
        <Stack
          direction={'row'}
          width={'100%'}
        >
          {mode === ModeViewerResult.SMALL && (
            <DoneIcon
              sx={{ ml: -1 }}
              color='success'
            />
          )}
          <Stack
            width={'100%'}
            spacing={2}
          >
            {mode === ModeViewerResult.SMALL && <>{preview}</>}
            {(mode === ModeViewerResult.MEDIUM || mode === ModeViewerResult.LARGE) && (
              <>
                <Grid
                  container
                  spacing={4}
                  sx={{ alignItems: 'flex-start' }}
                >
                  <Grid
                    item
                    container
                    xs={6}
                    spacing={2}
                  >
                    <PpoPUItem
                      vruInput={vruInput}
                      prevString={prevString}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    container
                    spacing={2}
                  >
                    {vruInput?.ppoTransformerResponseList && (
                      <PpoTTItem
                        meter={vruInput.ppoTransformerResponseList}
                        prevString={prevString}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                  >
                    <PpoPlanItem
                      vruInput={vruInput}
                      prevString={prevString}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Stack>
          <Grid>
            <Grid
              container
              direction='column'
              justifyContent='space-between'
              alignItems='center'
              height={'100%'}
            >
              <ExpandButton
                expand={mode != ModeViewerResult.SMALL}
                onAction={handleMode(ModeViewerResult.SMALL)}
              />
              {mode != ModeViewerResult.SMALL && (
                <IdPopup>
                  {vruInput?.meterResponse?.id && (
                    <div>
                      <CopyButton textToCopy={vruInput.meterResponse.id} />
                      id прибора учета: {vruInput.meterResponse.id}
                    </div>
                  )}
                  {vruInput?.ppoTransformerResponseList && (
                    <>
                      {vruInput?.ppoTransformerResponseList[0]?.transformerInfo?.id && (
                        <div>
                          <CopyButton
                            textToCopy={vruInput.ppoTransformerResponseList[0].transformerInfo.id}
                          />
                          id трансформатора:{' '}
                          {vruInput.ppoTransformerResponseList[0].transformerInfo.id}
                        </div>
                      )}
                      {vruInput.ppoTransformerResponseList.map((elem) => (
                        <div key={elem.id}>
                          <CopyButton textToCopy={elem.id} />
                          id фазы {elem.phaseName}: {elem.id}
                        </div>
                      ))}
                    </>
                  )}
                </IdPopup>
              )}
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </BoxShadow>
  );
};
