import React, { FC, MouseEventHandler } from 'react';
import {
  CountDiv,
  InfoDiv,
  StackCenter,
  TitleDiv,
} from '@/components/maps/ppoMap/resCard/index.styled';

interface IResRow {
  title: string | React.ReactElement;
  titleAlign?: 'left' | 'center' | 'right';
  count: number;
  children?: string | JSX.Element;
  onCountClick?: MouseEventHandler | undefined;
}

const ResRow: FC<IResRow> = ({ title, titleAlign = 'left', count, children, onCountClick }) => {
  return (
    <StackCenter
      direction='row'
      spacing={2}
    >
      <TitleDiv style={{ textAlign: titleAlign }}>{title}</TitleDiv>
      <CountDiv onClick={onCountClick}>{count}</CountDiv>
      <InfoDiv>{children && children}</InfoDiv>
    </StackCenter>
  );
};

export default ResRow;
