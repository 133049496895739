import { FileInputFiles } from '@/components/inputs/FileInput/utils';
import { SupplyBillBrowseDto } from '@/dto/taskmap/Dto';
import { syncErrorCatch } from '@/hooks/ActionLogHook';
import {
  useAllResponsables,
  useAllSupplyGroups,
  useAllTransitBills,
  useAllAcceptedTransitBills,
  useAllFactories,
  useAllContractors,
} from '@/hooks/useQuery/useAllSupplyCatalogs';
import moment from 'moment';
import { useMemo } from 'react';
import { StorehouseBillType, SupplyConstant } from '../SupplyConstant';
import { LS_PU_TYPE } from '../SupplyPUComponent/utils';
import useSupplyStore from '../SupplyStore';
import { validateDataStamp } from '../utils';

export type BillRequestFormFields = 'billRequest' | 'fileList';

export interface BillRequestFormData extends FormData {
  append(name: BillRequestFormFields, value: string | Blob, fileName?: string): void;
}

export const RESET_BACKEND_ITEMS_INITIAL_STATE = {
  pus: false,
  stamps: false,
  os: false,
};

export const validateBillDate = (isDraftSave: boolean, billDate: string) => {
  if (moment(billDate).isSame(new Date(), 'year')) {
    if (isDraftSave) {
      return true;
    }

    if (moment(billDate).isSameOrBefore(new Date())) {
      return true;
    } else {
      syncErrorCatch('Невозможно сохранить накладную с датой, большей чем текущая', {
        billDate: billDate,
      });
      return;
    }
  } else {
    syncErrorCatch('Укажите текущий год в поле "Дата выдачи"', {
      currentYear: moment(new Date()).year(),
      billDate: billDate,
    });
    return;
  }
};

export interface BillSaveOptions {
  isDraft: boolean;
  saveBillType: StorehouseBillType | null;
  successMessage?: string;
}

interface PrepareDataOptions extends BillSaveOptions {
  billType: number;
  supplyBillDto: SupplyBillBrowseDto | undefined;
  tabType: LS_PU_TYPE;
  files: FileInputFiles;
}

export interface BillSaveDataOptions {
  data: SupplyBillBrowseDto;
  formData: BillRequestFormData;
  successMessage?: string;
}

export const checkAndPrepareData = ({
  saveBillType,
  isDraft,
  billType,
  supplyBillDto,
  tabType,
  files,
}: PrepareDataOptions) => {
  const data = {} as SupplyBillBrowseDto;
  const allStore = useSupplyStore.getState();
  data.billNumber = allStore.billNumber;
  data.m15FormNumber = allStore.m15FormNumber;
  data.contractorId = allStore.contractorId;
  data.billDate = allStore.billDate;
  data.stampList = allStore.stampList?.filter((el) => el.from && el.to);
  data.puList = allStore.puList?.filter((el) => el.meterTypeId && el.puItems.length > 0);
  data.networkElementList = allStore.osList.filter(
    (el: { typeId: any; networkElementItems: string | any[] }) =>
      el.typeId && el.networkElementItems.length > 0
  );
  data.supplyId = allStore.supplyId;
  data.projectId = allStore.projectId;

  if (supplyBillDto) {
    data.id = supplyBillDto.id;
  }
  if (billType === SupplyConstant.RELOCATION_TYPE_GET) {
    delete data.id;
    data.relatedBillId = supplyBillDto?.id;
  }
  if (isDraft && saveBillType) {
    delete data.id;
    data.billTypeId = saveBillType;
  } else if (isDraft) {
    if (supplyBillDto?.billTypeId) {
      data.billTypeId = supplyBillDto?.billTypeId;
    } else {
      switch (tabType) {
        case 'deliver':
          data.billTypeId = SupplyConstant.DRAFT_TYPE_ISSUE;
          break;
        case 'return':
          data.billTypeId = SupplyConstant.DRAFT_TYPE_RETURN;
          break;
        case 'factory_get':
          data.billTypeId = SupplyConstant.DRAFT_FACTORY_TYPE_GET;
          break;

        case 'relocation_post':
          data.billTypeId = SupplyConstant.DRAFT_TYPE_RELOCATION_POST;
          break;

        case 'factory_post':
          data.billTypeId = SupplyConstant.DRAFT_TYPE_FACTORY_TYPE_POST;
          break;

        default:
          break;
      }
    }
  } else {
    data.billTypeId = saveBillType ?? billType!;
  }

  const isValid =
    data.stampList.length == 0 ||
    validateDataStamp(data.stampList, allStore.wholeCount, allStore.declaredCount);

  data.fileIdList = [];
  const formData: BillRequestFormData = new FormData();

  files.forEach((file) => {
    if ('id' in file) {
      data.fileIdList?.push(file.id);
    } else {
      formData.append(`fileList`, file);
    }
  });

  formData.append(
    'billRequest',
    new Blob([JSON.stringify(data)], {
      type: 'application/json',
    })
  );

  return { data, formData, isValid };
};

export const useResponsableOptions = (
  billType: number,
  tabType: LS_PU_TYPE,
  supplyGroupsBills: boolean,
  relocationGetBill: boolean,
  factoryPostBill: boolean
) => {
  const { responsablesOptions } = useAllResponsables();
  const { supplyGroupsOptions } = useAllSupplyGroups(supplyGroupsBills);
  const { transitBillsOptions } = useAllTransitBills(relocationGetBill);
  const { acceptedTransitBills } = useAllAcceptedTransitBills();
  const { factoriesOptions } = useAllFactories(factoryPostBill);
  const { contractorsOptions } = useAllContractors();

  const responsableOptions = useMemo(() => {
    if (
      billType === SupplyConstant.DRAFT_TYPE_RELOCATION_POST ||
      billType === SupplyConstant.RELOCATION_TYPE_POST
    ) {
      return supplyGroupsOptions;
    }

    if (billType === SupplyConstant.RELOCATION_TYPE_GET) {
      if (tabType === 'archive') {
        return acceptedTransitBills;
      } else {
        return transitBillsOptions;
      }
    }

    if (
      billType === SupplyConstant.FACTORY_TYPE_POST ||
      billType === SupplyConstant.DRAFT_TYPE_FACTORY_TYPE_POST
    ) {
      return factoriesOptions;
    }

    if (
      billType === SupplyConstant.ISSUED_TYPE ||
      billType === SupplyConstant.RETURNED_TYPE ||
      billType === SupplyConstant.DRAFT_TYPE_ISSUE ||
      billType === SupplyConstant.DRAFT_TYPE_RETURN ||
      billType === SupplyConstant.RETURNED_TYPE_WAITING_APPROVAL_PROJECT ||
      billType === SupplyConstant.RETURNED_TYPE_AGREED_PROJECT
    ) {
      return contractorsOptions;
    }

    return responsablesOptions;
  }, [
    responsablesOptions,
    supplyGroupsOptions,
    transitBillsOptions,
    contractorsOptions,
    acceptedTransitBills,
    tabType,
  ]);

  const responsableLabel = useMemo(() => {
    if (relocationGetBill) {
      return 'Входящая накладная';
    }

    if (
      billType === SupplyConstant.RETURNED_TYPE ||
      billType === SupplyConstant.DRAFT_TYPE_RETURN
    ) {
      return 'От кого';
    }

    return 'Кому';
  }, [relocationGetBill]);

  return { responsableOptions, responsableLabel };
};

const MOSCOW_SUPPLY_ID = '57b93477-d3d5-4d1e-8d5c-14a7a1c9a43e';
export const DEFAULT_SUPPLY_ID = MOSCOW_SUPPLY_ID;

export const SUCCESS_MESSAGES = {
  addProject: 'Проект успешно добавлен в накладную',
};
