import React, { useEffect } from 'react';
import { Autocomplete, TextField, Grid, Paper } from '@mui/material';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DateRange } from '@/components/inputs/DateRange';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { Line, Doughnut } from 'react-chartjs-2';
import moment from 'moment';
import { StatisticCallingMapDto } from '@/dto/taskmap/Dto';
import {
  GetCurrentGroup,
  GetStatisticCallingMapExecutors,
  GetStatisticCallingMapOperators,
  GetStatisticCallingMapResponsible,
} from '@/services/StatisticsService';
import { AccessEnum } from '@/app/auth/roles';
import { AccessController } from '@/components/common/AccessController';
import { useActionLog } from '@/hooks/ActionLogHook';
import {
  callStatus,
  chartOptionsPie,
  defaultKeys,
  getColorByStatus,
  lookUp,
  options,
  processData,
  textCenterPlugin,
} from './utils';
import { useAccess } from '@/hooks/useAccess';
import {
  CallingMapStatiscControleWrapper,
  CallingMapStatiscDashBordWrapper,
  CallingMapStatiscDouhnutWrapper,
  CallingMapStatiscLineWrapper,
} from './CallingMapStatistics.styled';
import { sortByAlphabetlabels } from '@/utils/heplers';

interface Filter {
  attribute: string;
  value: string | string[];
  filterArg?: number;
}

type GroupedData = Record<string, Record<string, number>>;

interface Option {
  value: string;
  label: string;
}

interface Props {
  header: string;
  load: boolean;
  data: StatisticCallingMapDto[];
  updateFunction: (filter: Filter[]) => void;
}

export const CallingMapStatistics: React.FC<Props> = ({ header, load, data, updateFunction }) => {
  const [dataInRange, setDateInRange] = React.useState<[Date, Date]>([
    new Date(Date.now() - 86400000 * 7),
    new Date(),
  ]);

  const { fetchCatch } = useActionLog();
  const TeamManagerAndVoorman = useAccess({
    access: [AccessEnum.TeamManagerAndVoorman],
    stopRedirect: true,
  });
  const notAdmin = useAccess({
    access: [AccessEnum.Statistic],
    stopRedirect: true,
  });

  const [filterData, setFilterData] = React.useState<StatisticCallingMapDto[]>([]);
  const [contract, setContract] = React.useState<string>('');
  const [executor, setExecutor] = React.useState<string>('');
  const [operator, setOperator] = React.useState<string>('');
  const [total, setTotal] = React.useState<number>(0);
  const [filter, setFilter] = React.useState<Filter[]>([]);
  const [contractorOptions, setContractorOptions] = React.useState<Option[]>([]);
  const [executorsOptions, setExecutorsOptions] = React.useState<Option[]>([]);
  const [operatorOptions, setOperatorOptions] = React.useState<Option[]>([]);
  const [dateArray, setdDateArray] = React.useState<string[]>([]);

  const isInRange = dataInRange.length !== 2;

  const onSubmitSearch = () => {
    updateFunction(filter);
    const startDate = moment(dataInRange[0]);
    const endDate = moment(dataInRange[1]);
    const arr: string[] = [];
    const currentDate = startDate;
    while (currentDate.isSameOrBefore(endDate)) {
      arr.push(currentDate.format('YYYY-MM-DD'));
      currentDate.add(1, 'days');
    }
    setdDateArray(arr);
  };
  const onClear = () => {
    setContract('');
    setExecutor('');
    setOperator('');
    setExecutorsOptions([]);
    setOperatorOptions([]);
    setFilterData([]);
  };

  useEffect(() => {
    const filters: Filter[] = [
      {
        attribute: 'period',
        value: [
          moment(dataInRange[0]).format('yyyy-MM-DD'),
          moment(dataInRange[1]).format('yyyy-MM-DD'),
        ],
      },
    ];
    if (contract) {
      filters.push({
        attribute: 'executorId',
        value: contract,
      });
    }
    if (executor) {
      filters.push({
        attribute: 'contractorId',
        value: executor,
        filterArg: 2,
      });
    }
    if (operator) {
      filters.push({
        attribute: 'operatorId',
        value: operator,
        filterArg: 2,
      });
    }

    setFilter(filters);
  }, [dataInRange, contract, executor, operator]);

  const groupedData: GroupedData = filterData.reduce(
    (acc: GroupedData, entry: StatisticCallingMapDto) => {
      const dayOfWeek: string = moment(entry.date).format('YYYY-MM-DD');
      if (!acc[dayOfWeek]) {
        acc[dayOfWeek] = {};
      }
      // Добавляем дефолтные ключи
      defaultKeys.forEach((key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!acc[dayOfWeek].hasOwnProperty(key)) {
          acc[dayOfWeek][key] = 0;
        }
      });

      const statusKey = entry.status ?? 'null';
      if (!acc[dayOfWeek][statusKey]) {
        acc[dayOfWeek][statusKey] = 0;
      }
      acc[dayOfWeek][statusKey] += entry.count;
      return acc;
    },
    {}
  );

  const allStatuses: string[] = Object.values(groupedData).reduce(
    (statuses: string[], dayData: Record<string, number>) => {
      Object.keys(dayData).forEach((status: string | null) => {
        if (status && !statuses.includes(status) && dayData[status] >= 0) {
          statuses.push(status);
        }
      });
      return statuses;
    },
    []
  );

  const formattedDates = [...new Set(dateArray.map((item) => item))];
  // cоздание уникального набора данных для каждого статуса
  const datasets = allStatuses.map((status: string) => {
    return {
      label: lookUp[status] || 'НЕИЗВЕСТНО',
      data: formattedDates.map(
        (weekday) => (groupedData[weekday] && groupedData[weekday][status]) || 0
      ),
      borderColor: getColorByStatus(status),
      fill: false,
    };
  });

  const chartData = {
    labels: formattedDates,
    datasets: datasets,
  };

  useEffect(() => {
    const totalCount = data.reduce((acc, cur) => (acc += cur?.count), 0);
    setTotal(totalCount);
  }, [data]);

  useEffect(() => {
    setFilterData(data.filter((task) => task?.status && callStatus[task.status]));
  }, [data]);

  const chartDataDoughnut: any = processData(filterData, total);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //  список ответственных подрядчиков отсортрован по алфавиту
        const { data: responsibleData } = await GetStatisticCallingMapResponsible();
        setContractorOptions(
          sortByAlphabetlabels(
            responsibleData.map((el) => ({ value: el.id, label: el.title })),
            'label'
          )
        );
        //  получаем список исполнителей отсортрован по алфавиту
        if (contract) {
          const { data: executorData } = await GetStatisticCallingMapExecutors(contract);
          setExecutorsOptions(
            sortByAlphabetlabels(
              executorData.map((el) => ({ value: el.id, label: el.title })),
              'label'
            )
          );
        }
        //  получаем список операторов отсортрован по алфавиту
        if (contract) {
          const { data: operatorData } = await GetStatisticCallingMapOperators(contract);
          setOperatorOptions(
            sortByAlphabetlabels(
              operatorData.map((el) => ({ value: el.id, label: el.fullName })),
              'label'
            )
          );
        }
      } catch (error) {
        fetchCatch(error);
      }
    };
    fetchData();
    if (TeamManagerAndVoorman && !notAdmin) {
      GetCurrentGroup().then(({ data }) => {
        setContract(data.id);
      });
    }
  }, [contract, executor, operator]);

  return (
    <>
      <Paper
        elevation={6}
        className={'footPaper'}
      >
        <p className={'headerPageStatistics'}>{header}</p>

        <Grid style={{ marginTop: 15 }}>
          <CallingMapStatiscControleWrapper>
            <Grid
              container
              spacing={2}
            >
              <Grid item>
                <DateRange
                  style={{ marginTop: 6 }}
                  label={'выбрать диапазон'}
                  isInRange={isInRange}
                  onChange={setDateInRange}
                  value={dataInRange}
                  argValue={8}
                  showSelect={false}
                />
              </Grid>

              <AccessController access={[AccessEnum.Statistic]}>
                <Grid item>
                  <Autocomplete
                    style={{ width: '250px' }}
                    options={contractorOptions}
                    value={{
                      label: contractorOptions.find((el) => el.value === contract)?.label ?? '',
                      value: contractorOptions.find((el) => el.value === contract)?.value ?? '',
                    }}
                    onChange={(event: any, newValue: any) => {
                      setContract(newValue?.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        name='contractorId'
                        {...params}
                        label={'Ответственный подрядчик'}
                        variant='standard'
                      />
                    )}
                  />
                </Grid>
              </AccessController>

              <AccessController access={[AccessEnum.Statistic, AccessEnum.TeamManagerAndVoorman]}>
                <Grid item>
                  <Autocomplete
                    style={{ width: '250px' }}
                    options={executorsOptions}
                    value={{
                      label: executorsOptions.find((el) => el.value === executor)?.label ?? '',
                      value: executorsOptions.find((el) => el.value === executor)?.value ?? '',
                    }}
                    onChange={(event: any, newValue: any) => {
                      setExecutor(newValue?.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        name='excutorId'
                        {...params}
                        label={'Назначеная бригада'}
                        variant='standard'
                      />
                    )}
                  />
                </Grid>
              </AccessController>

              <AccessController access={[AccessEnum.Statistic, AccessEnum.TeamManagerAndVoorman]}>
                <Grid item>
                  <Autocomplete
                    style={{ width: '250px' }}
                    options={operatorOptions}
                    value={{
                      label: operatorOptions.find((el) => el.value === operator)?.label ?? '',
                      value: operatorOptions.find((el) => el.value === operator)?.value ?? '',
                    }}
                    onChange={(event: any, newValue: any) => {
                      setOperator(newValue?.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        name='excutorId'
                        {...params}
                        label={'Оператор'}
                        variant='standard'
                      />
                    )}
                  />
                </Grid>
              </AccessController>

              <Grid
                item
                alignSelf={'center'}
              >
                <FilterButtons
                  onSearchClick={onSubmitSearch}
                  onCloseClick={onClear}
                />
              </Grid>
            </Grid>
          </CallingMapStatiscControleWrapper>

          {load ? (
            <LoadSpinner />
          ) : (
            <CallingMapStatiscDashBordWrapper>
              <CallingMapStatiscLineWrapper>
                <Line
                  data={chartData}
                  options={options}
                />
              </CallingMapStatiscLineWrapper>
              <CallingMapStatiscDouhnutWrapper>
                <Doughnut
                  key={total}
                  data={chartDataDoughnut}
                  options={chartOptionsPie}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  plugins={[ChartDataLabels, textCenterPlugin(total)]}
                />
              </CallingMapStatiscDouhnutWrapper>
            </CallingMapStatiscDashBordWrapper>
          )}
        </Grid>
      </Paper>
    </>
  );
};
