import { BoxShadow } from '@/components/ui/BoxShadow';
import { mediaBreakpointDown } from '@/styles/mixins';
import { styled } from '@mui/material';

export const FileImg = styled('img')({
  width: 85,
  height: 85,
  objectFit: 'contain',
  objectPosition: 'center',
});

export const FileWrapper = styled(BoxShadow)({
  marginTop: 34,
  paddingBottom: 5,
  paddingTop: 5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const FileNameWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginLeft: 30,
  [mediaBreakpointDown('md')]: {
    marginLeft: 10,
  },
});

export const FileName = styled('div')({
  fontWeight: 'bold',
  marginLeft: 25,
  wordBreak: 'break-word',
});
