import { Alert, Backdrop, CircularProgress, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { TaskCC, TaskCCAbonent } from '@/dto/taskmap/Dto';
import { ApiFindSuggestionsAddress, ApiFindTasksByAccNum } from '@/services/YodaRestService';
import '../../styles/callcenterstyle.css';
import { useActionLog } from '@/hooks/ActionLogHook';
import { color } from '@/styles/mixins';

//todo: Надо где то хранить состояние таблицы и полей фильтра, чтобы при нажатии "Назад" из экрана редактирования ничего не очищалось
const CCTaskSearch = () => {
  const { fetchCatch } = useActionLog();
  const [data, setData] = React.useState<TaskCC[]>([]);
  const [load, setLoad] = React.useState(false);
  const [accNum, setAccNum] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [flatNumber, setFlatNumber] = React.useState('');
  const [errorText, setErrorText] = React.useState('');

  const [value, setValue] = React.useState<string | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly string[]>([]);

  const columns: GridColDef[] = [
    {
      field: 'accountNumber',
      headerName: '№ лицевого счета',
      flex: 2,
      headerAlign: 'center',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<TaskCC>) => (
        <strong>
          <Link
            to={'/callcenter/' + params.row.id}
            tabIndex={params.hasFocus ? 0 : -1}
          >
            {params.value + ''}
          </Link>
        </strong>
      ),
    },
    {
      field: 'address',
      headerName: 'Адрес',
      flex: 7,
      headerAlign: 'center',
      disableColumnMenu: true,
    },
    {
      field: 'apartment',
      headerName: 'Кв.',
      flex: 1,
      headerAlign: 'center',
      disableColumnMenu: true,
    },

    {
      field: 'abonentsList',
      headerName: 'ФИО',
      flex: 4,
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams<TaskCC>) => {
        const renderNames =
          params.row?.abonentsList
            ?.map((el: TaskCCAbonent) => el?.fio)
            .splice(',')
            .join(', ') || 'ФИО отстутсвует';
        return <p>{renderNames}</p>;
      },
    },
  ];

  useEffect(() => {
    let active = true;
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }
    const timeOutId = setTimeout(() => findSuggestionsAddress(inputValue), 600);

    return () => {
      clearTimeout(timeOutId);
      active = false;
    };
  }, [inputValue]);

  useEffect(() => {
    if (flatNumber.length > 0 && (address.length > 0 || accNum.length > 0)) {
      const timeOutId = setTimeout(() => findTasks(accNum, address, flatNumber), 1600);
      return () => {
        clearTimeout(timeOutId);
      };
    }
  }, [accNum, address, flatNumber]);

  const handleClose = () => {
    setLoad(false);
  };

  const [focusElem, setFocusElem] = useState<RefObject<HTMLInputElement>>();

  const htmlElRef1 = useRef<HTMLInputElement>(null);
  const htmlElRef2 = useRef<HTMLInputElement>(null);
  const htmlElRef3 = useRef<HTMLInputElement>(null);

  useEffect(() => {
    focusElem && focusElem.current && focusElem.current.focus();
  }, [load]);

  const findTasks = (accNum: string, address: string, flatNumber: string) => {
    setLoad(true);
    ApiFindTasksByAccNum(accNum.replaceAll('\\', '%5C'), address, flatNumber)
      .then(function (value) {
        setLoad(false);
        setData(value.data);
      })
      .catch((err) => {
        fetchCatch(err, 'Ошибка загрузки заявок');
      });
  };

  const findSuggestionsAddress = (address: string) => {
    ApiFindSuggestionsAddress(address)
      .then(function (value) {
        let newOptions: readonly string[] = [];
        if (value) {
          newOptions = value.data;
        }

        setOptions(newOptions);
      })
      .catch((err) => {
        fetchCatch(err, 'Ошибка во время поиска адресов');
      });
  };
  return (
    <div>
      <Backdrop
        sx={{ color: color('trueWhite'), zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={load}
        onClick={handleClose}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <div className='search-container'>
        <div className='search-box'>
          <TextField
            inputRef={htmlElRef1}
            disabled={address === 'emptyValue' || load}
            id='standard-basic'
            label='№ кв'
            error={flatNumber.length === 0}
            helperText={flatNumber.length === 0 ? 'Введите номер квартиры' : ''}
            variant='outlined'
            onChange={(e) => {
              setFocusElem(htmlElRef1);
              if (e.target.value.length > 0) {
                setFlatNumber(e.target.value);
              } else if (e.target.value.length == 0) {
                setFlatNumber('');
              }
            }}
          />
          <TextField
            inputRef={htmlElRef2}
            id='address-textfield'
            disabled={flatNumber.length === 0 || load}
            sx={{ width: 600, marginLeft: '14px' }}
            label='Адрес'
            value={address}
            onChange={(e) => {
              setValue(e.target.value);
              setAddress(e.target.value == null ? '' : e.target.value);
              setFocusElem(htmlElRef2);
            }}
          />
          <TextField
            inputRef={htmlElRef3}
            disabled={flatNumber.length === 0 || load}
            id='standard-basic'
            label='№ Лицевого счета'
            variant='outlined'
            style={{ marginLeft: '10px' }}
            onChange={(e) => {
              setFocusElem(htmlElRef3);
              if (e.target.value.length > 3) {
                setAccNum(e.target.value);
              } else if (e.target.value.length == 0) {
                setAccNum('');
              }
            }}
          />
        </div>
      </div>
      {errorText && errorText.length > 0 ? <Alert severity='error'>{errorText}</Alert> : <div />}

      <div style={{ maxHeight: '100%', width: '100%', marginTop: 10 }}>
        <DataGrid
          className='call-center-table'
          rows={data}
          rowHeight={45}
          columns={columns}
          pageSize={12}
          rowsPerPageOptions={[12]}
          showColumnRightBorder
          showCellRightBorder
          isRowSelectable={() => false}
          disableColumnFilter={true}
          autoHeight={true}
          sortingOrder={['asc', 'desc']}
        />
      </div>
    </div>
  );
};
export default CCTaskSearch;
