import { Popup } from './Popup';
import { Button, Grid, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { SelectFilterOptType, SingleSelectFilter } from '../filter/MultySelectFilter';
import { useDropzone } from 'react-dropzone';
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  ruRU,
} from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import SaveIcon from '@mui/icons-material/Save';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { bindVectorDiagram } from '@/services/FileService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { BindResult } from '@/dto/taskmap/Dto';
import './LoadingVectorDiagramPopupStyle.css';
import { useCatalog } from '@/hooks/CatalogHook';

interface LoadingVectorDiagramsProps {
  onSuccess: () => void;
  isOpen: boolean;
  onClose: () => void;
  children?: any;
}

class FileItem {
  id?: string;
  path?: string;
  loadAt?: Date;
  lastModifiedDate?: Date;
  name?: string;
  size?: number;
  type?: string;
  file?: any;
  bindingResult?: string | null;
}

export const LoadingVectorDiagramsPopup = (props: LoadingVectorDiagramsProps) => {
  const { getContractCatalog } = useCatalog((state) => ({
    getContractCatalog: state.getContractCatalog,
  }));
  const contractList = getContractCatalog('convertIntoArray');
  const { addActionLog } = useActionLog();
  const [selectedContract, setSelectedContract] = useState<SelectFilterOptType[]>([]);
  const [fileTableItems, setFileTableItems] = useState<FileItem[]>([]);

  const [files, setFiles] = useState<any>([]);

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [fileItemCount, setFileItemCount] = React.useState<number>(0);
  const [selectedRowId, setSelectedRowId] = React.useState('');
  const [disabledButton, setDisabledButton] = React.useState<boolean>(true);

  const onDrop = useCallback((acceptedFiles: any) => {
    const fileItemArray: FileItem[] = [];
    acceptedFiles.forEach((file: any) => fileItemArray.push(createFileItemTable(file)));
    setFileTableItems(fileItemArray);
    setFiles(acceptedFiles);
    setDisabledButton(false);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      accepted: [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ],
    },
  });

  const removeFile = (
    files: any[],
    lastModifiedDate: Date,
    name: string,
    setFunc: (values: any[]) => void
  ) => {
    const result: any[] = files.filter(
      (value) => value.lastModified != lastModifiedDate && value.name != name
    );
    setFunc(result);
  };

  const createFileItemTable = (file: any): FileItem => {
    const fileItem: FileItem = {};
    if (file) {
      fileItem.id = uuid() + '';
      fileItem.file = file;
      fileItem.path = file.path;
      fileItem.loadAt = new Date();
      fileItem.lastModifiedDate = file.lastModifiedDate;
      fileItem.name = file.name;
      fileItem.size = file.size;
      fileItem.type = file.type;
      fileItem.bindingResult = '';
    }
    return fileItem;
  };

  const getTextComponent = (value: any) => {
    return (
      <Typography
        fontSize={14}
        sx={{ mt: 1 }}
        variant='caption'
        gutterBottom
      >
        {value && value.toString()}
      </Typography>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Название',
      width: 250,
      headerAlign: 'center',
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => getTextComponent(params.value),
    },
    {
      field: 'loadAt',
      headerName: 'Дата загрузки',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) =>
        getTextComponent(params.value ? moment(params.value).format('hh:mm:ss DD.MM.yyyy') : ''),
    },
    {
      field: 'bindingResult',
      headerName: 'Результат загрузки',
      width: 300,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div
          key={Math.random() + '_' + Math.random()}
          style={{ margin: '0 auto' }}
        >
          {params.value != undefined && params.value != null ? (
            params.value != '' ? (
              params.value
            ) : (
              <></>
            )
          ) : (
            <CloseIcon />
          )}
        </div>
      ),
    },
    {
      field: 'action',
      headerName: 'Действие',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Button
          onClick={() => {
            removeFile(files, params.row.lastModifiedDate, params.row.name, setFiles);
            removeFile(
              fileTableItems,
              params.row.lastModifiedDate,
              params.row.name,
              setFileTableItems
            );
          }}
          style={{ margin: '0 auto' }}
        >
          <DeleteForeverOutlinedIcon />
        </Button>
      ),
    },
  ];

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    setSelectedRowId(String(params.row.id));
  };
  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleSend = () => {
    if (!selectedContract || !JSON.parse(JSON.stringify(selectedContract)).value) {
      addActionLog(ActionLogType.ERROR, 'Небходимо выбрать контракт');
    } else if (files.length < 1) {
      addActionLog(ActionLogType.ERROR, 'Небходимо загрузить файлы');
    } else {
      bindVectorDiagram(JSON.parse(JSON.stringify(selectedContract)).value, files)
        .then((res) => {
          const temp = [...fileTableItems];
          temp.forEach((tempFileItem) => {
            const tempBindResult: BindResult | undefined = res.data.find(
              (value) => value.fileName === tempFileItem.name
            );
            if (tempBindResult) {
              tempFileItem.bindingResult = tempBindResult.mcId;
            }
          });
          setFileTableItems(temp);
          setDisabledButton(true);
        })
        .catch();
    }
  };
  return (
    <Popup
      isOpen={props.isOpen}
      onClose={() => {
        setFiles([]);
        setFileTableItems([]);
        setSelectedContract([]);
        props.onClose();
      }}
      onSuccess={props.onSuccess}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant={'h5'}
            style={{ marginBottom: 12, textAlign: 'center' }}
          >
            Загрузка векторных диаграм
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <SingleSelectFilter
            options={[...contractList].map(([key, value]) => ({ label: value, value: key }))}
            value={selectedContract}
            label={'Контракт'}
            onChange={(sel: any) => setSelectedContract(sel)}
            registArgName={'contract'}
            minWidth={300}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <div
            className={'dropZone'}
            {...getRootProps({})}
          >
            <input {...getInputProps()} />
            <div>Drag and drop your images here.</div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <DataGrid
            style={{ minHeight: '35vh' }}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            rows={fileTableItems}
            rowCount={fileItemCount}
            rowBuffer={pageSize}
            rowThreshold={pageSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode='server'
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            columns={columns}
            onRowEditStop={handleRowEditStop}
            onRowClick={handleRowClick}
          />
        </Grid>
        <Grid
          xs={7}
          item
        >
          <Button
            disabled={disabledButton}
            onClick={handleSend}
          >
            <SaveIcon />
            Привязать
          </Button>
        </Grid>
        <Grid
          xs={5}
          item
        >
          <Button
            onClick={() => {
              setFiles([]);
              setFileTableItems([]);
            }}
          >
            <SaveIcon />
            Очистить список
          </Button>
        </Grid>
      </Grid>
    </Popup>
  );
};
