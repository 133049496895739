import { getYodaJWTLocal } from '@/services/SupplyBillService';
import { useEffect, useState } from 'react';
import { decodeJWT, SAVE_SUPPLY_JWT_TOKEN } from './utils';

const useStorehouseUserName = () => {
  const [userName, setUserName] = useState(null);

  useEffect(() => {
    const decodeAndSetName = () => {
      const localJwt = getYodaJWTLocal();
      const decodedUserName = decodeJWT(localJwt)?.user_full_name;
      if (decodedUserName) setUserName(decodedUserName);
    };

    decodeAndSetName();

    window.addEventListener(SAVE_SUPPLY_JWT_TOKEN, decodeAndSetName);

    return () => {
      window.removeEventListener(SAVE_SUPPLY_JWT_TOKEN, decodeAndSetName);
    };
  }, []);

  return userName;
};

export default useStorehouseUserName;
