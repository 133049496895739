import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { Grid, Typography } from '@mui/material';
import { TextField } from '@/components/fixUI';
import { FormProvider, useForm } from 'react-hook-form';
import DateField from '@/components/DateField';
import { getPUItem } from '@/services/CatalogsService';
import { PuEditorResponse } from '@/dto/CatalogsDto';
import { useActionLog } from '@/hooks/ActionLogHook';
import { Loading } from '@/components/ui/Loading';
import CatalogPuEditor from '@/components/sections/CatalogsEditor/PuEditor/index';

const CatalogPuItem: FC = () => {
  const { puId } = useParams();
  const methods = useForm();
  const [taskId, setTaskId] = useState<string | undefined>(undefined);
  const [taskNumber, setTaskNumber] = useState<string | undefined>(undefined);
  const { fetchCatch } = useActionLog();

  const [loading, setLoading] = useState<boolean>(false);
  const [isLoad, setIsLoad] = useState<boolean>(false);

  const [data, setData] = useState<PuEditorResponse>();
  useEffect(() => {
    if (puId) {
      setLoading(true);
      getPUItem(puId)
        .then(({ data }) => setData(data))
        .catch((err) => fetchCatch(err, 'Ошибка загрузки прибора учета'))
        .finally(() => {
          setLoading(false);
          setIsLoad(true);
        });
    }
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <BoxShadow sx={{ p: 3, m: 2, width: 'calc(100% - 32px)' }}>
          {puId && (
            <CatalogPuEditor
              defaultData={data}
              loading={loading}
              puId={puId}
            />
          )}
        </BoxShadow>
      </FormProvider>
    </>
  );
};

export default CatalogPuItem;
