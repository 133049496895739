import { createStore } from '@/utils/createStore';
import {
  DeliveryPointResponse,
  DeliveryPointResponseKeys,
  SubscriberResponse,
} from '@/dto/taskmap/task/TaskResultDto';
import { findDeliveryPointById } from '@/services/TaskService';
import { fetchCatch } from '@/hooks/ActionLogHook';

interface AllDeliveryPoints {
  [x: string]: DeliveryPointResponse;
}

interface DeliveryPointsStore {
  // tmpId?: string;
  tmpDeliveryPoint?: DeliveryPointResponse;
  allDeliveryPoint: AllDeliveryPoints;

  updateSub: (sub: SubscriberResponse) => void;
  getTmpDeliveryPoint: (tmpId: string) => void;
  clearTmpDeliveryPoint: () => void;
  setTmpDeliveryPoint: (tmpId: string, deliveryPoint: DeliveryPointResponse) => void;
  setTmpDeliveryPointField: (
    tmpId: string,
    deliveryPointField: DeliveryPointResponseKeys,
    deliveryPointValue: any
  ) => void;
}

export const useDeliveryPoints = createStore<DeliveryPointsStore>(
  (set: any, get: any) => ({
    allDeliveryPoint: {},

    clearTmpDeliveryPoint: () => {
      set({
        tmpDeliveryPoint: undefined,
      });
    },

    updateSub: (sub: SubscriberResponse) => {
      set({
        tmpDeliveryPoint: { ...get().tmpDeliveryPoint, subscriber: sub },
      });
    },

    getTmpDeliveryPoint: (tmpId: string) => {
      // if (get().allDeliveryPoint[tmpId]) {
      //   set({ tmpDeliveryPoint: get().allDeliveryPoint[tmpId] });
      // } else {
      findDeliveryPointById(tmpId)
        .then((res) => {
          // // console.log(res.data);
          // setData(res.data);
          set({
            tmpDeliveryPoint: res.data,
            allDeliveryPoint: { ...get().allDeliveryPoint, [tmpId]: res.data },
          });
        })
        .catch((err) => fetchCatch(err, 'Ошибка загрузки'));
      // }
    },

    setTmpDeliveryPoint: (tmpId: string, deliveryPoint: DeliveryPointResponse) => {
      set({
        tmpDeliveryPoint: deliveryPoint,
        allDeliveryPoint: { ...get().allDeliveryPoint, [tmpId]: deliveryPoint },
      });
    },
    setTmpDeliveryPointField: (
      tmpId: string,
      deliveryPointField: DeliveryPointResponseKeys,
      deliveryPointValue: any
    ) => {
      set({
        tmpDeliveryPoint: { ...get().tmpDeliveryPoint, [deliveryPointField]: deliveryPointValue },
        // allDeliveryPoint: {
        //   ...get().allDeliveryPoint,
        //   [tmpId]: { ...get().tmpDeliveryPoint, [deliveryPointField]: deliveryPointValue },
        // },
      });
    },
  }),
  'Delivery Points'
);
