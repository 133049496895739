import React, { FC } from 'react';
import { Button } from '@mui/material';
import { useDeliveryPoints } from '../placemark/delivery-point/store';
import { replaceSubscriber } from '@/services/TaskService';
import { useActionLog, ActionLogType } from '@/hooks/ActionLogHook';
import { SubscriberResponse } from '@/dto/taskmap/task/TaskResultDto';
import { useCatalog } from '@/hooks/CatalogHook';
import { ChronologyRowDiv } from '@/components/features/taskEditor/item/chronology/Chronology.styled';
import ChronologyItem from '@/components/features/taskEditor/item/chronology/ChronologyItem';

interface IConsumerItem {
  fio: string;
  contractNum: string;
  puNumber: string;
  address: string;
  subscriber: SubscriberResponse;
  setIsLoading: (loadong: boolean) => void;
  type: string;
}

const ConsumerItem: FC<IConsumerItem> = ({
  fio,
  contractNum,
  puNumber,
  address,
  subscriber,
  setIsLoading,
  type,
}) => {
  const { tmpDeliveryPoint, setTmpDeliveryPoint } = useDeliveryPoints();
  const deliveryPointId = tmpDeliveryPoint?.id;
  const { fetchCatch, addActionLog } = useActionLog();
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const handleSubscriberReplace = (newSub: any) => {
    if (deliveryPointId) {
      setIsLoading(true);
      replaceSubscriber(newSub, deliveryPointId)
        .then(({ data }) => {
          setTmpDeliveryPoint(deliveryPointId, data);
          addActionLog(ActionLogType.SUCCESS, 'Сохранено');
        })
        .catch((err) => fetchCatch(err, 'Не сохранено'))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <ChronologyRowDiv borderColor={'borderGray'}>
      <ChronologyItem
        label={'Имя'}
        value={fio}
        width={'20%'}
      />
      <ChronologyItem
        label={'ЛС'}
        value={contractNum}
        width={'9%'}
      />
      <ChronologyItem
        label={'Контрагент'}
        value={getCatalogMapWithErr('taskConsumer').get(type !== 'undefined' ? type : '')}
        width={'9%'}
      />
      <ChronologyItem
        label={'№ Сущ. ПУ'}
        value={puNumber}
        width={'12%'}
      />
      <ChronologyItem
        label={'Адрес'}
        value={address}
        width={'40%'}
      />
      <Button onClick={() => handleSubscriberReplace(subscriber)}>выбрать</Button>
    </ChronologyRowDiv>
  );
};

export default ConsumerItem;
