import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { DateRange } from '@/components/inputs/DateRange';
import { Popup, IPopup } from '@/components/popups/Popup';
import { LoadingButton } from '@mui/lab';
import { getDateManual } from '@/services/RegistryService';
import moment from 'moment';
interface Props extends IPopup {
  handleSearch: () => void;
}

export const PopupForNewDate: React.FC<Props> = (props) => {
  const { onClose, handleSearch } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { fetchCatch, addActionLog } = useActionLog();
  const [dataInRange, setDateInRange] = useState<number[] | Date[]>([
    new Date(Date.now() - 86400000 * 14),
    new Date(),
  ]);
  const isInRange = dataInRange.length !== 2;

  const getNewRegistaryRespons = () => {
    setIsLoading(true);
    getDateManual({
      dateFrom: moment(dataInRange[0]).format('yyyy-MM-DD'),
      dateTo: moment(dataInRange[1]).format('yyyy-MM-DD'),
    })
      .then(() => {
        onClose();
        handleSearch();
        addActionLog(ActionLogType.SUCCESS, 'Реестр сформирован');
      })
      .catch((error) => {
        fetchCatch(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Popup {...props}>
      <Typography
        variant='h5'
        mb={3}
        textAlign={'center'}
      >
        Внесите данные
      </Typography>
      <Grid
        container
        minWidth={300}
        mb={3}
        alignItems={'center'}
        justifyContent={'center'}
        display={'flex'}
      >
        <Grid item>
          <DateRange
            label={'выбрать диапазон'}
            isInRange={isInRange}
            onChange={setDateInRange}
            value={dataInRange}
            argValue={8} // set inteval by default
            showSelect={false} // hide select option
          />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems={'center'}
        justifyContent={'center'}
        spacing={4}
      >
        <Grid item>
          <LoadingButton
            size='small'
            onClick={getNewRegistaryRespons}
            loading={isLoading}
            loadingPosition='start'
            variant='text'
            disabled={isInRange}
          >
            {!isLoading && <Typography>ок</Typography>}
          </LoadingButton>
        </Grid>
      </Grid>
    </Popup>
  );
};
