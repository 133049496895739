import { BoxShadow } from '@/components/ui/BoxShadow';
import { useCatalog } from '@/hooks/CatalogHook';
import { CircularProgress, Grid } from '@mui/material';
import { useActionLog } from '@/hooks/ActionLogHook';
import { ZoningAutocomplete } from './ZoningAutocomplete';
import { useSearch } from '@/components/maps/zoningMap/hook/SearchHook';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { findAllByZoningByFilter } from '@/services/ZoningService';

export const ZoningFilterPane = () => {
  const { getCatalogMapWithErr, getGroupCatalog, getContractCatalog } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    getGroupCatalog: state.getGroupCatalog,
    getContractCatalog: state.getContractCatalog,
  }));
  const { setIsInProgress } = useScreenHoldHook();
  const contractList = getContractCatalog('convertIntoMap');

  const { fetchCatch } = useActionLog();
  const { setFilter, setZoningResponse, loading } = useSearch();
  const filter = useSearch((state) => state.getFilter());

  const onClean = () => {
    setFilter(undefined);
    setZoningResponse(undefined);
  };

  const contractSelect = (val: any) => {
    setFilter({ ...filter, contractId: val });
  };

  const executorSelect = (val: any) => {
    setFilter({ ...filter, executorId: val });
  };

  const typeSelect = (val: any) => {
    setFilter({ ...filter, type: val });
  };

  const montagePlaceTypeSelect = (val: any) => {
    setFilter({ ...filter, montagePlaceType: val });
  };

  const phaseEnumSelect = (val: any) => {
    setFilter({ ...filter, phaseEnum: val });
  };

  const handleSearch = () => {
    setZoningResponse(undefined);
    if (filter) {
      setIsInProgress(true);
      findAllByZoningByFilter(filter)
        .then(({ data }) => setZoningResponse(data))
        .catch((err) => fetchCatch(err, 'Ошибка при поиске'))
        .finally(() => setIsInProgress(false));
    }
  };

  return (
    <BoxShadow>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Grid
          item
          xs={3}
        >
          <ZoningAutocomplete
            values={contractList}
            onChange={contractSelect}
            title='Контракт'
            value={filter?.contractId}
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <ZoningAutocomplete
            values={getGroupCatalog()}
            onChange={executorSelect}
            title='Бригада исполнителя'
            value={filter?.executorId}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <ZoningAutocomplete
            values={getCatalogMapWithErr('taskType')}
            onChange={typeSelect}
            title='Тип'
            value={filter?.type}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <ZoningAutocomplete
            values={getCatalogMapWithErr('montagePlaceType')}
            onChange={montagePlaceTypeSelect}
            title='Место установки'
            value={filter?.montagePlaceType}
          />
        </Grid>
        <Grid
          item
          xs={1}
        >
          <ZoningAutocomplete
            values={getCatalogMapWithErr('phase')}
            onChange={phaseEnumSelect}
            title='Фаза'
            value={filter?.phaseEnum}
          />
        </Grid>
        <Grid
          item
          container
          xs={1}
          justifyContent={'flex-end'}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <FilterButtons
              onSearchClick={handleSearch}
              onCloseClick={onClean}
            />
          )}
        </Grid>
      </Grid>
    </BoxShadow>
  );
};
