import React, { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { TextField } from '@/components/fixUI';
import { color } from '@/styles/mixins';
import {
  deleteEquipmentItem,
  editEquipmentItem,
  getEquipmentItem,
  getTaskNubmerForEquipment,
} from '@/services/CatalogsService';
import { useCatalog } from '@/hooks/CatalogHook';
import moment from 'moment';
import { Loading } from '@/components/ui/Loading';
import {
  CatalogEditorButtonsLoading,
  CatalogEditorTitleLoading,
} from './CatalogEquipmentEditor.styled';
import { addSuccessActionLog, useActionLog } from '@/hooks/ActionLogHook';
import { DialogTitle } from '@material-ui/core';
import { useQuery } from 'react-query';

import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { ru } from 'date-fns/locale';

export interface CatalogEquipmentEditorProps {
  equipmentId: string;
}

const CatalogEquipmentEditor: FC<CatalogEquipmentEditorProps> = ({ equipmentId }) => {
  const methods = useFormContext();
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = methods;

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);
  const { fetchCatch } = useActionLog();

  const { data, isLoading: loadingEquipment } = useQuery({
    queryKey: ['equipment'],
    refetchOnWindowFocus: false,
    enabled: !!equipmentId,
    queryFn: () => getEquipmentItem(equipmentId),
    onError: (error) => fetchCatch(error, 'Ошибка загрузки оборудования'),
  });

  const { data: dataTaskNumber, isLoading: loadingTaskNumber } = useQuery({
    queryKey: ['taskNumber'],
    refetchOnWindowFocus: false,
    enabled: !!equipmentId,
    queryFn: () => getTaskNubmerForEquipment(equipmentId),
  });

  const saveHandler = handleSubmit((data) => {
    if (data.timeLastActivity && !data.dateLastActivity) {
      setError('dateLastActivity', { type: 'error', message: 'Выберите дату' });
      return;
    }

    const lastActivity = moment(data.dateLastActivity)
      .set({
        hour: data.timeLastActivity.split(':')[0],
        minute: data.timeLastActivity.split(':')[1],
      })
      .valueOf();

    editEquipmentItem(equipmentId, { ...data[equipmentId], lastActivity })
      .then(() => {
        addSuccessActionLog('Изменения оборудования сохранены');
      })
      .catch((err) => {
        fetchCatch(err, 'Ошибка сохранения оборудования');
      });
  });

  const deleteHandler = () => {
    deleteEquipmentItem(equipmentId)
      .then(() => {
        addSuccessActionLog('Оборудование удалено');
      })
      .catch((err) => {
        fetchCatch(err, 'Ошибка удаления оборудования');
      });
  };

  const formatTimeMask = (value: string) => {
    if (!value) return '';
    const numericValue = value.replace(/\D/g, '');

    if (numericValue.length > 1) {
      const formattedValue: string = numericValue.slice(0, 2) + ':' + numericValue.slice(2, 4);
      return formattedValue.trim();
    }
    return numericValue;
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'dateLastActivity') {
        setValue('timeLastActivity', value.timeLastActivity ? value.timeLastActivity : '00:00');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <CatalogEditorTitleLoading loading={loadingTaskNumber}>
            <Typography variant={'h5'}>
              {dataTaskNumber?.data.taskNumber ? (
                <>
                  {`Оборудование привязано к заявке №`}
                  <Link
                    to={`/task/edit/${dataTaskNumber?.data.taskId}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <span
                      style={{ color: color('muiBlue') }}
                    >{`${dataTaskNumber.data.taskNumber}`}</span>
                  </Link>
                </>
              ) : (
                'Оборудование не привязано к заявке'
              )}
            </Typography>
          </CatalogEditorTitleLoading>
        </Grid>
        <Grid
          item
          xs={12 / 6}
          container
          gap={1}
        >
          <Loading loading={loadingEquipment}>
            <AutocompleteValue
              title='Производитель'
              defaultValue={data?.data.manufacturerId ? `${data?.data.manufacturerId}` : undefined}
              values={getCatalogMapWithErr('manufacturer')}
              fieldName={`${equipmentId}.manufacturerId`}
              sx={{ width: '100%' }}
            />
          </Loading>
          <Loading loading={loadingEquipment}>
            <Controller
              control={control}
              name='dateLastActivity'
              defaultValue={data?.data.lastActivity}
              render={({ field }) => (
                <DatePicker
                  dateFormat='dd.MM.yyyy'
                  locale={ru}
                  selected={field.value}
                  onChange={field.onChange}
                  customInput={
                    <TextField
                      fullWidth
                      error={errors.dateLastActivity}
                      label='Дата последней активности'
                      variant='standard'
                    />
                  }
                />
              )}
            />
          </Loading>
        </Grid>
        <Grid
          item
          xs={12 / 6}
          container
          gap={1}
        >
          <Loading loading={loadingEquipment}>
            <AutocompleteValue
              title='Тип оборудования'
              defaultValue={data?.data.typeId ? `${data?.data.typeId}` : undefined}
              values={getCatalogMapWithErr('networkEquipmentType')}
              fieldName={`${equipmentId}.typeId`}
              sx={{ width: '100%' }}
            />
          </Loading>

          <Loading loading={loadingEquipment}>
            <Controller
              control={control}
              defaultValue={
                data?.data.lastActivity ? moment(data?.data.lastActivity).format('HH:mm') : ''
              }
              name='timeLastActivity'
              render={({ field }) => (
                <TextField
                  label='Время последней активности'
                  variant='standard'
                  fullWidth
                  {...field}
                  value={formatTimeMask(field.value)}
                  onChange={(e) => field.onChange(formatTimeMask(e.target.value))}
                />
              )}
            />
          </Loading>
        </Grid>
        <Grid
          item
          xs={12 / 6}
          container
          gap={1}
        >
          <Loading loading={loadingEquipment}>
            <TextField
              label='Модель'
              variant='standard'
              defaultValue={data?.data.model}
              {...register(`${equipmentId}.model`)}
              sx={{ width: '100%' }}
            />
          </Loading>
          <Loading loading={loadingEquipment}>
            <TextField
              label='№ канала'
              variant='standard'
              {...register(`${equipmentId}.channelNum`)}
              defaultValue={data?.data.channelNum}
              sx={{ width: '100%' }}
            />
          </Loading>
        </Grid>
        <Grid
          item
          xs={12 / 6}
          container
          gap={1}
        >
          <Loading loading={loadingEquipment}>
            <TextField
              label='Заводской №'
              variant='standard'
              {...register(`${equipmentId}.number`)}
              defaultValue={data?.data.number}
              sx={{ width: '100%' }}
            />
          </Loading>
          <Loading loading={loadingEquipment}>
            <TextField
              label='Pan id'
              variant='standard'
              {...register(`${equipmentId}.networkPanId`)}
              defaultValue={data?.data.networkPanId}
              sx={{ width: '100%' }}
            />
          </Loading>
        </Grid>
        <Grid
          item
          xs={12 / 6}
          container
          gap={1}
        >
          <Loading loading={loadingEquipment}>
            <TextField
              label='МАС-адрес'
              variant='standard'
              {...register(`${equipmentId}.mac`)}
              defaultValue={data?.data.mac}
              sx={{ width: '100%' }}
            />
          </Loading>
          <Loading loading={loadingEquipment}>
            <TextField
              label='Номер прошивки'
              variant='standard'
              {...register(`${equipmentId}.versionNumber`)}
              defaultValue={data?.data.versionNumber}
              sx={{ width: '100%' }}
            />
          </Loading>
        </Grid>
        <Grid
          item
          xs={12 / 6}
          container
          gap={1}
        >
          <Loading loading={loadingEquipment}>
            <TextField
              label='IMEI'
              variant='standard'
              {...register(`${equipmentId}.imei`)}
              defaultValue={data?.data.imei}
              sx={{ width: '100%' }}
            />
          </Loading>
          <Loading loading={loadingEquipment}>
            <Controller
              defaultValue={data?.data.joiningPermitted}
              control={control}
              name={`${equipmentId}.joiningPermitted`}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onChange}
                      checked={value}
                    />
                  }
                  label='Присоединён в Пирамиде'
                />
              )}
            />
          </Loading>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <CatalogEditorButtonsLoading loading={loadingEquipment}>
            <>
              <Button onClick={() => setOpenDialog(true)}>Удалить оборудование</Button>
              <Button onClick={saveHandler}>Сохранить</Button>
            </>
          </CatalogEditorButtonsLoading>
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle disableTypography>
          <Typography style={{ alignItems: 'center' }}>
            Вы действительно хотите удалить оборудование?
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button onClick={deleteHandler}>Да, удалить</Button>
          <Button onClick={() => setOpenDialog(false)}>Отмена</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CatalogEquipmentEditor;
