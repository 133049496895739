import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { DataGrid, GridApi, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { completeIsuTaskInCheck, getisuTaskInCheck } from '@/services/TaskService';
import { Box, Button, IconButton, LinearProgress } from '@mui/material';

import { SelectFilterOptType, SingleLineFilter } from '@/components/filter/MultySelectFilter';
import { FormProvider, useForm } from 'react-hook-form';

import HorizontalGallery from '@/components/blocks/HorizontalGallery/HorizontalGallery';
import { Check, Save } from '@mui/icons-material';
import { TaskFilterParam } from '@/dto/taskmap/Dto';
import { IsuTaskInCheckDto } from '@/dto/taskmap/task/TasksDto';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { generateDetailedInfo } from '@/components/button/DetailedInfo/utils';
import { getDataFromFilter } from '@/components/inputs/MultiSelectNew/utils';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { McStatuses, METER_CHANGE_REWORK_STATIC_OPTIONS } from './utils';
import { MeterChangeReworkRow, MeterChangeReworkWrapper } from './MeterChangeRework.styled';
import usePressEnterButton from '@/hooks/usePressEnterButton';

const FILTERS_LABELS = {
  mcStatuses: 'Статус замены',
  responsables: 'Ответственный подрядчик',
  contractList: 'Контракт',
  taskNum: 'Номер заявки',
};

export const MeterChangeRework = () => {
  const { addActionLog, fetchCatch } = useActionLog();

  const columns: GridColDef[] = [
    {
      field: 'number',
      headerName: 'Номер',
      width: 80,
      renderCell: (t) => (
        <Button
          variant='text'
          onClick={() => window.open(`/task/edit/${t.id}`)}
        >
          {t.row.number}
        </Button>
      ),
    },
    ...METER_CHANGE_REWORK_STATIC_OPTIONS,
    {
      field: 'action',
      headerName: 'Применить',
      renderCell: (params) => {
        if (params.row.taskStatus === 'COMPLETED') {
          return <Check color='success' />;
        }
        const onClick = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking
          const api: GridApi = params.api;
          const thisRow: Record<string, IsuTaskInCheckDto> = {};
          api.setCellFocus(params.id, 'number');
          api
            .getAllColumns()
            .filter((c) => c.field !== 'action' && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          const isuTaskDto: IsuTaskInCheckDto = thisRow as unknown as IsuTaskInCheckDto;
          isuTaskDto.mcId = params.row.mcId;
          isuTaskDto.id = params.row.id;
          mutCompleteTask.mutate(isuTaskDto);
        };
        return (
          <IconButton
            color='default'
            onClick={onClick}
            aria-label='upload picture'
            component='label'
          >
            <Save />
          </IconButton>
        );
      },
    },
  ];

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const [tasks, setTasks] = useState<IsuTaskInCheckDto[]>([]);

  const methods = useForm();
  const { reset, watch, handleSubmit } = methods;

  const onResetClick = () => {
    reset();
    setSelectedContractList([]);
    setSelectedResponsables([]);
    setSelectedMcStatuses([]);
  };
  const onSubmitSearch = (data: any) => {
    const filtParam: TaskFilterParam = data;
    if (selectedContractList.length) {
      filtParam.contractIds = getDataFromFilter(selectedContractList, 'value');
      filtParam.contractIdsArg = contractListArg;
    }
    if (selectedResponsables.length) {
      filtParam.contractorIds = getDataFromFilter(selectedResponsables, 'value');
      filtParam.contractIdsArg = responsablesArg;
    }
    if (selectedMcStatuses.length) {
      filtParam.mcStatuses = getDataFromFilter(selectedMcStatuses, 'value');
      filtParam.mcStatusesArg = mcStatusesArg;
    }
    mutGetTasks.mutate(filtParam);
  };

  usePressEnterButton(handleSubmit(onSubmitSearch));
  const { getContractCatalog } = useCatalog((state) => ({
    getContractCatalog: state.getContractCatalog,
  }));
  const contractListResponse = getContractCatalog();
  const [selectedContractList, setSelectedContractList] = useState<SelectFilterOptType[]>([]);
  const [contractListArg, setContractListArg] = useState(0);

  const [selectedMcStatuses, setSelectedMcStatuses] = useState<SelectFilterOptType[]>([]);
  const [mcStatusesArg, setMcStatusesArg] = useState(0);

  const { taskGroupMap, getGroupCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
    taskGroupMap: state.taskGroupMap,
  }));
  const responsables = useMemo(() => taskMapToArray(getGroupCatalog()), [taskGroupMap]);
  const [selectedResponsables, setSelectedResponsables] = useState<SelectFilterOptType[]>([]);
  const [responsablesArg, setResponsablesArg] = useState(0);

  const [selectedRowId, setSelectedRowId] = useState('');

  useEffect(() => {
    mutGetTasks.mutate({});
  }, []);

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    if (params.row.mcId && String(params.row.mcId).length == 36) {
      setSelectedRowId(String(params.row.mcId));
    }
  };

  const mutCompleteTask = useMutation<void, any, IsuTaskInCheckDto>((taskDto) => {
    return completeIsuTaskInCheck(taskDto)
      .then((response) => {
        const responseTask: IsuTaskInCheckDto = response.data;
        const newTaskList: IsuTaskInCheckDto[] = [];
        tasks.forEach((t: IsuTaskInCheckDto) => {
          if (t.number === responseTask.number) {
            t = { ...responseTask };
          }
          newTaskList.push({ ...t });
        });
        setTasks(newTaskList);
        addActionLog(ActionLogType.SUCCESS, `Заявка успешно сохранена`);
      })
      .catch((err) => fetchCatch(err, 'Ошибка при сохранении'));
  });

  const mutGetTasks = useMutation<void, any, TaskFilterParam>((filter) => {
    return getisuTaskInCheck(filter)
      .then((data) => setTasks(data?.data))
      .catch((err) => fetchCatch(err));
  });

  const detailedInfo = generateDetailedInfo(
    [watch('taskNum'), FILTERS_LABELS.taskNum],
    [selectedMcStatuses, FILTERS_LABELS.mcStatuses],
    [selectedResponsables, FILTERS_LABELS.responsables],
    [selectedContractList, FILTERS_LABELS.contractList]
  );

  return (
    <MeterChangeReworkWrapper>
      {(mutGetTasks.isLoading || mutCompleteTask.isLoading) && (
        <Box>
          <LinearProgress />
        </Box>
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitSearch)}>
          <MeterChangeReworkRow>
            <SingleLineFilter
              registerName='taskNum'
              registerNameArg='taskNumArg'
              label={FILTERS_LABELS.taskNum}
            />

            <MultiSelect
              label={FILTERS_LABELS.mcStatuses}
              options={McStatuses}
              value={selectedMcStatuses}
              argValue={mcStatusesArg}
              onChange={(value: SelectFilterOptType[]) => setSelectedMcStatuses(value)}
              onArgChange={(arg: number) => setMcStatusesArg(arg)}
            />

            <MultiSelect
              label={FILTERS_LABELS.responsables}
              options={responsables}
              value={selectedResponsables}
              argValue={responsablesArg}
              onChange={(value: SelectFilterOptType[]) => setSelectedResponsables(value)}
              onArgChange={(arg: number) => setResponsablesArg(arg)}
            />

            <MultiSelect
              label={FILTERS_LABELS.contractList}
              options={contractListResponse ?? []}
              value={selectedContractList}
              argValue={contractListArg}
              onChange={(value: SelectFilterOptType[]) => setSelectedContractList(value)}
              onArgChange={(arg: number) => setContractListArg(arg)}
            />

            <FilterButtons
              info={detailedInfo}
              onSearchClick={() => handleSubmit(onSubmitSearch)}
              onCloseClick={onResetClick}
            />
          </MeterChangeReworkRow>
        </form>
      </FormProvider>
      <HorizontalGallery id={selectedRowId} />
      <DataGrid
        disableColumnMenu
        getRowId={(row) => row.id || row.mcId}
        rows={tasks}
        headerHeight={64}
        columns={columns}
        pageSize={5}
        onRowEditStop={handleRowEditStop}
        rowsPerPageOptions={[5]}
        onRowClick={handleRowClick}
      />
    </MeterChangeReworkWrapper>
  );
};
