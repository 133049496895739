import { Autocomplete, Button, TextField, Tooltip } from '@mui/material';
import React, { MutableRefObject, useMemo, useRef, useState } from 'react';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import {
  PnrPlanningIconWrapper,
  PnrPlanningModalButtonsWrapper,
  PnrPlanningModalConflictIcon,
  PnrPlanningModalConflictIconWrapper,
  PnrPlanningModalDateWrapper,
  PnrPlanningModalFirstStepColumn,
  PnrPlanningModalResponseItem,
  PnrPlanningModalResponseItemAnchor,
  PnrPlanningModalResponseItemWrapper,
  PnrPlanningModalSecondStepFormWrapper,
  PnrPlanningModalSecondStepInfoWrapper,
  PnrPlanningModalStepsAutosizerWrapper,
  PnrPlanningModalStepsError,
  PnrPlanningModalStepsHeader,
  PnrPlanningModalStepWrapper,
} from './PnrPlanningModalSteps.styled';
import { usePnrPlanningStore } from '@/components/sections/PnrPlanning/store';
import PnrPlanningModalFirstStepRow from './PnrPlanningModalFirstStepRow';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import AddIcon from '@mui/icons-material/Add';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { DateRange } from '@/components/inputs/DateRange';
import { RadioGroupForm } from '@/components/RadioGroupForm';
import { FilterArgEnum, SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import PnrPlanningModalSecondStepRow from './PnrPlanningModalSecondStepRow';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import {
  ApiCreateHouseOrders,
  ApiFindHouseOrders,
  ApiFindHousePlannings,
  ApiSaveHousePlannings,
} from '@/services/YodaRestService';
import { useParams } from 'react-router-dom';
import {
  checkAllMontagePlaces,
  checkIsValidPlannings,
  convertPlanningsToPlanningsDto,
  FILTER_OPTIONS,
  MULTIPLE_PLANNING_ORDER_ITEM,
  PLANNING_RADIO_ITEMS,
  usePlanningsTaskTypes,
} from './util';
import {
  getPlannedOrdersByEntrance,
  useHouseLevelFloorType,
  useNetworkElementPlanningType,
  useNetworkEquipmentTypes,
} from '@/components/features/pnr/HouseEntranceEdit/utils';
import { toFormateDate } from '@/utils/heplers';
import { CreateHouseOrderDto } from '@/dto/taskmap/Dto';
import shallow from 'zustand/shallow';
import PnrPlanningCommentModal from './PnrPlanningCommentModal';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FixedSizeList, FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { NetworkPlanningTypes } from '../PnrElementsInfo/utils';

export const PnrPlanningModalFirstStep = () => {
  const {
    closeModal,
    nextStep,
    plannedInstalations,
    hasAnyBackendConflict,
    addPlannedInstalation,
    setNewPlanning,
    openModalType,
  } = usePnrPlanningStore(
    (state) => ({
      closeModal: state.closeModal,
      nextStep: state.nextStep,
      plannedInstalations: state.plannedInstalations,
      hasAnyBackendConflict: state.hasAnyBackendConflict,
      addPlannedInstalation: state.addPlannedInstalation,
      setNewPlanning: state.setNewPlanning,
      openModalType: state.openModalType,
    }),
    shallow
  );
  const listRef = useRef() as MutableRefObject<FixedSizeList>;

  const { setIsInProgress } = useScreenHoldHook();
  const { houseDtoId } = useParams();
  const { fetchCatch, addActionLog } = useActionLog();

  const disableSavePlanning =
    !!openModalType && !checkIsValidPlannings(plannedInstalations.get(openModalType) || []);
  const disableNextStep =
    !openModalType ||
    !openModalType ||
    disableSavePlanning ||
    openModalType === NetworkPlanningTypes.PREPLAN ||
    !checkAllMontagePlaces(plannedInstalations.get(openModalType));

  const { networkEquipmentTypes } = useNetworkEquipmentTypes();
  const { networkElementPlanningTypes } = useNetworkElementPlanningType();
  const { floorTypes } = useHouseLevelFloorType();

  const handleSavePlaninngs = () => {
    if (!openModalType) return;
    const plannings = convertPlanningsToPlanningsDto(plannedInstalations);
    setIsInProgress(true);
    ApiSaveHousePlannings(houseDtoId!, plannings)
      .then(({ data }) => {
        setNewPlanning(data, networkEquipmentTypes, 0, networkElementPlanningTypes, floorTypes);
        addActionLog(ActionLogType.SUCCESS, `План успешно сохранен`);
      })
      .catch((error) => fetchCatch(error, 'Ошибка сохранения плана'))
      .finally(() => setIsInProgress(false));
  };

  const currentTypePlannedInstalations =
    plannedInstalations.get(openModalType || NetworkPlanningTypes.FIRSTLY) || [];

  const handleAddRow = () => {
    if (!openModalType) return;

    addPlannedInstalation();

    if (currentTypePlannedInstalations?.length > 2) {
      setTimeout(() => {
        const lastEditableCellId =
          currentTypePlannedInstalations[currentTypePlannedInstalations?.length - 1]?.tmpId;
        listRef?.current?.scrollToItem(lastEditableCellId, 'start');
      }, 10);
    }
  };

  return (
    <PnrPlanningModalStepWrapper>
      <PnrPlanningModalFirstStepColumn>
        <PnrPlanningModalStepsHeader>
          Данный план несет рекомендательный характер. При необходимости вы можете внести изменения
        </PnrPlanningModalStepsHeader>

        {hasAnyBackendConflict && (
          <PnrPlanningModalStepsError variant='body2'>
            <PnrPlanningIconWrapper>
              <WarningAmberRoundedIcon
                color='error'
                fontSize='inherit'
              />
            </PnrPlanningIconWrapper>
            На данном плане есть пересечения с уже имеющимся оборудованием
          </PnrPlanningModalStepsError>
        )}

        {openModalType === NetworkPlanningTypes.PREPLAN && (
          <PnrPlanningModalStepsError variant='body2'>
            <PnrPlanningIconWrapper gray>
              <InfoOutlinedIcon fontSize='inherit' />
            </PnrPlanningIconWrapper>
            Заявка не будет сформирована. Для создания заявки необходимо изменить статус «Признак» с
            предпланирования на планирование
          </PnrPlanningModalStepsError>
        )}
      </PnrPlanningModalFirstStepColumn>

      <PnrPlanningModalFirstStepColumn marginTop>
        <PnrPlanningModalStepsAutosizerWrapper>
          <AutoSizer>
            {({ height, width }: { width: number; height: number }) => (
              <List
                ref={listRef}
                height={height}
                itemCount={currentTypePlannedInstalations.length}
                itemSize={60}
                width={width}
                itemData={{
                  currentTypePlannedInstalations,
                }}
                itemKey={(index) => currentTypePlannedInstalations[index].tmpId}
                useIsScrolling
                className='virtualizedList'
              >
                {PnrPlanningModalFirstStepRow}
              </List>
            )}
          </AutoSizer>
        </PnrPlanningModalStepsAutosizerWrapper>
        <Button
          startIcon={<AddIcon />}
          sx={{ alignSelf: 'flex-start' }}
          onClick={handleAddRow}
          disabled={openModalType === NetworkPlanningTypes.PREPLAN}
        >
          Добавить строку
        </Button>
      </PnrPlanningModalFirstStepColumn>

      <PnrPlanningModalButtonsWrapper>
        <Tooltip
          title={
            openModalType === NetworkPlanningTypes.PREPLAN ? (
              <PnrPlanningModalConflictIconWrapper>
                <PnrPlanningModalConflictIcon
                  color='error'
                  fontSize='medium'
                />
                Запрещено создавать заявки с признаком "вторичный предплан"
              </PnrPlanningModalConflictIconWrapper>
            ) : (
              ''
            )
          }
          placement='top'
        >
          <div>
            <Button
              disabled={disableNextStep}
              onClick={nextStep}
              variant='contained'
            >
              Продолжить
            </Button>
          </div>
        </Tooltip>

        <Button onClick={closeModal}>Отмена</Button>

        <Button
          onClick={handleSavePlaninngs}
          disabled={disableSavePlanning}
          variant='text'
        >
          Сохранить планирование
        </Button>
      </PnrPlanningModalButtonsWrapper>

      <PnrPlanningCommentModal />
    </PnrPlanningModalStepWrapper>
  );
};

export const PnrPlanningModalSecondStep = () => {
  const { taskGroupMap, getGroupCatalog, getContractCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
    getContractCatalog: state.getContractCatalog,
    taskGroupMap: state.taskGroupMap,
  }));
  const planningsTaskTypes = usePlanningsTaskTypes();

  const contractorIdsOptions = useMemo(() => taskMapToArray(getGroupCatalog()), [taskGroupMap]);
  const contractIdsOptions = getContractCatalog();

  const {
    prevStep,
    plannedInstalations,
    setCreateResponse,
    setNewPlanning,
    setPlannedOrders,
    openModalType,
  } = usePnrPlanningStore((state) => ({
    plannedInstalations: state.plannedInstalations,
    prevStep: state.prevStep,
    setCreateResponse: state.setCreateResponse,
    setNewPlanning: state.setNewPlanning,
    setPlannedOrders: state.setPlannedOrders,
    openModalType: state.openModalType,
  }));
  const { setIsInProgress } = useScreenHoldHook();
  const { houseDtoId } = useParams();
  const { fetchCatch, addActionLog } = useActionLog();
  const { networkEquipmentTypes } = useNetworkEquipmentTypes();
  const { networkElementPlanningTypes } = useNetworkElementPlanningType();
  const { floorTypes } = useHouseLevelFloorType();

  const [contract, setContract] = React.useState<SelectFilterOptType | null>(null);
  const [contractor, setContractor] = React.useState<SelectFilterOptType | null>(null);
  const [taskType, setTaskType] = useState<SelectFilterOptType | null>(null);
  const [plannedDate, setPlannedDate] = useState<Date | null>(null);
  const [valueRadio, setValueRadio] = useState('');

  const onHandlePlannedDate = (newValue: Date[] | null) => {
    if (newValue && newValue[0]) {
      setPlannedDate(newValue[0]);
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueRadio(event.target.value);
  };

  const handleContinue = () => {
    if (!contract || !contractor || !plannedDate || !taskType) return;
    setIsInProgress(true);
    const ordersDto: CreateHouseOrderDto = {
      isMultiple: valueRadio === MULTIPLE_PLANNING_ORDER_ITEM.value,
      contractId: contract?.value,
      contractorId: contractor?.value,
      plannedDate: plannedDate,
      taskType: taskType?.value,

      plannings: convertPlanningsToPlanningsDto(plannedInstalations, openModalType),
    };

    ApiCreateHouseOrders(houseDtoId!, ordersDto)
      .then(({ data }) => {
        addActionLog(ActionLogType.SUCCESS, `Заявки успешно созданы`);
        setCreateResponse(data);
        ApiFindHouseOrders(houseDtoId!)
          .then(({ data }) => {
            const plannedOrdersByEntrance = getPlannedOrdersByEntrance(data);
            setPlannedOrders(plannedOrdersByEntrance);
          })
          .catch((error) => fetchCatch(error, 'Ошибка получения информации о плановых заявках'))
          .finally(() => setIsInProgress(false));

        return ApiFindHousePlannings(houseDtoId!)
          .then(({ data }) =>
            setNewPlanning(data, networkEquipmentTypes, 2, networkElementPlanningTypes, floorTypes)
          )
          .catch((error) => fetchCatch(error, 'Ошибка получения данных планировщика'))
          .finally(() => setIsInProgress(false));
      })
      .catch((error) => fetchCatch(error, 'Ошибка создания заявки'))
      .finally(() => setIsInProgress(false));
  };

  const disableNextStep = !plannedDate || !contract || !contractor || !valueRadio || !taskType;

  const currentTypePlannedInstalations = openModalType
    ? plannedInstalations.get(openModalType) || []
    : [];

  return (
    <PnrPlanningModalStepWrapper>
      <PnrPlanningModalStepsHeader>
        Выберете необходимые параметры для создания заявки
      </PnrPlanningModalStepsHeader>
      <PnrPlanningModalSecondStepFormWrapper>
        <Autocomplete
          disablePortal
          filterOptions={FILTER_OPTIONS}
          value={contract}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          options={contractIdsOptions}
          sx={{ width: 250 }}
          onChange={(e, val) => setContract(val)}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Контракт'
              variant='standard'
              InputLabelProps={{ shrink: true }}
            />
          )}
        />

        <Autocomplete
          disablePortal
          filterOptions={FILTER_OPTIONS}
          value={contractor}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          options={contractorIdsOptions ?? []}
          sx={{ width: 250 }}
          onChange={(e, val) => setContractor(val)}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Ответственный подрядчик'
              variant='standard'
              InputLabelProps={{ shrink: true }}
            />
          )}
        />

        <PnrPlanningModalDateWrapper>
          <DateRange
            label={'Плановая дата'}
            onChange={onHandlePlannedDate}
            value={[plannedDate]}
            argValue={FilterArgEnum.EQUAL}
            showSelect={false}
            showCalenderIcon={true}
            style={{ width: '100%' }}
            showClearButton={false}
            showPalceHolder={false}
          />
        </PnrPlanningModalDateWrapper>

        <Autocomplete
          disablePortal
          value={taskType}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          options={planningsTaskTypes}
          sx={{ width: 250 }}
          onChange={(e, val) => setTaskType(val)}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Тип заявки'
              variant='standard'
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </PnrPlanningModalSecondStepFormWrapper>
      <div style={{ display: 'flex' }}>
        <RadioGroupForm
          value={valueRadio}
          onChange={handleRadioChange}
          items={PLANNING_RADIO_ITEMS}
          labelPlacement='end'
          columnDirection
        />
      </div>

      <PnrPlanningModalSecondStepInfoWrapper>
        <PnrPlanningModalStepsAutosizerWrapper>
          <AutoSizer>
            {({ height, width }: { width: number; height: number }) => (
              <List
                height={height}
                itemCount={currentTypePlannedInstalations.length}
                itemSize={60}
                width={width}
                itemData={{
                  currentTypePlannedInstalations,
                }}
                itemKey={(index) => currentTypePlannedInstalations[index].tmpId}
                useIsScrolling
                className='virtualizedList'
              >
                {PnrPlanningModalSecondStepRow}
              </List>
            )}
          </AutoSizer>
        </PnrPlanningModalStepsAutosizerWrapper>
      </PnrPlanningModalSecondStepInfoWrapper>
      <PnrPlanningModalButtonsWrapper>
        <Button
          onClick={handleContinue}
          variant='contained'
          disabled={disableNextStep}
        >
          Создать
        </Button>

        <Button onClick={prevStep}>Назад</Button>
      </PnrPlanningModalButtonsWrapper>
    </PnrPlanningModalStepWrapper>
  );
};

export const PnrPlanningModalThirdStep = () => {
  const { closeModal, createResponse } = usePnrPlanningStore((state) => ({
    closeModal: state.closeModal,
    createResponse: state.createResponse,
  }));
  const { getGroupCatalog, getContractCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
    getContractCatalog: state.getContractCatalog,
  }));
  const contractIdsMap = getContractCatalog('convertIntoMap');

  return (
    <PnrPlanningModalStepWrapper>
      <PnrPlanningModalStepsHeader>
        В результате планирования успешно созданы заявки:
      </PnrPlanningModalStepsHeader>

      {createResponse?.map((order, index) => (
        <PnrPlanningModalResponseItemWrapper key={index}>
          <PnrPlanningModalResponseItem>
            {'№ '}
            <PnrPlanningModalResponseItemAnchor
              onClick={() => window.open(`/task/edit/${order.taskId}`)}
            >
              {order.taskNum}
            </PnrPlanningModalResponseItemAnchor>
          </PnrPlanningModalResponseItem>
          <PnrPlanningModalResponseItem>
            <Tooltip title={contractIdsMap.get(order.contractId) || ''}>
              <span>{contractIdsMap.get(order.contractId) || ''}</span>
            </Tooltip>
          </PnrPlanningModalResponseItem>
          <PnrPlanningModalResponseItem>
            <Tooltip title={getGroupCatalog()?.get(order.contractorId) || ''}>
              <span>{getGroupCatalog()?.get(order.contractorId) || ''}</span>
            </Tooltip>
          </PnrPlanningModalResponseItem>
          <PnrPlanningModalResponseItem>
            {toFormateDate(order.plannedDate)}
          </PnrPlanningModalResponseItem>
        </PnrPlanningModalResponseItemWrapper>
      ))}
      <PnrPlanningModalButtonsWrapper>
        <Button
          onClick={closeModal}
          variant='contained'
        >
          Ок
        </Button>
      </PnrPlanningModalButtonsWrapper>
    </PnrPlanningModalStepWrapper>
  );
};
