import './loadspinner.css';
const LoadSpinner: React.FC = () => {
  return (
    <div className={'loadWrapper'}>
      <div className='loader' />
      <p className='loadText'>Загрузка...</p>
    </div>
  );
};
export default LoadSpinner;
