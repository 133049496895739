import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Popup, IPopup } from '../Popup';
import {
  SelectAllPopupButtonsWrapper,
  SelectAllPopupTitle,
  SelectAllPopupTotal,
} from './SelectAllPopup.styled';
import { useActionLog } from '@/hooks/ActionLogHook';
import CircularProgress from '@mui/material/CircularProgress';
import { ApiGetAllUUID } from '@/services/YodaRestService';

export interface SelectAllPopupProps extends IPopup {
  filters: unknown;
  onSuccess?: (data?: string[]) => void;
}

const SelectAllPopup = (props: SelectAllPopupProps) => {
  const { fetchCatch } = useActionLog();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [allIds, setAllIds] = useState([]);

  useEffect(() => {
    if (props.isOpen) {
      setError('');
      setLoading(true);
      ApiGetAllUUID(props.filters)
        .then(({ data }) => setAllIds(data))
        .catch((err) => {
          fetchCatch(err, 'Ошибка выделения всех задач с текущим фильтром');
          setError(err?.response?.data ?? 'Попробуйте повторить запрос с более точными фильтрами');
        })
        .finally(() => setLoading(false));
    }
  }, [props.isOpen]);

  const handleConfirm = () => {
    props?.onSuccess && props?.onSuccess(allIds);
  };

  return (
    <Popup {...props}>
      <SelectAllPopupTitle>
        {error ? (
          <>{error}</>
        ) : (
          <>
            <>
              Действие будет применено для всех заявок выбранных по фильтру, в том числе и на других
              страницах.
            </>
            <SelectAllPopupTotal>
              Общее число заявок: {loading ? <CircularProgress size={15} /> : allIds.length}
            </SelectAllPopupTotal>
            <> </>
          </>
        )}
      </SelectAllPopupTitle>

      <SelectAllPopupButtonsWrapper>
        <Button
          disabled={!!error || loading}
          onClick={handleConfirm}
        >
          ок
        </Button>
        <Button onClick={props.onClose}>отменить</Button>
      </SelectAllPopupButtonsWrapper>
    </Popup>
  );
};

export default SelectAllPopup;
