import { Grid } from '@mui/material';
import React from 'react';
import { TaskFilterParam } from '@/dto/taskmap/Dto';
import { SMRStatisticsTableItem } from './types';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import { FormProvider, useForm } from 'react-hook-form';
import {
  getValue,
  SelectFilterOptType,
  SingleSelectFilter,
} from '../../../filter/MultySelectFilter';
import { DateRange } from '@/components/inputs/DateRange';
import './SMRStatisticsStyle.css';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import {
  SMRStatisticsCurrentButton,
  SMRStatisticsFiltersWrapper,
  SMRStatisticsWrapper,
} from './SMRStatistics.styled';
import { getHeaderColor, SMR_STATISTICS_TABLE_COLUMNS } from './utils';
import { useCatalog } from '@/hooks/CatalogHook';

interface SMRStatisticsProps {
  header: string;
  load: boolean;
  data?: SMRStatisticsTableItem[];
  updateFunction: (taskFilterParam: TaskFilterParam, date: Date, isNow: boolean) => void;
}

export const SMRStatistics: React.FC<SMRStatisticsProps> = ({
  header,
  load,
  data,
  updateFunction,
}) => {
  let filterParam: TaskFilterParam = {};

  const [selectedDate, setSelectedDate] = React.useState<number[] | Date[]>([new Date()]);

  let isNow = false;

  const methods = useForm();
  const { handleSubmit } = methods;
  const { getContractCatalog } = useCatalog((state) => ({
    getContractCatalog: state.getContractCatalog,
  }));
  const contractListResponse = getContractCatalog();
  const [selectedContractList, setSelectedContractList] = React.useState<SelectFilterOptType[]>([]);

  const onSubmitSearch = (data: any) => {
    filterParam = data;
    if (selectedContractList) {
      filterParam.contractIds = getValue(selectedContractList);
    }
    updateFunction(filterParam, new Date(selectedDate[0]), isNow);
  };

  const onResetClick = () => {
    setSelectedContractList([]);
  };

  const detailedInfo = [
    { items: (selectedContractList as unknown as SelectFilterOptType).label, title: 'Контракт' },
  ];

  return (
    <SMRStatisticsWrapper elevation={6}>
      <p className={'headerPageStatistics'}>{header}</p>
      {load ? (
        <LoadSpinner />
      ) : (
        <Grid>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitSearch)}>
              <SMRStatisticsFiltersWrapper>
                <SingleSelectFilter
                  registArgName='contractIdsArg'
                  label='Контракт'
                  minWidth={300}
                  options={contractListResponse}
                  value={selectedContractList}
                  onChange={(sel: any) => setSelectedContractList(sel)}
                />

                <DateRange
                  label={'выбрать дату'}
                  onChange={setSelectedDate}
                  value={selectedDate}
                  argValue={2} // set equel by default
                  showSelect={false} // hide select option
                />

                <FilterButtons
                  info={detailedInfo}
                  onSearchClick={() => {
                    isNow = false;
                    handleSubmit(onSubmitSearch);
                  }}
                  onCloseClick={onResetClick}
                />
                <SMRStatisticsCurrentButton
                  variant='contained'
                  type='submit'
                  onClick={(event) => {
                    isNow = true;
                    handleSubmit(onSubmitSearch);
                  }}
                >
                  Показать текущую статистику
                </SMRStatisticsCurrentButton>
              </SMRStatisticsFiltersWrapper>
            </form>
          </FormProvider>
          <table className={'smr'}>
            <tbody>
              <tr className={'smr'}>
                {SMR_STATISTICS_TABLE_COLUMNS.map((header, index) => (
                  <td
                    key={header}
                    className={'smr'}
                    style={{ backgroundColor: getHeaderColor(index) }}
                  >
                    {header}
                  </td>
                ))}
              </tr>

              {data?.map((item) => (
                <tr key={item.contractorName}>
                  <td
                    className={'smr'}
                    style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
                  >
                    {item.contractorName}
                  </td>
                  <td className={'smr'}>{item.onePhaseCount}</td>
                  <td className={'smr'}>{item.threePhaseCount}</td>
                  <td className={'smr'}>{item.puNotFoundCount}</td>
                  <td className={'smr'}>{item.puIsUsedCount}</td>
                  <td className={'smr'}>{item.stampNotFoundCount}</td>
                  <td className={'smr'}>{item.notCompletedCount}</td>
                  <td className={'smr'}>{item.incorrectMeasurementCount}</td>
                  <td className={'smr'}>{item.failOnePhaseCount}</td>
                  <td className={'smr'}>{item.failThreePhaseCount}</td>
                  <td className={'smr'}>{item.failUnknownPhaseCount}</td>
                  <td className={'smr'}>{item.allOnePhase}</td>
                  <td className={'smr'}>{item.allThreePhase}</td>
                  <td className={'smr'}>{item.allUnknownPhase}</td>
                  <td className={'smr'}>{item.dynamicsOnePhase}</td>
                  <td className={'smr'}>{item.dynamicsThreePhase}</td>
                  <td className={'smr'}>{item.dynamicsUnknownPhase}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Grid>
      )}
    </SMRStatisticsWrapper>
  );
};
export default SMRStatistics;
