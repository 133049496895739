import React, { FC } from 'react';
import fileFolder from '@/images/file/fileFolder.png';
import { FolderImg, FolderWrapper } from '@/components/features/drive/folder/Folder.styled';
import { useNavigate, useParams } from 'react-router-dom';
import { DriveItem } from '@/components/features/drive/utils';
import { useIsTouchDevice } from '@/hooks/useIsTouchDevice';

interface IFolder {
  item: DriveItem;
}

const ResFolder: FC<IFolder> = ({ item }) => {
  const isTouch = useIsTouchDevice();
  const { resGroupId } = useParams();
  const navigate = useNavigate();
  const handleClick = () => navigate(`/registry/drive/${resGroupId}/${item.name}_${item.id}`);

  return (
    <FolderWrapper
      onDoubleClick={!isTouch ? handleClick : undefined}
      onClick={isTouch ? handleClick : undefined}
    >
      <FolderImg src={fileFolder} />
      <div>{item.name}</div>
    </FolderWrapper>
  );
};

export default ResFolder;
