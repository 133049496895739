import { syncErrorCatch } from '@/hooks/ActionLogHook';
import { DropResult } from 'react-beautiful-dnd';
import { usePNRStore } from '../../store';
import shallow from 'zustand/shallow';
import { PnrDndEditModalFormValues } from './PnrDndEditModalForm';
import { HouseFlat, HouseSchemaType } from '@/dto/taskmap/Dto';

export enum UpdateDnDFloorFunc {
  CLEAR = 'clear',
  ADD = 'add',
  BLOCK = 'block',
}
export enum PnrDndEditModalFormType {
  ENTRANCE = 'entrance',
  HOUSE = 'house',
}

export interface ExistedFlat {
  flatNumber: string;
  entranceNumber: number;
  floorNumber: number;
}

export const existedFlatsByNumber = (flatNumber: string) => {
  const layoutData = usePNRStore.getState().layoutData;

  if (!layoutData) return [];
  const existedFlats: ExistedFlat[] = [];
  for (const entrance of layoutData.entrances) {
    if (!entrance.floors) continue;
    for (const floor of entrance.floors) {
      const duplicatedNumber = floor.flats.find((it) => it.number === flatNumber);
      if (duplicatedNumber) {
        existedFlats.push({
          flatNumber,
          entranceNumber: entrance.number,
          floorNumber: floor.number,
        });
      }
    }
  }
  return existedFlats;
};

export const generateExistedFlatsErrorMessage = (existedFlats: ExistedFlat[]) => {
  if (!existedFlats.length) return;
  if (existedFlats.length === 1) {
    syncErrorCatch(
      `Квартира ${existedFlats[0].flatNumber} уже существует. Подъезд: ${existedFlats[0].entranceNumber}, этаж: ${existedFlats[0].floorNumber}`
    );
  }
  if (existedFlats.length > 1) {
    syncErrorCatch('Ошибка добавления нескольких квартир. Подробности в журнале событий', [
      'Существующие квартиры',
      ...existedFlats.map((it) => ({
        ['Номер_квартиры']: it.flatNumber,
        ['Подъезд']: it.entranceNumber,
        ['Этаж']: it.floorNumber,
      })),
    ]);
  }
};

export const useHouseDnDEdit = () => {
  const { layoutData, backendLayoutData, setLayoutData } = usePNRStore(
    (state) => ({
      backendLayoutData: state.backendLayoutData,
      layoutData: state.layoutData,
      setLayoutData: state.setLayoutData,
    }),
    shallow
  );

  const handleDragEnd = (end: DropResult) => {
    if (!layoutData || !end.destination) {
      return;
    }
    const [sourceEntranceNumber, sourceEntranceFloor] = end.source.droppableId
      .split('-')
      .map(Number);
    const sourceEntrance = layoutData.entrances.find(
      (entrance) => entrance.number === sourceEntranceNumber
    );
    const sourceFloor = sourceEntrance?.floors?.find(
      (floor) => floor.number === sourceEntranceFloor
    );

    const [destinationEntranceNumber, destinationEntranceFloor] = end.destination.droppableId
      .split('-')
      .map(Number);
    const destinationEntrance = layoutData.entrances.find(
      (entrance) => entrance.number === destinationEntranceNumber
    );
    const destinationFloor = destinationEntrance?.floors?.find(
      (floor) => floor.number === destinationEntranceFloor
    );

    const flatIndex = sourceFloor?.flats?.findIndex((flat) => flat.number === end.draggableId);

    if (destinationFloor?.isNonResidential) {
      syncErrorCatch('Этаж заблокирован');
    } else {
      if (flatIndex !== undefined && flatIndex !== -1 && sourceFloor) {
        sourceFloor.flats.splice(flatIndex, 1);
      }

      if (destinationFloor) {
        destinationFloor.flats.push({ number: end.draggableId });
      }
    }
    const newLayout = { ...layoutData };
    setLayoutData(JSON.parse(JSON.stringify(newLayout)));
  };

  const updateFloorEntry = (
    entranceNumber: number,
    floorNumber: number,
    updateFunc: UpdateDnDFloorFunc,
    addNumber?: string
  ) => {
    if (!layoutData) return;
    const entrance = layoutData.entrances.find((it) => it.number === entranceNumber);
    if (!entrance) return;
    const floor = entrance.floors?.find((it) => it.number === floorNumber);
    if (!floor) return;
    if (updateFunc === UpdateDnDFloorFunc.ADD && addNumber) {
      floor.flats.push({ number: addNumber });
    }
    if (updateFunc === UpdateDnDFloorFunc.CLEAR) {
      floor.flats = [];
    }
    if (updateFunc === UpdateDnDFloorFunc.BLOCK) {
      floor.flats = [];
      floor.isNonResidential = !floor?.isNonResidential;
    }
    setLayoutData({ ...layoutData });
  };

  const handleHouseEditSubmit = ({
    flatByEntranceRange,
    flatSinceWith,
    floorCount,
  }: PnrDndEditModalFormValues) => {
    if (!layoutData) {
      return;
    }
    let flatNumber = flatSinceWith;

    const allEntrances = layoutData.entrances.map((entrance) => {
      const floors = [];
      for (let i = 1; i <= floorCount; i++) {
        const isNonResidential = layoutData?.entrances
          ?.find((it) => it.number === entrance.number)
          ?.floors?.find((it) => it.number === i)?.isNonResidential;

        let flats: HouseFlat[] = [];
        if (!isNonResidential) {
          flats = Array.from({ length: flatByEntranceRange }, () => {
            const currentFlatNumber = { number: String(flatNumber) };
            flatNumber++;

            return currentFlatNumber;
          });
        }

        floors.push({
          number: i,
          flats,
          isNonResidential: !!isNonResidential,
        });
      }
      return {
        ...entrance,
        floors: floors.sort((a, b) => b.number - a.number),
      };
    });

    setLayoutData({ ...layoutData, entrances: allEntrances });
  };

  const resetDnDModalEditions = () => {
    setLayoutData(
      JSON.parse(
        JSON.stringify(
          backendLayoutData ?? {
            schemaType: HouseSchemaType.CUSTOM,
            entrances: [],
          }
        )
      )
    );
  };

  const handleHouseReset = () => {
    if (!layoutData) {
      return;
    }
    const allEntrances = layoutData.entrances.map((entrance) => ({
      ...entrance,
      floors: entrance.floors
        ? entrance.floors?.map((floor) => ({
            ...floor,
            flats: [],
            isNonResidential: false,
          }))
        : [],
    }));

    setLayoutData({ ...layoutData, entrances: allEntrances });
  };

  return {
    handleDragEnd,
    updateFloorEntry,
    handleHouseEditSubmit,
    resetDnDModalEditions,
    handleHouseReset,
  };
};
