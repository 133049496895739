import React, { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  HousePnrBrowseInfoWrapper,
  HousePnrBrowseInfoBold,
  HousePnrBrowseInfoLoading,
} from './HousePnrBrowse.styled';
import { fetchCatch } from '@/hooks/ActionLogHook';
import { ApiFindHouseAddressChanges } from '@/services/YodaRestService';
import { useMutation } from 'react-query';
import { HouseAdressesChangesDto } from '@/dto/taskmap/Dto';
import { IdPopup } from '@/components/features/taskEditor/panel/IdPopup';

export interface HousePnrBrowseInfoProps {
  id?: string;
}

const HousePnrBrowseInfo = ({ id = '' }: HousePnrBrowseInfoProps) => {
  const [houseAddressChangeInfo, setHouseAddressChangeInfo] = useState<HouseAdressesChangesDto>();

  const mutFindHouseAddressChangeInfo = useMutation<void, any>(() => {
    return ApiFindHouseAddressChanges(id)
      .then(({ data }) => setHouseAddressChangeInfo(data))
      .catch((error) => fetchCatch(error, 'Ошибка во время получения информации об адресе'));
  });

  const handleClick = () => {
    mutFindHouseAddressChangeInfo.mutate();
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IdPopup
        title={
          <InfoOutlinedIcon
            color='primary'
            fontSize='small'
          />
        }
        onClickSideEffect={handleClick}
      >
        <HousePnrBrowseInfoWrapper>
          {!mutFindHouseAddressChangeInfo.isLoading &&
            !houseAddressChangeInfo?.updatedBy &&
            !houseAddressChangeInfo?.mergedBy &&
            !houseAddressChangeInfo?.addresses?.length && (
              <HousePnrBrowseInfoBold style={{ textAlign: 'center' }}>
                Нет информации
              </HousePnrBrowseInfoBold>
            )}
          <HousePnrBrowseInfoLoading loading={mutFindHouseAddressChangeInfo.isLoading}>
            {houseAddressChangeInfo && houseAddressChangeInfo?.updatedBy && (
              <div>
                <HousePnrBrowseInfoBold>Адрес изменен пользователем:</HousePnrBrowseInfoBold>
                <span> {houseAddressChangeInfo?.updatedBy}</span>
              </div>
            )}
          </HousePnrBrowseInfoLoading>
          <HousePnrBrowseInfoLoading loading={mutFindHouseAddressChangeInfo.isLoading}>
            {houseAddressChangeInfo && houseAddressChangeInfo?.mergedBy && (
              <div>
                <HousePnrBrowseInfoBold>Адрес объединен пользователем:</HousePnrBrowseInfoBold>
                <span> {houseAddressChangeInfo?.mergedBy}</span>
              </div>
            )}
          </HousePnrBrowseInfoLoading>
          <HousePnrBrowseInfoLoading loading={mutFindHouseAddressChangeInfo.isLoading}>
            {houseAddressChangeInfo && !!houseAddressChangeInfo?.addresses?.length && (
              <div>
                <HousePnrBrowseInfoBold>Объединенные адреса:</HousePnrBrowseInfoBold>
                <span>{houseAddressChangeInfo?.addresses?.join(', ')}</span>
              </div>
            )}
          </HousePnrBrowseInfoLoading>
        </HousePnrBrowseInfoWrapper>
      </IdPopup>
    </div>
  );
};

export default HousePnrBrowseInfo;
