import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import { SupplyBillFilterParam, SupplyBillTypeResponse } from '@/dto/SupplyBillServiceDto';
import moment from 'moment';
import { IFilterCombo, useStoredFilters } from '@/components/button/SaveButton/store';
import {
  AllInputKeys,
  defaultValueDateInput,
  defaultValueMultiInput,
  defaultValueSingleInputs,
  useSupplyBillBrowseFilter,
} from './store';
import shallow from 'zustand/shallow';
import {
  isObjKey,
  isIFilter,
  isNumberArray,
  isPrimitive,
} from '@/components/filter/FilterButtons/utils';
import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';

export const FILTERS_LABELS = {
  responsables: 'Кому',
  billType: 'Тип накладной',
  billNumber: 'Номер накладной',
  puNumber: 'Номер ПУ',
  billDate: 'Дата создания',
  puTypes: 'Тип ПУ',
  osNumber: 'IMEI СО',
  osTypes: 'Тип СО',
  supplyId: 'Склад',
  billCreatorList: 'Создана кем',
  billEditorList: 'Обновлена кем',
  stampNumber: 'Номер пломбы',
  dateRange: 'Период',
  project: 'Проект',
  releasedWithoutProjectId: 'Bыданные накладные без проекта',
};
export const SINGLE_LINE_ARGS = [
  FilterArgEnum.CONTAIN,
  FilterArgEnum.EQUAL,
  FilterArgEnum.NOT_EQUAL,
];

export const MULTIPLE_ARGS = [FilterArgEnum.IN_LIST, FilterArgEnum.NOT_IN_LIST];

export const DATE_ARGS = [
  FilterArgEnum.EQUAL,
  FilterArgEnum.NOT_EQUAL,
  FilterArgEnum.MORE,
  FilterArgEnum.LESS,
  FilterArgEnum.RANGE,
];

export const RANGE_DATE_ARG = [FilterArgEnum.RANGE];

export const PAGE_SIZE = 45;

export const convertBillTypesIntoFilter = (
  data?: SupplyBillTypeResponse[]
): SelectFilterOptType[] | undefined => {
  return data?.map((elem) => ({ value: `${elem.id}`, label: elem.name, ...elem }));
};

export const transformToLowerCase = (value?: string) => value?.toLowerCase() || '';

export const toSupplyBillFormateDate = (date?: Date | null | number) => {
  return (date && moment(date).format('yyyy-MM-DD')) || '';
};

export const STOREHOUSE_SAVE_FILTER_KEY = 'storehouseBrowse';

export const useStoreHouseFilter = () => {
  const { allFilters } = useStoredFilters();
  const {
    setDateValue,
    setDateValueArg,
    setSingleValue,
    setSingleValueArg,
    setMultiValue,
    setMultiValueArg,
    setDefaultValue,
  } = useSupplyBillBrowseFilter(
    (state) => ({
      setSingleValue: state.setSingleValue,
      setSingleValueArg: state.setSingleValueArg,
      setMultiValue: state.setMultiValue,
      setMultiValueArg: state.setMultiValueArg,
      setDateValue: state.setDateValue,
      setDateValueArg: state.setDateValueArg,
      setDefaultValue: state.setDefaultValue,
    }),
    shallow
  );

  const setValue = (key: AllInputKeys, selectedFilters: IFilterCombo) => {
    const filter = selectedFilters[key];

    if (!filter) return;

    if (isObjKey(key, defaultValueSingleInputs) && isIFilter(filter)) {
      if (!isPrimitive(filter.value)) return;
      setSingleValueArg(key)(filter.argValue);
      setSingleValue(key)(filter.value);
    }

    if (isObjKey(key, defaultValueMultiInput) && isIFilter(filter)) {
      if (isPrimitive(filter.value) || isNumberArray(filter.value)) return;
      setMultiValueArg(key)(filter.argValue);
      setMultiValue(key)(filter.value);
    }

    if (isObjKey(key, defaultValueDateInput) && isIFilter(filter)) {
      if (isPrimitive(filter.value) || !isNumberArray(filter.value)) return;
      setDateValueArg(key)(filter.argValue);
      setDateValue(key)(filter.value);
    }
  };

  const setFilterValues = (filterKey: string) => {
    const selectedFilters = allFilters[STOREHOUSE_SAVE_FILTER_KEY]?.find(
      (filter) => filter.filterName === filterKey
    )?.filters;

    setDefaultValue();
    for (const key in selectedFilters) {
      const allInputKey = key as AllInputKeys;
      setValue(allInputKey, selectedFilters);
    }
  };

  const mapArrayFilters = (filter: SelectFilterOptType[]) => filter.map((el) => el.value);

  const getFilterValues = (): SupplyBillFilterParam => {
    const allState = useSupplyBillBrowseFilter.getState();
    return {
      ...(allState.billNumber.value.length && {
        billNumber: allState.billNumber.value,
        billNumberArg: allState.billNumber.argValue,
      }),
      ...(allState.puNumber.value.length && {
        puNumber: allState.puNumber.value,
        puNumberArg: allState.puNumber.argValue,
      }),

      ...(allState.osNumber.value.length && {
        networkElementImei: allState.osNumber.value,
        networkElementImeiArg: allState.osNumber.argValue,
      }),
      ...(allState.stampNumber.value.length && {
        stampNumber: allState.stampNumber.value.toUpperCase(),
        stampNumberArg: allState.stampNumber.argValue,
      }),
      ...(allState.billDate.value.length && {
        billDate: allState.billDate.value[1]
          ? allState.billDate.value.map((item, index) =>
              toSupplyBillFormateDate(allState.billDate.value[index])
            )
          : toSupplyBillFormateDate(allState.billDate.value[0]),
        billDateArg: allState.billDate.argValue,
      }),
      ...(allState.billType.value.length && {
        billTypeList: mapArrayFilters(allState.billType.value),
        billTypeListArg: allState.billType.argValue,
      }),
      ...(allState.supplyId.value.length && {
        supplyIdList: mapArrayFilters(allState.supplyId.value),
        supplyIdListArg: allState.supplyId.argValue,
      }),
      ...(allState.responsables.value.length && {
        contractorIdList: mapArrayFilters(allState.responsables.value),
        contractorIdListArg: allState.responsables.argValue,
      }),

      ...(allState.puTypes.value.length && {
        puTypeIdList: mapArrayFilters(allState.puTypes.value),
        puTypeIdListArg: allState.puTypes.argValue,
      }),

      ...(allState.osTypes.value.length && {
        networkElementTypeIdList: mapArrayFilters(allState.osTypes.value),
        networkElementTypeIdListArg: allState.osTypes.argValue,
      }),

      ...(allState.billCreatorList.value.length && {
        billCreatorList: mapArrayFilters(allState.billCreatorList.value),
        billCreatorListArg: allState.billCreatorList.argValue,
      }),

      ...(allState.billEditorList.value.length && {
        billEditorList: mapArrayFilters(allState.billEditorList.value),
        billEditorListArg: allState.billEditorList.argValue,
      }),
      ...(allState.projectIdList.value.length && {
        projectIdList: mapArrayFilters(allState.projectIdList.value),
        projectIdListArg: allState.projectIdList.argValue,
      }),
      ...(allState.releasedWithoutProjectId && {
        releasedWithoutProjectId: allState.releasedWithoutProjectId,
      }),
    };
  };

  return { setFilterValues, getFilterValues };
};
