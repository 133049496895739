import { useMatch, useResolvedPath } from 'react-router-dom';
import {
  NavLink,
  NavLinkMain,
  NavLinkMainOut,
  NavLinkOut,
} from '@/components/widgets/Navbar/NavBar.styled';
import React from 'react';
import { color } from '@/styles/mixins';

export enum LinkType {
  ItemLink,
  OutLink,
  OutMainLink,
}

export const CustomLink = ({ to, children, type, ...props }: any) => {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  switch (type) {
    case LinkType.ItemLink:
      return (
        <NavLink
          to={to}
          {...props}
          style={isActive ? { color: color('fontGray') } : {}}
        >
          {children}
        </NavLink>
      );
    case LinkType.OutLink:
      return (
        <NavLinkOut
          href={to}
          target={'_blank'}
        >
          {children}
        </NavLinkOut>
      );
    case LinkType.OutMainLink:
      return (
        <NavLinkMainOut
          href={to}
          target={'_blank'}
        >
          {children}
        </NavLinkMainOut>
      );
    default:
      return (
        <NavLinkMain
          to={to}
          {...props}
          style={{ color: isActive ? color('fontGray') : color('muiBlue') }}
        >
          {children}
        </NavLinkMain>
      );
  }
};
