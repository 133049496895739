import { Box, Grid, LinearProgress } from '@mui/material';
import { ActionType, NetworkResponse } from './UspdDto';
import { useCallback, useRef, useState } from 'react';
import {
  createNewUSPD,
  deleteUSPD,
  findAllNetworkLocationResponse,
  updateUSPD,
} from '@/services/YodaRestService';
import { mapBoundsToGeoBounds } from '@/utils/heplers';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import UspdFilterPanel from './NetworkFilter';
import { useActionLog } from '@/hooks/ActionLogHook';
import MapNetwork from './Map';

export const NetworkMap = () => {
  const [networkResponse, setNetworkResponse] = useState<NetworkResponse[]>();
  const [mapRef, setMapRef] = useState<any>();
  const { setIsInProgress, isInProgress } = useScreenHoldHook();
  const [networkHiddenList, setNetvorkHiddenList] = useState<string[]>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState<NetworkResponse | null>(null);
  const [action, setAction] = useState<ActionType>('default');
  const { fetchCatch } = useActionLog();
  const polygonRef = useRef();

  const reset = () => {
    onLoad(true);
    setAction('default');
    setSelectedPoint(null);
    setIsEditMode(false);
  };

  const onSave = (coverageValue: number) => {
    setIsInProgress(true);
    if (action === 'drag') {
      if (selectedPoint?.id) {
        updateUSPD(selectedPoint.id, {
          latitudeY: selectedPoint.latitudeY,
          longitudeX: selectedPoint.longitudeX,
          coverageRadius: selectedPoint.coverageRadius || 500,
        })
          .then(() => {
            reset();
          })
          .catch((error) => fetchCatch(error))
          .finally(() => setIsInProgress(false));
      } else {
        createNewUSPD({
          latitudeY: selectedPoint?.latitudeY,
          longitudeX: selectedPoint?.longitudeX,
          coverageRadius: selectedPoint?.coverageRadius,
        })
          .then(({ data }) => {
            setSelectedPoint(data);
            const updatedPoints = [...(networkResponse || []), data];
            setNetworkResponse(updatedPoints);
            reset();
          })
          .catch((error) => fetchCatch(error))
          .finally(() => setIsInProgress(false));
      }
    } else {
      if (selectedPoint?.id) {
        updateUSPD(selectedPoint?.id, {
          latitudeY: selectedPoint?.latitudeY,
          longitudeX: selectedPoint?.longitudeX,
          coverageRadius: coverageValue,
        }).then(() => {
          reset();
        });
      } else {
        createNewUSPD({
          latitudeY: selectedPoint?.latitudeY,
          longitudeX: selectedPoint?.longitudeX,
          coverageRadius: coverageValue,
        })
          .then(({ data }) => {
            setSelectedPoint(data);
            const updatedPoints = [...(networkResponse || []), data];
            setNetworkResponse(updatedPoints);
            reset();
          })
          .catch((error) => fetchCatch(error))
          .finally(() => setIsInProgress(false));
      }
    }
  };

  const handleMapRef = (ref: any) => {
    setMapRef(ref);
  };

  const onLoad = (toCleanCache: boolean) => {
    const geoBounds = mapBoundsToGeoBounds(mapRef?.getBounds());
    if (geoBounds) {
      setIsInProgress(true);
      findAllNetworkLocationResponse(
        {
          leftBottom: {
            longitudeX: geoBounds.maxX,
            latitudeY: geoBounds.maxY,
          },
          rightTop: {
            longitudeX: geoBounds.minX,
            latitudeY: geoBounds.minY,
          },
        },
        toCleanCache
      )
        .then((res) => setNetworkResponse(res.data))
        .catch((error) => fetchCatch(error))
        .finally(() => setIsInProgress(false));
    }
  };

  const onChangeVisible = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const checked = event.target.checked;
    if (checked === false) {
      setNetvorkHiddenList([...networkHiddenList, name]);
    } else {
      setNetvorkHiddenList(networkHiddenList.filter((t) => t !== name));
    }
  };
  const handleDragEnd = (e: any, index: number, item: any) => {
    if (isEditMode && selectedPoint?.id === item.id) {
      setAction('drag');
      const coords = e.get('target').geometry.getCoordinates();
      const updatedPoints = networkResponse?.length ? [...networkResponse] : [];
      updatedPoints[index] = { ...item, latitudeY: coords[1], longitudeX: coords[0] };
      setNetworkResponse(updatedPoints);
      setSelectedPoint(updatedPoints[index]);
    }
  };
  const onDelete = (id: number | undefined | null) => {
    if (!id) {
      setSelectedPoint(null);
      setIsEditMode(false);
      const updatedPoints = networkResponse?.filter((el) => el.id !== id);
      setNetworkResponse(updatedPoints);
      return;
    }
    setIsEditMode(true);
    if (isEditMode) {
      deleteUSPD(id)
        .then(() => {
          const deletedPoints = networkResponse?.filter((p) => p.id !== id);
          setNetworkResponse(deletedPoints);
          setSelectedPoint(null);
          onLoad(true);
        })
        .catch((error) => fetchCatch(error))
        .finally(() => setIsEditMode(false));
    }
  };
  const addnewPoint = useCallback(() => {
    setIsEditMode(true);
    setAction('add');
    const center = mapRef?.getCenter();
    const newPoint = {
      latitudeY: center[1],
      longitudeX: center[0],
      coverageRadius: 500,
      type: 'USPD_CREATED_NEW',
      id: null,
    };
    setSelectedPoint(newPoint);
    const updatedPoints = [...(networkResponse?.filter((el) => el.id !== null) || []), newPoint];
    setNetworkResponse(updatedPoints);
  }, [isEditMode, mapRef]);

  return (
    <Box m={2}>
      <Grid
        container
        direction='column'
        justifyContent='flex-start'
        alignItems='stretch'
        spacing={2}
        marginTop={3}
      >
        {isInProgress && <LinearProgress />}
        <Grid
          container
          spacing={1}
        >
          <MapNetwork
            handleMapRef={handleMapRef}
            networkResponse={networkResponse}
            networkHiddenList={networkHiddenList}
            isEditMode={isEditMode}
            handleDragEnd={handleDragEnd}
            setSelectedPoint={setSelectedPoint}
            selectedPoint={selectedPoint}
            setIsEditMode={setIsEditMode}
            polygonRef={polygonRef}
            // setPolygonRef={setPolygonRef}
          />

          <UspdFilterPanel
            onLoad={onLoad}
            mapRef={mapRef}
            networkHiddenList={networkHiddenList}
            onChangeVisible={onChangeVisible}
            addnewPoint={addnewPoint}
            isEditMode={isEditMode}
            setSelectedPoint={setSelectedPoint}
            setIsEditMode={setIsEditMode}
            onDelete={onDelete}
            onSave={onSave}
            setAction={setAction}
            selectedPoint={selectedPoint}
            polygonRef={polygonRef}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
