import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getComparator, stableSort } from '../constants';
import { IRegistryMonitoringEditItem, useRegistryStore } from './store';
import { TableHeader } from './TableHeader';
import { ChangeEvent, useEffect } from 'react';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import IconButton from '@mui/material/IconButton';
import { BoxShadow } from '@/components/ui/BoxShadow';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { ActionLogType, syncErrorCatch, useActionLog } from '@/hooks/ActionLogHook';
import { OnRequestSort } from '../TableCells';
import { findRegistryById } from '@/services/RegistryService';
import { useParams } from 'react-router-dom';
import { deleteFile, deleteFileEntity, uploadFile } from '@/services/FileService';
import { FILE_SMR_TYPE_ID, FILE_SMR_STAGE_ID } from './constants';
import { useRegistryBrowseStore } from '@/components/features/registryBrowse/store';

export default function EnhancedTable({ id }: { id: string }) {
  const params = useParams();
  const { fetchCatch, addActionLog } = useActionLog();
  const {
    registryResultTable,
    orderBy,
    order,
    setOrderBy,
    setOrder,
    addFileById,
    updateTable,
    getEmails,
    emails,
  } = useRegistryStore((state) => ({
    registryResultTable: state.registryResultTable,
    order: state.order,
    orderBy: state.orderBy,
    setRegistryResultTable: state.setRegistryResultTable,
    setOrder: state.setOrder,
    setOrderBy: state.setOrderBy,
    addFileById: state.addFileById,
    updateTable: state.updateTable,
    getEmails: state.getEmails,
    emails: state.emails,
  }));

  const { setRegistryItem } = useRegistryBrowseStore((state) => ({
    setRegistryItem: state.setRegistryItem,
  }));
  useEffect(() => {
    updateTable(id);
    getEmails();
  }, [id]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IRegistryMonitoringEditItem
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      addFileById(e.target.files[0], id);
      reader.onload = function () {
        if (reader.result) {
          uploadFile(id, [FILE_SMR_TYPE_ID], FILE_SMR_STAGE_ID, e.target.files!)
            .then(() => {
              updateTable(id);
              addActionLog(ActionLogType.SUCCESS, 'Файл успешно загружен');
              if (params.id) {
                findRegistryById(params.id)
                  .then((res) => {
                    setRegistryItem(res.data);
                  })
                  .catch((res) => fetchCatch(res, 'Ошибка получения данных реестра'));
              } else {
                syncErrorCatch('Ошибка получения данных реестра: отсутствует ID');
              }
            })
            .catch((res) => fetchCatch(res, 'Ошибка загрузки файла, возможно проблема с форматом'));
        }
      };
    }
  };
  const handleDelete = (id: string) => {
    // deleteFile(fileld)  TODO удаление файлов по одному
    deleteFileEntity(id)
      .then((res) => {
        updateTable(id);
        addActionLog(ActionLogType.SUCCESS, 'Файл успешно удален');
        if (params.id) {
          findRegistryById(params.id)
            .then((res) => {
              setRegistryItem(res.data);
            })
            .catch((res) => fetchCatch('Ошибка получения данных реестра', res));
        } else {
          syncErrorCatch('Ошибка получения данных реестра: отсутствует ID');
        }
      })
      .catch((res) => fetchCatch('Ошибка удаления файла', res));
  };

  return (
    <>
      <BoxShadow
        sx={{
          marginBottom: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {emails.map((elem) => elem.email).join(', ')}
        <IconButton disabled>
          <AddCircleOutlineRoundedIcon />
        </IconButton>
      </BoxShadow>
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby='tableTitle'
            size={'small'}
          >
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort as OnRequestSort}
            />
            <TableBody>
              {stableSort<IRegistryMonitoringEditItem>(
                registryResultTable,
                getComparator(order, orderBy)
              ).map((row) => (
                <TableRow
                  hover
                  role='checkbox'
                  tabIndex={-1}
                  key={row.id}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>{row.managementCompany}</TableCell>
                  <TableCell>
                    {row.fileId ? 'Файл загружен' : null}
                    {row.file.map((elem) => `${elem.name}, `)}
                    <IconButton component='label'>
                      <input
                        onChange={(e) => handleFileChange(e, row.id)}
                        hidden
                        accept='*'
                        type='file'
                      />
                      <UploadFileRoundedIcon fontSize='small' />
                    </IconButton>
                    <IconButton
                      component='label'
                      onClick={() => handleDelete(row.id)}
                    >
                      <DeleteForeverRoundedIcon fontSize='small' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
