import React, { useEffect, useState } from 'react';
import Group from '@/components/features/drive/group/Group';
import Navigation from '@/components/features/drive/navigation/Navigation';
import Search from '@/components/features/drive/search/Search';
import { GetTechPris } from '@/services/DriveService';
import { DriveItem, driveMapToArray } from './utils';
import { Loading } from '@/components/ui/Loading';
import { useActionLog } from '@/hooks/ActionLogHook';
import { DriveWrapper, GroupsWrapper } from './Drive.styled';

const Drive = () => {
  const { fetchCatch } = useActionLog();
  const [value, setValue] = useState<string>('');
  const [data, setData] = useState<DriveItem[]>([]);
  const [filterData, setFilterData] = useState<DriveItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    GetTechPris()
      .then((res) => {
        setData(driveMapToArray(res.data));
        setFilterData(driveMapToArray(res.data));
      })
      .catch((err) => {
        fetchCatch(err, 'Ошибка загрузки');
      })
      .finally(() => setLoading(false));
  }, []);

  const filter = (str: string) => {
    setValue(str);
    setFilterData(data.filter((elem) => elem.name.toLowerCase().includes(str.toLowerCase())));
  };

  return (
    <>
      <Navigation />
      <Search
        value={value}
        onChange={filter}
      />

      <DriveWrapper>
        <Loading loading={loading}>
          <GroupsWrapper>
            {filterData.map((elem) => (
              <Group
                key={elem.id}
                item={elem}
              />
            ))}
          </GroupsWrapper>
        </Loading>
      </DriveWrapper>
    </>
  );
};

export default Drive;
