import { allBreakpointValue } from '@/styles/mixins';
import { styled } from '@mui/material';

interface NavbarLinksWrapper {
  isMobile?: boolean;
}

export const NavbarLinksWrapper = styled('div')<NavbarLinksWrapper>`
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex: 1 1 auto;
  align-items: ${({ isMobile }) => (isMobile ? 'start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  padding: 10px;
  width: 80vw;
  flex-wrap: wrap;

  ${allBreakpointValue('gap', 36, 12, 4, 8)}
`;
