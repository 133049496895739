import { Grid } from '@mui/material';
import React, { FC } from 'react';
import { StatusItem, StatusItemTypes } from './StatusItem';
import { Months } from './constants';
import { useNavigate } from 'react-router-dom';
import { IRegistryBrowse } from './store';
import { Item } from '@/components/features/registryEditor/constants';
import { RegistryBox } from '@/components/features/registryBrowse/Registry.styled';

const Vertical = () => <div style={{ width: '1px', background: 'rgba(0, 0, 0, 0.35)' }} />;

export const RegistryBrowseItem: FC<IRegistryBrowse> = ({
  id,
  month,
  fileCount,
  year,
  fileAttachedCount,
  completed,
}) => {
  const navigate = useNavigate();
  const handleClick = () => navigate(`/registry/browse/${id}`);
  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
    >
      <RegistryBox onClick={handleClick}>
        <Item>
          {Months[month]} {year}
        </Item>
        <Vertical />
        <Item>Реестр №{id}</Item>
        <Vertical />
        <StatusItem
          countAll={fileCount}
          countTmp={fileAttachedCount}
          type={completed ? StatusItemTypes.MESSAGE : StatusItemTypes.EDIT}
        />
      </RegistryBox>
    </Grid>
  );
};
