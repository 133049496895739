import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { KeyString, lookUpExistingEquipment } from './utils';
import { HighlightText, PresentPuWrapper } from './ExistingEquipment.styled';
import { PresentPu } from '@/dto/taskmap/task/TaskResultDto';
import { toFormateDate } from '@/utils/heplers';
import { ExpandButton } from '@/components/button/ExpandButton/ExpandButton';

interface PresentPuEquipmenProps {
  existingEquipment: {
    key: string;
    value: PresentPu;
  };
}
const PresentPuEquipmen = ({ existingEquipment }: PresentPuEquipmenProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      position={'relative'}
      marginY={1}
    >
      <Stack>
        <Typography>
          <HighlightText>
            {' '}
            {lookUpExistingEquipment[existingEquipment.key as KeyString]}:
          </HighlightText>
          {existingEquipment?.value?.type || '-'}
          <HighlightText> №:</HighlightText>
          {existingEquipment?.value?.number}

          <ExpandButton
            onAction={() => setOpen(!open)}
            expand={open}
          />
        </Typography>
        {open && (
          <>
            <Stack
              flexDirection={'row'}
              gap={3}
              mt={2}
            >
              <Stack>
                <PresentPuWrapper>
                  <Typography>Год выпуска:</Typography>
                  <HighlightText>{existingEquipment.value.editionYear ?? '-'}</HighlightText>
                </PresentPuWrapper>
                <PresentPuWrapper>
                  <Typography>Дата поверки:</Typography>
                  <HighlightText>
                    {toFormateDate(existingEquipment?.value?.verificationDate)}
                  </HighlightText>
                </PresentPuWrapper>
                <PresentPuWrapper>
                  <Typography> Следующая поверка:</Typography>
                  <HighlightText>
                    {toFormateDate(existingEquipment?.value?.nextVerificationDate)}
                  </HighlightText>
                </PresentPuWrapper>
              </Stack>

              <Stack>
                <PresentPuWrapper>
                  <Typography>Пломба:</Typography>
                  <HighlightText>{existingEquipment.value.stampNumber ?? '-'}</HighlightText>
                </PresentPuWrapper>
                <PresentPuWrapper>
                  <Typography>Пломба ККИ:</Typography>
                  <HighlightText>{existingEquipment.value.kkiStamp ?? '-'}</HighlightText>
                </PresentPuWrapper>
                <PresentPuWrapper>
                  <Typography>Антимагнитный индикатор:</Typography>
                  <HighlightText>{existingEquipment.value.aiNumber ?? '-'}</HighlightText>
                </PresentPuWrapper>
              </Stack>
            </Stack>
            <Stack>
              <div>
                т1:
                <HighlightText> {existingEquipment?.value?.measurement?.t1 || '-'}</HighlightText>
                т2:
                <HighlightText> {existingEquipment?.value?.measurement?.t2 || '-'} </HighlightText>
                т3:
                <HighlightText> {existingEquipment?.value?.measurement?.t3 || '-'}</HighlightText>
              </div>
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default PresentPuEquipmen;
