import { useInvestStatisticStore } from '../../store';
import { DoughnutLegendItem, DoughnutLegendItemProps } from './DoughnutLegendItem';
import { DoughnutLegendWrapper } from './BudgetStatisticDoughnut.styled';

type DoughnutLegendProps = Pick<DoughnutLegendItemProps, 'onClick' | 'withNartis'>;

export const DoughnutLegend = ({ withNartis, onClick }: DoughnutLegendProps) => {
  const equipmentsData = useInvestStatisticStore((state) => state.equipmentsData);

  return (
    <div style={{ flexGrow: 1, flex: 1 }}>
      <DoughnutLegendWrapper>
        {equipmentsData.map((item, idx) => (
          <DoughnutLegendItem
            key={idx}
            item={item}
            withNartis={withNartis}
            onClick={onClick}
            index={idx}
          />
        ))}
      </DoughnutLegendWrapper>
    </div>
  );
};
