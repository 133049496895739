import { Button, Typography, IconButton, Autocomplete } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import { TextField } from '@/components/fixUI';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { useAllOsScanFields } from '@/hooks/useQuery/useAllSupplyCatalogs';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { convertBillTypesIntoFilter } from '@/components/storehouse/SupplyBillBrowse/utils';
import { ConfirmationDialog } from '@/components/common/ConfirmationDialog';
import {
  SupplyNetworkElementTypeCreateDto,
  SupplyNetworkElementTypeResponse,
  SupplyNetworkElementScanField,
} from '@/dto/SupplyBillServiceDto';
import {
  SupplyBillApiCreateNetworkElementType,
  SupplyBillApiDeleteNetworkElementType,
  SupplyBillApiEditNetworkElementType,
} from '@/services/SupplyBillService';
import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import {
  StorehouseAdminPuWrapper,
  StorehouseAdminCommonModal,
  StorehouseAdminModalInner,
  StorehouseAdminSaveButton,
  StorehouseAdminDeleteAttention,
} from '../StorehouseAdminPu/StorehouseAdminPu.styled';
import { useAllNetworkElementTypes } from '@/hooks/useQuery/useAllNetworkElementTypes';
import { useInvalidateStorehouseTypes } from '../utils';
import { isChildType, useGeneratedYears } from '@/components/storehouse/SupplyPUComponent/utils';

type StorehouseAdmiActiveModal = 'create' | 'edit' | 'delete' | null;
type SelectedEdit = SelectFilterOptType & SupplyNetworkElementTypeResponse;
const ORDER_INCREMENT = 50;

const StorehouseAdminOs = () => {
  const { setIsInProgress, isInProgress } = useScreenHoldHook();
  const { fetchCatch, addActionLog } = useActionLog();
  const { invalidateOsTypes } = useInvalidateStorehouseTypes();

  const { networkElementTypesResponse } = useAllNetworkElementTypes(true);
  const osParentTypesOptions = useMemo(
    () => convertBillTypesIntoFilter(networkElementTypesResponse ?? []),
    [networkElementTypesResponse]
  );

  const { networkElementTypesResponse: networkElementParentsTypesResponse } =
    useAllNetworkElementTypes();
  const osAllTypesOptions = useMemo(
    () => convertBillTypesIntoFilter(networkElementParentsTypesResponse ?? []),
    [networkElementParentsTypesResponse]
  );

  const { scanFieldsOptions } = useAllOsScanFields();

  const [activeModal, setActiveModal] = useState<StorehouseAdmiActiveModal>(null);
  const [name, setName] = useState('');
  const [fullName, setFullName] = useState('');
  const [comment, setComment] = useState('');
  const [manufactureYear, setManufactureYear] = useState<SelectFilterOptType | null>();
  const [sortOrder, setSortOrder] = useState('');
  const [regExp, setRegExp] = useState('');
  const [selectedScanField, setSelectedScanField] = useState<SelectFilterOptType>();
  const [selectedEditType, setSelectedEditType] = useState<SelectedEdit | undefined>();
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [selectedParentType, setSelectedParentType] = useState<SelectFilterOptType>();

  const yearsOptions = useGeneratedYears();

  const handleOpenModal = (type: StorehouseAdmiActiveModal) => {
    setActiveModal(type);

    if (type === 'create') {
      const maxOrder = Math.max(
        ...((osAllTypesOptions as SelectedEdit[])?.map((option) => option.sortBy) ?? [0])
      );
      const nextOrder = maxOrder + ORDER_INCREMENT;

      setSortOrder(nextOrder.toString());
    }
  };
  const handleCloseModal = () => {
    setActiveModal(null);
    resetState();
  };

  const resetState = () => {
    setName('');
    setFullName('');
    setComment('');
    setManufactureYear(undefined);
    setRegExp('');
    setSelectedScanField(undefined);
    setSelectedParentType(undefined);
    setSortOrder('');
    setSelectedEditType(undefined);
    setDeleteAlertOpen(false);
  };

  const handleSave = (saveType: 'create' | 'edit') => {
    if (!selectedScanField?.value) return;

    setIsInProgress(true);
    const osType: SupplyNetworkElementTypeCreateDto = {
      id: selectedEditType?.id ?? null,
      name: name,
      fullName: fullName,
      comment: comment,
      manufactureYear: manufactureYear ? manufactureYear?.value : null,
      regexExpr: null,
      typeScanField: selectedScanField?.value as SupplyNetworkElementScanField,
      parentId: selectedParentType ? parseInt(selectedParentType?.value) : null,
      sortBy: parseInt(sortOrder),
    };

    let apiCall = SupplyBillApiCreateNetworkElementType;

    if (saveType === 'edit') {
      apiCall = SupplyBillApiEditNetworkElementType;
    }

    apiCall(osType)
      .then(() =>
        addActionLog(
          ActionLogType.SUCCESS,
          `Тип СО ${name} успешно ${saveType === 'create' ? 'создан' : 'отредактирован'}`
        )
      )
      .catch((err) =>
        fetchCatch(err, `Ошибка ${saveType === 'create' ? 'создания' : 'редактирования'} типа СО`)
      )
      .finally(() => {
        setIsInProgress(false);
        handleCloseModal();
        invalidateOsTypes();
      });
  };

  const handleDelete = () => {
    if (!selectedEditType?.id) return;

    setIsInProgress(true);

    SupplyBillApiDeleteNetworkElementType(selectedEditType?.id)
      .then(() => addActionLog(ActionLogType.SUCCESS, `Тип СО ${name} успешно удален`))
      .catch((err) => fetchCatch(err, 'Ошибка удаления типа СО'))
      .finally(() => {
        setIsInProgress(false);
        handleCloseModal();
        setDeleteAlertOpen(false);
        invalidateOsTypes();
      });
  };

  const handleOpenDeleteAlert = () => {
    setDeleteAlertOpen(true);
  };

  const handleSelectEditType = (type: SelectedEdit) => {
    setSelectedEditType(type);

    setName(type.name);
    setFullName(type.fullName);
    setComment(type.comment);

    const manufacturerYearOption = yearsOptions?.find(
      (option) => option?.value === type?.manufactureYear
    );
    if (manufacturerYearOption) {
      setManufactureYear(manufacturerYearOption);
    } else {
      setManufactureYear(undefined);
    }

    setRegExp(type?.numRegexExpr ?? '');

    const scanFieldOption = scanFieldsOptions?.find(
      (option) => option?.value === type?.typeScanField
    );
    if (scanFieldOption) {
      setSelectedScanField(scanFieldOption);
    } else {
      setSelectedScanField(undefined);
    }

    const parentTypeOption = osParentTypesOptions?.find(
      (option) => parseInt(option?.value) === type?.parentId
    );

    if (parentTypeOption) {
      setSelectedParentType(parentTypeOption);
    } else {
      setSelectedParentType(undefined);
    }

    setSortOrder(type?.sortBy.toString());
  };

  const childType = useMemo(() => isChildType(name), [name]);

  useEffect(() => {
    if (!childType) {
      setSelectedParentType(undefined);
    }
  }, [childType]);

  const disableCreate =
    !name?.length ||
    !fullName?.length ||
    !selectedScanField ||
    (childType && !selectedParentType) ||
    !sortOrder.length;

  return (
    <>
      <StorehouseAdminPuWrapper>
        <Typography
          variant='h6'
          align='center'
        >
          Типы СО
        </Typography>
        <Button
          variant='contained'
          onClick={() => handleOpenModal('create')}
        >
          Создание
        </Button>
        <Button
          variant='contained'
          onClick={() => handleOpenModal('edit')}
        >
          Редактирование
        </Button>
        <Button
          variant='contained'
          color='error'
          onClick={() => handleOpenModal('delete')}
          disabled
        >
          Удаление
        </Button>
      </StorehouseAdminPuWrapper>

      <StorehouseAdminCommonModal
        open={!!activeModal}
        onClose={handleCloseModal}
      >
        <StorehouseAdminModalInner>
          {activeModal === 'create' && (
            <>
              <Typography
                variant='h6'
                align='center'
              >
                Создание типа сетевого оборудования
              </Typography>

              <TextField
                value={name}
                label={'Наименование'}
                onChange={(e) => setName(e.target.value)}
                variant='standard'
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size={'small'}
                      onClick={() => setName('')}
                    >
                      <ClearRoundedIcon fontSize={'small'} />
                    </IconButton>
                  ),
                }}
              />
              <TextField
                value={fullName}
                label={'Полное наименование'}
                onChange={(e) => setFullName(e.target.value)}
                variant='standard'
                inputProps={{ maxLength: 200 }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size={'small'}
                      onClick={() => setFullName('')}
                    >
                      <ClearRoundedIcon fontSize={'small'} />
                    </IconButton>
                  ),
                }}
              />
              <TextField
                value={comment}
                label={'Комментарий'}
                onChange={(e) => setComment(e.target.value)}
                variant='standard'
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size={'small'}
                      onClick={() => setComment('')}
                    >
                      <ClearRoundedIcon fontSize={'small'} />
                    </IconButton>
                  ),
                }}
              />
              <Autocomplete
                options={yearsOptions ?? []}
                value={manufactureYear}
                onChange={(event: any, newValue: any) => setManufactureYear(newValue)}
                renderInput={(params) => (
                  <TextField
                    name='selectedScanField'
                    {...params}
                    label={'Год выпуска'}
                    variant='standard'
                  />
                )}
              />
              <TextField
                disabled
                value={regExp}
                label={'Регулярное выражение'}
                onChange={(e) => setRegExp(e.target.value)}
                variant='standard'
              />

              <Autocomplete
                options={scanFieldsOptions ?? []}
                value={selectedScanField}
                onChange={(event: any, newValue: any) => setSelectedScanField(newValue)}
                renderInput={(params) => (
                  <TextField
                    name='selectedScanField'
                    {...params}
                    label={'Сканируемое поле'}
                    variant='standard'
                  />
                )}
              />

              {childType && (
                <Autocomplete
                  options={osParentTypesOptions ?? []}
                  value={selectedParentType}
                  onChange={(event: any, newValue: any) => setSelectedParentType(newValue)}
                  renderInput={(params) => (
                    <TextField
                      name='selectedParentType'
                      {...params}
                      label={'Родительский тип'}
                      variant='standard'
                    />
                  )}
                />
              )}

              <TextField
                value={sortOrder}
                label={'Порядок сортировки'}
                onChange={(e) => setSortOrder(e.target.value.trim())}
                variant='standard'
              />

              <StorehouseAdminSaveButton
                variant='contained'
                onClick={() => handleSave('create')}
                loading={isInProgress}
                disabled={disableCreate}
              >
                Создать
              </StorehouseAdminSaveButton>
            </>
          )}
          {activeModal === 'edit' && (
            <>
              <Typography
                variant='h6'
                align='center'
              >
                Редактирование типа сетевого оборудования
              </Typography>

              <Autocomplete
                options={(osAllTypesOptions as SelectedEdit[]) ?? []}
                value={selectedEditType}
                onChange={(event: any, newValue: any) => handleSelectEditType(newValue)}
                renderInput={(params) => (
                  <TextField
                    name='setSelectedEditType'
                    {...params}
                    label={'Тип сетевого оборудования'}
                    variant='standard'
                  />
                )}
                getOptionLabel={(option: SelectedEdit) =>
                  `${option.label} | Сортировка: ${option.sortBy}`
                }
              />

              {selectedEditType && (
                <>
                  <TextField
                    value={name}
                    label={'Наименование'}
                    onChange={(e) => setName(e.target.value)}
                    variant='standard'
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          size={'small'}
                          onClick={() => setName('')}
                        >
                          <ClearRoundedIcon fontSize={'small'} />
                        </IconButton>
                      ),
                    }}
                  />
                  <TextField
                    value={fullName}
                    label={'Полное наименование'}
                    onChange={(e) => setFullName(e.target.value)}
                    variant='standard'
                    inputProps={{ maxLength: 200 }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          size={'small'}
                          onClick={() => setFullName('')}
                        >
                          <ClearRoundedIcon fontSize={'small'} />
                        </IconButton>
                      ),
                    }}
                  />
                  <TextField
                    value={comment}
                    label={'Комментарий'}
                    onChange={(e) => setComment(e.target.value)}
                    variant='standard'
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          size={'small'}
                          onClick={() => setComment('')}
                        >
                          <ClearRoundedIcon fontSize={'small'} />
                        </IconButton>
                      ),
                    }}
                  />
                  <Autocomplete
                    options={yearsOptions ?? []}
                    value={manufactureYear}
                    onChange={(event: any, newValue: any) => setManufactureYear(newValue)}
                    renderInput={(params) => (
                      <TextField
                        name='selectedScanField'
                        {...params}
                        label={'Год выпуска'}
                        variant='standard'
                      />
                    )}
                  />
                  <TextField
                    disabled
                    value={regExp}
                    label={'Регулярное выражение'}
                    onChange={(e) => setRegExp(e.target.value)}
                    variant='standard'
                  />

                  <Autocomplete
                    options={scanFieldsOptions ?? []}
                    value={selectedScanField}
                    disabled
                    onChange={(event: any, newValue: any) => setSelectedScanField(newValue)}
                    renderInput={(params) => (
                      <TextField
                        name='selectedScanField'
                        {...params}
                        label={'Сканируемое поле'}
                        variant='standard'
                      />
                    )}
                  />

                  {childType && (
                    <Autocomplete
                      options={osParentTypesOptions ?? []}
                      value={selectedParentType}
                      onChange={(event: any, newValue: any) => setSelectedParentType(newValue)}
                      getOptionDisabled={(option) =>
                        (option as SelectFilterOptType).value === selectedEditType.value
                      }
                      renderInput={(params) => (
                        <TextField
                          name='selectedParentType'
                          {...params}
                          label={'Родительский тип'}
                          variant='standard'
                        />
                      )}
                    />
                  )}

                  <TextField
                    value={sortOrder}
                    label={'Порядок сортировки'}
                    onChange={(e) => setSortOrder(e.target.value.trim())}
                    variant='standard'
                  />

                  <StorehouseAdminSaveButton
                    variant='contained'
                    onClick={() => handleSave('edit')}
                    loading={isInProgress}
                    disabled={disableCreate}
                  >
                    Сохранить
                  </StorehouseAdminSaveButton>
                </>
              )}
            </>
          )}
          {activeModal === 'delete' && (
            <>
              <Typography
                variant='h6'
                align='center'
              >
                Удаление типа сетевого оборудования
              </Typography>
              <Autocomplete
                options={osAllTypesOptions ?? []}
                value={selectedEditType}
                onChange={(event: any, newValue: any) => setSelectedEditType(newValue)}
                renderInput={(params) => (
                  <TextField
                    name='setSelectedEditType'
                    {...params}
                    label={'Тип сетевого оборудования'}
                    variant='standard'
                  />
                )}
              />

              {selectedEditType && (
                <StorehouseAdminSaveButton
                  variant='contained'
                  onClick={handleOpenDeleteAlert}
                >
                  Удалить
                </StorehouseAdminSaveButton>
              )}

              <ConfirmationDialog
                title={`Внимание!`}
                handleOk={() => handleDelete()}
                handleCancel={() => setDeleteAlertOpen(false)}
                open={deleteAlertOpen}
                textForOk='Всё равно удалить'
                textForCancel='Отмена'
              >
                <Typography variant='body1'>
                  Вы намереваетесь{' '}
                  <StorehouseAdminDeleteAttention>удалить</StorehouseAdminDeleteAttention> тип СО:{' '}
                  <StorehouseAdminDeleteAttention>
                    {selectedEditType?.name}
                  </StorehouseAdminDeleteAttention>
                  . Отменить действие будет{' '}
                  <StorehouseAdminDeleteAttention>невозможно</StorehouseAdminDeleteAttention>!
                </Typography>

                <Typography variant='body1'>
                  Рекомендуется производить удаление только со свежесозданными типами СО, операции
                  над которыми еще не производились на складах
                </Typography>
              </ConfirmationDialog>
            </>
          )}
        </StorehouseAdminModalInner>
      </StorehouseAdminCommonModal>
    </>
  );
};

export default StorehouseAdminOs;
