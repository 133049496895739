import React, { FC, useState } from 'react';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { Button, Grid } from '@mui/material';
import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import { pillarDeliveryPointToSmr } from '@/services/TaskService';

interface PillarDeliveryPointToSmrButtonProps {
  transStationId: string;
}

export const PillarDeliveryPointToSmrButton: FC<PillarDeliveryPointToSmrButtonProps> = ({
  transStationId,
}) => {
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const { fetchCatch, addActionLog } = useActionLog();

  const onSubmit = () => {
    setIsLoading(true);
    pillarDeliveryPointToSmr(transStationId) // TODO: change api
      .then(({ data }) => {
        addActionLog(ActionLogType.SUCCESS, `Точки поставок переведены в СМР`);
      })
      .catch((err) => fetchCatch(err, `Ошибка перевода точк поставок в СМР`))
      .finally(() => {
        setIsOpenPopup(false);
        setIsLoading(false);
      });
  };

  return (
    <Grid
      item
      xs={12}
    >
      <Button onClick={() => setIsOpenPopup(true)}>{`Перевести точки поставок в СМР`}</Button>
      <AccessPopup
        onSuccess={onSubmit}
        isOpen={isOpenPopup}
        onClose={() => setIsOpenPopup(false)}
        successText={'Да'}
        headerText={`Перевести все точки поставки из ППО в СМР?`}
        // titleText={'Действие невозможно отменить. Продолжить?'}
        isLoading={isLoading}
      />
    </Grid>
  );
};
