import { useState } from 'react';
import { Placemark } from 'react-yandex-maps';
import { TaskHintAttributeList, TaskNetworkEquipmentHintAttributeList } from '@/dto/taskmap/Dto';
import {
  ApiFindTaskHintByTaskId,
  ApiFindTaskHintNetworkEquipmentByTaskId,
} from '@/services/YodaRestService';
import React from 'react';
import { usePnr } from '@/components/maps/pnrMap/hooks/PnrHook';
import { GeoCircle, useMap } from '@/components/features/pnr/hooks/MapHook';
import { useGeoCard } from '@/components/maps/pnrMap/hooks/GeoCardHook';
import { GeoPoint, GeoPointType } from '@/components/maps/GeoPoint';
import { loadSelectedAttributeList } from '../../manager/MapPnrSetting';
import { useCatalog } from '@/hooks/CatalogHook';

function getColorByStatus(status: string) {
  switch (status) {
    case 'check':
      return 'yellow';
    case 'in_work':
      return 'blue';
    case 'assigned':
      return 'gray';
    case 'completed':
      return 'green';
    case 'not_relevant':
      return 'dark';
    default:
      return 'black';
  }
}

function toDefaultHintHtml(geoPoint: GeoPoint, taskGroupMap: Map<string, string>): string {
  if (geoPoint.getType().valueOf() == GeoPointType.FREE_POINT) {
    return '';
  }
  const geoTask: any = geoPoint?.getObject();
  if (geoPoint.getType().valueOf() == GeoPointType.PPO_POINT) {
    return `<div>№ Заявки: ${geoTask.number}</div>
        <a target='_blank' href='/task/edit/${geoTask.taskId}'>редактировать</a>`;
  }
  if (geoTask) {
    return `<div>№: ${geoTask.number} <a target='_blank' href='/task/edit/${
      geoTask.taskId
    }'>редактировать</a></div>
    
        <div>Дог:  ${geoTask.customerId}</div>
        <div>Отв.: ${taskGroupMap.get(geoTask.contractorId)}</div>
        <div>Исп:  ${taskGroupMap.get(geoTask.executorId)}</div>`;
  }
  return '';
}
function toTaskHintHtml(obj: any) {
  if (obj) {
    const attributeList: string[] = loadSelectedAttributeList();
    return Object.keys(obj)
      .filter((t: string) => attributeList.indexOf(t) > -1)
      .map((t: string) => {
        return ` <div>
            ${TaskHintAttributeList[t]}: ${obj[t]}
          </div>`;
      })
      .join('');
  }
  return '';
}

function toTaskNetworkEquipmentHintHtml(obj: any) {
  let result = '';
  if (obj) {
    for (const networkHint of obj) {
      result =
        result +
        Object.keys(networkHint)
          .map((t: string) => {
            return ` <div>
            ${TaskNetworkEquipmentHintAttributeList[t]}: ${networkHint[t]}
          </div>`;
          })
          .join('') +
        '<hr/>';
    }
  }
  return result;
}

export const TaskPlacemark = (props: any) => {
  let placemarkRef: any;
  const geoPoint: GeoPoint = props.geoPoint;
  const [hintHtml, setHintHtml] = useState('');
  const { addGeoCircle, getGeoCirctlByGeoTask, getAllGeoCircle } = useMap();
  const { addToGeoCard, geoCardIndexOf, updateGeoCardItem } = useGeoCard();
  const { isTaskTagged } = usePnr();
  const { getGroupCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
  }));

  const getOptions = (geoPoint: GeoPoint) => {
    const position = geoCardIndexOf(geoPoint);
    let preset = 'islands#blueStretchyIcon';
    const geoObject = geoPoint.getObject();
    const isFreePoint = geoPoint.getType().valueOf() == GeoPointType.FREE_POINT;
    if (isFreePoint) {
      preset = 'islands#violetCircleDotIcon';
    } else {
      if (geoObject) {
        if (position > -1) {
          preset = 'islands#blueStretchyIcon';
        } else if (geoObject.type.toUpperCase() === 'NETWORKELEMENTS') {
          preset = 'islands#blueStarCircleIcon';
        } else {
          preset = isTaskTagged(geoObject.taskId) ? 'islands#blueInfoIcon' : 'twirl#yellowIcon';
        }
      }
    }
    return {
      preset: preset,
      iconColor: getColor(geoPoint),
      draggable: isFreePoint,
    };
  };

  const handleRef = (ref: any) => {
    placemarkRef = ref;
  };

  const getColor = (geoPoint: GeoPoint): string => {
    const geoCircles: GeoCircle[] = getAllGeoCircle();
    const geoTask = geoPoint.getObject();
    const geoPointColor = geoPoint.getColor && geoPoint.getColor();
    if (geoTask) {
      if (geoPointColor) {
        return geoPointColor;
      }
      if (
        geoCircles &&
        geoTask &&
        geoCircles.filter((t) => t.coveredList?.indexOf(geoTask.taskId) > -1)?.length > 0
      ) {
        return 'red';
      }
      return getColorByStatus(geoTask.status);
    }
    return 'pink';
  };

  const addToCard = () => {
    if (geoPoint) {
      addToGeoCard(geoPoint);
      addGeoCircle(geoPoint, geoPoint.getCoords(), 0);
    }
  };

  const findHint = (geoPoint: GeoPoint, toShowBalloon: boolean) => {
    const taskId = geoPoint?.getObject()?.taskId;
    if (!hintHtml && taskId) {
      if (geoPoint && geoPoint.getObject()?.type.toUpperCase() === 'NETWORKELEMENTS') {
        ApiFindTaskHintNetworkEquipmentByTaskId(taskId)
          .then((res: any) => {
            const val = toTaskNetworkEquipmentHintHtml(res.data);
            const hint =
              val && val.length > 0 ? val : toDefaultHintHtml(geoPoint, getGroupCatalog());
            setHintHtml(hint);
            if (toShowBalloon) {
              placemarkRef?.balloon.open();
            }
          })
          .catch((err: any) => console.log(err.response.data));
      } else {
        ApiFindTaskHintByTaskId(taskId)
          .then((res: any) => {
            const val = toTaskHintHtml(res.data);
            const hint =
              val && val.length > 0 ? val : toDefaultHintHtml(geoPoint, getGroupCatalog());
            setHintHtml(hint);
            if (toShowBalloon) {
              placemarkRef?.balloon.open();
            }
          })
          .catch((err: any) => console.log(err.response.data));
      }
    }
  };

  const loadHintByClick = () => {
    if (geoPoint && geoPoint?.getType().valueOf() != GeoPointType.FREE_POINT) {
      findHint(geoPoint, true);
    }
  };

  const loadHintByHover = () => {
    if (geoPoint && geoPoint?.getType().valueOf() != GeoPointType.FREE_POINT) {
      findHint(geoPoint, false);
    }
  };
  const getIndexPosition = () => {
    if (geoPoint) {
      const index = geoCardIndexOf(geoPoint);
      return index > -1 ? index + 1 : '';
    }
  };

  const onDragEnd = (event: any) => {
    if (geoPoint) {
      const geoCircle: GeoCircle | undefined = getGeoCirctlByGeoTask(geoPoint);
      const coords = event.get('target').geometry.getCoordinates();
      geoPoint.setCoords(coords);
      updateGeoCardItem(geoPoint);
      addGeoCircle(geoPoint, coords, geoCircle?.radius || 0);
    }
  };

  return (
    <>
      {geoPoint && (
        <Placemark
          instanceRef={(ref: any) => ref && handleRef(ref)}
          geometry={geoPoint.getCoords()}
          properties={{
            balloonPanelMaxMapArea: 0,
            balloonContentBody: hintHtml,
            hintContent: hintHtml,
            geoTask: geoPoint.getObject(),
            iconContent: getIndexPosition(),
          }}
          options={getOptions(geoPoint)}
          onContextMenu={addToCard}
          onHover={loadHintByHover}
          onClick={loadHintByClick}
          onDragEnd={onDragEnd}
        />
      )}
    </>
  );
};
