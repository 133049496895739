import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { IComplaintItem } from '../types';
import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  ComplaintInfoHeader,
  ComplaintInfoRound,
  ComplaintInfoStage,
} from '../ComplaintInfo/ComplaintInfo.styled';
import { NO_CALL_COUNT, tariffOptions } from '../utils';
import CloseIcon from '@mui/icons-material/Close';
import {
  complaintMissedCall,
  downloadComplaintImage,
  editComplaintEnergy,
  energyAfterCallCenter,
} from '@/services/ComplaintService/ComplaintService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useComplaintStore } from '../store';
import shallow from 'zustand/shallow';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { useEffect } from 'react';
import { ECallCenterResult, useAllCallcenterResults } from './utils';
import { CallCenterModalFileInput, CallCenterModalLoading } from './CallCenterModal.styled';
import React from 'react';
import { useComplaintsEditAccess } from '../roles';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';
import MissedCall from '../ComplaintInfo/MissedCallComponent';

const CallCenterModal = ({
  selectedCompliant,
  borderColor,
}: {
  selectedCompliant: IComplaintItem;
  borderColor: string;
}) => {
  const hasAccessToEdit = useComplaintsEditAccess();
  const isMobile = useMobileSize();

  const methods = useForm();
  const { register, setValue, handleSubmit, control } = methods;
  const { addActionLog, fetchCatch } = useActionLog();

  const { setSelectedCompliant, fetchSingleComplaint, handleSearch } = useComplaintStore(
    (state) => ({
      setSelectedCompliant: state.setSelectedCompliant,
      fetchSingleComplaint: state.fetchSingleComplaint,
      handleSearch: state.handleSearch,
    }),
    shallow
  );
  const { setIsInProgress, isInProgress } = useScreenHoldHook();
  const { data: callCenterResults, isLoading: isCallCenterResultsLoading } =
    useAllCallcenterResults();

  const handleDownloadImgFileInput = (imageId: number | null | string) =>
    downloadComplaintImage(imageId);

  const onSubmit = (data: FieldValues) => {
    setIsInProgress(true);
    editComplaintEnergy(data, selectedCompliant.complaint.base.id)
      .then(({ data }) => {
        addActionLog(ActionLogType.SUCCESS, 'Успешно');
        fetchSingleComplaint(selectedCompliant.complaint.base.id, selectedCompliant.complaint.type);
      })
      .catch((error) => fetchCatch(error))
      .finally(() => setIsInProgress(false));
  };

  const handleCallCenter = (stage: ECallCenterResult) => {
    setIsInProgress(true);
    if (stage === ECallCenterResult.NO_CALL) {
      complaintMissedCall(selectedCompliant.complaint.base.id)
        .then(({ data }) => {
          if (data) {
            addActionLog(ActionLogType.SUCCESS, `Недозвон: ${data}`);
            if (data === NO_CALL_COUNT) {
              fetchSingleComplaint(
                selectedCompliant.complaint.base.id,
                selectedCompliant.complaint.type
              );
            }
          } else {
            handleSearch();
          }
          setSelectedCompliant(null);
        })
        .catch((error) => fetchCatch(error))
        .finally(() => setIsInProgress(false));

      return;
    }
    const data = { callCenterResultType: stage };
    energyAfterCallCenter(selectedCompliant.complaint.base.id, data)
      .then(() => {
        addActionLog(ActionLogType.SUCCESS, 'Успешно');
        fetchSingleComplaint(selectedCompliant.complaint.base.id, selectedCompliant.complaint.type);
      })
      .catch((error) => fetchCatch(error))
      .finally(() => setIsInProgress(false));
  };

  useEffect(() => {
    if (selectedCompliant) {
      setValue('responseTime', selectedCompliant?.responseDate);
      setValue('energyType', selectedCompliant?.energyType?.name);
      setValue('tariffication', selectedCompliant?.tariffication);
      setValue('res', selectedCompliant?.res);
      setValue('classification', selectedCompliant?.classification?.name);
      setValue('sapNumber', selectedCompliant.sapNumber);
    }
  }, [selectedCompliant]);
  return (
    <Stack
      overflow={'auto'}
      maxHeight={'80vh'}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            spacing={1}
          >
            <ComplaintInfoHeader>
              <ComplaintInfoStage>
                <ComplaintInfoRound color={borderColor} />
                <span>{selectedCompliant.complaint.stage.title}</span>
              </ComplaintInfoStage>

              <Typography variant='subtitle2'>{selectedCompliant?.sapNumber}</Typography>

              <IconButton onClick={() => setSelectedCompliant(null)}>
                <CloseIcon />
              </IconButton>
            </ComplaintInfoHeader>
          </Grid>
        </Grid>
        <Divider />

        <Grid
          container
          mt={2}
          xs={12}
          md={12}
        >
          <Grid
            item
            xs={12}
            md={8}
            container
            spacing={2}
          >
            <MissedCall />
            <Grid
              item
              xs={12}
              md={12}
            >
              <Controller
                name='declarerFio'
                control={control}
                defaultValue={selectedCompliant?.declarerFio}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label='ФИО заявителя'
                    disabled
                    variant='standard'
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Controller
                name='account'
                control={control}
                defaultValue={selectedCompliant?.account}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label='Лицевой счет'
                    disabled
                    variant='standard'
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Controller
                name='phoneNumber'
                defaultValue={selectedCompliant?.phoneNumber}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    placeholder='+7'
                    disabled
                    label='Телефон'
                    variant='standard'
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Controller
                name='phoneNumberAdditional'
                defaultValue={selectedCompliant?.phoneNumberAdditional}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder='+7'
                    fullWidth
                    label='Доп. телефон'
                    variant='standard'
                    disabled={!hasAccessToEdit}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Controller
                name='meterType'
                control={control}
                defaultValue={selectedCompliant?.meterType}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label='Тип ПУ'
                    variant='standard'
                    disabled
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Controller
                name='meterNumber'
                control={control}
                defaultValue={selectedCompliant?.complaint.meterNumber}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    disabled
                    label='№ прибора учета'
                    variant='standard'
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Controller
                name='tariffication'
                control={control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    fullWidth
                    options={tariffOptions}
                    value={{
                      value: field?.value ?? '',
                      label:
                        tariffOptions.find(
                          (el) =>
                            el.value === field?.value ||
                            el.value === selectedCompliant?.tariffication
                        )?.label || '',
                    }}
                    disabled
                    onChange={(event: any, newValue: any) => {
                      field?.onChange(newValue?.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        name='tariffication'
                        {...params}
                        label={'Тарификация'}
                        variant='standard'
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              container
              mt={2}
              spacing={1}
            >
              <Grid
                item
                xs={12}
                md={4}
              >
                <Controller
                  name='address.region'
                  control={control}
                  rules={{ required: 'Обязательно для заполнения' }}
                  defaultValue={selectedCompliant?.complaint.address.region || null}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label='Регион'
                      variant='standard'
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      disabled={!hasAccessToEdit}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
              >
                <Controller
                  name='address.area'
                  control={control}
                  defaultValue={selectedCompliant?.complaint.address.area || null}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label='Район'
                      variant='standard'
                      disabled={!hasAccessToEdit}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
              >
                <Controller
                  name='address.locality'
                  control={control}
                  rules={{ required: 'Обязательно для заполнения' }}
                  defaultValue={selectedCompliant?.complaint.address.locality}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label='Населеный пункт'
                      variant='standard'
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      disabled={!hasAccessToEdit}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
              >
                <Controller
                  name='address.street'
                  control={control}
                  defaultValue={selectedCompliant?.complaint.address.street || null}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label='Улица'
                      variant='standard'
                      disabled={!hasAccessToEdit}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
              >
                <Controller
                  name='address.house'
                  control={control}
                  rules={{ required: 'Обязательно для заполнения' }}
                  defaultValue={selectedCompliant?.complaint.address.house}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label='Дом'
                      variant='standard'
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      disabled={!hasAccessToEdit}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
              >
                <Controller
                  name='address.apartment'
                  control={control}
                  defaultValue={selectedCompliant?.complaint.address.apartment || null}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label='Кврартира'
                      variant='standard'
                      disabled={!hasAccessToEdit}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
            >
              <Controller
                name='text'
                control={control}
                defaultValue={selectedCompliant?.complaint.text}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label='Текст обращения'
                    multiline
                    disabled
                    rows={4}
                    variant='standard'
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
            >
              <FormProvider {...methods}>
                <CallCenterModalFileInput
                  register={register('files')}
                  setFormFiles={setValue}
                  filesFromBackend={selectedCompliant?.files}
                  filesFromBackendGetFunction={handleDownloadImgFileInput}
                  disableEdit
                />
              </FormProvider>
            </Grid>
          </Grid>
          <Grid
            mt={isMobile ? 1 : 0}
            item
            xs={12}
            md={4}
            container
            spacing={isMobile ? 2 : 1}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {isCallCenterResultsLoading ? (
              <CallCenterModalLoading loading />
            ) : (
              <>
                {callCenterResults?.data?.map((result) => (
                  <Grid
                    item
                    key={result.name}
                  >
                    <Button
                      size='small'
                      style={{ minWidth: 180 }}
                      variant='outlined'
                      fullWidth
                      onClick={() => handleCallCenter(result.name)}
                      disabled={!hasAccessToEdit}
                    >
                      {result.title}
                    </Button>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          md={8}
          mt={4}
        >
          <Grid
            item
            xs={6}
          >
            <Button
              variant='text'
              color='primary'
              type='submit'
              fullWidth
              disabled={isInProgress || !hasAccessToEdit}
            >
              Сохранить
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Button
              variant='text'
              type='reset'
              fullWidth
              onClick={() => setSelectedCompliant(null)}
            >
              Отмена
            </Button>
          </Grid>
        </Grid>
      </form>
    </Stack>
  );
};

export default CallCenterModal;
