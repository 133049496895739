import { Box, Tab, Tabs } from '@mui/material';
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { HouseDataFilterParam, HouseLayoutDto } from '@/dto/taskmap/Dto';
import {
  ApiFindHouseData,
  ApiFindHouseOrders,
  ApiFindHouseRelevantTaskDtos,
  ApiFindHousePlannings,
  ApiFindHouseLayout,
} from '@/services/YodaRestService';
import { useActionLog } from '@/hooks/ActionLogHook';
import '../houseStyle.css';
import { useParams } from 'react-router-dom';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { HouseEntranceEditTabsWrapper } from './HouseEntranceEdit.styled';
import {
  getNetworkElementsSheme,
  getPlannedOrdersByEntrance,
  useNetworkElementPlanningType,
  useNetworkEquipmentTypes,
  getNetworkElementsWithoutEntranceCount,
  useHouseLevelFloorType,
} from './utils';
import HouseEntranceSheme from '@/components/sections/HouseEntranceSheme/HouseEntranceSheme';
import HouseOdpuReplacement from '@/components/HouseOdpuReplacement/HouseOdpuReplacement';
import PnrHermes from '@/components/PnrHermes/PnrHermes';
import { usePnrPlanningStore } from '@/components/sections/PnrPlanning/store';
import shallow from 'zustand/shallow';
import { KEYS } from '@/hooks/useQuery/Keys';
import { ActiveOrdersPUPnrComponent } from '../ActiveOrdersPUPnrComponent';
import { FirstTab } from '../FirstTab/FirstTab';
import { HouseEntranceHeader } from '../HouseEntranceHeader/HouseEntranceHeader';
import { usePNRStore } from '../store';
import { useMetaTitle } from '@/hooks/useMetaTitle';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export const HouseEntranceEdit = () => {
  const fetchCatch = useActionLog((state) => state.fetchCatch);

  const { houseDtoId } = useParams();

  const {
    houseData,
    setHouseData,
    setSelectedIndicator,
    setEntranceCount,
    setFloorCount,
    setFlatCount,
    setAddress,
    setEntranceIdx,
    setEntranceToFlatCountMap,
    setFloorInEntranceIdx,
    setFloorToFlats,
    smrTaskToAppartment,
    setNetworkElementsSheme,
    setHouseRelevantTaskApartmentsPrevious,
    setHouseRelevantTaskApartmentsCurrent,
    setNetworkElementsWithoutEntranceCount,
    setVisibleIndicators,
    setLayoutDataFromBackend,
    setCurrentEntranceLayout,
    layoutData,
  } = usePNRStore(
    (state) => ({
      setVisibleIndicators: state.setVisibleIndicators,
      houseData: state.houseData,
      setHouseData: state.setHouseData,
      setSelectedIndicator: state.setSelectedIndicator,
      setEntranceCount: state.setEntranceCount,
      setFloorCount: state.setFloorCount,
      setFlatCount: state.setFlatCount,
      setAddress: state.setAddress,
      setEntranceIdx: state.setEntranceIdx,
      setEntranceToFlatCountMap: state.setEntranceToFlatCountMap,
      setFloorInEntranceIdx: state.setFloorInEntranceIdx,
      setFloorToFlats: state.setFloorToFlats,
      smrTaskToAppartment: state.smrTaskToAppartment,
      setNetworkElementsSheme: state.setNetworkElementsSheme,
      setHouseRelevantTaskApartmentsPrevious: state.setHouseRelevantTaskApartmentsPrevious,
      setHouseRelevantTaskApartmentsCurrent: state.setHouseRelevantTaskApartmentsCurrent,
      setNetworkElementsWithoutEntranceCount: state.setNetworkElementsWithoutEntranceCount,
      setLayoutDataFromBackend: state.setLayoutDataFromBackend,
      layoutData: state.layoutData,
      setCurrentEntranceLayout: state.setCurrentEntranceLayout,
    }),
    shallow
  );
  useMetaTitle(`${houseData?.address ?? ''} - ПНР`);

  const {
    setNetworkEquipmentOptions,
    setPlannedOrders,
    setNewPlanning,
    setNetworkElementPlanningOptions,
  } = usePnrPlanningStore(
    (state) => ({
      setNetworkEquipmentOptions: state.setNetworkEquipmentOptions,
      setPlannedOrders: state.setPlannedOrders,
      setNewPlanning: state.setNewPlanning,
      setNetworkElementPlanningOptions: state.setNetworkElementPlanningOptions,
    }),
    shallow
  );

  const { filteredNetworkEquipment, networkEquipmentTypes } = useNetworkEquipmentTypes();
  const { networkElementPlanningOptions, networkElementPlanningTypes } =
    useNetworkElementPlanningType();

  const { floorTypes } = useHouseLevelFloorType();

  const [selectedTab, setSelectedTab] = React.useState(0);

  const { setIsInProgress } = useScreenHoldHook();

  React.useEffect(() => {
    if (houseDtoId) {
      mutFindHouseData.mutate({});
    }
  }, []);

  React.useEffect(() => {
    if (houseData) {
      setAddress(houseData.address ? houseData.address : '');
      setEntranceCount(houseData.entranceCount ? houseData.entranceCount : 0);
      setFloorCount(houseData.floorCount ? houseData.floorCount : 0);
      setFlatCount(houseData.flatCount ? houseData.flatCount : 0);
      if (houseData.entranceCount) {
        setNetworkElementsSheme(getNetworkElementsSheme(houseData));
        setNetworkElementsWithoutEntranceCount(getNetworkElementsWithoutEntranceCount(houseData));
      }
      houseData.smrTasks?.forEach((el) => {
        if (el.apartment) {
          smrTaskToAppartment.set(el.apartment, el);
        }
      });
    }
  }, [houseData]);

  React.useEffect(() => {
    if (layoutData) {
      setHouseEntrancePagination(layoutData);
      const floorInEntranceMap = new Map<number, number[]>();
      const entranceNumbers = layoutData?.entrances?.map((entrance) => entrance.number);
      const minNumber = Math.min(...entranceNumbers);
      const maxNumber = Math.max(...entranceNumbers);
      for (let i = minNumber; i <= maxNumber; i++) {
        const houseEntranceItem = layoutData?.entrances?.find((entrance) => entrance.number === i);
        const floorArr: number[] = [];
        if (houseEntranceItem) {
          houseEntranceItem?.floors?.forEach((floor) => {
            if (floor.number >= 1) {
              floorArr.push(floor.number);
            }
          });
        }
        floorInEntranceMap.set(i, floorArr);
      }
      const entranceToFloorToFlatsMap = new Map<number, Map<number, string[]>>();
      const entranceToFlatCountMap = new Map<number, number>();
      for (let x = minNumber; x <= maxNumber; x++) {
        let entranceFlatCount = 0;
        const floorToFlatsMap = new Map<number, string[]>();
        for (let i = 1; i <= floorInEntranceMap.get(x)!.length; i++) {
          const houseEntranceItem = layoutData?.entrances?.find(
            (entrance) => entrance.number === x
          );
          const entranceFloorItem = houseEntranceItem?.floors
            ? houseEntranceItem?.floors.find((floor) => floor.number === i)
            : null;
          if (entranceFloorItem && entranceFloorItem.flats.length > 0) {
            const flats = entranceFloorItem.flats;
            entranceFlatCount += flats.length;

            floorToFlatsMap.set(
              i,
              flats?.map((flat) => flat.number)
            );
          }
        }
        entranceToFlatCountMap.set(x, entranceFlatCount);
        entranceToFloorToFlatsMap.set(x, floorToFlatsMap);
      }
      setEntranceToFlatCountMap(entranceToFlatCountMap);
      setFloorInEntranceIdx(floorInEntranceMap);
      setFloorToFlats(entranceToFloorToFlatsMap);
    }
  }, [layoutData]);

  React.useEffect(() => {
    if (houseData) {
      mutFindHouseRelevantTaskCurrentYear.mutate();
      mutFindHouseRelevantTaskPreviousYears.mutate();
      mutFindHouseLayout.mutate();
      mutFindHouseOrders.mutate();
    }
  }, [houseData]);

  React.useEffect(() => {
    setNetworkEquipmentOptions(filteredNetworkEquipment);
    setNetworkElementPlanningOptions(networkElementPlanningOptions);

    if (houseData && networkEquipmentTypes.size) {
      mutFindHousePlannings.mutate();
    }
  }, [houseData, networkEquipmentTypes, networkElementPlanningOptions]);

  const mutFindHouseData = useMutation<void, any, HouseDataFilterParam>(
    () => {
      return (
        ApiFindHouseData(houseDtoId!)
          .then((res) => setHouseData(res.data))
          //   .then((res) => setHouseData(MOCK))
          .catch((error) => fetchCatch(error, 'Ошибка получения информации о доме'))
      );
    },
    { mutationKey: KEYS.pnrHouseData }
  );

  const mutFindHousePlannings = useMutation<void, any>(
    () => {
      return ApiFindHousePlannings(houseDtoId!)
        .then(({ data }) => {
          setNewPlanning(
            data,
            networkEquipmentTypes,
            'newPlan',
            networkElementPlanningTypes,
            floorTypes
          );
        })
        .catch((error) => fetchCatch(error, 'Ошибка получения данных планировщика'));
    },
    { mutationKey: KEYS.pnrPlannings }
  );

  const mutFindHouseRelevantTaskCurrentYear = useMutation<void, any>(() => {
    return ApiFindHouseRelevantTaskDtos(houseDtoId!, true)
      .then(({ data }) =>
        setHouseRelevantTaskApartmentsCurrent(data?.map((task) => task.apartment))
      )
      .catch((error) =>
        fetchCatch(error, 'Ошибка получения информации о плановых работах в текущем году')
      );
  });

  const mutFindHouseRelevantTaskPreviousYears = useMutation<void, any>(() => {
    return ApiFindHouseRelevantTaskDtos(houseDtoId!, false)
      .then(({ data }) =>
        setHouseRelevantTaskApartmentsPrevious(data?.map((task) => task.apartment))
      )
      .catch((error) =>
        fetchCatch(error, 'Ошибка получения информации о плановых работах в предыдущих годах')
      );
  });

  const mutFindHouseOrders = useMutation<void, any>(
    () => {
      return ApiFindHouseOrders(houseDtoId!)
        .then(({ data }) => {
          const plannedOrdersByEntrance = getPlannedOrdersByEntrance(data);
          setPlannedOrders(plannedOrdersByEntrance);
        })
        .catch((error) => fetchCatch(error, 'Ошибка получения информации о плановых заявках'));
    },
    { mutationKey: KEYS.pnrHouseOrders }
  );

  const mutFindHouseLayout = useMutation<void, any>(() => {
    return ApiFindHouseLayout(houseDtoId!)
      .then(({ data }) => {
        // MOCK_LAYOUT
        setLayoutDataFromBackend(data);
        let selectIndicator = -1;
        for (let i = 0; i < data?.entrances.length; i++) {
          const entrance = data?.entrances[i];
          if (entrance.number >= 1) {
            selectIndicator = entrance.number;
            break;
          }
        }
        setSelectedIndicator(selectIndicator);
        const selectedEntrance = data?.entrances.find((it) => it.number === selectIndicator);
        if (selectedEntrance) {
          setCurrentEntranceLayout(selectedEntrance);
        }
      })
      .catch((error) => {
        fetchCatch(error, 'Ошибка получения схемы дома');
      });
  });

  useEffect(() => {
    if (mutFindHouseData.isLoading || mutFindHouseLayout.isLoading) {
      setIsInProgress(true);
    } else {
      setIsInProgress(false);
    }
  }, [mutFindHouseData.isLoading, mutFindHouseLayout.isLoading]);

  const setHouseEntrancePagination = (houseLayout: HouseLayoutDto) => {
    const entranceArr: number[] = [];

    houseLayout?.entrances
      ?.sort((a, b) => a.number - b.number)
      ?.forEach((entranceItem) => {
        entranceArr.push(entranceItem.number);
      });

    setEntranceIdx(entranceArr);
    setVisibleIndicators(
      entranceArr.length > 5 ? entranceArr.slice(0, 5) : entranceArr.slice(0, entranceArr.length)
    );
  };

  const refetchHousePlannings = () => {
    mutFindHousePlannings.mutate();
  };

  const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <div style={{ padding: '20px' }}>
      <HouseEntranceHeader refetchHousePlannings={refetchHousePlannings} />
      <HouseEntranceEditTabsWrapper>
        <Tabs
          value={selectedTab}
          onChange={handleTabsChange}
        >
          <Tab label='РЕДАКТИРОВАНИЕ' />
          <Tab label='ЗАЯВКИ' />
          <HouseEntranceSheme
            isLoading={mutFindHouseLayout.isLoading || mutFindHouseData.isLoading}
          />

          <Tab label='ОДПУ' />

          <Tab label='ГЕРМЕС' />
        </Tabs>
      </HouseEntranceEditTabsWrapper>

      <TabPanel
        value={selectedTab}
        index={0}
      >
        <FirstTab />
      </TabPanel>
      <TabPanel
        value={selectedTab}
        index={1}
      >
        <ActiveOrdersPUPnrComponent houseData={houseData} />
      </TabPanel>

      <TabPanel
        value={selectedTab}
        index={3}
      >
        <HouseOdpuReplacement />
      </TabPanel>

      <TabPanel
        value={selectedTab}
        index={4}
      >
        <PnrHermes />
      </TabPanel>
    </div>
  );
};
