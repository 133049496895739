import { mediaBreakpointDown } from '@/styles/mixins';
import { styled } from '@mui/material';

export const RegistryHeaderTitle = styled('div')({
  width: '30%',
  padding: '14px 28px',
  display: 'flex',
  [mediaBreakpointDown('fhd')]: {
    width: '40%',
  },
  [mediaBreakpointDown('xl')]: {
    width: '60%',
  },
  [mediaBreakpointDown('md')]: {
    width: '90%',
  },
});
