import { useState } from 'react';

export enum KeyForwarrrantyTypeFields {
  CHANNGE_SIM = 'b0eaa8d2-79d0-8349-0a9a-087f4c3e52d4',
  TRANSFER_PU_ANTENNA = '1e775eaf-0321-cc13-624f-c7706582dd1a',
  REBOOTING_GSM_MODULE = '6008c014-7228-ae47-b691-296831013ed7',
  GHANGING_PU_ANTENNA = '0b267ecc-f8ac-4bc1-2973-8a3b651657c3',
  FLASHING = 'e7cfcbe2-858b-0977-a997-420de06c2b95',
  REPLACING_AB_ONE = '5588a6ca-1ca8-ebca-3b77-221be4ee53be',
  REPLACING_AB_TWO = 'd0a8f9ac-3ce5-b1ee-0179-e15ff74ca59e',
  CHANNGING_TARIFF_SCHEDULE = 'f900220e-b153-38c4-1e62-a2fa32b35ae4',
  INSTALLING_ZIGBEE_MODULE = 'f71765ec-29be-af55-e2c2-2f33aed2de18',
  REMOTE_REPROGRAMING_CONTROLE_UNIT = '324d0086-b83c-7067-e5ad-b561d3350e6c',
  REINSTALLATION_PU = '93b9a7ee-32fc-1ab0-d3ef-1f5342650647',
  DRIVE_INTO_CANAL = 'a0400106-22be-fc51-e219-8059b87d4313',
  ISSUE_DD = '0a028b05-dcde-8464-1194-115a50a412c9',
  ADJUST_BINDING = '3799ec80-b857-e68a-6655-845d6755b4d3',
  CONVERT_INTO_CALCULATION = '4b7cf339-4c78-8709-32b1-31a158e3d4f3',
  CORRECT_PU_SWITCHING_CIRCUIT = '4a31d038-573e-0fce-1399-f962f7bf48c9',
  CHECK_CONNECTION_CIRCUIT = '7ee3af63-8739-8eae-b1ed-66d44db2e89d',
  OUTWEIGH_PU = 'dc1e15a0-ebe1-86fc-411d-9d6d2c198d81',
  FIXING_CONNECTION = 'c5f786d9-3215-4427-8b8c-7ff550de6741',
  CURRENT_TRANSFORMER_REPLACEMENT = 'b5d6173f-14ce-4ba0-af7c-2e33e5376abc',
}

const warrrantyTypeFields = [
  {
    key: KeyForwarrrantyTypeFields.CHANNGE_SIM,
    value: ['puNumber', 'sim', 'stampNumber'],
  }, // "Смена сим-карты",
  {
    key: KeyForwarrrantyTypeFields.TRANSFER_PU_ANTENNA,
    value: ['puNumber', 'stampNumber', 'mac'],
  }, // "Перенос антенны ПУ",
  {
    key: KeyForwarrrantyTypeFields.REBOOTING_GSM_MODULE,
    value: ['puNumber', 'stampNumber'],
  }, // "Перезагрузка GSM модуля",
  {
    key: KeyForwarrrantyTypeFields.GHANGING_PU_ANTENNA,
    value: ['puNumber', 'stampNumber', 'mac'],
  }, // "Смена антенны ПУ",
  {
    key: KeyForwarrrantyTypeFields.FLASHING,
    value: ['puNumber', 'mac', 'measurement', 'oldMeasurement'],
  }, // "Перепрошивка",
  {
    key: KeyForwarrrantyTypeFields.REPLACING_AB_ONE,
    value: ['puNumber', 'automaticBreakerId'],
  }, // "Замена АВ",
  {
    key: KeyForwarrrantyTypeFields.REPLACING_AB_TWO,
    value: ['puNumber', 'automaticBreakerId'],
  }, // "Замена АВ",
  {
    key: KeyForwarrrantyTypeFields.CHANNGING_TARIFF_SCHEDULE,
    value: ['puNumber', 'stampNumber', 'mac', 'measurement', 'tariff', 'oldMeasurement'],
  }, // "Смена тарифного расписания",
  {
    key: KeyForwarrrantyTypeFields.INSTALLING_ZIGBEE_MODULE,
    value: ['puNumber', 'stampNumber', 'mac', 'measurement', 'tariff', 'oldMeasurement'],
  }, // "Установка ZigBee модуля",
  {
    key: KeyForwarrrantyTypeFields.REMOTE_REPROGRAMING_CONTROLE_UNIT,
    value: ['puNumber', 'stampNumber', 'mac', 'measurement', 'tariff', 'oldMeasurement'],
  }, // "Дистанционное перепрограммирование ПУ",
  {
    key: KeyForwarrrantyTypeFields.REINSTALLATION_PU,
    value: ['puNumber', 'stampNumber', 'mac', 'measurement', 'tariff', 'oldMeasurement'],
  }, // "Переустановка ПУ",
  {
    key: KeyForwarrrantyTypeFields.DRIVE_INTO_CANAL,
    value: ['mac'],
  }, // "Загон в канал"
  {
    key: KeyForwarrrantyTypeFields.ISSUE_DD,
    value: [],
  }, // "Выдать ДД"
  {
    key: KeyForwarrrantyTypeFields.ADJUST_BINDING,
    value: ['longitude', 'latitude', 'previousAccountingPuNumber', 'newAccountingPuNumber'],
  }, // "Скорректировать привязку"
  {
    key: KeyForwarrrantyTypeFields.CONVERT_INTO_CALCULATION,
    value: [
      'longitude',
      'latitude',

      'previousAccountingPuNumber',
      'newAccountingPuNumber',
      'previousAccountingPuType',
      'newAccountingPuType',
    ],
  }, // "Перевести в расчет"
  {
    key: KeyForwarrrantyTypeFields.CORRECT_PU_SWITCHING_CIRCUIT,
    value: ['longitude', 'latitude'],
  }, // "Исправить схему включения ПУ"
  {
    key: KeyForwarrrantyTypeFields.CHECK_CONNECTION_CIRCUIT,
    value: ['longitude', 'latitude'],
  }, // "Проверить схему включения"
  {
    key: KeyForwarrrantyTypeFields.OUTWEIGH_PU,
    value: ['longitude', 'latitude'],
  }, // "Перевесить ПУ"
  {
    key: KeyForwarrrantyTypeFields.FIXING_CONNECTION,
    value: ['puNumber', 'stampNumber', 'measurement', 'oldMeasurement'],
  }, // "Исправление подключения"
  {
    key: KeyForwarrrantyTypeFields.CURRENT_TRANSFORMER_REPLACEMENT,
    value: ['puNumber', 'type', 'taskTransformator', 'ktt'],
  }, // "Замена ТТ"
];

export interface ShowFields {
  workDoneAt?: boolean;
  executor?: boolean;
  puNumber?: boolean;
  stampNumber?: boolean;
  mac?: boolean;
  measurement?: boolean;
  oldMeasurement?: boolean;
  sim?: boolean;
  automaticBreakerId?: boolean;
  type?: boolean;
  taskTransformator?: boolean;
  tariff?: boolean;
  ktt?: boolean;

  previousAccountingPuNumber?: boolean;
  newAccountingPuNumber?: boolean;
  previousAccountingPuType?: boolean;
  newAccountingPuType?: boolean;
  longitude?: boolean;
  latitude?: boolean;
  comment?: boolean;
}

export type ShowFieldsKey = keyof ShowFields;
const initFields = {
  workDoneAt: false,
  executor: false,
  puNumber: false,
  stampNumber: false,
  mac: false,
  measurement: false,
  oldMeasurement: false,
  sim: false,
  automaticBreakerId: false,
  type: false,
  taskTransformator: false,
  tariff: false,
  ktt: false,

  previousAccountingPuNumber: false,
  newAccountingPuNumber: false,
  previousAccountingPuType: false,
  newAccountingPuType: false,
  longitude: false,
  latitude: false,
};

const getFieldsByTagList = (list: string[]): ShowFields => {
  const DEFAULT_FIELDS = { workDoneAt: true, executor: true, comment: true };
  const intersection = warrrantyTypeFields
    .filter((x) => list.includes(x.key))
    .flatMap((el) => el.value);

  const uniqueArray = intersection.filter((item, pos) => intersection.indexOf(item) == pos);
  const tmp: ShowFields = initFields;
  for (const tmpKey in tmp) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tmp[tmpKey] = uniqueArray.includes(tmpKey);
  }
  const fieldsWithDefault = { ...tmp, ...DEFAULT_FIELDS };
  return fieldsWithDefault;
};

function useWarrantyFields(initialValue?: ShowFields) {
  const [fields, setFields] = useState<ShowFields>(initialValue ? initialValue : initFields);
  const updateFields = (list: string[]): void => {
    setFields({ ...getFieldsByTagList(list) });
  };

  return { fields, updateFields };
}

export default useWarrantyFields;
