import { getSingleTaskfromCallMap } from '@/services/CallingMapService';
import { CircularProgress, TablePagination } from '@mui/material';
import { ruRU, GridFooter } from '@mui/x-data-grid';
import { DataGrid } from './CallingMapTable.styled';
import { useCallingMapState } from '../store';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { useActionLog } from '@/hooks/ActionLogHook';
import shallow from 'zustand/shallow';
import { columns, makeFilter } from '../utils/CallingMapUtils';
import { Dispatch, SetStateAction } from 'react';
import { GeolessGetDataPayload } from '../types/types';

export interface GeoLessPointsTableProps {
  getGeoLessTableData: (param: GeolessGetDataPayload, formatedFilter: any) => void;
  setIsCountLoading: Dispatch<SetStateAction<boolean>>;

  getTableCount: (cb: (flag: boolean) => void) => void;
  isCountLoading: boolean;
}

function GeoLessPointsTable({ getGeoLessTableData, isCountLoading }: GeoLessPointsTableProps) {
  const {
    geoLess,
    setSingleGeoPoint,
    setTaskId,
    geoLessCount,
    currentPage,
    pageSize,
    setCurrentPage,
    setPageSize,
  } = useCallingMapState(
    (state) => ({
      geoLess: state.geoLess,
      setSingleGeoPoint: state.setSingleGeoPoint,
      setTaskId: state.setTaskId,
      geoLessCount: state.geoLessCount,
      currentPage: state.currentPage,
      pageSize: state.pageSize,
      setCurrentPage: state.setCurrentPage,
      setPageSize: state.setPageSize,
    }),
    shallow
  );
  const { setIsInProgress } = useScreenHoldHook();
  const { fetchCatch } = useActionLog();
  const {
    callCenterRequestType,
    callPeriod,
    requestPeriod,
    installationCallStatus,
    montagePlaceType,
    phase,
    taskType,
  } = useCallingMapState.getState();
  const formatedFilter = makeFilter(
    {
      callCenterRequestType,
      taskType,
      montagePlaceType,
      phase,
      installationCallStatus,
      callPeriod,

      requestPeriod,
    },
    [],
    undefined
  );

  const handleRowClik = (row: any) => {
    setIsInProgress(true);
    getSingleTaskfromCallMap(row?.id)
      .then(({ data }) => {
        setSingleGeoPoint(data);
        setTaskId(row?.id);
      })
      .catch((error) => fetchCatch(error, 'Ошибка загрузки информации о заявке'))
      .finally(() => setIsInProgress(false));
  };

  function onPageChange(ev: any, newPage: number) {
    setIsInProgress(true);
    setCurrentPage(newPage);
    getGeoLessTableData(
      {
        pageSize,
        currentPage: newPage,
      },
      formatedFilter
    );
  }
  function onPageSizeChange(newPageSize: any) {
    setPageSize(Number(newPageSize.target.value));
    setIsInProgress(true);
    getGeoLessTableData(
      { pageSize: Number(newPageSize.target.value), currentPage },
      formatedFilter
    );
  }

  return (
    <div style={{ height: '80vh', scrollBehavior: 'auto' }}>
      <DataGrid
        rows={geoLess.map((el, index) => ({
          ...el,
          orderNumber: index + 1 + pageSize * currentPage,
        }))}
        columns={columns}
        getRowId={(row) => row.taskId}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        disableColumnMenu
        pagination
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        onRowClick={handleRowClik}
        disableSelectionOnClick
        components={{
          Footer: GridFooter,
          Pagination: () => (
            <>
              <TablePagination
                count={geoLessCount}
                page={currentPage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={onPageSizeChange}
                labelDisplayedRows={(props) => (
                  <DisplayedRows
                    {...props}
                    isCountLoading={isCountLoading}
                  />
                )}
                onPageChange={onPageChange}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                labelRowsPerPage={'Заявок на странице:'}
              />
            </>
          ),
        }}
      />
    </div>
  );
}

const DisplayedRows = ({
  from,
  to,
  count,
  isCountLoading,
}: {
  from: number;
  to: number;
  count: number;
  isCountLoading: boolean;
}) => {
  return (
    <div style={{ display: 'inline-block', padding: 4, minWidth: 18 }}>
      {`${from} - ${to} из `}
      {isCountLoading ? <CircularProgress size={15} /> : count}
    </div>
  );
};

export default GeoLessPointsTable;
