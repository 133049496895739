import React, { FC } from 'react';
import { CableBackground } from './CableIcon';
import { useCatalog } from '@/hooks/CatalogHook';
import { useActionLog } from '@/hooks/ActionLogHook';
import { PpoCableImg, PpoCableImgProps, PpoCableText, PpoCableWrapper } from './PpoCable.styled';

export enum MetalEnum {
  Al = 101,
  Cu = 100,
  Fe = 2,
  Plast = 3,
}

const metalColorMap = new Map<MetalEnum, string>([
  [MetalEnum.Al, '#B5B6B8'],
  [MetalEnum.Cu, '#E47233'],
  [MetalEnum.Fe, '#45322e'],
  [MetalEnum.Plast, '#ff00ff'],
]);
export const metalNameMap = new Map<MetalEnum, string>([
  [MetalEnum.Al, 'Аллюминий'],
  [MetalEnum.Cu, 'Медь'],
]);

const getMetalColor = (metal: MetalEnum): string => {
  const pt = metalColorMap.get(metal);
  return pt ? pt : '';
};

interface IPpoCable extends PpoCableImgProps {
  metal?: MetalEnum;
  sectionId?: number;
  linesNumber?: number | string | null;
}

export const PpoCable: FC<IPpoCable> = ({ metal, sectionId, linesNumber, add }) => {
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const section = sectionId && getCatalogMapWithErr('cableSection').get(sectionId?.toString());

  return (
    <>
      <PpoCableWrapper>
        <PpoCableImg add={add}>
          <>
            {linesNumber && (
              <PpoCableText>
                <span>{linesNumber}</span>
                {!!section && (
                  <>
                    <span>x</span>
                    <span>{section}</span>
                  </>
                )}
              </PpoCableText>
            )}
          </>
        </PpoCableImg>
        <CableBackground color={metal ? getMetalColor(metal) : 'rgb(255,255,255)'} />
      </PpoCableWrapper>
    </>
  );
};
