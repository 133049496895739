import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

import { useCallingMapState } from '../store';
import { Controller, useFormContext } from 'react-hook-form';

import { isBefore, startOfDay } from 'date-fns';
import { color } from '@/styles/mixins';

import shallow from 'zustand/shallow';
import { checkName, countMapKey, handleExecutorSchedule } from '../utils/CallingMapUtils';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import ru from 'date-fns/locale/ru';
import { CALL_TYPES } from '../types/types';
import { useEffect, useState } from 'react';
import {
  getAllExecutersCallMap,
  getExecutersAvalibleCallMap,
  getExecutorsSchedule,
} from '@/services/CallingMapService';
import { useCatalog } from '@/hooks/CatalogHook';
import {
  StyledCheckbox,
  StyledDiv,
  StyledSpan,
  StyledTypography,
} from '../components/СallingMap.styled';
import React from 'react';
import moment from 'moment';
import { useActionLog } from '@/hooks/ActionLogHook';
import { formatPhoneNumberMask } from '@/utils/heplers';
const showAllText = 'Выбрать из всего списка';

const montageTimeOptions = [
  { label: 'Первая половина дня', value: 'Первая половина дня' },
  { label: 'Вторая половина дня', value: 'Вторая половина дня' },
  { label: 'В любое время', value: 'В любое время' },
];
const wrongInfoOptions = [
  { label: 'Неверный адрес', value: 'Неверный адрес' },
  { label: 'Несуществующий номер', value: 'Несуществующий номер' },
  { label: 'продали квартиру/дом', value: 'продали квартиру/дом' },
  { label: 'не имеет отношения к адресу', value: 'не имеет отношения к адресу' },
];

type ActiveTabFormProps = {
  type: string | null;
};

const ActiveTabForm = ({ type }: ActiveTabFormProps) => {
  const methods = useFormContext();
  const { getGroupCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
  }));
  const [showAllEexecutors, setshowAllEexecutors] = useState(false);

  const { fetchCatch } = useActionLog();

  const { executors, avalibleExecutors, setAvalibleExecutors, setExecutors, singleGeoPoint } =
    useCallingMapState(
      (state) => ({
        avalibleExecutors: state.avalibleExecutors,
        executors: state.executors,
        setAvalibleExecutors: state.setAvalibleExecutors,
        setExecutors: state.setExecutors,
        singleGeoPoint: state.singleGeoPoint,
      }),
      shallow
    );

  const avalibleExecutorsOptions = () => {
    if (showAllEexecutors) {
      return avalibleExecutors.map((ex) => ({
        value: ex.userId,
        label: checkName(ex, `${getGroupCatalog()?.get(ex.groupId) || ''}`),
        workloadDays: ex.workloadDays ?? null,
      }));
    }
    if (executors.length) {
      return executors.map((ex) => ({
        value: ex.userId,
        label: checkName(ex, `${getGroupCatalog()?.get(ex.groupId) || ''}`),
        workloadDays: ex.workloadDays ?? null,
      }));
    } else {
      return avalibleExecutors.map((ex) => ({
        value: ex.userId,
        label: checkName(ex, `${getGroupCatalog()?.get(ex.groupId) || ''}`),
        workloadDays: ex.workloadDays ?? null,
      }));
    }
  };

  const validateMontageDate = (selectedTime: any) => {
    const currentTime = startOfDay(new Date());
    const text = type === CALL_TYPES.CALLBACK ? 'обратного звонка' : 'монтажа';
    if (isBefore(selectedTime, currentTime)) {
      return `Некорректная дата. Дата ${text} не должна быть ранее даты ввода.`;
    }
    return true;
  };
  const validateNewAbonentNumber = (number: string) => {
    const formattedString = number.replace(/[^\d]/g, '');
    if (singleGeoPoint?.abonents.find((abonnet) => abonnet.contactPhone === formattedString)) {
      return 'Номер уже существует';
    }
    return true;
  };

  useEffect(() => {
    if (!avalibleExecutors.length) {
      getExecutersAvalibleCallMap()
        .then(({ data }) => {
          setAvalibleExecutors(data);
        })
        .catch((err) => {
          fetchCatch(err);
        });
      getAllExecutersCallMap()
        .then(({ data }) => {
          setExecutors(data);
        })
        .catch((err) => {
          fetchCatch(err);
        });
    }
  }, []);

  useEffect(() => {
    methods.clearErrors();
    methods.reset();
  }, [type]);

  const handleExcutorsSchedule = (time: string) => {
    getExecutorsSchedule(time, time)
      .then(({ data: { workloadExecutors } }) => {
        setExecutors(handleExecutorSchedule(executors, workloadExecutors));
        setAvalibleExecutors(handleExecutorSchedule(avalibleExecutors, workloadExecutors));
      })
      .catch((err) => {
        fetchCatch(err);
      });
  };

  const handleTime = (
    field: any,
    date: any,
    type: string,
    handleExcutorsSchedule: (time: string) => void
  ) => {
    field.onChange(date);
    const formattedDate = moment(date, 'YYYY-MM-DD', true);
    const minDate = moment('2023-01-01', 'YYYY-MM-DD');
    if (
      type === CALL_TYPES.AGREEMENT &&
      formattedDate.isValid() &&
      formattedDate.isAfter(minDate)
    ) {
      handleExcutorsSchedule(moment(date).format('YYYY-MM-DD'));
    }
  };

  return (
    <>
      <Grid
        container
        spacing={5}
      >
        {(type === CALL_TYPES.AGREEMENT || type === CALL_TYPES.CALLBACK) && (
          <Grid
            item
            xs={3}
          >
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ru}
            >
              <Controller
                control={methods.control}
                name='montageDate'
                rules={{ required: true, validate: validateMontageDate }}
                render={({ field }) => (
                  <DesktopDatePicker
                    label={type === 'AGREEMENT' ? 'Дата монтажа' : 'Дата обратного звонка'}
                    inputFormat='dd.MM.yyyy'
                    value={field.value || null}
                    onChange={(date) => {
                      handleTime(field, date, type, handleExcutorsSchedule);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ width: '100%' }}
                        {...params}
                        required
                        error={!!methods?.formState?.errors?.montageDate}
                        InputLabelProps={{
                          shrink: true,
                          sx: {
                            '& .MuiFormLabel-asterisk': {
                              color: 'red',
                            },
                          },
                        }}
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        )}
        {(type === CALL_TYPES.AGREEMENT || type === CALL_TYPES.CALLBACK) && (
          <>
            <Grid
              item
              xs={4}
            >
              <Controller
                control={methods.control}
                name='montageTime'
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Autocomplete
                    fullWidth
                    options={montageTimeOptions}
                    onChange={(event, newVal) => field.onChange(newVal?.value)}
                    value={montageTimeOptions.find((e) => e.value === field.value) || null}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          required
                          label={
                            type === CALL_TYPES.CALLBACK
                              ? 'Время обратного звонка'
                              : 'Время монтажа'
                          }
                          {...field}
                          error={methods?.formState?.errors?.montageTime?.type === 'required'}
                          InputLabelProps={{
                            shrink: true,
                            sx: {
                              '& .MuiFormLabel-asterisk': {
                                color: 'red',
                              },
                              border: 'none',
                            },
                          }}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            {type === CALL_TYPES.AGREEMENT && (
              <Grid
                item
                xs={5}
              >
                <Controller
                  control={methods.control}
                  name='executorId'
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      fullWidth
                      options={avalibleExecutorsOptions()}
                      value={
                        avalibleExecutorsOptions().find((e) => e.value === field.value) || null
                      }
                      onChange={(event, newVal) => field.onChange(newVal?.value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={'Мастер'}
                          {...field}
                          error={methods?.formState?.errors?.executorId?.type === 'required'}
                          required
                          InputLabelProps={{
                            shrink: true,
                            sx: {
                              '& .MuiFormLabel-asterisk': {
                                color: 'red',
                              },
                            },
                          }}
                        />
                      )}
                      renderOption={(props, option, { inputValue }) => {
                        const [firstName, second, third, forth] = option.label.split(' ');
                        const { workloadDays } = option;
                        const { countMap } = (workloadDays && workloadDays[0]) || {};
                        const amCount = countMap && countMap[countMapKey?.amCount];
                        const pmCount = countMap && countMap[countMapKey?.pmCount];
                        const anyCount = countMap && countMap[countMapKey?.anyCount];
                        return (
                          <StyledDiv
                            {...(props as any)}
                            isHighlighted={true}
                            isBold={true}
                          >
                            <StyledTypography>
                              {firstName} {second} {third}
                              <StyledSpan>({forth})</StyledSpan>
                              {!!amCount && (
                                <>
                                  <br />
                                  <StyledSpan customColor='orange'>
                                    {amCount && `Первая половина дня: `}
                                  </StyledSpan>
                                  {amCount}
                                </>
                              )}
                              {!!pmCount && (
                                <>
                                  <br />
                                  <StyledSpan customColor={color('blue')}>
                                    {!!pmCount && `Вторая половина дня: `}
                                  </StyledSpan>
                                  {pmCount}
                                </>
                              )}
                              {!!anyCount && (
                                <>
                                  <br />
                                  <StyledSpan customColor={color('purple')}>
                                    {!!anyCount && `В любое время: `}
                                  </StyledSpan>
                                  {anyCount}
                                </>
                              )}
                            </StyledTypography>
                          </StyledDiv>
                        );
                      }}
                    />
                  )}
                />
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      sx={{ color: color('blue') }}
                      checked={showAllEexecutors}
                      onChange={() => setshowAllEexecutors(!showAllEexecutors)}
                    />
                  }
                  label={showAllText}
                />
              </Grid>
            )}
          </>
        )}
        {type === CALL_TYPES.MISSED_CALL && (
          <Grid
            item
            xs={12}
          >
            <Controller
              control={methods.control}
              name='missedCallReason'
              render={({ field }) => <ReasonRadioGroup field={field} />}
            />
          </Grid>
        )}
        {type === CALL_TYPES.WRONG_ADDRESS && (
          <Grid
            item
            xs={12}
          >
            <Controller
              control={methods.control}
              name='wrongInfo'
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  fullWidth
                  options={wrongInfoOptions}
                  onChange={(event, newVal) => field.onChange(newVal?.value)}
                  value={wrongInfoOptions.find((e) => e.value === field.value) || null}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={'Выберети причину'}
                        {...field}
                        error={methods?.formState?.errors?.wrongInfo?.type === 'required'}
                        InputLabelProps={{
                          shrink: true,
                          sx: {
                            '& .MuiFormLabel-asterisk': {
                              color: 'red',
                            },
                            border: 'none',
                          },
                        }}
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
        )}

        {(type === CALL_TYPES.AGREEMENT ||
          type === CALL_TYPES.REFUSAL ||
          type === CALL_TYPES.WRONG_ADDRESS ||
          type === CALL_TYPES.CALLBACK) && (
          <Grid
            item
            xs={12}
          >
            <Controller
              control={methods.control}
              name='comment'
              render={({ field }) => (
                <TextField
                  multiline
                  maxRows={3}
                  label='Комментарий'
                  fullWidth
                  {...field}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            {Object.values(methods?.formState?.errors).some((err) => err) && (
              <>
                {methods?.formState?.errors?.montageDate && (
                  <>
                    <Typography sx={{ width: '100%', color: color('trueRed') }}>
                      {methods?.formState?.errors?.montageDate?.message}
                    </Typography>
                    <Typography sx={{ width: '100%', color: color('trueRed') }}>
                      {Object.values(methods?.formState?.errors).length > 1 &&
                        'Заполнены не все обязательные поля'}
                    </Typography>
                  </>
                )}
                {!methods?.formState?.errors?.montageDate && (
                  <Typography sx={{ width: '100%', color: color('trueRed') }}>
                    Заполнены не все обязательные поля
                  </Typography>
                )}
              </>
            )}
          </Grid>
        )}

        {type === CALL_TYPES.ADD_NUMBER && (
          <>
            <Grid
              item
              xs={9}
            >
              <Controller
                control={methods.control}
                name='fio'
                rules={{ required: true, pattern: /^[A-Za-zА-Яа-я ]+$/ }}
                render={({ field }) => (
                  <TextField
                    id='tf-number-tt'
                    label='ФИО контактного лица'
                    fullWidth
                    {...field}
                    required
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        '& .MuiFormLabel-asterisk': {
                          color: 'red',
                        },
                      },
                    }}
                  />
                )}
              />
              {methods?.formState?.errors?.fio?.type === 'required' && (
                <Typography sx={{ width: '100%', color: color('trueRed') }}>
                  Поле должно быть заполнено
                </Typography>
              )}
              {methods?.formState?.errors?.fio?.type === 'pattern' && (
                <Typography sx={{ width: '100%', color: color('trueRed') }}>
                  Поле должно содержать только буквы и/или пробелы
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={3}
            >
              <Controller
                control={methods.control}
                name='contactPhone'
                rules={{
                  required: true,
                  validate: validateNewAbonentNumber,
                  // eslint-disable-next-line no-useless-escape
                  pattern: /^((8|\+7)[\- ]?)(\(?\d{3}\)?[\- ]?)(\d{3}[\- ]?)(\d{2}[\- ]?)(\d{2})$/,
                }}
                render={({ field }) => (
                  <TextField
                    id='tf-number-tt'
                    label='Номер телефона'
                    fullWidth
                    {...field}
                    error={methods?.formState?.errors?.contactPhone?.type === 'pattern'}
                    value={formatPhoneNumberMask(field.value)}
                    onChange={(e) => field.onChange(formatPhoneNumberMask(e.target.value))}
                    required
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        '& .MuiFormLabel-asterisk': {
                          color: 'red',
                        },
                      },
                    }}
                  />
                )}
              />
              {methods?.formState?.errors?.contactPhone?.type === 'pattern' && (
                <Typography sx={{ width: '100%', color: color('trueRed') }}>
                  Введите номер в формате +79998887766
                </Typography>
              )}
              {methods?.formState?.errors?.contactPhone?.type === 'required' && (
                <Typography sx={{ width: '100%', color: color('trueRed') }}>
                  Поле должно быть заполнено
                </Typography>
              )}
              {methods?.formState?.errors?.contactPhone?.type === 'validate' && (
                <Typography sx={{ width: '100%', color: color('trueRed') }}>
                  {methods?.formState?.errors?.contactPhone?.message}
                </Typography>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default ActiveTabForm;

const ReasonRadioGroup = ({ field }: any) => (
  <FormControl fullWidth>
    <FormLabel id='demo-controlled-radio-buttons-group'>
      Причина недозвона<span style={{ color: color('trueRed') }}>*</span>
    </FormLabel>
    <RadioGroup
      aria-labelledby='demo-controlled-radio-buttons-group'
      name='controlled-radio-buttons-group'
      value={field.value}
      onChange={(e) => field?.onChange(e.target.value)}
    >
      <Grid
        container
        spacing={1}
        mt={2}
      >
        <Grid
          item
          xs={6}
          flexDirection={'column'}
          display={'flex'}
          gap={2}
        >
          <FormControlLabel
            value='Абонент не отвечает'
            control={<Radio />}
            label='Абонент не отвечает'
          />
          <FormControlLabel
            value='Абонент отменил вызов'
            control={<Radio />}
            label='Абонент отменил вызов'
          />
        </Grid>
        <Grid
          item
          xs={6}
          flexDirection={'column'}
          display={'flex'}
          gap={2}
        >
          <FormControlLabel
            value='Абонент недоступен'
            control={<Radio />}
            label='Абонент недоступен'
          />
          <FormControlLabel
            value='Автоответчик'
            control={<Radio />}
            label='Автоответчик'
          />
        </Grid>
      </Grid>
    </RadioGroup>
  </FormControl>
);
