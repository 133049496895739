import { Box, styled } from '@mui/material';

export const RegistryBox = styled(Box)({
  borderRadius: '10px',
  border: '1px solid #EFEFEF',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  background: 'rgba(245, 245, 245, 1)',
  padding: '14px 28px',
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'space-around',
});
