import React, { FC } from 'react';
import { usePNRStore } from '../store';
import shallow from 'zustand/shallow';
import { ArrowButton } from '@/components/ui/ArrowButton/ArrowButton';
import {
  BottomPaginationPnrButton,
  BottomPaginationPnrButtonWrapper,
} from './BottomPaginationPnr.styled';

export interface IBottomPaginationPnr {
  onPaginationIndicatorClick: (index: number) => void;
  onLeftArrowIndicatorClick: () => void;
  onRightArrowIndicatorClick: () => void;
}

export const BottomPaginationPnr: FC<IBottomPaginationPnr> = ({
  onPaginationIndicatorClick,
  onLeftArrowIndicatorClick,
  onRightArrowIndicatorClick,
}: IBottomPaginationPnr) => {
  const { selectedIndicator, visibleIndicators } = usePNRStore(
    (state) => ({
      selectedIndicator: state.selectedIndicator,
      visibleIndicators: state.visibleIndicators,
    }),
    shallow
  );

  return (
    <BottomPaginationPnrButtonWrapper>
      <ArrowButton
        direction='left'
        onClick={() => onLeftArrowIndicatorClick()}
      />

      {visibleIndicators?.map((it) => {
        return (
          <BottomPaginationPnrButton
            selected={it === selectedIndicator}
            onClick={() => onPaginationIndicatorClick(it)}
            key={it}
          >
            {it}
          </BottomPaginationPnrButton>
        );
      })}

      <ArrowButton
        direction='right'
        onClick={() => onRightArrowIndicatorClick()}
      />
    </BottomPaginationPnrButtonWrapper>
  );
};
