import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled, Stack } from '@mui/material';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { color, font } from '@/styles/mixins';

export const ActionLogItemPre = styled('pre')`
  ${font('text5')}
  white-space:pre-wrap;
  color: ${color('errorGrayNavbar')};
`;

interface ActionLogItemExpandIconProps {
  hide: boolean;
}
export const ActionLogItemExpandIcon = styled(ExpandMoreIcon)<ActionLogItemExpandIconProps>(
  ({ hide }) => ({
    transform: `rotate(${hide ? 0 : 180}deg)`,
    transition: '0.3s',
    color: color('errorGrayNavbar'),
    fontSize: 20,
    cursor: 'pointer',
  })
);

export const IconWrapper = styled('div')({
  position: 'relative',
  border: 'solid #EBECF0 2px',
  display: 'flex',
  width: '40px',
  height: '40px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '5px',
  backgroundColor: 'white',
  zIndex: 2,
});

export const VerticalLine = styled('div')({
  position: 'absolute',
  width: '2px',
  backgroundColor: '#EBECF0',
  top: '50px',
  bottom: '-10px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1,
});
export const MainContentText = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 2,
  width: '100%',
});
export const ReportGmailerrorredIconRed = styled(ReportGmailerrorredIcon)({
  color: 'red',
});
export const CheckCircleOutlineIconGreen = styled(CheckCircleOutlineIcon)({
  color: 'green',
});

export const StrongText = styled('strong')`
  ${font('text2')}
  font-weight: 500;
`;

export const SmallText = styled('p')<{ hide?: boolean }>`
  ${font('text5')}
  margin: 0;
  transition: color 0.3s ease-in-out;
  user-select: none;
  color: ${({ hide }) => (hide ? color('muiBlue') : color('errorGrayNavbar'))};
  cursor: pointer;
`;
