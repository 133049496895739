import { StatisticMoreThanOneApealDto } from './types';
import React, { useMemo } from 'react';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import Paper from '@mui/material/Paper';
import { Grid } from '@material-ui/core';
import { MultiSelect } from '../../../inputs/MultiSelectNew';
import { Checkbox, FormControlLabel, TablePagination } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectFilterOptType } from '../../../filter/MultySelectFilter';
import { GridFooter } from '@mui/x-data-grid';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { getDataFromFilter } from '../../../inputs/MultiSelectNew/utils';
import { generateDetailedInfo } from '../../../button/DetailedInfo/utils';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { useSaveFilterButton } from '../../../filter/FilterButtons/utils';
import { StatisticMoreThanOneApealColumns } from './utils';
import {
  StatisticMoreThanOneApealFiltersWrapper,
  StatisticMoreThanOneApealDataGrid,
} from './StatisticMoreThanOneApeal.styled';

export interface Filter {
  contractIds?: string[];
  contractorIds?: string[];
  withoutTko: boolean;
  pageSize: number;
  currentPage: number;
}

interface StatisticMoreThanOneApealProps {
  header: string;
  load: boolean;
  data: StatisticMoreThanOneApealDto[];
  updateFunction: (filter: Filter) => void;
}

const SAVE_FILTER_KEY = 'StatisticMoreThanOneApeal';

export const StatisticMoreThanOneApeal: React.FC<StatisticMoreThanOneApealProps> = ({
  header,
  load,
  data,
  updateFunction,
}) => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const { getFilters } = useSaveFilterButton(SAVE_FILTER_KEY);

  const [filter, setFilter] = React.useState<Filter>({
    pageSize: 5,
    currentPage: 0,
    withoutTko: false,
  });

  const { getContractCatalog } = useCatalog((state) => ({
    getContractCatalog: state.getContractCatalog,
  }));
  const contractListResponse = getContractCatalog();
  const [selectedContractList, setSelectedContractList] = React.useState<SelectFilterOptType[]>([]);

  const { taskGroupMap, getGroupCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
    taskGroupMap: state.taskGroupMap,
  }));
  const responsables = useMemo(() => taskMapToArray(getGroupCatalog()), [taskGroupMap]);
  const [selectedResponsables, setSelectedResponsables] = React.useState<SelectFilterOptType[]>([]);
  const isFirstRender = React.useRef(true);

  const onSubmitSearch = () => {
    const newFilter = { ...filter };
    if (selectedContractList.length) {
      newFilter.contractIds = getDataFromFilter(selectedContractList, 'value');
    }
    if (selectedResponsables.length) {
      newFilter.contractorIds = getDataFromFilter(selectedResponsables, 'value');
    }
    setFilter(newFilter);
    updateFunction(newFilter);
  };

  const handleTKO = (e: any) => {
    const withoutTko = e.target.checked;
    setFilter((filter) => ({ ...filter, withoutTko }));
  };

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    updateFunction(filter);
  }, [filter.pageSize, filter.currentPage]);

  const onResetClick = () => {
    setSelectedContractList([]);
    setSelectedResponsables([]);
    setFilter(() => ({ currentPage: 0, pageSize: 5, withoutTko: false }));
    updateFunction({ currentPage: 0, pageSize: 5, withoutTko: false });
  };

  const detailedInfo = generateDetailedInfo(
    [selectedContractList, 'Контракт'],
    [selectedResponsables, 'Ответственный подрядчик']
  );

  const onSelectSavedFilter = (filterKey: string) => {
    const savedFilters = getFilters<SelectFilterOptType[]>(filterKey);
    savedFilters.selectedContractList && setSelectedContractList(savedFilters.selectedContractList);
    savedFilters.selectedResponsables && setSelectedResponsables(savedFilters.selectedResponsables);
  };

  return (
    <Paper elevation={6}>
      <p className={'headerPageStatistics'}>{header}</p>

      <Grid>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitSearch)}>
            <StatisticMoreThanOneApealFiltersWrapper>
              <MultiSelect
                label={'Контракт'}
                value={selectedContractList}
                onlyInListArgument
                options={contractListResponse}
                onChange={(sel: SelectFilterOptType[]) => setSelectedContractList(sel)}
              />

              <MultiSelect
                label={'Ответственный подрядчик'}
                value={selectedResponsables}
                onlyInListArgument
                options={responsables}
                onChange={(sel: SelectFilterOptType[]) => setSelectedResponsables(sel)}
              />
              <FormControlLabel
                label='Без ТКО'
                control={
                  <Checkbox
                    checked={filter.withoutTko}
                    onChange={handleTKO}
                  />
                }
              />

              <FilterButtons
                disable={!selectedContractList.length || !selectedResponsables.length || load}
                info={detailedInfo}
                onSearchClick={() => handleSubmit(onSubmitSearch)}
                onCloseClick={onResetClick}
                saveFilter={{
                  filterType: SAVE_FILTER_KEY,
                  infoToSave: { selectedContractList, selectedResponsables },
                  selectCallback: onSelectSavedFilter,
                }}
              />
            </StatisticMoreThanOneApealFiltersWrapper>
          </form>
        </FormProvider>
        {load ? (
          <LoadSpinner />
        ) : (
          <div style={{ height: 400, width: '100%' }}>
            <StatisticMoreThanOneApealDataGrid
              className={'rootTableContainer'}
              rows={data}
              getRowId={(row) => row?.taskNumber}
              columns={StatisticMoreThanOneApealColumns}
              showColumnRightBorder
              showCellRightBorder
              isRowSelectable={() => false}
              disableColumnFilter={true}
              localeText={{ noRowsLabel: 'Нет данных для отображения' }}
              components={{
                Footer: GridFooter,
                Pagination: () => {
                  if (selectedContractList.length && selectedResponsables.length) {
                    return (
                      <TablePagination
                        count={-1}
                        rowsPerPage={filter.pageSize}
                        onRowsPerPageChange={(newPageSize) =>
                          setFilter((filter) => ({
                            ...filter,
                            pageSize: Number(newPageSize.target.value),
                          }))
                        }
                        page={filter.currentPage}
                        labelDisplayedRows={({ from, to }) => (
                          <div style={{ paddingTop: 16 }}>
                            {from} - {to}
                          </div>
                        )}
                        onPageChange={(ev, newPage) =>
                          setFilter((filter) => ({
                            ...filter,
                            currentPage: newPage,
                          }))
                        }
                        nextIconButtonProps={{
                          disabled: !data.length,
                        }}
                        labelRowsPerPage={null}
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      />
                    );
                  }
                  return <></>;
                },
              }}
            />
          </div>
        )}
      </Grid>
    </Paper>
  );
};
