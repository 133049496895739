import { font } from '@/styles/mixins';
import { Grid, styled } from '@mui/material';

export const TaskCommentDeleteModalButtonsWrapper = styled('div')({
  display: 'flex',
  gap: 40,
  justifyContent: 'center',
});

export const TaskCommentGridGray = styled(Grid)(() => ({
  color: 'rgba(0, 0, 0, 0.38)',
  display: 'flex',
  alignItems: 'center',
}));
export const TaskCommentGridGrayRight = styled(TaskCommentGridGray)(() => ({
  textAlign: 'right',
}));

export const TaskCommentDeleteModalTitle = styled('p')`
  ${font('h2')};
  text-align: center;
`;

export const TaskCommentDeleteModalInner = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 30,
});
