import { Typography, Button } from '@mui/material';
import React, { FC, useState } from 'react';
import { usePNRStore } from '../store';
import { ApiResetHouseLayout } from '@/services/YodaRestService';
import { addSuccessActionLog, useActionLog } from '@/hooks/ActionLogHook';
import { LoadingButton } from '@mui/lab';
import CommonModal from '@/components/common/CommonModal/CommonModal';
import shallow from 'zustand/shallow';

interface IEntranceResetAlertModal {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const EntranceResetAlertModal: FC<IEntranceResetAlertModal> = ({ open, setOpen }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { houseData, setLayoutDataFromBackend } = usePNRStore(
    (state) => ({
      houseData: state.houseData,
      setLayoutDataFromBackend: state.setLayoutDataFromBackend,
    }),
    shallow
  );

  const { fetchCatch } = useActionLog();

  const closeModal = () => setOpen(false);

  const resetEntranceData = () => {
    if (houseData) {
      setIsLoading(true);
      ApiResetHouseLayout(houseData.houseId)
        .then(({ data }) => {
          setOpen(false);
          setLayoutDataFromBackend(data);
          addSuccessActionLog('Успешный сброс распределения');
        })
        .catch((error) => fetchCatch(error, 'Ошибка сброса распределения'))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <CommonModal
      open={open}
      onClose={closeModal}
    >
      <Typography
        variant='h6'
        textAlign='center'
      >
        Ранее заполненные данные по подъездам будут удалены. Продолжить?
      </Typography>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 40,
          gap: 20,
        }}
      >
        <LoadingButton
          variant='contained'
          onClick={resetEntranceData}
          loading={isLoading}
        >
          OK
        </LoadingButton>
        <Button onClick={closeModal}>Отмена</Button>
      </div>
    </CommonModal>
  );
};
