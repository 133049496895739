import React, { useEffect, useState } from 'react';
import File from '@/components/features/drive/file/File';
import { useParams } from 'react-router-dom';
import Navigation from '@/components/features/drive/navigation/Navigation';
import Search from '@/components/features/drive/search/Search';
import { GetFiles } from '@/services/DriveService';
import { Loading } from '@/components/ui/Loading';
import { useActionLog } from '@/hooks/ActionLogHook';
import { convertStr } from '@/components/features/drive/utils';
import { DriveWrapper } from './Drive.styled';
import { DriveItem, driveMapToArray } from './utils';

const Files = () => {
  const { fetchCatch } = useActionLog();
  const { groupId, folderId } = useParams();
  const [value, setValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<DriveItem[]>([]);
  const [filterData, setFilterData] = useState<DriveItem[]>([]);

  useEffect(() => {
    setLoading(true);
    groupId &&
      folderId &&
      GetFiles(groupId.split('_')[1], convertStr(folderId))
        .then((res) => {
          setData(driveMapToArray(res.data));
          setFilterData(driveMapToArray(res.data));
        })
        .catch((err) => {
          fetchCatch(err, 'Ошибка загрузки');
        })
        .finally(() => setLoading(false));
  }, []);

  const filter = (str: string) => {
    setValue(str);
    setFilterData(data.filter((elem) => elem.name.toLowerCase().includes(str.toLowerCase())));
  };

  return (
    <>
      <Navigation />
      <Search
        value={value}
        onChange={filter}
      />
      <DriveWrapper>
        <Loading loading={loading}>
          {filterData.map((elem) => (
            <File
              key={elem.name}
              item={elem}
            />
          ))}
        </Loading>
      </DriveWrapper>
    </>
  );
};

export default Files;
