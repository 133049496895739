import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Loading } from '@/components/ui/Loading';
import { TransStationResponse } from '@/dto/taskmap/task/TaskResultDto';
import { TaskResponse } from '@/dto/taskmap/task/TasksDto';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { findTransStationByTaskId, saveTransStationByTaskId } from '@/services/TaskService';
import { useGalleryData } from '@/components/Gallery/store';
import { ItemTP } from './ItemTP';
import { filter } from './utils';
import { useCatalog } from '@/hooks/CatalogHook';

interface PanelProps {
  taskResponse: TaskResponse;
  reloadParent?: () => void;
  reloadParentFlag?: boolean;
}

export const Panel = (props: PanelProps) => {
  const { taskResponse, reloadParentFlag } = props;
  const [tabPane, setTabPane] = useState('1');
  const [transStations, setTransStations] = useState<TransStationResponse[]>();
  const { fetchCatch, addActionLog } = useActionLog();
  const [loadingTransStation, setLoadingTransStation] = useState(false);
  const [reloadFlag, setReloadFlag] = useState(false);

  const clearImageGroups = useGalleryData(({ clearImageGroups }) => clearImageGroups);
  const setTaskType = useGalleryData(({ setTaskType }) => setTaskType);

  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  const inverseReloadFlag = () => {
    setReloadFlag(!reloadFlag);
  };

  const load = () => {
    if (taskResponse) {
      setLoadingTransStation(true);
      findTransStationByTaskId(taskResponse.id)
        .then((res: any) => {
          setTransStations(res.data);
          inverseReloadFlag();
        })
        .catch((err) => fetchCatch(err, 'Ошибка загрузки'))
        .finally(() => setLoadingTransStation(false));
    }
  };

  useEffect(() => {
    load();
  }, [taskResponse, reloadParentFlag]);

  const handleChangeTabPane = (event: React.SyntheticEvent, newTab: string) => {
    setTabPane(newTab);
  };

  useEffect(() => {
    taskResponse?.type && setTaskType(taskResponse?.type);
  }, [reloadFlag]);

  const handleClick = (e: any) => {
    e.stopPropagation();
    clearImageGroups();
  };
  const onSave = (id: string) => (data: any) => {
    const d = data.pillars[id];
    saveTransStationByTaskId(id, filter(d, getCatalogMapWithErr('uspdInstallPlace')))
      .then(() => {
        load();
        addActionLog(ActionLogType.SUCCESS, `Данные успешно сохранениы`);
      })
      .catch((err) => fetchCatch(err, 'Ошибка сохранения'));
  };

  return (
    <Box
      sx={{
        width: '100%',
        typography: 'body1',
        paddingBottom: 2,
      }}
    >
      <TabContext value={tabPane}>
        <Box>
          <TabList
            onChange={handleChangeTabPane}
            centered
            aria-label='result odpu tabs'
          >
            <Tab
              label='ППО'
              value='1'
              onClick={handleClick}
            />
          </TabList>
        </Box>
        <TabPanel
          value='1'
          sx={{ padding: '17px 0 0 0' }}
        >
          <Grid
            container
            spacing={2}
          >
            <Loading loading={loadingTransStation}>
              {transStations?.map((t, index) => (
                <Grid
                  key={t.id}
                  item
                  container
                >
                  <ItemTP
                    onSave={onSave(t.id)}
                    transStation={t}
                    keyStr={`pillars.${t.id}`}
                    contractId={taskResponse?.contractResponse?.id}
                    contractorId={taskResponse.contractorId ?? ''}
                  />
                </Grid>
              ))}
            </Loading>
          </Grid>
        </TabPanel>
      </TabContext>
    </Box>
  );
};
