import React, { useEffect, useState } from 'react';
import Folder from '@/components/features/drive/folder/Folder';
import { useParams } from 'react-router-dom';
import Navigation from '@/components/features/drive/navigation/Navigation';
import Search from '@/components/features/drive/search/Search';
import { GetSmrDate } from '@/services/DriveService';
import { Loading } from '@/components/ui/Loading';
import { useActionLog } from '@/hooks/ActionLogHook';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import IconButton from '@mui/material/IconButton';
import { DriveWrapper, FoldersWrapper, AddButton } from './Drive.styled';
import { DriveItem, driveMapToArray } from './utils';

const LIM = 36;

const Folders = () => {
  const { fetchCatch } = useActionLog();
  const { groupId } = useParams();
  const [value, setValue] = useState<string>('');
  const [offset, setOffset] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const [data, setData] = useState<DriveItem[]>([]);
  const [filterData, setFilterData] = useState<DriveItem[]>([]);

  const getSomeNext = (prev?: boolean) => {
    document.body.style.cursor = 'wait';
    const tmpOffset = prev ? offset - 2 * LIM : offset;
    groupId &&
      GetSmrDate(groupId.split('_')[1], tmpOffset, LIM)
        .then((res) => {
          setOffset(tmpOffset + LIM);
          setData(driveMapToArray(res.data));
          if (data.length > 0) {
            setFilterData(filterFunc(driveMapToArray(res.data)));
          } else {
            setFilterData(driveMapToArray(res.data));
          }
        })
        .catch((err) => {
          fetchCatch(err, 'Ошибка загрузки');
        })
        .finally(() => {
          setLoading(false);
          document.body.style.cursor = 'default';
        });
  };

  useEffect(() => {
    setLoading(true);
    getSomeNext();
  }, []);

  const filterFunc = (arr: DriveItem[]): DriveItem[] =>
    arr.filter((elem) => elem.name.toLowerCase().includes(value.toLowerCase()));

  const filter = (str: string) => {
    setValue(str);
    setFilterData(data.filter((elem) => elem.name.toLowerCase().includes(str.toLowerCase())));
  };

  return (
    <>
      <Navigation />
      <Search
        value={value}
        onChange={filter}
      />
      <DriveWrapper>
        <Loading loading={loading}>
          <FoldersWrapper>
            {filterData.map((elem) => (
              <Folder
                key={elem.name}
                item={elem}
              />
            ))}

            <AddButton>
              <IconButton
                size={'large'}
                onClick={() => getSomeNext(true)}
                disabled={offset - LIM <= 0}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                size={'large'}
                onClick={() => getSomeNext(false)}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </AddButton>
          </FoldersWrapper>
        </Loading>
      </DriveWrapper>
    </>
  );
};

export default Folders;
