import { Link } from 'react-router-dom';
import React from 'react';
import YodaNewLogo from '@/images/icons/YodaNew.svg';
import YodaMobileLogo from '@/images/icons/YodaMobile.svg';
import NavbarBolt from './NavbarBolt/NavbarBolt';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';

const NavbarLogoWithBolt = () => {
  const isMobile = useMobileSize();

  return (
    <>
      <div style={{ display: 'flex', gap: 8 }}>
        <NavbarBolt />
        <Link to='/'>
          <img src={isMobile ? YodaMobileLogo : YodaNewLogo} />
        </Link>
      </div>
    </>
  );
};

export default NavbarLogoWithBolt;
