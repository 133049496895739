import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Paper,
  PaperProps,
  Stack,
  Typography,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { DesktopDatePicker, TextField } from '@/components/fixUI';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';
import { useDisabledAll, useAllowedAccessByRole } from '@/hooks/useAccess';
import { useForm } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import { useCatalog } from '@/hooks/CatalogHook';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { color } from '@/styles/mixins';
import { CreatePu, DuplicatePu } from '@/services/CreatePuService';
import { ReplacementResponseList } from '@/dto/taskmap/task/TaskResultDto';
import Draggable from 'react-draggable';
import { RolesEnum } from '@/app/auth/roles';

const convertMap = (map: Map<string, string>): SelectFilterOptType[] => {
  const res: SelectFilterOptType[] = [];
  map.forEach((val, key) => res.push({ value: key, label: val }));
  return res;
};

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle='#draggable-dialog-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

interface ReplacementPuDialogProps {
  id: string;
  replacement: ReplacementResponseList;
  update?: () => void;
}

export const ReplacementPuDialog: FC<ReplacementPuDialogProps> = ({ update, id, replacement }) => {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const { fetchCatch, addActionLog } = useActionLog();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const { register, handleSubmit, setValue } = useForm();
  const [isDuplicate, setIsDuplicate] = useState(false);

  const onSubmit = (data: any) => {
    setLoading(true);
    !isDuplicate &&
      CreatePu(data[id])
        .then((res) => {
          addActionLog(ActionLogType.SUCCESS, 'ПУ успешно создан');
          setOpen(false);
        })
        .catch((err) => {
          if (err.response.status === 409) {
            setIsDuplicate(true);
            fetchCatch(err, 'ПУ уже существует, попробуйте создать дубликат');
          } else {
            fetchCatch(err, 'Ошибка создания ПУ');
          }
        })
        .finally(() => setLoading(false));
    isDuplicate &&
      DuplicatePu(id, data[id])
        .then((res) => {
          addActionLog(ActionLogType.SUCCESS, 'Дубликат ПУ успешно создан');
          setOpen(false);
        })
        .catch((err) => {
          fetchCatch(err, 'Ошибка создания дубликата ПУ');
        })
        .finally(() => {
          setLoading(false);
          setIsDuplicate(false);
        });
  };

  const [releaseDate, setReleaseDate] = useState<Date | null>(null);
  useEffect(() => {
    if (!releaseDate) {
      setReleaseDate(null);
      setValue(`${id}.releaseDate`, null);
    }
  }, [releaseDate]);
  const onHandleReleaseDate = (newValue: Date | null) => {
    if (newValue) {
      setReleaseDate(newValue);
      setValue(`${id}.releaseDate`, moment(newValue).valueOf());
    }
  };

  const [verificationDate, setVerificationDate] = useState<Date | null>(null);
  useEffect(() => {
    if (!verificationDate) {
      setVerificationDate(null);
      setValue(`${id}.verificationDate`, null);
    }
  }, [verificationDate]);
  const onHandleVerificationDate = (newValue: Date | null) => {
    if (newValue) {
      setVerificationDate(newValue);
      setValue(`${id}.verificationDate`, moment(newValue).valueOf());
    }
  };

  const [nextVerificationDate, setNextVerificationDate] = useState<Date | null>(null);
  useEffect(() => {
    if (!nextVerificationDate) {
      setNextVerificationDate(null);
      setValue(`${id}.nextVerificationDate`, null);
    }
  }, [nextVerificationDate]);
  const onHandleNextVerificationDate = (newValue: Date | null) => {
    if (newValue) {
      setNextVerificationDate(newValue);
      setValue(`${id}.nextVerificationDate`, moment(newValue).valueOf());
    }
  };

  const [selectedMeterType, setSelectedMeterType] = useState<SelectFilterOptType | null>(null);
  useEffect(() => {
    if (!selectedMeterType) {
      setSelectedMeterType(null);
      setValue(`${id}.meterType`, null);
    }
  }, [selectedMeterType]);
  const onHandleSelectedMeterType = (newValue: SelectFilterOptType | null) => {
    if (newValue) {
      setSelectedMeterType(newValue);
      setValue(`${id}.meterType`, newValue.value);
    }
  };

  const clearAll = () => {
    setSelectedMeterType(null);
    setNextVerificationDate(null);
    setVerificationDate(null);
    setReleaseDate(null);
    setValue(`${id}.imei`, null);
    setValue(`${id}.mac`, '');
    setValue(`${id}.number`, '');
    setValue(`${id}.networkAdress`, '');
    setValue(`${id}.accClass`, '');
  };

  const { getCatalogMapWithErr, updateCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    updateCatalogMapWithErr: state.updateCatalogMapWithErr,
  }));

  const onCancel = () => {
    clearAll();
    setOpen(false);
    setIsDuplicate(false);
  };

  const onOpen = () => {
    setOpen(true);
    setValue(`${id}.imei`, replacement.installedPuImei);
    setValue(`${id}.mac`, replacement.installedPuMac);
    setValue(`${id}.number`, replacement.installedPuNumber);
  };

  const showCreatePuButton = useAllowedAccessByRole({
    allowRoles: [RolesEnum.ProjectManager, RolesEnum.Leader],
  });

  return (
    <>
      {showCreatePuButton && (
        <Button
          variant='text'
          size='small'
          onClick={onOpen}
          disabled={disabledAll || onlyShow}
        >
          Создать ПУ
        </Button>
      )}
      <Dialog
        open={open}
        onClose={onCancel}
        fullWidth
        maxWidth={'lg'}
        PaperComponent={PaperComponent}
        aria-labelledby='draggable-dialog-title'
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => 1300 }}
          open={loading}
        >
          <CircularProgress color='inherit' />
        </Backdrop>

        <Stack
          spacing={3}
          padding={4}
          style={{ cursor: 'move' }}
          id='draggable-dialog-title'
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sx={{ textAlign: 'center' }}
            >
              <Typography variant={'h4'}>Добавить прибор учета</Typography>
            </Grid>
            <Grid
              item
              xs={4}
            >
              <Autocomplete
                disablePortal
                value={selectedMeterType}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                options={convertMap(getCatalogMapWithErr('meterType'))}
                // options={puTypesOptions}
                onChange={(e, value) => {
                  onHandleSelectedMeterType(value);
                  // setSelectedMeterType(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Тип ПУ'
                    variant='standard'
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <TextField
                label='IMEI'
                disabled={disabledAll || onlyShow}
                variant='standard'
                sx={{ width: '100%' }}
                {...register(`${id}.imei`)}
              />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <TextField
                label='MAC'
                disabled={disabledAll || onlyShow}
                variant='standard'
                sx={{ width: '100%' }}
                {...register(`${id}.mac`)}
              />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <TextField
                label='Заводской №'
                disabled={disabledAll || onlyShow}
                variant='standard'
                sx={{ width: '100%' }}
                {...register(`${id}.number`)}
              />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <TextField
                label='Сетевой адрес'
                disabled={disabledAll || onlyShow}
                variant='standard'
                sx={{ width: '100%' }}
                {...register(`${id}.networkAdress`)}
              />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <TextField
                label='Класс точности'
                disabled={disabledAll || onlyShow}
                variant='standard'
                sx={{ width: '100%' }}
                {...register(`${id}.accClass`)}
              />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  {...register(`${id}.releaseDate`)}
                  label='Дата выпуска'
                  inputFormat='dd.MM.yyyy'
                  value={releaseDate}
                  onChange={onHandleReleaseDate}
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: '100%', mt: 1 }}
                      {...params}
                      variant='standard'
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={4}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  {...register(`${id}.verificationDate`)}
                  label='Дата поверки'
                  inputFormat='dd.MM.yyyy'
                  value={verificationDate}
                  onChange={onHandleVerificationDate}
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: '100%', mt: 1 }}
                      {...params}
                      variant='standard'
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={4}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  {...register(`${id}.nextVerificationDate`)}
                  label='Дата следующей поверки'
                  inputFormat='dd.MM.yyyy'
                  value={nextVerificationDate}
                  onChange={onHandleNextVerificationDate}
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: '100%', mt: 1 }}
                      {...params}
                      variant='standard'
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ textAlign: 'center' }}
            >
              {isDuplicate && (
                <>
                  <WarningAmberRoundedIcon
                    fontSize={'large'}
                    htmlColor={color('warningRed')}
                  />
                  <Typography
                    textAlign={'center'}
                    variant='body1'
                    mb={3}
                    mt={3}
                    sx={{ color: color('warningRed') }}
                  >
                    Внимание! Созданный Вами прибор учета уже существует в базе YODA! Нажав кнопку
                    &quot;Создать дубль&quot; Вы даете согласие на создание дубля данного прибора
                    учета
                  </Typography>
                  <Typography
                    textAlign={'center'}
                    variant='body1'
                  >
                    Процесс создания дубля: Мы предполагаем, что возникла ошибка в указании
                    MAC-адреса или заводского номера на корпусе ПУ. Для исправления мы заменим
                    последние пять символов в MAC-адресе на &quot;ЕЕЕЕЕ&quot;, а к номеру ПУ в конце
                    добавим эти символы (&quot;ЕЕЕЕЕ&quot;). Например, если был MAC:
                    00000000000F0D00 № 00000000, то будет заменено на: 00000000000ЕЕЕЕЕ №
                    00000000ЕЕЕЕЕ.
                  </Typography>
                </>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ textAlign: 'center' }}
            >
              <Button
                onClick={handleSubmit(onSubmit)}
                autoFocus
                sx={{ mr: 4 }}
                // disabled={disabledAll}
              >
                {isDuplicate ? 'Создать дубль' : 'Сохранить'}
              </Button>
              <Button onClick={onCancel}>Отмена</Button>
            </Grid>
          </Grid>
        </Stack>
      </Dialog>
    </>
  );
};
