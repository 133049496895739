import React, { useEffect, useState } from 'react';
import { DataTypeConvert, defaultArgInput as defaultArgInput_ } from './utils';
import { IInput, IQureInput } from './types';
import {
  Checkbox,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  IconButton,
  Stack,
  Box,
  TextField,
} from '@mui/material';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { generateDetailedInfo } from '@/components/button/DetailedInfo/utils';
import { useSaveFilterButton } from '@/components/filter/FilterButtons/utils';
import { SelectFilterOptType } from '../MultiSelectNew/types';
import RenderInputs from './DynamicInputs';
import {
  FilterComboErrorTypes,
  IFilterCombo,
  useStoredFilters,
} from '@/components/button/SaveButton/store';
import { useActionLog } from '@/hooks/ActionLogHook';
import {
  ButtonsGrid,
  ContainerForInputs,
  FieldsList,
  MainWrapper,
} from './CustomeStyleDynamicFilter.styled';
import CloseIcon from '@mui/icons-material/Close';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { TasksBrowseDragIconWrapper } from '@/components/features/tasksBrowse/TasksBrowseDragOrder/TasksBrowseDragOrder.styled';
import { reorder } from '@/components/features/tasksBrowse/TasksBrowseDragOrder/utils';

const sortFunction = (x: DataTypeConvert, y: DataTypeConvert) =>
  Number(y.isActive) - Number(x.isActive);

type DynamicInputProps = {
  data: DataTypeConvert[];
  callBackFn: (queryFields: any, filterSetting: any) => void;
  searchFn: () => void;
  getCatalogList?: (key: string) => { label: string; value: string }[];
  saveFilterKey: string;
  defaultArgInput?: any;
  disabledSearch?: boolean;
  children?: JSX.Element;
  justifyContent?: string;
};

function DynamicInput({
  data = [],
  callBackFn,
  searchFn,
  saveFilterKey,
  defaultArgInput = defaultArgInput_,
  getCatalogList,
  disabledSearch,
  children,
  justifyContent = 'center',
}: DynamicInputProps) {
  const { addTmpFilters, tmpFilter } = useStoredFilters();
  const arr = tmpFilter[saveFilterKey] || {};
  const [queryFields, setQueryFields] = useState<IQureInput>({});

  const [filterSetting, setFilterSettings] = useState<DataTypeConvert[]>(
    arr.filterSetting ? (arr.filterSetting as DataTypeConvert[]) : []
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [searchTerm, setSearchTerm] = useState('');

  const withChild = !!children;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const fixData = data.map((elem) => {
    const tmpData = arr.filterSetting as DataTypeConvert[];
    const tmpElem = tmpData?.find((el) => el.id === elem.id);
    if (tmpElem) {
      return { ...elem, isActive: tmpElem.isActive };
    }
    return elem;
  });

  const [checked, setChecked] = React.useState<DataTypeConvert[]>(fixData);
  const [filteredFields, setFilteredFields] = React.useState<DataTypeConvert[]>(fixData);

  useEffect(() => {
    callBackFn && callBackFn(queryFields, filterSetting);
  }, [filterSetting, queryFields]);

  const handleToggle = (item: IInput) => () => {
    const newChecked = checked.map((elem) =>
      elem.value === item.value ? { ...elem, isActive: !elem.isActive } : elem
    );
    setChecked([...newChecked].sort(sortFunction));
    setFilteredFields([...newChecked].sort(sortFunction));
    setSearchTerm('');
    setFilterSettings([...newChecked].sort(sortFunction).filter((el) => el.isActive));
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;
    const newItems = reorder(checked, source.index, destination.index);
    setChecked(newItems.sort(sortFunction));
    setFilteredFields(newItems.sort(sortFunction));
    setSearchTerm('');
    setFilterSettings(newItems.sort(sortFunction).filter((el) => el.isActive));
  };

  const deleteInput = (item: IInput) => {
    const newChecked = checked.map((elem) =>
      elem.value === item.value ? { ...elem, isActive: false } : elem
    );
    setChecked([...newChecked].sort(sortFunction));
    setFilteredFields([...newChecked].sort(sortFunction));
    setFilterSettings([...newChecked].sort(sortFunction).filter((el) => el.isActive));
  };

  const clearAll1 = () => {
    // удаляет поля и значения
    const allCler = [...checked].sort(sortFunction).map((elem) => ({ ...elem, isActive: false }));
    setChecked(allCler);
    setFilteredFields(allCler);
    setFilterSettings([...allCler].sort(sortFunction).filter((el) => el.isActive));
  };

  const clearAll = () => {
    // очищает значения полеей
    setQueryFields({});
  };

  const dataToShowInEye: [SelectFilterOptType[], string][] = Object.entries(queryFields).map(
    ([key, value]) => {
      return [
        value?.values?.map((el: any) => ({ label: el.label || el, value: '' })),
        data.find((el: any) => el?.value === key)?.label || 'Не найдено',
      ];
    }
  );

  const detailedInfo = generateDetailedInfo(...dataToShowInEye);

  const { getFilters } = useSaveFilterButton(saveFilterKey);

  const onSelectSavedFilter = (filterKey: string) => {
    const savedFilters = getFilters<any[]>(filterKey);
    const dataForQ: Record<string, any> = {};
    savedFilters.filterSetting.forEach((el) => {
      if (!dataForQ[el.id]) {
        dataForQ[el.id] = savedFilters[el.id];
      }
    });
    Object.keys(dataForQ).length && setQueryFields(dataForQ);
    savedFilters.filterSetting && setFilterSettings(savedFilters.filterSetting);
    if (savedFilters.checked) {
      setChecked(savedFilters.checked);
      setFilteredFields(savedFilters.checked);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      const filteredBySearchTerm = checked.filter((field) =>
        field.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredFields(filteredBySearchTerm);
    } else {
      setFilteredFields(filteredFields);
    }
  }, [searchTerm]);

  const { fetchCatch } = useActionLog();

  const saveFilter = {
    filterType: saveFilterKey,
    infoToSave: {
      ...queryFields,
      filterSetting,
      checked,
    },
    selectCallback: onSelectSavedFilter,
  };

  const onChange = () => {
    try {
      addTmpFilters(saveFilter.filterType, saveFilter?.infoToSave as IFilterCombo);
    } catch (error) {
      if (error instanceof Error && error.message) {
        if (error?.message === FilterComboErrorTypes.NO_INFO) {
          fetchCatch(error, FilterComboErrorTypes.NO_INFO);
        } else {
          fetchCatch(error, FilterComboErrorTypes.DUPLICATE);
        }
      }
    }
  };

  useEffect(() => {
    setQueryFields(queryFields);
  }, [queryFields, filterSetting]);

  useEffect(() => {
    onChange();
  }, [filterSetting]);
  const isMobile = useMobileSize();

  return (
    <MainWrapper
      container
      justifyContent={justifyContent}
    >
      <ContainerForInputs
        item
        container
        xs={withChild ? 12 : 9}
      >
        {filterSetting.map((input) => (
          <RenderInputs
            key={input.value}
            input={input}
            queryFields={queryFields}
            defaultArgInput={defaultArgInput}
            setQueryFields={setQueryFields}
            getCatalogList={getCatalogList}
          />
        ))}
      </ContainerForInputs>

      <ButtonsGrid
        item
        my={1}
        withChild={withChild}
      >
        {withChild && (
          <Grid
            item
            xs={10}
            mt={2}
          >
            {children}
          </Grid>
        )}
        <FilterButtons
          addFilterInput={handleClick}
          onSearchClick={searchFn}
          info={detailedInfo}
          onCloseClick={clearAll}
          disable={!disabledSearch}
          saveFilter={saveFilter}
        />

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Stack minWidth={300}>
            {isMobile && (
              <Stack alignItems={'end'}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Stack>
            )}

            <Box padding={1}>
              <TextField
                inputRef={(input) => input && input.focus()}
                fullWidth
                id='standard-basic'
                label='Введите текст'
                variant='outlined'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>
            <DragDropContext onDragEnd={onDragEnd}>
              <FieldsList>
                <Droppable droppableId='droppable-list-dynamic-input'>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {filteredFields.map((item, index) => {
                        const labelId = `checkbox-list-label-${item.value}`;

                        return (
                          <Draggable
                            key={item.value}
                            draggableId={item.value}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <ListItem disablePadding>
                                  <TasksBrowseDragIconWrapper {...provided.dragHandleProps}>
                                    <DragHandleIcon />
                                  </TasksBrowseDragIconWrapper>

                                  <ListItemButton
                                    onClick={handleToggle(item)}
                                    dense
                                  >
                                    <ListItemIcon>
                                      <Checkbox
                                        edge='start'
                                        checked={item.isActive}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      id={labelId}
                                      primary={item?.label}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                  )}
                </Droppable>
              </FieldsList>
            </DragDropContext>
          </Stack>
        </Popover>
      </ButtonsGrid>
    </MainWrapper>
  );
}

export default React.memo(DynamicInput);
