import { Autocomplete, Button, TextField } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useCatalog } from '@/hooks/CatalogHook';
import styles from './Popup.module.scss';
import { ApiAssignContractorRequest } from '@/services/YodaRestService';
import { useActionLog } from '@/hooks/ActionLogHook';
import { IPopup, Popup } from './Popup';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';

interface Props extends IPopup {
  taskIds: string[];
}

type AssignContractorPopupFormValues = {
  contractorId: string;
};

export const AssignContractorPopup: React.FC<Props> = (props) => {
  const { taskIds, onClose, onSuccess } = props;
  const { control, handleSubmit, reset } = useForm<AssignContractorPopupFormValues>();
  const { fetchCatch } = useActionLog();
  const { taskGroupMap, getGroupCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
    taskGroupMap: state.taskGroupMap,
  }));
  const options = useMemo(() => taskMapToArray(getGroupCatalog()), [taskGroupMap]);

  useEffect(() => {
    reset();
  }, [props.isOpen]);

  const onSubmit = handleSubmit((values) => {
    ApiAssignContractorRequest({
      taskIds,
      ...values,
    })
      .then(() => {
        onClose();
        onSuccess && onSuccess();
      })
      .catch((error) => {
        fetchCatch(error);
      });
  });

  return (
    <Popup {...props}>
      <div className={styles.item}>
        <span>Подрядчик</span>
        <Controller
          control={control}
          name='contractorId'
          render={({ field }) => (
            <Autocomplete
              style={{ width: '40%' }}
              options={options}
              value={{
                value: field?.value ?? '',
                label: getGroupCatalog()?.get(field?.value) ?? '',
              }}
              onChange={(event: any, newValue: any) => {
                field?.onChange(newValue?.value);
              }}
              renderInput={(params) => (
                <TextField
                  name='contractorId'
                  {...params}
                  label={'Подрядчик'}
                  variant='standard'
                />
              )}
            />
          )}
        />
      </div>
      <div className={styles['modal-buttons']}>
        <Button
          onClick={onSubmit}
          variant='contained'
        >
          Назначить
        </Button>
      </div>
    </Popup>
  );
};
