import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getComparator, stableSort } from '../constants';
import { IRegistryResultItem, useRegistryResultStore } from './store';
import { TableHeader } from './TableHeader';
import { TableToolbar } from './TableToolbar';
import { FC, useEffect } from 'react';
import { useCatalog } from '@/hooks/CatalogHook';
import { OnRequestSort } from '../TableCells';

interface IResultTable {
  id?: string;
}

export const ResultTable: FC<IResultTable> = ({ id }) => {
  const {
    registryResultTable,
    selected,
    orderBy,
    order,
    setSelected,
    setOrderBy,
    setOrder,
    updateTable,
  } = useRegistryResultStore((state) => ({
    registryResultTable: state.registryResultTable,
    order: state.order,
    orderBy: state.orderBy,
    selected: state.selected,
    setOrder: state.setOrder,
    setOrderBy: state.setOrderBy,
    setSelected: state.setSelected,
    updateTable: state.updateTable,
  }));

  const { getCatalogMapWithErr, getGroupCatalog } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    getGroupCatalog: state.getGroupCatalog,
  }));

  useEffect(() => {
    if (id) {
      updateTable(id);
    }
  }, [id]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IRegistryResultItem
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[];

    if (selectedIndex === -1) {
      newSelected = [...selected, name];
    } else {
      newSelected = selected.filter((el, ind) => ind !== selectedIndex);
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  return (
    <Paper>
      <TableToolbar
        numSelected={selected.length}
        id={id ? id : ''}
      />
      <TableContainer sx={{ maxHeight: '600px', overflow: 'scroll' }}>
        <Table
          aria-labelledby='tableTitle'
          size={'small'}
        >
          <TableHeader
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort as OnRequestSort}
          />

          <TableBody>
            {!registryResultTable.length && (
              <TableRow>
                <TableCell
                  colSpan={7}
                  align={'center'}
                >
                  Пусто
                </TableCell>
              </TableRow>
            )}
            {stableSort<IRegistryResultItem>(
              registryResultTable,
              getComparator(order, orderBy)
            ).map((row, index) => {
              const isItemSelected = isSelected(row.id);
              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row.id)}
                  role='checkbox'
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>{getCatalogMapWithErr('taskType').get(row.type)}</TableCell>
                  <TableCell>{row.adress}</TableCell>
                  <TableCell>{row.data}</TableCell>
                  <TableCell>{getGroupCatalog()?.get(row.executor) ?? ''}</TableCell>
                  <TableCell>
                    {(row?.responsible && getGroupCatalog()?.get(row.responsible)) || ''}
                  </TableCell>
                  <TableCell>
                    {row?.managementCompany &&
                      getCatalogMapWithErr('odpuManagementCompany').get(`${row.managementCompany}`)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
