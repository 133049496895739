import { GridFooter, ruRU } from '@mui/x-data-grid';
import styles from './TasksBrowse.module.scss';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ApiGetRequests, ApiGetRequestsCount, ApiCancelRequest } from '@/services/YodaRestService';
import {
  AssignContractorPopup,
  AssignContractPopup,
  AssignExecAndDatePopup,
  CopyPopup,
  ReAssignContractorPopup,
  CreateColumnPopup,
} from '@/components/popups';
import { useActionLog } from '@/hooks/ActionLogHook';
import { CancelPopup } from '@/components/popups/CancelPopup';
import { useTasksBrowseFilter } from './store';
import { converter, FilterVariants } from './utils';
import { HelpFilter } from './HelpFilter/HelpFilter';
import { MainFilter } from './MainFilter/MainFilterDynamic';
import { LoadingVectorDiagramsPopup } from '@/components/popups/LoadingVectorDiagramsPopup';
import shallow from 'zustand/shallow';
import TasksBrowseNewPagination from './TasksBrowseNewPagination/TasksBrowseNewPagination';
import { DataGrid } from './TasksBrowseNew.styled';
import SelectAllPopup from '@/components/popups/SelectAllPopup/SelectAllPopup';
import { ReturnTaskBackPopup } from '@/components/popups/ReturnTaskBackPopup';
import TasksBrowseToolbar from './TasksBrowseToolbar/TasksBrowseToolbar';
import { useColumnsWithAccess } from '@/components/features/tasksBrowse/hooks/useColumnsWithAccess';
import { useCol } from './storeCol';
import { useHackedDataGridPro } from '@/hooks/useHackedDataGridPro';
import { IQureFilter } from '@/components/statistics/StatisticWithDateForRP/types';
import { DataTypeConvert } from '@/components/inputs/DynamicFilter/utils';
import { converToNumber } from '@/components/statistics/StatisticWithDateForRP/utils';
import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';
import { SelectAllCipherPopup } from '@/components/popups/SelectAllCipherPopup';
import { AddTagPopup } from '@/components/Tags/AddTagPopup';

export const DEFAULT_COUNT = 10000000;

export const TasksBrowse: FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  useHackedDataGridPro(); // get rid of license key dataGridPro

  useEffect(() => {
    function keydownHandler(ev: KeyboardEvent) {
      if (ev.key === 'Enter') {
        onSubmit(anchorEl ? FilterVariants.SECOND : FilterVariants.MAIN);
      }
      if (ev.code == 'KeyA' && (ev.ctrlKey || ev.metaKey)) {
        if (document?.activeElement?.tagName !== 'INPUT') {
          ev.preventDefault();
          setCheckedRows(items.map((elem) => elem.id));
        }
      }
    }
    document.addEventListener('keydown', keydownHandler, false);
    return function cleanup() {
      document.removeEventListener('keydown', keydownHandler, false);
    };
  });

  const [isLoading, setIsLoading] = useState(false);
  const [first, setFirst] = useState(true);
  const [isLoadingTotal, setIsLoadingTotal] = useState(false);
  const [totalRows, setTotalRows] = useState(DEFAULT_COUNT);

  const [pageSize, setPageSize] = useState(10);

  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [showCopyPopup1, setShowCopyPopup1] = useState(false);
  const [showCopyPopup2, setShowCopyPopup2] = useState(false);
  const [showCopyPopup3, setShowCopyPopup3] = useState(false);
  const [showAssignContractorPopup, setShowAssignContractorPopup] = useState(false);
  const [showAssignContractPopup, setShowAssignContractPopup] = useState(false);
  const [showReturnBackPopup, setShowReturnBackPopup] = useState(false);
  const [showChiperPopup, setShowChiperPopup] = useState(false);
  const [showReAssignContractPopup, setShowReAssignContractPopup] = useState(false);
  const [showExecAndDatePopup, setShowExecAndDatePopup] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [showLoadingVectorDiagrams, setShowLoadingVectorDiagrams] = useState(false);
  const [showCreateColumn, setShowshowCreateColumn] = useState(false);
  const [showSelectAllPopup, setShowSelectAllPopup] = useState(false);
  const [showAddTagPopup, setShowAddTagPopup] = useState(false);
  const [selectAllCounter, setSelectAllCounter] = useState<number | null>(null);

  const { fetchCatch } = useActionLog();
  const [activeFilters, setActiveFilters] = useState<any>({});

  const [checkedRows, setCheckedRows] = useState<string[]>([]);

  const [currentPage, setCurrentPage] = useState(0);
  const { columns } = useCol((state) => ({
    columns: state.columns,
  }));

  const [items, setItems] = useState<any[]>([]);

  const setDefaultValue = useTasksBrowseFilter((state) => state.setDefaultValue, shallow);

  const getFiltreItems = () => {
    ApiGetRequests({
      page: currentPage,
      filters: activeFilters,
      pageSize: pageSize,
    })
      .then(({ data }) => {
        setItems(
          data.map((item: any) => ({
            ...item,
            id: item.taskId,
          }))
        );
      })
      .catch((error) => {
        fetchCatch(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    setFirst(false);
  };
  const getFiltreCount = () => {
    ApiGetRequestsCount(activeFilters)
      .then(({ data }) => {
        setTotalRows(data);
      })
      .catch((error) => {
        fetchCatch(error);
      })
      .finally(() => setIsLoadingTotal(false));
  };

  const getItems = () => {
    getFiltreItems();
    getFiltreCount();
  };

  useEffect(() => {
    if (!first) {
      setIsLoading(true);
      setIsLoadingTotal(true);
      getFiltreItems();
      getFiltreCount();
    }
  }, [activeFilters]);

  useEffect(() => {
    setFirst(false);
  }, [currentPage, pageSize]);
  useEffect(() => {
    if (!first) {
      setIsLoading(true);
      getFiltreItems();
    }
  }, [currentPage, pageSize]);

  const handleReset = () => {
    setActiveFilters({});
    setCurrentPage(0);
    setDefaultValue();
  };

  const [filterParamsToSend, setFilterParamsToSend] = useState<IQureFilter[]>([]);

  const callBackFn = (queryFields: any, filterSetting: DataTypeConvert[]) => {
    const objectKeys = Object.keys(queryFields);
    const kyes = filterSetting.map((el: any) => el.value);
    objectKeys.forEach((key) => {
      if (!kyes.includes(key)) {
        delete queryFields[key];
      }
    });
    const result = Object.keys(queryFields)
      .filter(
        (item) =>
          queryFields[item]?.values?.length && queryFields[item]?.values.some((el: any) => el)
      )
      .map((el) => ({
        name: el,
        arg: queryFields[el]?.arg,
        values: queryFields[el]?.values.map((val: string) =>
          converToNumber(queryFields[el]?.type) ? +val : val
        ),
        type: queryFields[el]?.type,
      }));
    setFilterParamsToSend(result);
  };

  const onSubmit = (variant: FilterVariants) => {
    let fixedValues;
    const o = useTasksBrowseFilter.getState();

    if (variant === FilterVariants.SECOND) {
      handleClearCheckedRows();
      fixedValues = converter({ o, variant });
    }
    if (variant === FilterVariants.MAIN) {
      fixedValues = filterParamsToSend?.reduce(
        (res, current) => {
          if (current.name === 'accountingNum') {
            if (
              current.arg === FilterArgEnum.IN_LIST ||
              current.arg === FilterArgEnum.NOT_IN_LIST
            ) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const str = current.values[0] as string;
              return {
                ...res,
                accountingNumList: str.split(','),
                [`${current.name}Arg`]: current.arg,
              };
            }
            return {
              ...res,
              accountingNumList: current.values,
              [`${current.name}Arg`]: current.arg,
            };
          } else {
            let tmp;
            if (current.type === 'DATE') {
              tmp = current.values;
            } else if (current.type === 'TAG') {
              tmp = current.values.map((elem) => elem.value);
            } else if (
              (current.type === 'TEXT' &&
                (current.arg === FilterArgEnum.NOT_IN_LIST ||
                  current.arg === FilterArgEnum.IN_LIST)) ||
              current.name === 'existingPuNum'
            ) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const str = current.values[0] as string;
              tmp = str.split(',');
            } else if (current.name === 'tpName' || current.name === 'cipher') {
              tmp = [current.values[0]];
            } else {
              tmp = current.values[0];
            }

            if (
              current.name === 'electricNetworksEnterpriseId' ||
              current.name === 'cipher' ||
              current.name === 'tpName' ||
              current.name === 'odpuStage' ||
              current.name === 'regionalElectricNetworkId' ||
              current.name === 'ppoState'
            ) {
              return {
                ...res,
                [`${current.name}List`]: tmp,
                [`${current.name}Arg`]: current.arg,
              };
            }
            if (current.name === 'tagList') {
              return {
                ...res,
                [`${current.name}`]: tmp,
                [`tagsArg`]: current.arg,
              };
            }
            return {
              ...res,
              [current.name]: tmp,
              [`${current.name}Arg`]: current.arg,
            };
          }
        },
        {
          isShowingNotRelevant: o.isShowingNotRelevant,
          onlyWithCallCenter: o.onlyWithCallCenter,
          onlyWithChanges: o.onlyWithChanges,
          onlyWithCreationConfirmation: o.onlyWithCreationConfirmation,
          onlyWithTko: o.onlyWithTko,
        }
      );
    }

    setActiveFilters(fixedValues);
    setCheckedRows([]);
    setCurrentPage(0);
    setFirst(false);
  };

  const onSuccessCancel = () => {
    ApiCancelRequest(checkedRows)
      .then(() => {
        setIsLoading(true);
        setIsLoadingTotal(true);
        setShowCancelPopup(false);
        getItems();
      })
      .catch((error) => fetchCatch(error));
  };

  const updateCount = () => {
    setIsLoadingTotal(true);
    ApiGetRequestsCount(activeFilters)
      .then(({ data }) => setTotalRows(data))
      .catch((error) => fetchCatch(error))
      .finally(() => setIsLoadingTotal(false));
  };

  const getFirstCheckedRowValueByKey = (key = 'contractId') => {
    if (checkedRows.length === 0) {
      return;
    }
    const firstCheckedItem = items.find((elem) => elem.taskId === checkedRows[0]);
    if (!firstCheckedItem || firstCheckedItem[key] === undefined) {
      return; // проверка на null или undefined
    }
    return firstCheckedItem[key];
  };

  useEffect(() => {
    const scrollToTop = () => {
      const virtualScroller = document.querySelector(
        '.MuiDataGrid-virtualScroller'
      ) as HTMLElement | null;
      if (virtualScroller) {
        virtualScroller.scrollTop = 0; // Прокручиваем в верхнюю часть
        virtualScroller.style.overflowY = 'scroll';
      }
    };
    if (currentPage >= 1 && items.length > 20) {
      scrollToTop();
    }
  }, [currentPage, items]);

  const onCloseCreateColumn = useCallback(() => setShowshowCreateColumn(false), []);
  const onCloseShowAllPopup = () => setShowSelectAllPopup(false);
  const onCloseShowAllChiperPopup = () => setShowChiperPopup(false);
  const handleShowAllPopupSuccess = (ids?: string[]) => {
    setSelectAllCounter(ids?.length ?? null);
    setCheckedRows(ids ?? []);
    setShowSelectAllPopup(false);
  };
  const handleClearCheckedRows = () => {
    setCheckedRows([]);
    setSelectAllCounter(null);
  };

  const { colmnsWithAccessAndRenderCell } = useColumnsWithAccess();

  const memoColmnsToPreserveStateCol = useMemo(
    () => colmnsWithAccessAndRenderCell,
    [columns, colmnsWithAccessAndRenderCell]
  );

  return (
    <>
      <div className={styles.container}>
        <MainFilter
          onSubmit={() => onSubmit(FilterVariants.MAIN)}
          isLocked={Boolean(anchorEl)}
          callBackFn={callBackFn}
        >
          <HelpFilter
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            onSubmit={() => onSubmit(FilterVariants.SECOND)}
          />
        </MainFilter>
        <div style={{ marginTop: '10px', height: '6000px' }}>
          <DataGrid
            checkboxSelection
            keepNonExistentRowsSelected
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            disableColumnFilter
            onSelectionModelChange={(model: any, info) => setCheckedRows(model)}
            selectionModel={checkedRows}
            loading={isLoading}
            onRowDoubleClick={(el) => window.open(`/task/edit/${el.id}`)}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            columns={memoColmnsToPreserveStateCol}
            showCellRightBorder
            showColumnRightBorder
            disableColumnMenu
            rows={items}
            componentsProps={{
              header: {
                checkPopupError: !!checkedRows.length,
                setShowCopyPopup: setShowCopyPopup,
                setShowCopyPopup1: setShowCopyPopup1,
                setShowCopyPopup2: setShowCopyPopup2,
                setShowCopyPopup3: setShowCopyPopup3,
                setShowAssignContractorPopup: setShowAssignContractorPopup,
                setShowAssignContractPopup: setShowAssignContractPopup,
                setShowReturnBackPopup: setShowReturnBackPopup,
                setShowReAssignContractPopup: setShowReAssignContractPopup,
                setShowExecAndDatePopup: setShowExecAndDatePopup,
                setShowCancelPopup: setShowCancelPopup,
                setShowLoadingVectorDiagrams: setShowLoadingVectorDiagrams,
                setShowshowCreateColumn: setShowshowCreateColumn,
                setShowChiperPopup: setShowChiperPopup,
                setShowAddTagPopup: setShowAddTagPopup,
              },
            }}
            pagination
            components={{
              Header: TasksBrowseToolbar,
              Footer: GridFooter,
              Pagination: () => (
                <TasksBrowseNewPagination
                  checkedRows={checkedRows}
                  clearCheckedRows={handleClearCheckedRows}
                  totalRows={totalRows}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  onRowsPerPageChange={(newPageSize) =>
                    setPageSize(Number(newPageSize.target.value))
                  }
                  isLoadingTotal={isLoadingTotal}
                  onPageChange={(ev, newPage) => setCurrentPage(newPage)}
                  updateCount={updateCount}
                  selectAllPopupOpen={() => setShowSelectAllPopup(true)}
                  selectAllCounter={selectAllCounter}
                />
              ),
            }}
          />
        </div>
      </div>
      <>
        <CancelPopup
          onSuccess={onSuccessCancel}
          isOpen={showCancelPopup}
          onClose={() => setShowCancelPopup(false)}
        />
        {showCopyPopup && (
          <CopyPopup
            onSuccess={getItems}
            taskIds={checkedRows}
            isOpen={showCopyPopup}
            contractId={getFirstCheckedRowValueByKey()}
            onClose={() => setShowCopyPopup(false)}
          />
        )}
        {showCopyPopup1 && (
          <CopyPopup
            onSuccess={getItems}
            taskIds={checkedRows}
            isOpen={showCopyPopup1}
            taskType={'COMPLAINT_MKD'}
            contractId={getFirstCheckedRowValueByKey()}
            onClose={() => setShowCopyPopup1(false)}
          />
        )}
        {showCopyPopup2 && (
          <CopyPopup
            onSuccess={getItems}
            taskIds={checkedRows}
            isOpen={showCopyPopup2}
            taskType={'REPROGRAMMING'}
            contractId={getFirstCheckedRowValueByKey()}
            onClose={() => setShowCopyPopup2(false)}
          />
        )}
        {showCopyPopup3 && (
          <CopyPopup
            onSuccess={getItems}
            taskIds={checkedRows}
            isOpen={showCopyPopup3}
            contractId={getFirstCheckedRowValueByKey()}
            taskType={'SBSD'}
            onClose={() => setShowCopyPopup3(false)}
          />
        )}
        <AssignContractorPopup
          onSuccess={getItems}
          taskIds={checkedRows}
          isOpen={showAssignContractorPopup}
          onClose={() => setShowAssignContractorPopup(false)}
        />
        <ReAssignContractorPopup
          onSuccess={getItems}
          taskIds={checkedRows}
          isOpen={showReAssignContractPopup}
          onClose={() => setShowReAssignContractPopup(false)}
        />
        {showAssignContractPopup && (
          <AssignContractPopup
            onSuccess={getItems}
            taskIds={checkedRows}
            isOpen={showAssignContractPopup}
            onClose={() => setShowAssignContractPopup(false)}
          />
        )}
        <AssignExecAndDatePopup
          onSuccess={getItems}
          taskIds={checkedRows}
          isOpen={showExecAndDatePopup}
          onClose={() => setShowExecAndDatePopup(false)}
        />
        {showLoadingVectorDiagrams && (
          <LoadingVectorDiagramsPopup
            onSuccess={getItems}
            isOpen={showLoadingVectorDiagrams}
            onClose={() => setShowLoadingVectorDiagrams(false)}
          />
        )}
        <CreateColumnPopup
          isOpen={showCreateColumn}
          headerText={'Внесите данные'}
          successText='Сохранить'
          onClose={onCloseCreateColumn}
        />

        <SelectAllPopup
          isOpen={showSelectAllPopup}
          onClose={onCloseShowAllPopup}
          filters={activeFilters}
          onSuccess={handleShowAllPopupSuccess}
        />
        <SelectAllCipherPopup
          isOpen={showChiperPopup}
          onClose={onCloseShowAllChiperPopup}
          taskIds={checkedRows}
          onSuccess={handleShowAllPopupSuccess}
        />
        <ReturnTaskBackPopup
          onSuccess={getItems}
          taskIds={checkedRows}
          isOpen={showReturnBackPopup}
          singleExecutorId={getFirstCheckedRowValueByKey('executorId')}
          onClose={() => setShowReturnBackPopup(false)}
        />
        <AddTagPopup
          isOpen={showAddTagPopup}
          onClose={() => setShowAddTagPopup(false)}
          taskIds={checkedRows}
        />
      </>
    </>
  );
};
